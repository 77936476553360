import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Table from "components/Admin/ArchivedBatches/ArchivedBatches";

import { useDispatch, useSelector } from "react-redux";
import {
  getWaitingBatches,
  reAssignRecycler,
  getRecyclersList,
  approveBatch,
  rejectBatch,
} from "store/actions/archivedBatches";

const WaitingBatches = () => {
  const dispatch = useDispatch();
  const { waitingBatches, recyclersList } = useSelector(
    (state) => state.archivedBatches
  );
  const [page, setPage] = useState(
    waitingBatches ? waitingBatches.pag.page : 1
  );
  const [amount, setAmount] = useState(
    waitingBatches ? waitingBatches.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    waitingBatches ? waitingBatches.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    waitingBatches ? waitingBatches.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    waitingBatches ? waitingBatches.pag.search_by : "branch_name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getWaitingBatches(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  useEffect(() => {
    dispatch(getRecyclersList(history));
  }, [dispatch]);

  const showBatchHandler = (id) => {
    history.push("/admin/archived-batches/item/" + id);
  };
  const approveBatchHandler = (id) => {
    dispatch(
      approveBatch(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history,
        1
      )
    );
  };
  const rejectBatchHandler = (id) => {
    dispatch(
      rejectBatch(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history,
        1
      )
    );
  };
  const reAssignRecyclerHandler = (id, recyclerId) => {
    dispatch(
      reAssignRecycler(
        id,
        recyclerId,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history,
        1
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Waiting Archived Batches</h4>
        </CardHeader>
        <CardBody>
          {waitingBatches && (
            <div>
              <Table
                rows={waitingBatches.data}
                totalRows={waitingBatches.pag.total}
                rowsPerPage={amount}
                setRowsPerPage={setAmount}
                order={orderType}
                setOrder={setOrderType}
                orderBy={orderField}
                setOrderBy={setOrderField}
                page={page - 1}
                setPage={setPage}
                searchHandler={setSearchValue}
                searchValue={searchValue}
                selectedSearchBy={searchBy}
                handleSearchByChange={setSearchBy}
                showBatch={showBatchHandler}
                approveBatch={approveBatchHandler}
                rejectBatch={rejectBatchHandler}
                reAssignRecycler={reAssignRecyclerHandler}
                recyclerList={recyclersList}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default WaitingBatches;
