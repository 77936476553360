const styles = (theme) => ({
  button: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: theme.palette.grey[2],
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[2],
    border: "none",
    borderRadius: "3px!important",
    height: "40px!important",
    position: "relative",
    padding: "12px 30px!important",
    margin: ".3125rem 1px",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "uppercase",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition: theme.transitions.create(["background-color", "transform"], {
      duration: theme.transitions.duration.standard,
    }),
    lineHeight: "1.42857143",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.grey[2],
      boxShadow: theme.shadows[2],
    },
    "& .fab,& .fas,& .far,& .fal, &.material-icons": {
      position: "relative",
      display: "inline-block",
      top: "0",
      marginTop: "-1em",
      marginBottom: "-1em",
      fontSize: "1.1rem",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "& svg": {
      position: "relative",
      display: "inline-block",
      top: "0",
      width: "18px",
      height: "18px",
      marginRight: "4px",
      verticalAlign: "middle",
    },
    "&$justIcon": {
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        marginTop: "0px",
        position: "absolute",
        width: "100%",
        transform: "none",
        left: "0px",
        top: "0px",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
      },
    },
  },
  white: {
    "&,&:focus,&:hover": {
      backgroundColor: theme.palette.common.white + "!important",
      color: theme.palette.grey[2],
    },
  },
  primary: {
    color: theme.palette.primary.contentText + "!important",
    background: theme.palette.primary.main + "!important",
    boxShadow: theme.shadows[2],
    "&:hover,&:focus": {
      background: theme.palette.primary.main + "!important",
      boxShadow: theme.shadows[2],
    },
  },
  secondary: {
    color: theme.palette.secondary.contentText + "!important",
    background: theme.palette.secondary.main + "!important",
    boxShadow: theme.shadows[2],
    "&:hover,&:focus": {
      background: theme.palette.secondary.main + "!important",
      boxShadow: theme.shadows[2],
    },
  },
  info: {
    color: theme.palette.primary.contentText + "!important",
    backgroundColor: theme.palette.info.main + "!important",
    boxShadow: theme.shadows[2],
    "&:hover,&:focus": {
      backgroundColor: theme.palette.info.main + "!important",
      boxShadow: theme.shadows[2],
    },
  },
  success: {
    color: theme.palette.primary.contentText + "!important",
    backgroundColor: theme.palette.success.main + "!important",
    boxShadow: theme.shadows[2],
    "&:hover,&:focus": {
      backgroundColor: theme.palette.success.main + "!important",
      boxShadow: theme.shadows[2],
    },
  },
  warning: {
    color: theme.palette.primary.contentText + "!important",
    backgroundColor: theme.palette.warning.main + "!important",
    boxShadow: theme.shadows[2],
    "&:hover,&:focus": {
      backgroundColor: theme.palette.warning.main + "!important",
      boxShadow: theme.shadows[2],
    },
  },
  danger: {
    color: theme.palette.primary.contentText + "!important",
    backgroundColor: theme.palette.error.main + "!important",
    boxShadow: theme.shadows[2],
    "&:hover,&:focus": {
      backgroundColor: theme.palette.error.main + "!important",
      boxShadow: theme.shadows[2],
    },
  },
  simple: {
    "&,&:focus,&:hover": {
      color: theme.palette.text.primary + "!important",
      background: "transparent",
      boxShadow: "none!important",
    },
    "&$primary": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.primary.main + "!important",
      },
    },
    "&$info": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.primary.contentText + "!important",
      },
    },
    "&$success": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.primary.contentText + "!important",
      },
    },
    "&$warning": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.primary.contentText + "!important",
      },
    },
    "&$danger": {
      "&,&:focus,&:hover,&:visited": {
        color: theme.palette.error.main + "!important",
      },
    },
  },
  transparent: {
    "&,&:focus,&:hover": {
      color: theme.palette.text.primary + "!important",
      background: "transparent",
      boxShadow: "none!important",
    },
  },
  transparentReverse: {
    color: theme.palette.primary.contentText + "!important",
    background: "transparent",
    boxShadow: "none!important",
    "&:hover,&:focus": {
      boxShadow: theme.shadows[4] + "!important",
    },
  },
  transparentWhite: {
    "&,&:focus,&:hover": {
      color: theme.palette.common.white + "!important",
      background: "transparent",
      boxShadow: "none!important",
    },
  },
  transparentBlack: {
    "&,&:focus,&:hover": {
      color: theme.palette.common.black + "!important",
      background: "transparent",
      boxShadow: "none!important",
    },
  },
  disabled: {
    opacity: "0.65",
    pointerEvents: "none",
  },
  round: {
    borderRadius: "30px",
  },
  block: {
    width: "100% !important",
  },
  link: {
    "&,&:hover,&:focus": {
      backgroundColor: "transparent",
      color: theme.palette.text.primary + "!important",
      boxShadow: "none",
    },
  },
  justIcon: {
    paddingLeft: "12px",
    paddingRight: "12px",
    fontSize: "20px",
    height: "41px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px",
    },
    "&$lg": {
      height: "57px",
      minWidth: "57px",
      width: "57px",
      lineHeight: "56px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "32px",
        lineHeight: "56px",
      },
      "& svg": {
        width: "32px",
        height: "32px",
      },
    },
    "&$sm": {
      height: "30px",
      minWidth: "30px",
      width: "30px",
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "17px",
        lineHeight: "29px",
      },
      "& svg": {
        width: "17px",
        height: "17px",
      },
    },
  },
});

export default styles;
