import React from "react";
import useClasses from "hooks/useClasses";
import Main from "./CollectableMainData";

import styles from "./CollectableStyle";

const CollectableInfo = ({ collectable }) => {
  const classes = useClasses(styles);
  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <div className={classes.childContainer}>
          <Main collectable={collectable} />
          {/* <Request collectable={collectable} /> */}
        </div>
        {/* <div className={classes.childContainer}>
          <Company collectable={collectable} />
        </div> */}
      </div>
    </div>
  );
};

export default CollectableInfo;
