import * as actionsType from "../actions/actionsType";

const tutorialsReducer = (
  state = { tutorials: null, tutorial: null },
  action
) => {
  switch (action.type) {
    case actionsType.GET_TUTORIALS:
      return { ...state, tutorials: action.payload };
    case actionsType.GET_TUTORIAL:
      return { ...state, tutorial: action.payload };
    default:
      return state;
  }
};

export default tutorialsReducer;
