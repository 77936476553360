import React from "react";
import { Autocomplete, Grid, InputLabel, TextField } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import useClasses from "hooks/useClasses";
import styles from "./ReportInputStyle";

export default function BranchesInput({
  branches,
  onChangeHandler,
  allBranches,
  error,
  helperText,
}) {
  const classes = useClasses(styles);
  const onChange = (value) => {
    onChangeHandler({ target: { name: "branches", value } });
  };

  return (
    <>
      <InputLabel className={error ? classes.labelError : classes.label}>
        Branches:
      </InputLabel>
      <Autocomplete
        multiple
        id="branches"
        className={classes.selectInput}
        options={allBranches}
        value={branches}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        getOptionLabel={(option) => option.name}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} label="branches" placeholder="branches" />
        )}
      />
      <FormHelperText style={error ? { color: "red" } : {}}>
        {helperText}
      </FormHelperText>
    </>
  );
}
