import React from "react";
import useClasses from "hooks/useClasses";
import Button from "components/Shared/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import styles from "./styles";

export default function Home() {
  const classes = useClasses(styles);
  return (
    <div className={classes.footer}>
      <div className={classes.copyrigth}>
        <span>
          &copy;{" "}
          <a
            href="https://www.etadweer.com"
            target="_blank"
            className={classes.a}
          >
            {" "}
            Etadweer
          </a>{" "}
          {1900 + new Date().getYear()}. All Rights Reserved.
        </span>
      </div>
      <div className={classes.footerLinks}>
        <Button
          component={Link}
          to="/policy"
          color="transparentReverse"
          size="lg"
          style={{ padding: "5px" }}
        >
          Privacy & Policy
        </Button>
        <Button
          component={Link}
          to="/copyright"
          color="transparentReverse"
          size="lg"
          style={{ padding: "5px" }}
        >
          Copyright
        </Button>
        <Button
          component={Link}
          to="/recycles"
          color="transparentReverse"
          size="lg"
          style={{ padding: "5px" }}
        >
          Recycles
        </Button>
        <Button
          component={Link}
          to="/awareness"
          color="transparentReverse"
          size="lg"
          style={{ padding: "5px" }}
        >
          Awareness
        </Button>
      </div>
    </div>
  );
}
