import React from "react";
import useClasses from "hooks/useClasses";

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import styles from "./style";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};
const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
};

const CustomTabs = ({ tabs }) => {
  const classes = useClasses(styles);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="collectable Info Tabs"
          classes={{
            indicator: classes.indicator
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.index}
              label={tab.label}
              {...a11yProps(tab.index)}
              classes={{
                textColorPrimary: classes.cutomTab,
                selected: classes.selected
              }}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map((tab) => (
        <TabPanel key={tab.index} value={value} index={tab.index}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  );
};

export default CustomTabs;
