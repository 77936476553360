import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Shared/CustomButtons/Button.js";
import Table from "components/CompanyAdmin/Collectables/Collectables";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { getBranchNotCollected } from "store/actions/companyAdmin";

const BranchNotCollected = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { notCollected } = useSelector((state) => state.companyAdmin);
  const [page, setPage] = useState(notCollected ? notCollected.pag.page : 1);
  const [amount, setAmount] = useState(
    notCollected ? notCollected.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    notCollected ? notCollected.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    notCollected ? notCollected.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    notCollected ? notCollected.pag.search_by : "item_name"
  );

  useEffect(() => {
    dispatch(
      getBranchNotCollected(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          branch_id: id,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/company-branches/recycable/" + id);
  };

  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4>Company branch's Not Collected Recycables</h4>
          </CardHeader>
          <CardBody>
            {notCollected && (
              <Table
                rows={notCollected.data}
                totalRows={notCollected.pag.total}
                rowsPerPage={amount}
                setRowsPerPage={setAmount}
                order={orderType}
                setOrder={setOrderType}
                orderBy={orderField}
                setOrderBy={setOrderField}
                page={page - 1}
                setPage={setPage}
                showRecycable={showRecycableHandler}
                searchHandler={setSearchValue}
                searchValue={searchValue}
                selectedSearchBy={searchBy}
                handleSearchByChange={setSearchBy}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default BranchNotCollected;
