import * as actionsType from "../actions/actionsType";

const notitficationsReducer = (state = { notifications: null }, action) => {
  switch (action.type) {
    case actionsType.GET_NOTIFICATION:
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
};

export default notitficationsReducer;
