import React, { useEffect, useState } from "react";
import useClasses from "hooks/useClasses";
import styles from "./BatchInfoStyle";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Modal from "components/Shared/CustomModal/CustomModal";
import Button from "components/Shared/CustomButtons/Button";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { useTheme } from "@mui/material";
import CollectBatchInput from "./CollectBatchInput";
import SetPriceInput from "./SetPriceInput";

const BatchInfo = ({
  archivedBatch,
  approveBatch,
  rejectBatch,
  collectBatch,
}) => {
  const [currentStatus, setCurrentStatus] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [canApprove, setCanApprove] = useState(false);
  const [canReject, setCanReject] = useState(false);
  const [canCollect, setCanCollect] = useState(false);

  const classes = useClasses(styles);
  const theme = useTheme();
  useEffect(() => {
    if (
      archivedBatch.is_recycler_approved === 0 &&
      archivedBatch.is_recycler_rejected === 0 &&
      archivedBatch.is_admin_approved === 0 &&
      archivedBatch.is_admin_rejected === 0
    ) {
      setCurrentStatus("Waiting for the recycler to approve or reject");
      setCanApprove(true);
      setCanReject(true);
      setCanCollect(false);
    } else if (
      archivedBatch.is_recycler_approved === 1 &&
      archivedBatch.is_admin_approved === 0 &&
      archivedBatch.is_admin_rejected === 0
    ) {
      setCurrentStatus(
        "Recycler approved waiting for the admin to approve or reject"
      );
      setCanApprove(false);
      setCanReject(false);
      setCanCollect(false);
    } else if (
      archivedBatch.is_recycler_rejected === 1 &&
      archivedBatch.is_admin_approved === 0 &&
      archivedBatch.is_admin_rejected === 0
    ) {
      setCurrentStatus(
        "Recycler rejected waiting for the admin to re-assign a recycler"
      );
      setCanApprove(false);
      setCanReject(false);
      setCanCollect(false);
    } else if (archivedBatch.is_admin_rejected === 1) {
      setCurrentStatus(
        "Admin rejected the recycler offer waiting the recycler to give another offer"
      );
      setCanApprove(true);
      setCanReject(true);
      setCanCollect(false);
    } else if (
      archivedBatch.is_recycler_approved === 1 &&
      archivedBatch.is_recycler_rejected === 0 &&
      archivedBatch.is_admin_approved === 1 &&
      archivedBatch.is_admin_rejected === 0 &&
      !archivedBatch.is_collected
    ) {
      setCurrentStatus(
        "Admin and recycler approve waiting for the recycler to collect it from the branch"
      );
      setCanApprove(false);
      setCanReject(false);
      setCanCollect(true);
    } else if (
      archivedBatch.is_recycler_approved === 1 &&
      archivedBatch.is_admin_approved === 1 &&
      archivedBatch.is_collected === 1
    ) {
      setCurrentStatus("Batch Collected ");
      setCanApprove(false);
      setCanReject(false);
      setCanCollect(false);
    }
  }, [archivedBatch]);

  const handleApprove = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Approving this Batch</h5>
        <h6>
          Different between minimum and maximum must be less than 25% of maximum
          price{" "}
        </h6>
        <SetPriceInput
          minPrice={archivedBatch.min_price}
          maxPrice={archivedBatch.max_price}
          approveHandler={(prices) => approveBatch(prices)}
          cancelHandler={() => setOpenModal(false)}
        />
      </div>
    );
    setOpenModal(true);
  };
  const handleReject = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to reject the price of this Batch ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="danger"
            size="lg"
            onClick={rejectBatch}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Reject
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };
  const handleCollect = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to Collect this Batch ? </h5>
        <CollectBatchInput
          collectHandler={(code) => collectBatch(code)}
          cancelHandler={() => setOpenModal(false)}
        />
      </div>
    );
    setOpenModal(true);
  };
  return (
    <div className={classes.container}>
      {/* Branch Name */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Branch Name:</span>
        <span className={classes.content}>{archivedBatch.branch}</span>
      </div>
      {/* Addresses */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>
          <LocationCityIcon
            style={{
              width: "20px",
              height: "20px",
              verticalAlign: "middle",
              color: theme.palette.primary.mainColor,
            }}
          />
          {" Branch Address:"}
        </span>
        {archivedBatch.branch_lat && archivedBatch.branch_lng && canCollect ? (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${archivedBatch.branch_lat},${archivedBatch.branch_lng}`}
            target="_blank"
          >
            <span className={classes.content}>
              {archivedBatch.branch_address}
            </span>
          </a>
        ) : (
          <span className={classes.content}>
            {archivedBatch.branch_address}
          </span>
        )}
      </div>

      {/* Recycler */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Recycler assigned:</span>
        <span className={classes.content}>{archivedBatch.recycler}</span>
      </div>
      {/* min price */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Minimum Price:</span>
        <span className={classes.content}>{archivedBatch.min_price}</span>
      </div>
      {/* max price */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Maximum Price:</span>
        <span className={classes.content}>{archivedBatch.max_price}</span>
      </div>
      {/* current status */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Current Batch Status:</span>
        <span className={classes.content}>{currentStatus}</span>
      </div>

      <div className={classes.buttonsContainer}>
        {canApprove && (
          <div className={classes.buttonContainer}>
            {" "}
            <Button
              color="success"
              size="lg"
              onClick={handleApprove}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Approve
            </Button>
          </div>
        )}
        {canReject && (
          <div className={classes.buttonContainer}>
            {" "}
            <Button
              color="danger"
              size="lg"
              onClick={handleReject}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Reject
            </Button>
          </div>
        )}

        {canCollect && (
          <div className={classes.buttonContainer}>
            {" "}
            <Button
              color="success"
              size="lg"
              onClick={handleCollect}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Collect Batch
            </Button>
          </div>
        )}
      </div>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default BatchInfo;
