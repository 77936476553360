import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import {
  DateRangePicker,
  DateRange,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";
// import CustomInput from "components/Shared/CustomInput/Input";

export default function DatePickerRange({
  startDate,
  endDate,
  changeStartDate,
  changeEndDate,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        isClearable
        filterDate={(d) => {
          return new Date() > d;
        }}
        label="Select Start Date"
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mmaa"
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        value={startDate}
        onChange={(date) => changeStartDate(date)}
        renderInput={(params) => (
          <TextField {...params} label="Start Date" placeholder="Start Date" />
        )}
      />
      <DatePicker
        label="Select End Date"
        value={endDate}
        onChange={(date) => changeEndDate(date)}
        isClearable
        filterDate={(d) => {
          return new Date() > d;
        }}
        showTimeSelect
        dateFormat="MMMM d, yyyy h:mmaa"
        selected={startDate}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
