import React from "react";
import useClasses from "hooks/useClasses";
import { NavLink } from "react-router-dom";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  createModalHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
  clientLink: {
    color: theme.palette.primary.contentText,
    textDecoration: "none",
  },
});

export default function WaitingTransactions({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,
  handleSuccess,
  handleFailure,
}) {
  const classes = useClasses(styles);
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const successHandler = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to send the success for this Transaction ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => handleSuccess(id)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Send
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to send success for those transactions ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => handleSuccess(selected)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Send
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };
  const failureHandler = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to send failure for this transaction ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="danger"
              size="lg"
              onClick={() => handleFailure(id)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Send
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to send failure for those transactions ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="danger"
              size="lg"
              onClick={() => handleFailure(selected)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Send
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };
  const selectedToolbarButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        color="primary"
        size="lg"
        onClick={() => successHandler()}
        style={{ margin: "0 10px" }}
      >
        Send Success
      </Button>
      <Button
        color="danger"
        size="lg"
        onClick={() => failureHandler()}
        style={{ margin: "0 10px" }}
      >
        Send Failure
      </Button>
    </div>
  );

  const toolbarButtons = null;

  const searchByOptions = [
    {
      value: "client_name",
      label: "Client Name",
    },
    {
      value: "phone",
      label: "phone",
    },
    {
      value: "redeem_company_name",
      label: "Redeem Company Name",
    },
  ];
  const headCells = [
    {
      id: "client_name",
      sortable: true,
      label: "Client Name",
    },
    {
      id: "redeem_company_name",
      sortable: true,
      label: "Redeem Company Name",
    },
    {
      id: "phone",
      sortable: true,
      label: "Phone",
    },
    {
      id: "points_redeemed",
      sortable: true,
      label: "Points to be Redeemed",
    },
    {
      id: "money_gained",
      sortable: true,
      label: "Money Gained",
    },
    {
      id: "status",
      sortable: false,
      label: "Status",
    },
    {
      id: "validity_date",
      sortable: true,
      label: "Validity Date",
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created Date",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => handleClick(event, row.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  color="primary"
                />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <NavLink
                  to={`/admin/users/clients/${row.client.id}`}
                  className={classes.clientLink}
                >
                  <span>{row.client.display_name}</span>
                </NavLink>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.redeem_company.name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.phone}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.points_redeemed}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.money_gained} EGP
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_processing === 1 && (
                  <div>
                    <p>Status: Processing</p>
                  </div>
                )}
                {row.is_failure === 1 && (
                  <div>
                    <p>Status: Failure</p>
                  </div>
                )}
                {row.is_redeemed === 1 && (
                  <div>
                    <p>Status: Redeemed</p>
                    <p>{new Date(row.redeem_date).toLocaleString()}</p>
                  </div>
                )}
                {row.is_expired === 1 && (
                  <div>
                    <p>Status: Expired</p>
                  </div>
                )}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.validity_date
                  ? new Date(row.validity_date).toLocaleString()
                  : "No Validity Date"}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                {row.is_processing === 1 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="send success">
                      <IconButton
                        aria-label="success"
                        onClick={() => successHandler([row.id])}
                      >
                        <CheckIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="send failure">
                      <IconButton
                        aria-label="failure"
                        onClick={() => failureHandler([row.id])}
                      >
                        <ClearIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Waiting Transactions
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={true}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
