import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import useClasses from "hooks/useClasses";

import styles from "./styles";

const validationSchema = yup.object({
  name: yup.string("Enter name").required("Name is required"),
  name_ar: yup.string("Enter name arabic").required("Name arabic is required"),
  min_quantity: yup.number().integer().min(1),
  points: yup.number().integer().min(0),
});

const CreateSub = ({
  categories,
  sub,
  isCreate,
  createHandler,
  updateHandler,
}) => {
  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };

  let category_ids = [];
  if (sub) {
    if (sub.category.length !== 0) {
      sub.category.map((cat) => {
        const index = categories.findIndex((el) => el.id === cat.id);
        if (index !== -1) {
          category_ids.push(categories[index].id);
        }
      });
    }
  }
  const formik = useFormik({
    initialValues: {
      name: sub ? sub.name : "",
      name_ar: sub ? sub.name_ar : "",
      min_quantity: sub ? sub.min_quantity : 1,
      category_ids: category_ids,
      points: sub ? sub.points : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    if (isCreate) {
      createHandler(values);
    } else {
      const result = { ...values };
      result.id = sub.id;
      updateHandler(result);
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* name */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name:</InputLabel>
          <Input
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </Grid>
        {/* name_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name Arabic:</InputLabel>
          <Input
            id="name_ar"
            name="name_ar"
            label="Enter Name Arabic"
            value={formik.values.name_ar}
            onChange={formik.handleChange}
            error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
            helperText={formik.touched.name_ar && formik.errors.name_ar}
            type="text"
          />
        </Grid>
        {/* min quantity */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Minimum Quantity:</InputLabel>
          <Input
            id="min_quantity"
            name="min_quantity"
            label="Enter Min Quantity"
            value={formik.values.min_quantity}
            onChange={formik.handleChange}
            error={
              formik.touched.min_quantity && Boolean(formik.errors.min_quantity)
            }
            helperText={
              formik.touched.min_quantity && formik.errors.min_quantity
            }
            type="number"
          />
        </Grid>
        {/* Points */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Points:</InputLabel>
          <Input
            id="points"
            name="points"
            label="Enter Points"
            value={formik.values.points}
            onChange={formik.handleChange}
            error={formik.touched.points && Boolean(formik.errors.points)}
            helperText={formik.touched.points && formik.errors.points}
            type="number"
          />
        </Grid>
        {/* Categories */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Categories:</InputLabel>
          <Select
            id="category_ids"
            name="category_ids"
            label="Categories"
            className={classes.selectInput}
            value={formik.values.category_ids}
            onChange={formik.handleChange}
            multiple={true}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={findName(categories, value)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            inputProps={{ "aria-label": "Without label" }}
          >
            {categories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                <Checkbox
                  checked={formik.values.category_ids.indexOf(cat.id) > -1}
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary={cat.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateSub;
