import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "components/Admin/Users/Recyclers/Recyclers";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getRecyclers,
  createNewRecycler,
  updateRecycler,
  deleteRecycler,
  changeActiveRecycler,
  // getAllBranches,
} from "store/actions/usersRecylers";

const Recyclers = () => {
  const usersRecyclersData = useSelector(
    (state) => state.usersRecyclers.usersRecyclers
  );
  // const branchesListData = useSelector(
  //   (state) => state.usersRecyclers.allBranches
  // );
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    usersRecyclersData ? usersRecyclersData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    usersRecyclersData ? usersRecyclersData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    usersRecyclersData ? usersRecyclersData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    usersRecyclersData ? usersRecyclersData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    usersRecyclersData ? usersRecyclersData.pag.search_by : "display_name"
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getRecyclers(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteRecycler(id, pag, history));
  };

  const changeStatusHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(changeActiveRecycler(id, pag, history));
  };

  const createHandler = (newUser) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
    };
    dispatch(createNewRecycler(newUser, pag, history));
  };

  const updateHandler = (newUser) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(updateRecycler(newUser, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Recyclers Table</h4>
        </CardHeader>
        <CardBody>
          {usersRecyclersData && (
            <Table
              rows={usersRecyclersData.data}
              totalRows={usersRecyclersData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              deleteUser={deleteHandler}
              createUser={createHandler}
              updateUser={updateHandler}
              changeStatus={changeStatusHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Recyclers;
