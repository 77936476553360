import * as actionsType from "../actions/actionsType";

const companyAdminReducer = (
  state = {
    branches: null,
    branch: null,
    archivedItems: null,
    archivedBatches: null,
    collected: null,
    collectableIds: null,
    notCollected: null,
    collectable: null,
    archivedBatch: null,
    allCompanyBranches: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_COMPANY_ALL_BRANCHES:
      return { ...state, allCompanyBranches: action.payload };
    case actionsType.GET_COLLECTABLE_COMPANY_ADMIN:
      return {
        ...state,
        collectable: action.payload,
      };
    case actionsType.GET_BRANCHES_COMPANY_ADMIN:
      return {
        ...state,
        branches: action.payload,
      };
    case actionsType.GET_BRANCH_COMPANY_ADMIN:
      return {
        ...state,
        branch: action.payload,
      };
    case actionsType.SET_BRANCH_ARRAY_COMPANY_ADMIN:
      return {
        ...state,
        collectableIds: action.payload,
      };
    case actionsType.GET_BRANCH_ARCHIVED_BATCHES_COMPANY_ADMIN:
      return {
        ...state,
        archivedBatches: action.payload,
      };
    case actionsType.GET_BRANCH_ARCHIVED_ITEMS_COMPANY_ADMIN:
      return {
        ...state,
        archivedItems: action.payload,
      };
    case actionsType.GET_ARCHIVED_BATCH_COMPANY_ADMIN:
      return {
        ...state,
        archivedBatch: action.payload,
      };
    case actionsType.GET_BRANCH_COLLECTED_COMPANY_ADMIN:
      return {
        ...state,
        collected: action.payload,
      };
    case actionsType.GET_BRANCH_NOT_COLLECTED_COMPANY_ADMIN:
      return {
        ...state,
        notCollected: action.payload,
      };
    default:
      return state;
  }
};

export default companyAdminReducer;
