import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import useClasses from "hooks/useClasses";

import styles from "./styles";
import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";
import CustomEditor from "components/Shared/CustomEditor/CustomEditor";

const validationSchema = yup.object({
  title: yup.string("Enter title").required("Title is required"),
  sub_title: yup.string("Enter Subtitle").required("Subtitle is required"),
});

const Tutorial = ({ tutorial, isCreate, createHandler, updateHandler }) => {
  const [bgImage, setBgImage] = useState(
    tutorial
      ? tutorial.bg_image
        ? { file: null, data_url: tutorial.bg_image }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [bgImageError, setBgImageError] = useState(null);
  const [fgImage, setFgImage] = useState(
    tutorial
      ? tutorial.fg_image
        ? { file: null, data_url: tutorial.fg_image }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [fgImageError, setFgImageError] = useState(null);
  const formik = useFormik({
    initialValues: {
      id: !isCreate ? (tutorial ? tutorial.id : "") : "",
      title: tutorial ? (tutorial.title ? tutorial.title : "") : "",
      sub_title: tutorial ? (tutorial.sub_title ? tutorial.sub_title : "") : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    const result = { ...values };
    let error = false;
    if (bgImageError || fgImageError) {
      error = true;
    }
    if (bgImage.file !== null) {
      result.bg_image = bgImage.file;
    } else if (bgImage.data_url === null) {
      setBgImageError("Enter Image!");
      error = true;
    }
    if (fgImageError) {
      error = true;
    }
    if (fgImage.file !== null) {
      result.fg_image = fgImage.file;
    } else if (fgImage.data_url === null) {
      setFgImageError("Enter Image!");
      error = true;
    }
    if (error === false) {
      if (isCreate) {
        createHandler(result);
      } else {
        updateHandler(result);
      }
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {(bgImageError !== null || fgImageError !== null) && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {bgImageError !== null && (
                  <li key="image" style={{ color: "#fff" }}>
                    {bgImageError}
                  </li>
                )}
                {fgImageError !== null && (
                  <li key="imageAr" style={{ color: "#fff" }}>
                    {fgImageError}
                  </li>
                )}
              </ul>
            </div>
          </Grid>
        )}
        {Object.keys(formik.errors).length !== 0 && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {Object.keys(formik.errors).map((error, index) => (
                  <li key={index} style={{ color: "#fff" }}>
                    {formik.errors[error]}
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        )}
        {/* title */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Title:</InputLabel>
          <Input
            id="title"
            name="title"
            label="Enter Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            type="text"
          />
        </Grid>
        {/* sub title */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Subtitle Title:</InputLabel>
          <Input
            id="sub_title"
            name="sub_title"
            label="Enter Subtitle Title"
            value={formik.values.sub_title}
            onChange={formik.handleChange}
            error={formik.touched.sub_title && Boolean(formik.errors.sub_title)}
            helperText={formik.touched.sub_title && formik.errors.sub_title}
            type="text"
          />
        </Grid>
        {/* bgImage */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Background Image:</InputLabel>
          <ImageUpload
            image={bgImage}
            setImage={setBgImage}
            error={bgImageError}
            setError={setBgImageError}
          />
        </Grid>
        {/* ImageAr */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>forground Image:</InputLabel>
          <ImageUpload
            image={fgImage}
            setImage={setFgImage}
            error={fgImageError}
            setError={setFgImageError}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Tutorial;
