import React, { useState } from "react";
import { Grid, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Chip from "@mui/material/Chip";
import useClasses from "hooks/useClasses";

import styles from "./BranchStyle";

export default function WorkingDaysInput({
  days,
  daysList,
  onChangeHandler,
  error,
  helperText,
}) {
  const classes = useClasses(styles);
  const [open, setOpen] = React.useState(false);
  const handleChange = (e) => {
    let all_days = {
      target: {
        name: "all_days",
        value: false,
      },
    };
    if (e.target.value.length === 7) {
      all_days = {
        target: {
          name: "all_days",
          value: true,
        },
      };
    }
    onChangeHandler(e);
    onChangeHandler(all_days);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid item xs={12}>
          <InputLabel className={error ? classes.labelError : classes.label}>
            Working Days:
          </InputLabel>
          <Select
            error={error}
            id="days"
            name="days"
            label="Working Days"
            className={classes.selectInput}
            value={days}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={handleChange}
            multiple={true}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={findName(daysList, value)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            inputProps={{ "aria-label": "Without label" }}
          >
            {daysList.map((day) => (
              <MenuItem key={day.id} value={day.id}>
                <Checkbox
                  checked={days.indexOf(day.id) > -1}
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary={day.name} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText style={error ? { color: "red" } : {}}>
            {helperText}
          </FormHelperText>
        </Grid>
      </Grid>
    </>
  );
}
