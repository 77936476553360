import React from "react";
import classNames from "classnames";
import useClasses from "hooks/useClasses";
import { NavLink } from "react-router-dom";

import styles from "./sidebarStyle";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import Icon from "@mui/material/Icon";

const SidebarSubLink = ({ parentRoute, route, activeRoute, clickHandler }) => {
  const classes = useClasses(styles);
  let activePro = " ";
  let listTextClasses;
  listTextClasses = classNames({
    [" " + classes.primaryText]: activeRoute(
      route.layout + parentRoute.path + route.path
    ),
  });
  let listItemClasses;
  listItemClasses = classNames({
    [" " + classes.primary]: activeRoute(
      route.layout + parentRoute.path + route.path
    ),
  });

  return (
    <div>
      <NavLink
        to={route.layout + parentRoute.path + route.path}
        className={activePro + classes.item}
        activeClassName="active"
        onClick={clickHandler}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          <ListItemText
            primary={route.name}
            className={classNames(
              classes.itemText,
              classes.whiteFont + listTextClasses
            )}
            disableTypography={true}
          />
        </ListItem>
      </NavLink>
    </div>
  );
};

export default SidebarSubLink;
