import React from "react";
import useClasses from "hooks/useClasses";

import Grid from "@mui/material/Grid";

const styles = {
  grid: {
    margin: "0 -15px !important",
    width: "unset",
  },
};

export default function GridContainer(props) {
  const classes = useClasses(styles);
  const { children, ...rest } = props;
  return (
    <Grid container {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
}
