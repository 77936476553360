import React from "react";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

export default function Branches({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchHandler,
  searchValue,
  selectedSearchBy,
  handleSearchByChange,

  showCollected,
  showArchived,
  showNotCollected,
  editBranch,
  addBranch,
  changeStatus,
  deleteBranch,
  resetBranch,
}) {
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to Delete this branch ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => {
                deleteBranch(id);
                setOpenModal(false);
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Delete
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete those branches ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => {
                deleteBranch(selected);
                setOpenModal(false);
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Delete
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const handleStatusChange = () => {
    if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to Change Status for those Branches ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => {
                changeStatus(selected);
                setOpenModal(false);
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              change
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const handleReset = () => {
    if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to reset those branches ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="success"
              size="lg"
              onClick={() => {
                resetBranch(selected);
                setOpenModal(false);
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Reset
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const selectedToolbarButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        color="primary"
        size="lg"
        onClick={() => handleReset()}
        style={{ margin: "0 10px" }}
      >
        Reset
      </Button>
      <Button
        color="primary"
        size="lg"
        onClick={() => handleStatusChange()}
        style={{ margin: "0 10px" }}
      >
        Change Status
      </Button>
      <Button
        color="danger"
        size="lg"
        onClick={() => handleDelete()}
        style={{ margin: "0 10px" }}
      >
        Delete
      </Button>
    </div>
  );

  const toolbarButtons = (
    <div>
      <Button color="primary" onClick={addBranch}>
        Add new Branch
      </Button>
    </div>
  );

  const searchByOptions = [
    {
      value: "name",
      label: "Name",
    },
    {
      value: "name_ar",
      label: "Name Arabic",
    },
    {
      value: "phone",
      label: "Phone Number",
    },
    {
      value: "company_name",
      label: "Company Name",
    },
  ];
  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "Id",
    },
    {
      id: "name",
      sortable: true,
      label: "Name",
    },
    {
      id: "name_ar",
      sortable: true,
      label: "Name Arabic",
    },
    {
      id: "collectables_assigned",
      sortable: false,
      label: "Recycables Assigned",
    },
    {
      id: "collectables_collected",
      sortable: false,
      label: "Recycables Collected",
    },
    {
      id: "collectables_delivered",
      sortable: false,
      label: "Recycables Delivered",
    },
    {
      id: "phone",
      sortable: true,
      label: "Phone",
    },
    {
      id: "company_name",
      sortable: true,
      label: "Company",
    },
    {
      id: "is_closed",
      sortable: true,
      label: "Status",
    },
    {
      id: "Recycables details",
      sortable: false,
      label: "Details",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => handleClick(event, row.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {"# " + row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name_ar}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.collectables_assigned}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.collectables_collected}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.collectables_delivered}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.phone}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.company_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_closed === 0 ? "Not Closed" : "Closed"}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => showCollected(row.id)}
                    style={{ margin: "10px", width: "150px" }}
                  >
                    Collected
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => showNotCollected(row.id)}
                    style={{ margin: "10px", width: "150px" }}
                  >
                    Not Collected
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => showArchived(row.id)}
                    style={{ margin: "10px", width: "150px" }}
                  >
                    Archived
                  </Button>
                </div>
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title="Edit branch">
                      <IconButton
                        aria-label="edit"
                        onClick={() => editBranch(row.id)}
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete branch">
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleDelete([row.id])}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Drop-off locations
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        handleFilterReset
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={true}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
