import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useDispatch, useSelector } from "react-redux";
import Table from "components/Admin/Vouchers/Voucher/VoucherItems";
import {
  getVoucherItems,
  deleteVoucherItems,
  deleteExpiredItems,
  deleteNotUsedItems,
} from "store/actions/vouchers";

const VoucherItems = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const itemsData = useSelector((state) => state.vouchers.items);
  const [page, setPage] = useState(itemsData ? itemsData.pag.page : 1);
  const [amount, setAmount] = useState(itemsData ? itemsData.pag.amount : 10);
  const [orderField, setOrderField] = useState(
    itemsData ? itemsData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    itemsData ? itemsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    itemsData ? itemsData.pag.search_by : "code"
  );

  useEffect(() => {
    dispatch(
      getVoucherItems(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          voucher_id: id,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (vid) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
      voucher_id: id,
    };
    dispatch(deleteVoucherItems(vid, pag, history));
  };
  const deleteAllNotUsedHandler = () => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
      voucher_id: id,
    };
    dispatch(deleteNotUsedItems(pag, history));
  };
  const deleteAllExpiredHandler = () => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
      voucher_id: id,
    };
    dispatch(deleteExpiredItems(pag, history));
  };

  return (
    <div>
      <div>
        <Button
          onClick={history.goBack}
          color="transparent"
          style={{ padding: 0 }}
        >
          <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
          <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
        </Button>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4>Voucher Codes Table</h4>
        </CardHeader>
        <CardBody>
          {itemsData && (
            <Table
              rows={itemsData.data}
              totalRows={itemsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              deleteHandler={deleteHandler}
              deleteAllNotUsedHandler={deleteAllNotUsedHandler}
              deleteAllExpiredHandler={deleteAllExpiredHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default VoucherItems;
