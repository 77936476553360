const styles = (theme) => ({
  cardTitleWhite: {
    color: theme.palette.primary.contrastText,
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  successText: {
    color: theme.palette.success.main,
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: theme.palette.grey[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  cardCategory: {
    color: theme.palette.grey[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    color: theme.palette.text.primary,
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitle: {
    color: theme.palette.grey[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: theme.palette.grey[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  loadingContainer: {
    textAlign: "center",
  },
  loading: {
    color: theme.palette.primary.main,
    width: "100px!important",
    height: "100px!important",
  },
  messageContainer: {
    textAlign: "center",
    margin: "20px auto",
  },
  message: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    fontSize: "18px",
  },
});

export default styles;
