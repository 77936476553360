import { format } from "prettier";
import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getCompanyBranchUsers =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({ type: actionsType.GET_COMPANY_BRANCH_USERS, payload: null });
      const { data } = await api.getCompanyBranchUser(formData);
      dispatch({ type: actionsType.GET_COMPANY_BRANCH_USERS, payload: data });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const createNewCompanyBranchUser =
  (newUser, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.createCompnayBranchUser(newUser);
      dispatch(getCompanyBranchUsers(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "New Company Branch user created successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const deleteCompanyBranchUser =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteCompanyBranchUsers({ ids: idsArray });
      dispatch(getCompanyBranchUsers(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Company Branch Users deleted successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getCompanyAllBranches = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_COMPANY_ALL_BRANCHES, payload: null });
    const { data } = await api.getCompanyAdminAllBranches();
    dispatch({ type: actionsType.GET_COMPANY_ALL_BRANCHES, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCompanyAdminCompany =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({ type: actionsType.GET_COMPANY_ADMIN_COMPANY, payload: null });
      const { data } = await api.getCompanyAdminCompany(formData);
      dispatch({ type: actionsType.GET_COMPANY_ADMIN_COMPANY, payload: data });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const createNewCompanyAdminCompany =
  (newUser, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.createCompanyAdminCompany(newUser);
      dispatch(getCompanyAdminCompany(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "New Company Admin created successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const deleteCompanyAdminCompany =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteCompanyAdminCompany({ ids: idsArray });
      dispatch(getCompanyAdminCompany(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Company Admin deleted successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getCompanyDeletedUsers =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({ type: actionsType.GET_COMPANY_DELETED_USERS, payload: null });
      const { data } = await api.getCompanyDeletedUsers(formData);
      dispatch({ type: actionsType.GET_COMPANY_DELETED_USERS, payload: data });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
