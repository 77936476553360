import React from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useDispatch } from "react-redux";
import RedeemCompany from "components/Admin/RedeemCompanies/RedeemCompany";
import { createRedeemCompany } from "store/actions/redeemCompanies";

const CreateRedeemCompany = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const createHandler = (newData) => {
    dispatch(createRedeemCompany(newData, history));
  };

  return (
    <div>
      <div>
        <Button
          onClick={history.goBack}
          color="transparent"
          style={{ padding: 0 }}
        >
          <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
          <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
        </Button>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4>Create New Redeem Company</h4>
        </CardHeader>
        <CardBody>
          <RedeemCompany
            redeemCompany={null}
            isCreate={true}
            createHandler={createHandler}
            updateHandler={null}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateRedeemCompany;
