import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";
// Admins

export const getAdmins = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: null });
    const { data } = await api.getUsersAdmins(formData);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createNewAdmin =
  (newUser, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.createUsersAdmins(newUser);
      dispatch({ type: actionsType.GET_USERS_ADMINS, payload: null });
      const { data } = await api.getUsersAdmins(formData);
      dispatch({ type: actionsType.GET_USERS_ADMINS, payload: data });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "the new admin user has been created successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const updateAdmin = (newUser, formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.UpdateUsersAdmins(newUser);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: null });
    const { data } = await api.getUsersAdmins(formData);
    dispatch({ type: actionsType.GET_USERS_ADMINS, payload: data });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "admin user has been updated successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteAdmin =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteUsersAdmins({ ids: idsArray });
      dispatch({ type: actionsType.GET_USERS_ADMINS, payload: null });
      const { data } = await api.getUsersAdmins(formData);
      dispatch({ type: actionsType.GET_USERS_ADMINS, payload: data });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "admin user has been Deleted successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
