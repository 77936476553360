import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getStates = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_STATE,
      payload: null,
    });
    const { data } = await api.getStates(formData);
    dispatch({ type: actionsType.GET_STATE, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteState =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteState({ ids: idsArray });
      dispatch(getStates(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "State deleted Successfully" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const createState = (newData, formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.createState(newData);
    dispatch(getStates(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New State has been created successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateState = (newData, formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.updateState(newData);
    dispatch(getStates(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "State has been updated successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const changeStatusState =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusState({ ids: idsArray });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "State's status Changed Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch(getStates(formData, history));
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
