import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const removeMessage = () => async (dispatch) => {
  dispatch({
    type: actionsType.REMOVE_MESSAGE,
  });
};

export const getHome = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getHome();
    dispatch({ type: actionsType.GET_HOME, payload: data.data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getManagerHome = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getManagerHome();
    dispatch({ type: actionsType.GET_MANAGER_HOME, payload: data.data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const getCompanyAdminHome = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCompanyAdminHome();
    dispatch({ type: actionsType.GET_COMPANY_ADMIN_HOME, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
