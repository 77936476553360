import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getRedeemCompanies = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_REDEEM_COMPANIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getRedeemCompanies(formData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({ type: actionsType.GET_REDEEM_COMPANIES, payload: data });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteRedeemCompany =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteRedeemCompany({ ids: idsArray });
      dispatch(getRedeemCompanies(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Redeem Company deleted Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const changeStatusRedeemCompany =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusRedeemCompany({ ids: idsArray });
      dispatch(getRedeemCompanies(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Redeem Company status Changed Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const createRedeemCompany = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("company_name", newData.company_name);
    formNewData.append("company_name_ar", newData.company_name_ar);
    formNewData.append("bg_color", newData.bg_color);
    formNewData.append("logo", newData.logo);
    formNewData.append("logo_ar", newData.logo_ar);
    formNewData.append("terms_url", newData.terms_url);
    formNewData.append("description_before", newData.description_before);
    formNewData.append("description_before_ar", newData.description_before_ar);
    formNewData.append("description_after", newData.description_after);
    formNewData.append("description_after_ar", newData.description_after_ar);
    formNewData.append("redeem_service_text", newData.redeem_service_text);
    formNewData.append(
      "redeem_service_text_ar",
      newData.redeem_service_text_ar
    );
    formNewData.append("version_ios", newData.version_ios);
    formNewData.append("version_android", newData.version_android);
    formNewData.append("display_code", newData.display_code);
    await api.createRedeemCompany(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Redeem Company has been created successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/redeem-companies/");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateRedeemCompany = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("id", newData.id);
    formNewData.append("company_name", newData.company_name);
    formNewData.append("company_name_ar", newData.company_name_ar);
    formNewData.append("bg_color", newData.bg_color);
    formNewData.append("terms_url", newData.terms_url);
    formNewData.append("description_before", newData.description_before);
    formNewData.append("description_before_ar", newData.description_before_ar);
    formNewData.append("description_after", newData.description_after);
    formNewData.append("description_after_ar", newData.description_after_ar);
    formNewData.append("redeem_service_text", newData.redeem_service_text);
    formNewData.append(
      "redeem_service_text_ar",
      newData.redeem_service_text_ar
    );
    formNewData.append("version_ios", newData.version_ios);
    formNewData.append("version_android", newData.version_android);
    formNewData.append("display_code", newData.display_code);

    if (newData.logo) {
      formNewData.append("logo", newData.logo);
    }
    if (newData.logo_ar) {
      formNewData.append("logo_ar", newData.logo_ar);
    }
    await api.updateRedeemCompany(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Redeem Company has been updated successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/redeem-companies/");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getRedeemCompany = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_REDEEM_COMPANY,
      payload: null,
    });
    const { data } = await api.getRedeemCompany(id);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({ type: actionsType.GET_REDEEM_COMPANY, payload: data.data });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
