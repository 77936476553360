import React from "react";
import useClasses from "hooks/useClasses";
import { NavLink } from "react-router-dom";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  createModalHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
  clientLink: {
    color: theme.palette.primary.contentText,
    textDecoration: "none",
  },
});

export default function Transactions({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,
}) {
  const classes = useClasses(styles);
  const selectedToolbarButtons = null;

  const toolbarButtons = null;

  const searchByOptions = [
    {
      value: "client_name",
      label: "Client Name",
    },
    {
      value: "redeem_company_name",
      label: "Redeem Company Name",
    },
  ];
  const headCells = [
    // {
    //   id: "id",
    //   sortable: true,
    //   label: "ID",
    // },
    {
      id: "client_name",
      sortable: true,
      label: "Client Name",
    },
    {
      id: "redeem_company_name",
      sortable: true,
      label: "Redeem Company Name",
    },
    {
      id: "points_redeemed",
      sortable: true,
      label: "Points to be Redeemed",
    },
    {
      id: "money_gained",
      sortable: true,
      label: "Money Gained",
    },
    // {
    //   id: "is_expired",
    //   sortable: false,
    //   label: "Expired",
    // },
    {
      id: "Status",
      sortable: false,
      label: "Status",
    },
    {
      id: "validity_date",
      sortable: true,
      label: "Valid Till",
    },
    // {
    //   id: "is_redeemed",
    //   sortable: false,
    //   label: "Redeemed",
    // },
    // {
    //   id: "redeem_date",
    //   sortable: false,
    //   label: "Redeem Date",
    // },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              {/* <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.id}
              </TableCell> */}
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <NavLink
                  to={`/admin/users/clients/${row.client.id}`}
                  className={classes.clientLink}
                >
                  <span>{row.client.display_name}</span>
                </NavLink>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.redeem_company.name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.points_redeemed}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.money_gained} EGP
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_processing === 1 && (
                  <div>
                    <p>Status: Processing</p>
                  </div>
                )}
                {row.is_failure === 1 && (
                  <div>
                    <p>Status: Failure</p>
                  </div>
                )}
                {row.is_redeemed === 1 && (
                  <div>
                    <p>Status: Redeemed</p>
                    <p>{new Date(row.redeem_date).toLocaleString()}</p>
                  </div>
                )}
                {row.is_expired === 1 && (
                  <div>
                    <p>Status: Expired</p>
                  </div>
                )}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.validity_date
                  ? new Date(row.validity_date).toLocaleString()
                  : "Valid"}
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Transactions
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      {/* <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal> */}
    </React.Fragment>
  );
}
