import React, { useState } from "react";
import {
  Avatar,
  Typography,
  Container,
  Paper,
  Grid,
  InputLabel,
} from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";

const validationSchema = yup.object({
  password: yup.string("Enter your password").required("Password is required"),
});

const ArchivedConfirmation = ({ archiveAll, cancelArchive }) => {
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (values) => {
    archiveAll(values);
  };
  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <>
            <InputLabel style={{ fontWeight: "bold", marginLeft: "10px" }}>
              Password:
            </InputLabel>
            <Input
              id="password"
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              handleShowPassword={handleShowPassword}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
            marginTop: "20px",
          }}
        >
          <Button
            color="success"
            size="lg"
            type="submit"
            style={{ flex: 1, margin: "0 10px" }}
          >
            Archive
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={cancelArchive}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ArchivedConfirmation;
