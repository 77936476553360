import React from "react";
import { Grid, InputLabel } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";
import useClasses from "hooks/useClasses";

import styles from "./style";

const validationSchema = yup.object({});

const SettingsComponent = ({ data, handleUpdate }) => {
  const formik = useFormik({
    initialValues: {
      validity_period: data.validity_period ? data.validity_period : 0,
      reminder: data.reminder ? data.reminder : 0,
      min_pickup_requests: data.min_pickup_requests
        ? data.min_pickup_requests
        : 1,
      minimum_redeem_point: data.minimum_redeem_point
        ? data.minimum_redeem_point
        : 1,
      rate_point: data.rate_point ? data.rate_point : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // const [formData, setFormData] = useState(initialState);
  const classes = useClasses(styles);
  const history = useHistory();

  const handleSubmit = (values) => {
    handleUpdate(values);
  };

  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(formik.errors).length !== 0 && (
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "15px",
                  padding: "5px",
                }}
              >
                <ul>
                  {Object.keys(formik.errors).map((error, index) => (
                    <li key={index} style={{ color: "#fff" }}>
                      {formik.errors[error]}
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          )}
          {/*  validity_period */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Validity Period (number of days):
            </InputLabel>
            <Input
              id="validity_period"
              name="validity_period"
              label="Enter Validity Period"
              value={formik.values.validity_period}
              onChange={formik.handleChange}
              error={
                formik.touched.validity_period &&
                Boolean(formik.errors.validity_period)
              }
              helperText={
                formik.touched.validity_period && formik.errors.validity_period
              }
              type="number"
            />
          </Grid>
          {/*  reminder */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Reminder:</InputLabel>
            <Input
              id="reminder"
              name="reminder"
              label="Enter Reminder"
              value={formik.values.reminder}
              onChange={formik.handleChange}
              error={formik.touched.reminder && Boolean(formik.errors.reminder)}
              helperText={formik.touched.reminder && formik.errors.reminder}
              type="number"
            />
          </Grid>
          {/*  min_pickup_requests */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Mininmun items for pickup requests:
            </InputLabel>
            <Input
              id="min_pickup_requests"
              name="min_pickup_requests"
              label="Enter Min items"
              value={formik.values.min_pickup_requests}
              onChange={formik.handleChange}
              error={
                formik.touched.min_pickup_requests &&
                Boolean(formik.errors.min_pickup_requests)
              }
              helperText={
                formik.touched.min_pickup_requests &&
                formik.errors.min_pickup_requests
              }
              type="number"
            />
          </Grid>
          {/*  minimum_redeem_point */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Minimum Points to be redeemed:
            </InputLabel>
            <Input
              id="minimum_redeem_point"
              name="minimum_redeem_point"
              label="Enter Min Points"
              value={formik.values.minimum_redeem_point}
              onChange={formik.handleChange}
              error={
                formik.touched.minimum_redeem_point &&
                Boolean(formik.errors.minimum_redeem_point)
              }
              helperText={
                formik.touched.minimum_redeem_point &&
                formik.errors.minimum_redeem_point
              }
              type="number"
            />
          </Grid>
          {/*  rate_point */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Rate Points:</InputLabel>
            <Input
              id="rate_point"
              name="rate_point"
              label="Enter Rate Points"
              value={formik.values.rate_point}
              onChange={formik.handleChange}
              error={
                formik.touched.rate_point && Boolean(formik.errors.rate_point)
              }
              helperText={formik.touched.rate_point && formik.errors.rate_point}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              className={classes.submit}
              type="submit"
            >
              Update General Settings
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default SettingsComponent;
