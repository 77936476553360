import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getCategories = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_CATEGORIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCategories(formData);
    dispatch({ type: actionsType.GET_CATEGORIES, payload: data });
    dispatch(getCompanies(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteCategory =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteCategory({ ids: idsArray });
      dispatch(getCategories(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "Category deleted Successfully" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const changeStatusCategory =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusCategory({ ids: idsArray });
      dispatch(getCategories(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Category status Changed Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_CATEGORIES_COMPANIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllCompanies();

    dispatch({
      type: actionsType.GET_CATEGORIES_COMPANIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createCategory = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("name", newData.name);
    formNewData.append("name_ar", newData.name_ar);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    formNewData.append("image", newData.image);
    formNewData.append("image_dark", newData.image_dark);
    formNewData.append("company_id", newData.company_ids);
    formNewData.append("is_active", newData.is_active);
    if (newData.badge !== "") {
      formNewData.append("badge", newData.badge);
      formNewData.append("badge_ar", newData.badge_ar);
      formNewData.append("badge_bg_color", newData.badge_bg_color);
    }
    formNewData.append("has_pickup", newData.has_pickup);
    formNewData.append("has_dropoff", newData.has_dropoff);
    formNewData.append("points", newData.points);
    formNewData.append("is_offer", newData.is_offer);
    formNewData.append("offer_points", newData.offer_points);
    await api.createCategory(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Category has been created successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/categories/");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateCategory = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("id", newData.id);
    formNewData.append("name", newData.name);
    formNewData.append("name_ar", newData.name_ar);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    if (newData.image) {
      formNewData.append("image", newData.image);
    }
    if (newData.image_dark) {
      formNewData.append("image_dark", newData.image_dark);
    }
    formNewData.append("company_id", newData.company_ids);
    formNewData.append("is_active", newData.is_active);
    formNewData.append("badge", newData.badge);
    formNewData.append("badge_ar", newData.badge_ar);
    if (newData.badge !== "") {
      formNewData.append("badge_bg_color", newData.badge_bg_color);
    } else {
      formNewData.append("badge_bg_color", "");
    }
    formNewData.append("has_pickup", newData.has_pickup);
    formNewData.append("has_dropoff", newData.has_dropoff);
    formNewData.append("points", newData.points);
    formNewData.append("is_offer", newData.is_offer);
    formNewData.append("offer_points", newData.offer_points);
    await api.updateCategory(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Category has been updated successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/categories/");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCategory = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_CATEGORY,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCategory(id);
    dispatch({ type: actionsType.GET_CATEGORY, payload: data.data });
    dispatch(getCompanies(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
