import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getCities = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_CITIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCities(formData);
    dispatch({ type: actionsType.GET_CITIES, payload: data });
    dispatch(getAllStates(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteCity = (idsArray, formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.deleteCity({ ids: idsArray });
    dispatch(getCities(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "City deleted Successfully" },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createCity = (newData, formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.createCity(newData);
    dispatch(getCities(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New City has been created successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateCity = (newData, formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.updateCity(newData);
    dispatch(getCities(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "City has been updated successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const changeStatusCity =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusCity({ ids: idsArray });
      dispatch(getCities(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "City's Status Changed Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getAllStates = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_ALL_STATES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllStates();

    dispatch({
      type: actionsType.GET_ALL_STATES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
