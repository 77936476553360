const styles = (theme) => ({
  wrapperClass: {
    padding: "1rem",
    border: "1px solid " + theme.palette.primary.main,
  },
  editorClass: {
    backgroundColor: theme.palette.background.secondary,
    color: theme.palette.primary.contarstText,
    padding: "1rem",
    border: "2px solid " + theme.palette.primary.main,
    minHeight: "200px",
  },
  toolbarClass: {
    border: "1px solid " + theme.palette.primary.main,
    color: theme.palette.common.black,
  },
});

export default styles;
