import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/Manager/Collectables/Archived/ArchivedBatches";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import {
  getArchivedBatchesManager,
  generateCode,
} from "store/actions/managers";

const ArchivedBatches = () => {
  const archivedBatchesData = useSelector(
    (state) => state.manager.archivedBatches
  );
  const generatedCode = useSelector((state) => state.manager.generatedCode);
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    archivedBatchesData ? archivedBatchesData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    archivedBatchesData ? archivedBatchesData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    archivedBatchesData ? archivedBatchesData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    archivedBatchesData ? archivedBatchesData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const history = useHistory();
  const [time, setTime] = useState(59);
  const [batchId, setBatchId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let interval;
    let timer;
    if (isOpen) {
      timer = setInterval(() => {
        setTime((time) => {
          if (time === 0) {
            return 0;
          } else return time - 1;
        });
      }, 1000);
      interval = setInterval(() => {
        dispatch(generateCode(batchId, history));
        setTime(59);
      }, 60000);
    }

    return () => {
      clearInterval(interval);
      clearInterval(timer);
    };
  }, [isOpen]);

  useEffect(() => {
    dispatch(
      getArchivedBatchesManager(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  const showItemsHandler = (id) => {
    history.push("/admin/recycables/archived-items/" + id);
  };

  const generateCodeHandler = (id) => {
    dispatch(generateCode(id, history));
    setBatchId(id);
    setTime(59);
    setIsOpen(true);
  };
  const closeCodeHandler = () => {
    setBatchId(null);
    setTime(59);
    setIsOpen(false);
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Archived Batches</h4>
        </CardHeader>
        <CardBody>
          {archivedBatchesData && (
            <Table
              rows={archivedBatchesData.data}
              totalRows={archivedBatchesData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              showItems={showItemsHandler}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              code={generatedCode}
              generateCode={generateCodeHandler}
              closeCode={closeCodeHandler}
              timer={time}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ArchivedBatches;
