import React, { useState } from "react";
import { useSelector } from "react-redux";
import useClasses from "hooks/useClasses";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import AdminKnowledgeBase from "components/Shared/KnowledgeBase/AdminKnowledgeBase";
import StaffKnowledgeBase from "components/Shared/KnowledgeBase/StaffKnowledgeBase";

const styles = {
  cardTitle: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  cardSubtitle: {
    color: "#191919",
    fontWeight: "300",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#191919",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const adminTutorials = [
  {
    title: "How to Register and Login",
    yid: "1_I1MxCSWWI",
  },
  {
    title: "How to collect recycable",
    yid: "K5vtgMI3lgw",
  },
];
const managerTutorials = [
  {
    title: "How to Register and Login",
    yid: "1_I1MxCSWWI",
  },
  {
    title: "How to collect recycable",
    yid: "K5vtgMI3lgw",
  },
];
const recyclerTutorials = [
  {
    title: "How to Register and Login",
    yid: "1_I1MxCSWWI",
  },
  {
    title: "How to collect recycable",
    yid: "K5vtgMI3lgw",
  },
];
const companyAdminTutorials = [
  {
    title: "How to Register and Login",
    yid: "1_I1MxCSWWI",
  },
  {
    title: "How to collect recycable",
    yid: "K5vtgMI3lgw",
  },
];

const KnowledgeBase = () => {
  const classes = useClasses(styles);
  const authData = useSelector((state) => state.auth.authData);

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitle}>KnowledgeBase Page</h4>
        </CardHeader>
        <CardBody>
          <div>
            <h4 className={classes.cardSubTitle}>
              this is the Knowlegde base page where you can find all videos
              tutorial{" "}
            </h4>
          </div>
          {authData && (
            <div>
              {(authData.role === "admin" || authData.role === "sub-admin") && (
                <AdminKnowledgeBase
                  admin={adminTutorials}
                  manager={managerTutorials}
                  companyAdmin={companyAdminTutorials}
                  recycler={recyclerTutorials}
                />
              )}
              {(authData.role === "manager" || authData.role === "agent") && (
                <StaffKnowledgeBase staff={managerTutorials} />
              )}
              {authData.role === "recycler" && (
                <StaffKnowledgeBase staff={recyclerTutorials} />
              )}
              {authData.role === "company-admin" && (
                <StaffKnowledgeBase staff={companyAdminTutorials} />
              )}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default KnowledgeBase;
