import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Table from "components/Admin/Users/SubAdmin/SubAdmins";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdmins,
  createNewAdmin,
  updateAdmin,
  deleteAdmin,
} from "store/actions/usersAdmins";

const SubAdmins = () => {
  const usersAdminsData = useSelector((state) => state.usersAdmins.usersAdmins);
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    usersAdminsData ? usersAdminsData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    usersAdminsData ? usersAdminsData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    usersAdminsData ? usersAdminsData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    usersAdminsData ? usersAdminsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getAdmins(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
    };
    dispatch(deleteAdmin(id, pag, history));
  };

  const createHandler = (newUser) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
    };
    dispatch(createNewAdmin(newUser, pag, history));
  };

  const updateHandler = (newUser) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
    };
    dispatch(updateAdmin(newUser, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Admins/Sub-admins/Viewers Table</h4>
        </CardHeader>
        <CardBody>
          {usersAdminsData && (
            <Table
              rows={usersAdminsData.data}
              totalRows={usersAdminsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              deleteUser={deleteHandler}
              createUser={createHandler}
              updateUser={updateHandler}
              searchHandler={setSearchValue}
              searchValue={searchValue}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default SubAdmins;
