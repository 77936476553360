import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import CustomInput from "components/Shared/CustomInput/Input";

import useClasses from "hooks/useClasses";

import styles from "./styles";

const validationSchema = yup.object({
  display_name: yup.string("Enter name").required("Name is required"),
});

const UpdateProfile = ({ user, updateHandler }) => {
  const formik = useFormik({
    initialValues: {
      display_name: user ? user.display_name : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    updateHandler(values);
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* name */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Name:</InputLabel>
          <CustomInput
            id="display_name"
            name="display_name"
            label="Enter Name"
            value={formik.values.display_name}
            onChange={formik.handleChange}
            error={
              formik.touched.display_name && Boolean(formik.errors.display_name)
            }
            helperText={
              formik.touched.display_name && formik.errors.display_name
            }
            type="text"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default UpdateProfile;
