import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel, Input } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import CustomInput from "components/Shared/CustomInput/Input";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";

import useClasses from "hooks/useClasses";

import styles from "./styles";
import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";

const validationSchema = yup.object({
  name: yup.string("Enter name").required("Name is required"),
  name_ar: yup.string("Enter name arabic").required("Name arabic is required"),
  state_id: yup
    .number("choose one of the states")
    .required("State is required"),
});

const City = ({ states, city, isCreate, createHandler, updateHandler }) => {
  const formik = useFormik({
    initialValues: {
      name: city ? city.name : "",
      name_ar: city ? city.name_ar : "",
      state_id: city ? city.state.id : "",
      is_active: city ? city.is_active : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    if (isCreate) {
      createHandler(values);
    } else {
      const result = { ...values };
      result.id = city.id;
      updateHandler(result);
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* name */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name:</InputLabel>
          <CustomInput
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </Grid>
        {/* name_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name Arabic:</InputLabel>
          <CustomInput
            id="name_ar"
            name="name_ar"
            label="Enter Name Arabic"
            value={formik.values.name_ar}
            onChange={formik.handleChange}
            error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
            helperText={formik.touched.name_ar && formik.errors.name_ar}
            type="text"
          />
        </Grid>
        {/* State */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>State:</InputLabel>
          <Select
            id="state_id"
            name="state_id"
            label="State"
            className={classes.selectInput}
            value={formik.values.state_id}
            onChange={formik.handleChange}
            input={<Input />}
            inputProps={{ "aria-label": "Without label" }}
          >
            {states.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                <ListItemText primary={state.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {/* is_active */}
        <Grid item xs={12}>
          <CustomSwitch
            name="is_active"
            value={formik.values.is_active}
            onChangeHandler={formik.handleChange}
            title="Active"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default City;
