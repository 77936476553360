import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Grid } from "@mui/material";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import UpdateProfile from "components/Profile/UpdateProfile";
import ChangePassword from "components/Profile/ChangePassword";

import { updateProfile, changePassword } from "store/actions/profile";

const Profile = () => {
  const authData = useSelector((state) => state.auth.authData);
  const dispatch = useDispatch();
  const history = useHistory();

  const updateProfileHandler = (newData) => {
    dispatch(updateProfile(newData, history));
  };

  const changePasswordHandler = (newData) => {
    dispatch(changePassword(newData, history));
  };

  return (
    <div>
      {authData && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader color="primary">
                <h4>Edit Profile</h4>
              </CardHeader>
              <CardBody>
                <UpdateProfile
                  user={authData}
                  updateHandler={updateProfileHandler}
                />
              </CardBody>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader color="primary">
                <h4>Change Password</h4>
              </CardHeader>
              <CardBody>
                <ChangePassword updateHandler={changePasswordHandler} />
              </CardBody>
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Profile;
