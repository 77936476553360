import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Branch from "components/Admin/Branches/Branch/Branch";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { updateBranch, getBranch } from "store/actions/branches";

const BranchContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { branch, companies, days, categories } = useSelector(
    (state) => state.branches
  );
  useEffect(() => {
    dispatch(getBranch(id, history));
  }, [id, dispatch]);

  const updateHandler = (data) => {
    data.id = id;
    dispatch(updateBranch(data, history));
  };

  return (
    <div>
      <div>
        <Button
          onClick={history.goBack}
          color="transparent"
          style={{ padding: 0 }}
        >
          <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
          <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
        </Button>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4>Branch Info</h4>
        </CardHeader>
        <CardBody>
          {branch && companies && days && categories && (
            <Branch
              categories={categories}
              branch={branch}
              companiesList={companies}
              daysList={days}
              isCreate={false}
              handleUpdate={updateHandler}
              handleCreate={null}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default BranchContainer;
