import React from "react";
import useClasses from "hooks/useClasses";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import Search from "@mui/icons-material/Search";
import CustomInput from "components/Shared/CustomInput/CustomInput.js";
import Button from "components/Shared/CustomButtons/Button.js";

const styles = (theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    marginRight: "20px",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  searchContainer: {
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "75%",
    padding: "0 10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "block",
      marginBottom: "10px",
    },
  },
  searchInput: {
    margin: "0 10px",
    flex: 3,
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
  },
  searchInputContainer: {
    margin: "0 10px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flex: 3,
    [theme.breakpoints.down("md")]: {
      margin: "0",
      width: "100%",
      marginBottom: "10px",
    },
  },
  searchByContainer: {
    margin: "0 10px",
    flex: 2,
    [theme.breakpoints.down("md")]: {
      margin: "0 ",
      width: "100%",
      marginBottom: "10px",
    },
  },
  searchByInput: {
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginBottom: "10px",
    },
  },
  filterContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
    padding: "0 10px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "block",
      marginBottom: "10px",
    },
  },
  filterInput: {
    marginRight: "10px",
    flex: 3,
    [theme.breakpoints.down("md")]: {
      marginBottom: "20px",
      width: "100%",
    },
  },
  filterButton: {
    flex: 1,
    fontSize: "12px",
    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "100%",
      margin: "10px auto",
      marginBottom: "20px",
    },
  },
  clearButton: {
    width: "15%",
    fontSize: "12px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      display: "block",
      width: "100%",
      margin: "10px auto",
      marginBottom: "20px",
    },
  },
});

const EnhancedTableToolbar = (props) => {
  const classes = useClasses(styles);
  const {
    numSelected,
    setSearchHandler,
    searchHandler,
    clearSearchHandler,
    searchValue,
    selectedSearchBy,
    handleSearchByChange,
    selectedToolbarButtons,
    toolbarButtons,
    showSearchBy,
    searchByOptions,
    showFilter,
    filterTitle,
    selectedFilter,
    handleFilterChange,
    filterOptions,
    handleFilterReset,
    hideToolbar,
  } = props;

  const handleSearchBy = (event) => {
    handleSearchByChange(event.target.value);
  };

  return (
    <>
      {!hideToolbar && (
        <Toolbar className={classes.root}>
          {numSelected > 0 ? (
            <Typography
              className={classes.title}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {numSelected} selected
            </Typography>
          ) : null}

          {numSelected > 0 ? (
            <div>{selectedToolbarButtons}</div>
          ) : (
            <div className={classes.container}>
              {toolbarButtons}

              <div className={classes.searchContainer}>
                <div className={classes.searchInputContainer}>
                  <CustomInput
                    formControlProps={{
                      className: classes.searchInput,
                    }}
                    inputProps={{
                      placeholder: "Search",
                      inputProps: {
                        "aria-label": "Search",
                      },
                      onChange: setSearchHandler,
                      value: searchValue,
                    }}
                  />
                  <Button
                    className={classes.searchButton}
                    color="transparent"
                    aria-label="search"
                    justIcon
                    round
                    onClick={searchHandler}
                  >
                    <Search />
                  </Button>
                </div>
                {showSearchBy && (
                  <div className={classes.searchByContainer}>
                    <InputLabel shrink htmlFor="search-by">
                      Search By
                    </InputLabel>
                    <NativeSelect
                      className={classes.searchByInput}
                      value={selectedSearchBy}
                      onChange={handleSearchBy}
                      inputProps={{
                        name: "search-by",
                        id: "search-by",
                      }}
                    >
                      {searchByOptions.map((option, index) => (
                        <option key={index} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                )}
                <Button
                  className={classes.clearButton}
                  color="secondary"
                  onClick={clearSearchHandler}
                >
                  clear
                </Button>
              </div>

              {showFilter && (
                <div className={classes.filterContainer}>
                  <div className={classes.filterInputContainer}>
                    <InputLabel shrink htmlFor="filter-input">
                      {filterTitle}
                    </InputLabel>
                    <NativeSelect
                      className={classes.filterInput}
                      value={selectedFilter}
                      onChange={handleFilterChange}
                      inputProps={{ id: "filter-input" }}
                    >
                      <option value="">None</option>
                      {filterOptions.map((option, index) => (
                        <option key={index} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>

                  <Button
                    className={classes.filterButton}
                    color="secondary"
                    aria-label="edit"
                    justIcon
                    round
                    onClick={handleFilterReset}
                  >
                    reset
                  </Button>
                </div>
              )}
            </div>
          )}
        </Toolbar>
      )}
    </>
  );
};

export default EnhancedTableToolbar;
