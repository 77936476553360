const styles = (theme) => ({
  disabled: {
    "&:before": {
      backgroundColor: "transparent !important",
    },
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: theme.palette.grey[4] + " !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: theme.palette.primary.main,
    },
  },
  underlineError: {
    "&:after": {
      borderColor: theme.palette.error.main,
    },
  },
  underlineSuccess: {
    "&:after": {
      borderColor: theme.palette.success.main,
    },
  },
  labelRoot: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey[3] + " !important",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    lineHeight: theme.typography.lineHeight,
    letterSpacing: "unset",
  },
  labelRootError: {
    color: theme.palette.error.main,
  },
  labelRootPrimary: {
    color: theme.palette.primary.main,
  },
  labelRootSuccess: {
    color: theme.palette.success.main,
  },
  feedback: {
    position: "absolute",
    top: "18px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "24px",
    height: "24px",
    textAlign: "center",
    pointerEvents: "none",
  },
  marginTop: {
    marginTop: "16px",
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  customInput: {
    // "&::-webkit-autofill": {
    WebkitBoxShadow: "none!important",
    // }
  },
});

export default styles;
