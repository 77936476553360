import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/Admin/SubCategories/SubCategories";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import {
  getSubCategories,
  createSubCategory,
  updateSubCategory,
  deleteSubCategory,
} from "store/actions/subCategories";

const SubCategories = () => {
  const subCategoriesData = useSelector(
    (state) => state.subCategories.subCategories
  );
  const allCategories = useSelector((state) => state.subCategories.categories);
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    subCategoriesData ? subCategoriesData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    subCategoriesData ? subCategoriesData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    subCategoriesData ? subCategoriesData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    subCategoriesData ? subCategoriesData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    subCategoriesData ? subCategoriesData.pag.search_by : "name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getSubCategories(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteSubCategory(id, pag, history));
  };
  const createHandler = (newData) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(createSubCategory(newData, pag, history));
  };
  const updateHandler = (newData) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(updateSubCategory(newData, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Sub Categories Table</h4>
        </CardHeader>
        <CardBody>
          {subCategoriesData && allCategories && (
            <Table
              rows={subCategoriesData.data}
              totalRows={subCategoriesData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              createHandler={createHandler}
              updateHandler={updateHandler}
              deleteHandler={deleteHandler}
              categories={allCategories}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default SubCategories;
