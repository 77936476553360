import * as actionsType from "../actions/actionsType";

const locationsReducer = (
  state = {
    states: null,
    cities: null,
    allStates: null,
    districts: null,
    allCities: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_STATE:
      return { ...state, states: action.payload };
    case actionsType.GET_CITIES:
      return { ...state, cities: action.payload };
    case actionsType.GET_ALL_STATES:
      return { ...state, allStates: action.payload };
    case actionsType.GET_DISTRICTS:
      return { ...state, districts: action.payload };
    case actionsType.GET_ALL_CITIES:
      return { ...state, allCities: action.payload };
    default:
      return state;
  }
};

export default locationsReducer;
