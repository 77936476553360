const styles = (theme) => ({
  root: {
    flexWrap: "unset",
    position: "relative",
    padding: "20px 15px",
    lineHeight: "20px",
    marginBottom: "20px",
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderRadius: "3px",
    minWidth: "unset",
    maxWidth: "unset",
    boxShadow: theme.shadows[2],
  },
  top20: {
    top: "20px",
  },
  top40: {
    top: "40px",
  },
  info: {
    backgroundColor: theme.palette.info.main + "!important",
    color: theme.palette.info.contarstText + "!important",
    boxShadow: theme.shadows[1],
  },
  success: {
    backgroundColor: theme.palette.success.main + "!important",
    color: theme.palette.success.contarstText + "!important",
    boxShadow: theme.shadows[1],
  },
  warning: {
    backgroundColor: theme.palette.warning.main + "!important",
    color: theme.palette.warning.contarstText + "!important",
    boxShadow: theme.shadows[1],
  },
  danger: {
    backgroundColor: theme.palette.error.main + "!important",
    color: theme.palette.error.contarstText + "!important",
    boxShadow: theme.shadows[1],
  },
  primary: {
    backgroundColor: theme.palette.primary.main + "!important",
    color: theme.palette.primary.contarstText + "!important",
    boxShadow: theme.shadows[1],
  },
  message: {
    padding: "0",
    display: "block",
    maxWidth: "89%",
  },
  close: {
    width: "11px",
    height: "11px",
  },
  iconButton: {
    width: "24px",
    height: "24px",
    padding: "0px",
  },
  icon: {
    display: "block",
    left: "15px",
    position: "absolute",
    top: "50%",
    marginTop: "-15px",
    width: "30px",
    height: "30px",
  },
  infoIcon: {
    color: theme.palette.info.main + "!important",
  },
  successIcon: {
    color: theme.palette.success.main + "!important",
  },
  warningIcon: {
    color: theme.palette.warning.main + "!important",
  },
  dangerIcon: {
    color: theme.palette.error.main + "!important",
  },
  primaryIcon: {
    color: theme.palette.primary.main + "!important",
  },
  iconMessage: {
    paddingLeft: "50px",
    display: "block",
  },
});

export default styles;
