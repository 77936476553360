import React from "react";
// import useClasses from "hooks/useClasses";
import Modal from "components/Shared/CustomModal/CustomModal";
import Button from "components/Shared/CustomButtons/Button";
import Table from "components/Shared/CustomTable/Table/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import BatchStatus from "./BatchesStatus";
import SetPriceInput from "./SetPriceInput";
import CollectBatchInput from "./CollectBatchInput";

export default function ArchivedBatches({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  showBatch,

  approveBatch,
  rejectBatch,
  isApproved,
  collectBatch,
  isCollected,
}) {
  // const classes = useClasses(styles);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const selectedToolbarButtons = null;

  const handleApprove = (id, minPrice, maxPrice) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Approving this Batch</h5>
          <h6>
            Different between minimum and maximum must be less than 25% of
            maximum price{" "}
          </h6>
          <SetPriceInput
            minPrice={minPrice}
            maxPrice={maxPrice}
            approveHandler={(prices) => approveBatch(id, prices)}
            cancelHandler={() => setOpenModal(false)}
          />
        </div>
      );
    }
    setOpenModal(true);
  };
  const handleReject = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to reject the price of this Batch ? </h5>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="danger"
              size="lg"
              onClick={() => rejectBatch(id)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Reject
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };
  const handleCollect = (batchId) => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to Collect this Batch ? </h5>
        <CollectBatchInput
          collectHandler={(code) => collectBatch(batchId, code)}
          cancelHandler={() => setOpenModal(false)}
        />
      </div>
    );
    setOpenModal(true);
  };

  const toolbarButtons = null;

  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "Batch ID",
    },
    {
      id: "branch_name",
      sortable: true,
      label: "Branch Name",
    },
    {
      id: "number_of_recyclables",
      sortable: false,
      label: "Number of collectables",
    },
    {
      id: "price_min",
      sortable: true,
      label: "Min Price",
    },
    {
      id: "price_max",
      sortable: true,
      label: "Max Price",
    },
    {
      id: "Status",
      sortable: false,
      label: "Status",
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created At",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                Batch {row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.branch}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.number_of_recyclables} Collectables
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.price_min}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.price_max}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    minWidth: "350px",
                  }}
                >
                  <BatchStatus batch={row} />
                </div>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {!isApproved && !isCollected && (
                      <Tooltip title="Approve">
                        <IconButton
                          aria-label="approve"
                          onClick={() =>
                            handleApprove(row.id, row.price_min, row.price_max)
                          }
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                    )}

                    {!isApproved && !isCollected && (
                      <Tooltip title="Reject">
                        <IconButton
                          aria-label="reject"
                          onClick={() => handleReject(row.id)}
                        >
                          <ClearIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    )}

                    {isApproved && !isCollected && (
                      <Tooltip title="Collect Batch">
                        <IconButton
                          aria-label="collect Batch"
                          onClick={() => handleCollect(row.id)}
                        >
                          <CheckIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                  <Tooltip title="open collectable details">
                    <IconButton
                      aria-label="show"
                      onClick={() => showBatch(row.id)}
                    >
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Batches
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={null}
        handleSearchByChange={null}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={false}
        searchByOptions={[]}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
        hideToolbar={true}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
