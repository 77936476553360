import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import MenuItem from "@mui/material/MenuItem";
import useClasses from "hooks/useClasses";

import styles from "./styles";

const validationSchema = yup.object({
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string("Enter name").required("Name is required"),
});

const CompanyAdminForm = ({
  isNewUser,
  createUser,
  updateUser,
  userUpdateData,
}) => {
  const formik = useFormik({
    initialValues: {
      email: isNewUser ? "" : userUpdateData.email,
      name: isNewUser ? "" : userUpdateData.display_name,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    if (isNewUser) {
      createUser({
        display_name: values.name,
        email: values.email,
      });
    } else {
      updateUser({
        display_name: values.name,
        email: values.email,
        id: userUpdateData.id,
      });
    }
  };
  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <GridContainer>
        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            Name:
          </FormLabel>
          <Input
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </GridItem>

        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            Email:
          </FormLabel>
          <Input
            id="email"
            name="email"
            label="Enter Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </GridItem>
        <GridItem xs={12}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isNewUser ? "Add" : "Edit"}
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default CompanyAdminForm;
