import React from "react";
import useClasses from "hooks/useClasses";
import styles from "./CollectableStyle";
import { Avatar } from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/Phone";

const Contact = ({ collectable }) => {
  const classes = useClasses(styles);
  return (
    <div>
      <h2 className={classes.sectionTitle}>Contact Data:</h2>
      <div className={classes.containerMain}>
        <div>
          <span className={classes.label}>Client:</span>
          <div className={classes.clientInfo}>
            <div className={classes.userImageContainer}>
              <Avatar
                alt={collectable.client_name}
                src={collectable.client_image}
                className={classes.userImage}
              >
                <span style={{ textTransform: "uppercase" }}>
                  {collectable.client_name[0]}
                </span>
              </Avatar>
            </div>
            <div style={{ flex: 1 }}>
              <span className={classes.content}>{collectable.client_name}</span>
            </div>
          </div>
        </div>
        <div>
          <span className={classes.label}>Phone:</span>
          <a href={`tel:${collectable.client_phone}`}>
            <PhoneInTalkIcon
              style={{
                width: "20px",
                height: "20px",
                verticalAlign: "middle",
                color: "green",
              }}
            />
            <span className={classes.content}>{collectable.client_phone}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
