import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";

const validationSchema = yup.object({
  minPrice: yup
    .number()
    .min(1, "Minimum Price must be greater than zero")
    .required("Minimum Price is required"),
  maxPrice: yup
    .number()
    .min(1, "Maximum Price must be greater than zero")
    .required("Maximum Price is required"),
});

const SetPriceInput = ({
  minPrice,
  maxPrice,
  approveHandler,
  cancelHandler,
}) => {
  const [customError, setCustomError] = useState("");
  const formik = useFormik({
    initialValues: {
      minPrice: minPrice ? minPrice : 0,
      maxPrice: maxPrice ? maxPrice : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = (values) => {
    setCustomError("");
    const difference = values.maxPrice - values.minPrice;
    const quarter = (values.maxPrice * 25) / 100;

    if (values.maxPrice <= values.minPrice) {
      setCustomError("Maximum price must be greater than minimum price");
    } else if (difference > quarter) {
      setCustomError(
        `Different between minimum and maximum (${difference}) must be less than 25% of maximum price (${quarter})`
      );
    } else {
      approveHandler(values);
    }
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        {customError.length !== 0 && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <span style={{ color: "#fff" }}>{customError}</span>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        {/* minPrice */}
        <Grid item xs={12}>
          <InputLabel
            color="primary"
            style={{
              textAlign: "left",
              fontWeight: "bolder",
              marginBottom: "20px",
            }}
          >
            Minimum Price
          </InputLabel>
          <Input
            id="minPrice"
            name="minPrice"
            label="Minimum Price"
            value={formik.values.minPrice}
            onChange={formik.handleChange}
            error={formik.touched.minPrice && Boolean(formik.errors.minPrice)}
            helperText={formik.touched.minPrice && formik.errors.minPrice}
            type="number"
          />
        </Grid>
        {/* maxPrice */}
        <Grid item xs={12}>
          <InputLabel
            color="primary"
            style={{
              textAlign: "left",
              fontWeight: "bolder",
              marginBottom: "20px",
            }}
          >
            Maximum Price
          </InputLabel>
          <Input
            id="maxPrice"
            name="maxPrice"
            label="Maximum Price"
            value={formik.values.maxPrice}
            onChange={formik.handleChange}
            error={formik.touched.maxPrice && Boolean(formik.errors.maxPrice)}
            helperText={formik.touched.maxPrice && formik.errors.maxPrice}
            type="number"
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-arround",
              alignItems: "center",
              width: "100%",
              margin: "20px auto",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              style={{ flex: 1, margin: "0 10px" }}
            >
              Approve
            </Button>
            <Button
              color="secondary"
              onClick={cancelHandler}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};

export default SetPriceInput;
