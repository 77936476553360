const styles = (theme) => ({
  container: {
    width: "100%",
    marginBottom: "20px",
  },
  searchInputContainer: {
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "500px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  searchInput: {
    flex: 1,
    marginRight: "30px!important",
    marginTop: "0!important",
    [theme.breakpoints.down("md")]: {
      display: "block!important",
      width: "100%!important",
      margin: "0!important",
    },
  },
  searchButton: {
    margin: "20px 10px 0",
    [theme.breakpoints.down("md")]: {
      display: "block!important",
      margin: "20px auto!important",
      width: "90%",
    },
    // display: "block!important",
  },
});

export default styles;
