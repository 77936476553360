import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getTutorials = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_TUTORIALS,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getTutorials(formData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({ type: actionsType.GET_TUTORIALS, payload: data });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteTutorial =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteTutorial({ ids: idsArray });
      dispatch(getTutorials(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "Tutorial deleted Successfully" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const changeStatusTutorial =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusTutorial({ ids: idsArray });
      dispatch(getTutorials(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Tutorial status Changed Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const createTutorial = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("title", newData.title);
    formNewData.append("sub_title", newData.sub_title);
    formNewData.append("bg_image", newData.bg_image);
    formNewData.append("fg_image", newData.fg_image);
    await api.createTutorial(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Tutorial has been created successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/tutorials/");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateTutorial = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("id", newData.id);
    formNewData.append("title", newData.title);
    formNewData.append("sub_title", newData.sub_title);
    if (newData.bg_image) {
      formNewData.append("bg_image", newData.bg_image);
    }
    if (newData.fg_image) {
      formNewData.append("fg_image", newData.fg_image);
    }
    await api.updateTutorial(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Tutorial has been updated successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/tutorials/");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getTutorial = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_TUTORIAL,
      payload: null,
    });
    const { data } = await api.getTutorial(id);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({ type: actionsType.GET_TUTORIAL, payload: data.data });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
