import * as actionsType from "../actions/actionsType";

const usersCompanyAdminReducer = (
  state = {
    allCompanies: null,
    companyAdmin: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_ALL_COMPANIES:
      return { ...state, allCompanies: action.payload };
    case actionsType.GET_USERS_COMPANY_ADMIN:
      return { ...state, companyAdmin: action.payload };
    default:
      return state;
  }
};

export default usersCompanyAdminReducer;
