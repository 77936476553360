import React from "react";
import useClasses from "hooks/useClasses";
import styles from "./CollectableStyle";
import VoucherPreview from "components/Shared/VoucherPreview/VoucherPreview";

const Benifit = ({ collectable }) => {
  const classes = useClasses(styles);
  return (
    <div>
      <h2 className={classes.sectionTitle}>Benifit Data:</h2>
      <div>
        <span className={classes.label}>Point:</span>
        {/* <span className={classes.content}>{collectable.point}</span> */}
        <span className={classes.content}>50</span>
      </div>
      {collectable.voucher_logo && collectable.voucher_discount_image && (
        <div>
          <span className={classes.label}>Voucher Preview:</span>
          <VoucherPreview
            isDark={false}
            logo={collectable.voucher_logo}
            discountImage={collectable.voucher_discount_image}
          />
        </div>
      )}
      {collectable.voucher_logo_dark &&
        collectable.voucher_discount_image_dark && (
          <div>
            <span className={classes.label}>Voucher Preview Dark:</span>
            <VoucherPreview
              isDark={true}
              logo={collectable.voucher_logo_dark}
              discountImage={collectable.voucher_discount_image_dark}
            />
          </div>
        )}
      {collectable.voucher_discount_type && (
        <div>
          <span className={classes.label}>Voucher Discount Type:</span>
          <span className={classes.content}>
            {collectable.voucher_discount_type}
          </span>
        </div>
      )}
      {collectable.voucher_discount_percentage && (
        <div>
          <span className={classes.label}>Voucher Discount Percentage:</span>
          <span className={classes.content}>
            {collectable.voucher_discount_percentage}
          </span>
        </div>
      )}
      {collectable.voucher_cap && (
        <div>
          <span className={classes.label}>Voucher CAP:</span>
          <span className={classes.content}>{collectable.voucher_cap}</span>
        </div>
      )}
      {collectable.voucher_discount_fixed && (
        <div>
          <span className={classes.label}>Voucher Discount Fixed:</span>
          <span className={classes.content}>
            {collectable.voucher_discount_fixed}
          </span>
        </div>
      )}
      <div>
        <span className={classes.label}>Qr Code:</span>
        <span className={classes.content}>
          {collectable.qr_code ? collectable.qr_code : "No Code"}
        </span>
      </div>
      <div>
        <span className={classes.label}>Voucher Code:</span>
        <span className={classes.content}>
          {collectable.voucher_code ? collectable.voucher_code : "No Code"}
        </span>
      </div>
      <div>
        <span className={classes.label}>Voucher used:</span>
        <span className={classes.content}>
          {collectable.voucher_is_used === 1 ? "Used" : "Not Used"}
        </span>
      </div>
    </div>
  );
};

export default Benifit;
