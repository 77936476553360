import React from "react";
import CouponBg from "assets/img/lightCouponBg.png";
import DarkCouponBg from "assets/img/darkCouponBg.png";
import useClasses from "hooks/useClasses";
import styles from "./styles";

function VoucherPreview({ isDark, logo, discountImage }) {
  const classes = useClasses(styles);
  return (
    <div className={classes.voucherImageContainer}>
      <div className={classes.bgContainer}>
        <img
          className={classes.bg}
          src={isDark ? DarkCouponBg : CouponBg}
          alt="CouponBg"
        />
      </div>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt="logo" />
      </div>
      <div className={classes.discountContainer}>
        <img className={classes.discount} src={discountImage} alt="discount" />
      </div>
    </div>
  );
}

export default VoucherPreview;
