import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import RecycableInfo from "components/CompanyAdmin/Collectables/Collectable/Collectable";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useDispatch, useSelector } from "react-redux";
import { getCollectable } from "store/actions/companyAdmin";

import useClasses from "hooks/useClasses";

const styles = (theme) => ({
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap",
  },
});

const Collectable = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useClasses(styles);
  const idsArray = useSelector((state) => state.companyAdmin.collectableIds);
  let positionId;
  if (idsArray) {
    positionId = idsArray.indexOf(id);
  }
  const selectedCollectable = useSelector(
    (state) => state.companyAdmin.collectable
  );
  useEffect(() => {
    dispatch(getCollectable(id, history));
  }, [id, dispatch]);

  const previousHandler = () => {
    history.replace(
      `/admin/company-branches/recycable/${idsArray[positionId - 1]}`
    );
  };
  const nextHandler = () => {
    history.replace(
      `/admin/company-branches/recycable/${idsArray[positionId + 1]}`
    );
  };

  return (
    <div>
      {selectedCollectable && (
        <div>
          <div>
            <Button
              onClick={history.goBack}
              color="transparent"
              style={{ padding: 0 }}
            >
              <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
              <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
            </Button>
          </div>
          <Card>
            <CardHeader color="primary">
              <h4>Recycable Info</h4>
            </CardHeader>
            <CardBody>
              <div className={classes.controlsContainer}>
                {typeof positionId !== "undefined" && (
                  <div>
                    <Button
                      color="secondary"
                      onClick={previousHandler}
                      disabled={positionId === 0}
                    >
                      <ArrowBackIcon
                        style={{ width: "25px", height: "25px" }}
                      />
                    </Button>
                  </div>
                )}
                {typeof idsArray !== "undefined" &&
                typeof positionId !== "undefined" &&
                positionId < idsArray.length - 1 ? (
                  <div>
                    <Button color="secondary" onClick={nextHandler}>
                      <ArrowForwardIcon
                        style={{ width: "25px", height: "25px" }}
                      />
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button color="secondary" onClick={history.goBack}>
                      Return
                    </Button>
                  </div>
                )}
              </div>
              <RecycableInfo collectable={selectedCollectable} />
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Collectable;
