import React, { useState } from "react";
import classNames from "classnames";
import useClasses from "hooks/useClasses";
import { NavLink } from "react-router-dom";

import styles from "./sidebarStyle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import SidebarSubLink from "./SidebarSubLink";
import Icon from "@mui/material/Icon";

const SidebarLink = ({ route, activeRoute, clickHandler }) => {
  const [showChild, setShowChild] = useState(false);
  const classes = useClasses(styles);
  let activePro = " ";
  let listTextClasses;
  listTextClasses = classNames({
    [" " + classes.primaryText]: activeRoute(route.layout + route.path),
  });
  let listItemClasses;
  listItemClasses = classNames({
    [" " + classes.primary]: activeRoute(route.layout + route.path),
  });
  const showChildHandler = () => {
    if (route.children) {
      setShowChild((prevShow) => !prevShow);
    } else {
      clickHandler();
    }
  };
  return (
    <div>
      <NavLink
        to={route.children ? "#" : route.layout + route.path}
        className={activePro + classes.item}
        activeClassName="active"
        onClick={showChildHandler}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          {typeof route.icon === "string" ? (
            <Icon
              className={classNames(
                classes.itemIcon,
                classes.whiteFont + listTextClasses
              )}
            >
              {route.icon}
            </Icon>
          ) : (
            <route.icon
              className={classNames(
                classes.itemIcon,
                classes.whiteFont + listTextClasses
              )}
            />
          )}
          <ListItemText
            primary={route.name}
            className={classNames(
              classes.itemText,
              classes.whiteFont + listTextClasses
            )}
            disableTypography={true}
          />
          {route.children &&
            (showChild ? (
              <ExpandMore
                className={classNames(
                  classes.itemIcon,
                  classes.whiteFont + listTextClasses
                )}
              />
            ) : (
              <ChevronRightIcon
                className={classNames(
                  classes.itemIcon,
                  classes.whiteFont + listTextClasses
                )}
              />
            ))}
        </ListItem>
      </NavLink>
      {route.children && showChild && (
        <List className={classes.list}>
          {route.children.map((prop, key) => (
            <SidebarSubLink
              parentRoute={route}
              route={prop}
              key={key}
              activeRoute={activeRoute}
              clickHandler={clickHandler}
            />
          ))}
        </List>
      )}
    </div>
  );
};

export default SidebarLink;
