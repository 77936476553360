import * as api from "../api";
import * as actionsType from "./actionsType";
import { getMe } from "./profile";
import { errorHandler } from "./general";

export const login = (formData, history) => async (dispatch) => {
  try {
    const { data } = await api.login(formData);
    const authData = data.data;
    const userData = data.data.user;
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.AUTH, payload: userData });
    localStorage.setItem("token", authData.access_token);
    const now = new Date().getTime();
    const exp = now + authData.expires_in * 1000;
    localStorage.setItem("exp", exp);
    history.push("/admin");
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Login Successfully" },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    localStorage.removeItem("token");
    history.push("/");
    dispatch(errorHandler(error, history));
  }
};

export const getAccount = (history) => async (dispatch) => {
  try {
    const token = localStorage.getItem("token");
    const exp = localStorage.getItem("exp");
    const now = new Date().getTime();
    if (token && exp) {
      if (exp < now) {
        localStorage.removeItem("token");
        localStorage.removeItem("exp");
        history.push("/");
      } else if (exp - now <= 86400000) {
        dispatch({
          type: actionsType.SET_LOADING,
          payload: true,
        });
        const { data } = await api.refreshToken();
        const authData = data.data;
        const userData = data.data.user;
        dispatch({ type: actionsType.AUTH, payload: userData });
        dispatch({
          type: actionsType.SET_LOADING,
          payload: false,
        });
        localStorage.setItem("token", authData.access_token);
        const newExp = now + authData.expires_in * 1000;
        localStorage.setItem("exp", newExp);
        history.push("/admin");
        dispatch({
          type: actionsType.SET_MESSAGE,
          payload: { type: "success", text: "Login Successfully" },
        });
      } else {
        dispatch(getMe(history));
      }
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("exp");
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const userLogout = (history) => async (dispatch) => {
  try {
    await api.logout();
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "logout successfully" },
    });
    localStorage.removeItem("token");
    localStorage.removeItem("exp");
    dispatch({ type: actionsType.LOGOUT });
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("exp");
    console.log(error);
  }
};

export const forgotPass = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.forgetPassword(formData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: data.message },
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const resetPass = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.resetPassword(formData);
    dispatch({ type: actionsType.AUTH, payload: data.data.user });
    localStorage.setItem("token", data.data.access_token);
    const now = new Date().getTime();
    const exp = now + data.data.expires_in * 1000;
    localStorage.setItem("exp", exp);
    history.push("/");
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Login Successfully" },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const setPass = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.setPassword(formData);
    history.push("/login");
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "password set Successfully" },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
