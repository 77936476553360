import * as actionsType from "../actions/actionsType";

const archivedBatchesReducer = (
  state = {
    waitingBatches: null,
    approvedBatches: null,
    rejectedBatches: null,
    batchesItems: null,
    recyclersList: null,
    collectableIds: null,
    collectedBatches: null,
    archivedBatch: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_WAITING_BATCHES:
      return {
        ...state,
        waitingBatches: action.payload,
      };
    case actionsType.GET_APPROVED_BATCHES:
      return {
        ...state,
        approvedBatches: action.payload,
      };
    case actionsType.GET_COLLECTED_BATCHES:
      return {
        ...state,
        collectedBatches: action.payload,
      };
    case actionsType.GET_REJECTED_BATCHES:
      return {
        ...state,
        rejectedBatches: action.payload,
      };
    case actionsType.GET_ARCHIVED_BATCH_ITEMS:
      return {
        ...state,
        batchesItems: action.payload,
      };
    case actionsType.GET_ARCHIVED_BATCH:
      return {
        ...state,
        archivedBatch: action.payload,
      };
    case actionsType.GET_RECYCLERS_LIST:
      return {
        ...state,
        recyclersList: action.payload,
      };
    case actionsType.SET_ITEMS_ARRAY:
      return {
        ...state,
        collectableIds: action.payload,
      };
    default:
      return state;
  }
};

export default archivedBatchesReducer;
