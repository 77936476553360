import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Table from "components/Admin/Collectables/Collectables";

import { useDispatch, useSelector } from "react-redux";
import {
  getArchivedBatchItems,
  getRecyclersList,
  approveBatch,
  rejectBatch,
  reAssignRecycler,
} from "store/actions/archivedBatches";
import BatchInfo from "components/Admin/ArchivedBatches/BatchInfo";

const ArchivedItems = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { batchesItems, archivedBatch, recyclersList } = useSelector(
    (state) => state.archivedBatches
  );
  const [page, setPage] = useState(batchesItems ? batchesItems.pag.page : 1);
  const [amount, setAmount] = useState(
    batchesItems ? batchesItems.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    batchesItems ? batchesItems.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    batchesItems ? batchesItems.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    batchesItems ? batchesItems.pag.search_by : "item_name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(getRecyclersList(history));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getArchivedBatchItems(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          archived_collectables_id: id,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/archived-batches/recycable/" + id);
  };

  const approveBatchHandler = () => {
    dispatch(
      approveBatch(
        id,
        {
          archived_collectables_id: id,
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history,
        2
      )
    );
  };
  const rejectBatchHandler = () => {
    dispatch(
      rejectBatch(
        id,
        {
          archived_collectables_id: id,
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history,
        2
      )
    );
  };
  const reAssignRecyclerHandler = (recyclerId) => {
    dispatch(
      reAssignRecycler(
        id,
        recyclerId,
        {
          archived_collectables_id: id,
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history,
        2
      )
    );
  };
  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        {archivedBatch && (
          <Card>
            <CardHeader color="primary">
              <h4>Batch #{id}</h4>
            </CardHeader>
            <CardBody>
              <BatchInfo
                archivedBatch={archivedBatch}
                approveBatch={approveBatchHandler}
                rejectBatch={rejectBatchHandler}
                reAssignRecycler={reAssignRecyclerHandler}
                recyclersList={recyclersList}
              />
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader color="primary">
            <h4>Batch #{id}'s Recycables List</h4>
          </CardHeader>
          <CardBody>
            {batchesItems && (
              <div>
                <Table
                  rows={batchesItems.data}
                  totalRows={batchesItems.pag.total}
                  rowsPerPage={amount}
                  setRowsPerPage={setAmount}
                  order={orderType}
                  setOrder={setOrderType}
                  orderBy={orderField}
                  setOrderBy={setOrderField}
                  page={page - 1}
                  setPage={setPage}
                  showRecycable={showRecycableHandler}
                  searchHandler={setSearchValue}
                  searchValue={searchValue}
                  selectedSearchBy={searchBy}
                  handleSearchByChange={setSearchBy}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ArchivedItems;
