import * as actionsType from "../actions/actionsType";

const subCategoriesReducer = (
  state = {
    subCategories: null,
    categories: null,
    priorities: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_SUB_CATEGORIES:
      return {
        ...state,
        subCategories: action.payload,
      };
    case actionsType.GET_ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case actionsType.GET_ALL_PRIORITIES:
      return {
        ...state,
        priorities: action.payload,
      };
    default:
      return state;
  }
};

export default subCategoriesReducer;
