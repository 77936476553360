import React, { useState } from "react";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import useClasses from "hooks/useClasses";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import styles from "./styles";

const CustomEditor = ({ name, value, handleChange }) => {
  const blocksFromHtml = htmlToDraft(value);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );
  const classes = useClasses(styles);
  const handleEditorChange = (state) => {
    setEditorState(state);
    let currentContentAsHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    handleChange({ target: { name, value: currentContentAsHTML } });
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleEditorChange}
      wrapperClassName={classes.wrapperClass}
      editorClassName={classes.editorClass}
      toolbarClassName={classes.toolbarClass}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "textAlign",
          "colorPicker",
          "link",
          "emoji",
          "remove",
          "history",
        ],
      }}
    />
  );
};
export default CustomEditor;
