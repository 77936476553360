const styles = (theme) => ({
  container: {
    margin: "20px 5px",
  },
  imageContainer: {
    margin: "10px 0",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  image: {
    width: "100px",
    height: "100px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
      margin: "0 auto",
    },
  },
  infoContainer: {
    margin: "10px 0",
    flex: 1,
  },
  infoFlexContainer: {
    margin: "10px 0",
    display: "flex",
    justifyContent: "space-arround",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  label: {
    fontWeight: 900,
    fontSize: "18px",
    marginRight: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  content: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  singleAddressContainer: {
    margin: "10px 5px",
  },
});

export default styles;
