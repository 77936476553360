import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Table from "components/Manager/Collectables/Archived/ArchivedItems";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Button from "components/Shared/CustomButtons/Button.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { getArchivedItemsManager } from "store/actions/managers";

const ArchivedItems = () => {
  const { id } = useParams();
  const archivedItemsData = useSelector((state) => state.manager.archivedItems);
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    archivedItemsData ? archivedItemsData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    archivedItemsData ? archivedItemsData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    archivedItemsData ? archivedItemsData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    archivedItemsData ? archivedItemsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    archivedItemsData ? archivedItemsData.pag.search_by : "item_name"
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getArchivedItemsManager(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          archived_collectables_id: id,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  // const ExportHandler = () => {
  //   dispatch(
  //     exportArchive(
  //       {
  //         page,
  //         amount,
  //         order_field: orderField,
  //         order_type: orderType,
  //         search_value: searchValue,
  //         search_by: searchBy,
  //         date_from: startDate,
  //         date_to: endDate,
  //       },
  //       history
  //     )
  //   );
  // };
  const showRecycableHandler = (id) => {
    history.push("/admin/recycables/show/" + id);
  };

  return (
    <div>
      <div>
        <Button
          onClick={history.goBack}
          color="transparent"
          style={{ padding: 0 }}
        >
          <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
          <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
        </Button>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4>Batch #{id}'s Collectables </h4>
        </CardHeader>
        <CardBody>
          {archivedItemsData && (
            <Table
              rows={archivedItemsData.data}
              totalRows={archivedItemsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              showRecycable={showRecycableHandler}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ArchivedItems;
