import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel, Switch } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import CustomInput from "components/Shared/CustomInput/Input";
import FormLabel from "@mui/material/FormLabel";

import useClasses from "hooks/useClasses";

import styles from "./styles";

const validationSchema = yup.object({
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string("Enter name").required("Name is required"),
  company: yup
    .string("Enter Recycler company")
    .required("Company name is required"),
});

const RecyclerForm = ({
  isNewUser,
  createUser,
  updateUser,
  userUpdateData,
}) => {
  const formik = useFormik({
    initialValues: {
      email: isNewUser ? "" : userUpdateData.email,
      name: isNewUser ? "" : userUpdateData.display_name,
      company: isNewUser ? "" : userUpdateData.company_name,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);
  const handleSubmit = (values) => {
    if (isNewUser) {
      createUser({
        display_name: values.name,
        email: values.email,
        company_name: values.company,
      });
    } else {
      updateUser({
        display_name: values.name,
        email: values.email,
        company_name: values.company,
        id: userUpdateData.id,
      });
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Name:
          </FormLabel>
          <CustomInput
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Email:
          </FormLabel>
          {isNewUser ? (
            <CustomInput
              id="email"
              name="email"
              label="Enter Email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          ) : (
            <span style={{ marginLeft: "10px" }}>{formik.values.email}</span>
          )}
        </Grid>

        <Grid item xs={12}>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Company Name (Recycler's Company):
          </FormLabel>
          <CustomInput
            id="company"
            name="company"
            label="Enter Company Name"
            type="text"
            value={formik.values.company}
            onChange={formik.handleChange}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={formik.touched.company && formik.errors.company}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isNewUser ? "Add" : "Edit"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RecyclerForm;
