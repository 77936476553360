const styles = (theme) => ({
  indicator: {
    backgroundColor: theme.palette.primary.main + "!important"
  },
  cutomTab: {
    fontWeight: 900
  },
  selected: {
    color: theme.palette.primary.main + "!important",
    borderBottomColor: theme.palette.primary.main + "!important"
  }
});

export default styles;
