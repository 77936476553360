import React from "react";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RestoreIcon from "@mui/icons-material/Restore";

export default function Collectables({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,

  showRecycable,
}) {
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const selectedToolbarButtons = null;

  const searchByOptions = [
    {
      value: "item_name",
      label: "Item Name",
    },
    {
      value: "client_name",
      label: "Client Name",
    },
    {
      value: "client_phone",
      label: "Client phone",
    },
    {
      value: "qr_code",
      label: "QR Code",
    },
    {
      value: "branch_name",
      label: "Branch Name",
    },
  ];
  const headCells = [
    {
      id: "item_name",
      sortable: true,
      label: "Item Name",
    },
    {
      id: "user_name",
      sortable: true,
      label: "User Name",
    },
    {
      id: "user_phone",
      sortable: true,
      label: "User Phone",
    },
    {
      id: "branch_name",
      sortable: true,
      label: "Branch Name",
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created Date",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.item_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.client_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.client_phone}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.branch_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <Tooltip title="open collectable details">
                    <IconButton
                      aria-label="show"
                      onClick={() => showRecycable(row.id)}
                    >
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Collectables Found
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={null}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
