import React from "react";
// import useClasses from "hooks/useClasses";
import Table from "components/Shared/CustomTable/Table/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function ArchivedBatches({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  showItems,
}) {
  // const classes = useClasses(styles);
  const selectedToolbarButtons = null;

  const toolbarButtons = null;

  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "Batch ID",
    },
    {
      id: "branch_name",
      sortable: true,
      label: "Branch Name",
    },
    {
      id: "number_of_recyclables",
      sortable: false,
      label: "Number of collectables",
    },
    {
      id: "recycler_name",
      sortable: true,
      label: "Assigned Recycler",
    },
    {
      id: "Status",
      sortable: false,
      label: "Status",
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created At",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                Batch #{row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.branch}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.number_of_recyclables} Collectables
              </TableCell>

              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.recycler}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_collected === 0 ? (
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      minWidth: "350px",
                    }}
                  >
                    <h3>Not Collected</h3>
                  </div>
                ) : (
                  <div>
                    <h3>Collected By {row.recycler}</h3>
                  </div>
                )}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <Tooltip title="open collectable details">
                  <IconButton
                    aria-label="show"
                    onClick={() => showItems(row.id)}
                  >
                    <VisibilityIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Batches
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={null}
        handleSearchByChange={null}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={false}
        searchByOptions={[]}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
        hideToolbar={true}
      />
      {/* <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal> */}
    </React.Fragment>
  );
}
