import React from "react";
import useClasses from "hooks/useClasses";
import styles from "./CollectableStyle";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Images from "./Images";

const Main = ({ collectable }) => {
  const classes = useClasses(styles);
  return (
    <div>
      <h2 className={classes.sectionTitle}>Recycable Main Data:</h2>
      <div>
        <span className={classes.label}>Name:</span>
        <span className={classes.content}>{collectable.item_name}</span>
      </div>
      <div>
        <span className={classes.label}>Category:</span>
        <span className={classes.content}>{collectable.category}</span>
      </div>
      <div>
        <span className={classes.label}>Sub Category:</span>
        <span className={classes.content}>{collectable.sub_category}</span>
      </div>
      <div>
        <span className={classes.label}>Brand:</span>
        <span className={classes.content}>{collectable.brand}</span>
      </div>
      <div>
        <span className={classes.label}>Count:</span>
        <span className={classes.content}>{collectable.item_count}</span>
      </div>
      <div>
        <span className={classes.label}>Condition:</span>
        <span className={classes.content}>
          {collectable.condition + " " + collectable.condition_ar}
        </span>
      </div>
      <Images collectable={collectable} />
    </div>
  );
};

export default Main;
