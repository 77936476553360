import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "components/CompanyAdmin/Users/Branches/Branches";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyBranchUsers,
  createNewCompanyBranchUser,
  deleteCompanyBranchUser,
  getCompanyAllBranches,
} from "store/actions/companyAdminUsers";

const Agents = () => {
  const usersBranchesData = useSelector(
    (state) => state.companyAdminUsers.branchUsers
  );
  const allBranchesData = useSelector(
    (state) => state.companyAdmin.allCompanyBranches
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    usersBranchesData ? usersBranchesData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    usersBranchesData ? usersBranchesData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    usersBranchesData ? usersBranchesData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    usersBranchesData ? usersBranchesData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    usersBranchesData ? usersBranchesData.pag.search_by : "display_name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getCompanyBranchUsers(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  useEffect(() => {
    dispatch(getCompanyAllBranches(history));
  }, [dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteCompanyBranchUser(id, pag, history));
  };
  const createHandler = (newUser) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(createNewCompanyBranchUser(newUser, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Company Branch Users</h4>
        </CardHeader>
        <CardBody>
          {usersBranchesData && (
            <Table
              rows={usersBranchesData.data}
              totalRows={usersBranchesData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              deleteUser={deleteHandler}
              createUser={createHandler}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              branches={allBranchesData}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Agents;
