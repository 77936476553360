import * as api from "../api";
import * as actionsType from "./actionsType";

export const errorHandler = (error, history) => (dispatch) => {
  dispatch({
    type: actionsType.SET_LOADING,
    payload: false,
  });
  console.log(error);
  let errorsMessage = "";
  let errors = error?.response?.data?.errors;
  if (errors) {
    if (errors.length !== 0) {
      errors.map((err) => (errorsMessage += " " + err));
    }
  }
  if (errorsMessage.length === 0) {
    errorsMessage = "Something went wrong";
  }

  let message = error?.response?.status?.toString() + " " + errorsMessage;
  dispatch({
    type: actionsType.SET_MESSAGE,
    payload: { type: "danger", text: message },
  });
  if (error) {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.removeItem("token");
        history.push("/");
      }
    }
  }
};
