import React, { useState, useEffect } from "react";
import useClasses from "hooks/useClasses";

import Chip from "@mui/material/Chip";

import styles from "./styles";

import CouponBg from "assets/img/lightCouponBg.png";
import DarkCouponBg from "assets/img/darkCouponBg.png";
import VoucherPreview from "components/Shared/VoucherPreview/VoucherPreview";

const VoucherInfo = ({ voucher, branches, categories }) => {
  const classes = useClasses(styles);
  const [selectedBranches, setSelectedBranches] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  useEffect(() => {
    if (voucher) {
      if (voucher.is_all_branches === 1) {
        setSelectedBranches(branches);
      } else {
        setSelectedBranches(voucher.branches);
      }
      if (voucher.is_all_categories === 1) {
        setSelectedCategories(categories);
      } else {
        setSelectedCategories(voucher.categories);
      }
    }
  }, [voucher]);

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <div className={classes.childContainer}>
          {/* main info */}
          <div className={classes.contentContainer}>
            {voucher.is_active === 0 ? (
              <Chip
                key="notActive"
                label="Not Active"
                classes={{ root: classes.chipRed }}
              />
            ) : (
              <Chip
                key="active"
                label="Active"
                classes={{ root: classes.chipGreen }}
              />
            )}
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Collection Name: </span>
            <span className={classes.title}>{voucher.collection_name}</span>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>title: </span>
            <span className={classes.content}>{voucher.title}</span>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>title Arabic: </span>
            <span className={classes.content}>{voucher.title_ar}</span>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Description: </span>
            <div
              className={classes.htmlContainer}
              dangerouslySetInnerHTML={{ __html: voucher.description }}
            />
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Description Arabic: </span>
            <div
              className={classes.htmlContainer}
              dangerouslySetInnerHTML={{ __html: voucher.description_ar }}
            />
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Categories: </span>
            <div className={classes.chips}>
              {selectedCategories.map((category) => (
                <Chip
                  key={category.id}
                  label={category.name}
                  classes={{ root: classes.chip }}
                />
              ))}
            </div>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Company: </span>
            <span className={classes.content}>{voucher.company}</span>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Branches: </span>
            <div className={classes.chips}>
              {selectedBranches.map((branch) => (
                <Chip
                  key={branch.id}
                  label={branch.name}
                  classes={{ root: classes.chip }}
                />
              ))}
            </div>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>
              Available per month for user:{" "}
            </span>
            <span className={classes.content}>
              {voucher.available_per_month_for_user}
            </span>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Reminder: </span>
            <span className={classes.content}>{voucher.reminder} Days</span>
          </div>
        </div>
        <div className={classes.childContainer}>
          {/* discount info  */}
          <div className={classes.contentContainer}>
            <p className={classes.label}>Type of Voucher Codes: </p>
            {voucher.is_single_voucher === 0 ? (
              <Chip
                key="multiCode"
                label="Multi Codes Voucher"
                classes={{ root: classes.chipPurple }}
              />
            ) : (
              <>
                <Chip
                  key="singleCode"
                  label="Single Code Voucher"
                  classes={{ root: classes.chipBlue }}
                />
                <div className={classes.contentContainer}>
                  <span className={classes.label}>Available Count: </span>
                  <span className={classes.content}>
                    {voucher.available_count}
                  </span>
                </div>
                <div className={classes.contentContainer}>
                  <span className={classes.label}>Available Code: </span>
                  <span className={classes.content}>{voucher.code}</span>
                </div>
                <div className={classes.contentContainer}>
                  <span className={classes.label}>Validity Period: </span>
                  <span className={classes.content}>
                    {voucher.validity_period}
                  </span>
                </div>
              </>
            )}
          </div>
          <div className={classes.contentContainer}>
            <p className={classes.label}>Discount Type: </p>
            {voucher.discount_type === 0 ? (
              <>
                <Chip
                  key="fixedDiscount"
                  label="Fixed Discount"
                  classes={{ root: classes.chipPurple }}
                />
                <div className={classes.contentContainer}>
                  <span className={classes.label}>Fixed Discount: </span>
                  <span className={classes.content}>
                    {voucher.discount_fixed}
                  </span>
                </div>
              </>
            ) : (
              <>
                <Chip
                  key="percentageDiscount"
                  label="Percentage Discount"
                  classes={{ root: classes.chipPurple }}
                />
                <div className={classes.contentContainer}>
                  <span className={classes.label}>Percentage Discount: </span>
                  <span className={classes.content}>
                    {voucher.discount_percentage}
                  </span>
                </div>
                <div className={classes.contentContainer}>
                  <span className={classes.label}>Cap: </span>
                  <span className={classes.content}>{voucher.cap}</span>
                </div>
              </>
            )}
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Voucher Image: </span>
            <VoucherPreview
              isDark={false}
              logo={voucher.logo}
              discountImage={voucher.discount}
            />
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Voucher Image Arabic: </span>
            <VoucherPreview
              isDark={false}
              logo={voucher.logo_ar}
              discountImage={voucher.discount_ar}
            />
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Voucher Image Dark: </span>
            <VoucherPreview
              isDark={true}
              logo={voucher.logo_dark}
              discountImage={voucher.discount_dark}
            />
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Voucher Image Arabic Dark: </span>
            <VoucherPreview
              isDark={true}
              logo={voucher.logo_ar_dark}
              discountImage={voucher.discount_ar_dark}
            />
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Badge: </span>
            <span className={classes.content}>{voucher.badge}</span>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Badge Arabic: </span>
            <span className={classes.content}>{voucher.badge_ar}</span>
          </div>
          <div className={classes.contentContainer}>
            <span className={classes.label}>Badge Background Color: </span>
            <div
              className={classes.colorPreview}
              style={{
                backgroundColor: voucher.badge_bg_color,
                width: "150px",
                height: "50px",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherInfo;
