import React from "react";
import classNames from "classnames";
import useClasses from "hooks/useClasses";

import styles from "./cardStyle";

export default function Card(props) {
  const classes = useClasses(styles);
  const { className, children, plain, profile, chart, ...rest } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardProfile]: profile,
    [classes.cardChart]: chart,
    [className]: className !== undefined,
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}
