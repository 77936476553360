const styles = (theme) => ({
  container: {
    margin: "20px 5px",
  },
  mainInfoContainer: {
    padding: "20px",
  },
  contentContainer: {
    margin: "10px 0",
  },
  label: {
    fontWeight: 900,
    fontSize: "18px",
    marginRight: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  content: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
});

export default styles;
