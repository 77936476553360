import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "components/Admin/Collectables/Collectables";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getExpired,
  bounceCollectable,
  reviveExpired,
} from "store/actions/collectables";

const Expired = () => {
  const expiredData = useSelector((state) => state.collectables.expired);
  const dispatch = useDispatch();
  const [page, setPage] = useState(expiredData ? expiredData.pag.page : 1);
  const [amount, setAmount] = useState(
    expiredData ? expiredData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    expiredData ? expiredData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    expiredData ? expiredData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    expiredData ? expiredData.pag.search_by : "item_name"
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getExpired(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/recycables/show/" + id);
  };

  const bounceRecycableHandler = (id) => {
    dispatch(
      bounceCollectable(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };
  const reviveRecycableHandler = (id) => {
    dispatch(
      reviveExpired(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Expired Collectables</h4>
        </CardHeader>
        <CardBody>
          {expiredData && (
            <Table
              rows={expiredData.data}
              totalRows={expiredData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchValue={searchValue}
              searchHandler={setSearchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              isSelectable
              canRevive
              showRecycable={showRecycableHandler}
              bounceRecycable={bounceRecycableHandler}
              reviveRecycable={reviveRecycableHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Expired;
