import React from "react";
import useClasses from "hooks/useClasses";
import { CircularProgress } from "@mui/material";
import styles from "./style";
import { useSelector } from "react-redux";

export default function CustomLoading(props) {
  const classes = useClasses(styles);
  const loading = useSelector((state) => state.home.loading);
  return (
    <>
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress className={classes.loading} />
        </div>
      )}
    </>
  );
}
