const styles = (theme) => ({
  footer: {
    flex: "1",
    paddingLeft: "30px",
  },
  a: {
    textDecoration: "none",
    color: theme.palette.primary.contentText,
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.text.primary,
    },
    "&:focus": {
      color: theme.palette.primary.contentText,
    },
  },
  footerLinks: {
    display: "flex",
    justifyContent: "space-arround",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
});

export default styles;
