import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import useClasses from "hooks/useClasses";

import styles from "./styles";
import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";
import CustomEditor from "components/Shared/CustomEditor/CustomEditor";
import ColorPicker from "components/Shared/ColorPicker/ColorPicker";
import VoucherPreview from "components/Shared/VoucherPreview/VoucherPreview";

const validationSchema = yup.object({
  collection_name: yup
    .string("Enter Collection Name")
    .required("Collection Name is required"),
  title: yup.string("Enter title").required("title is required"),
  title_ar: yup
    .string("Enter title arabic")
    .required("title arabic is required"),
  description: yup
    .string("Enter description")
    .required("Description is required"),
  description_ar: yup
    .string("Enter description arabic")
    .required("Description arabic is required"),
  discount_type: yup.number().min(0).max(1).required("Error in Type Discount"),
  discount_fixed: yup
    .string()
    .nullable()
    .when("type_discount", {
      is: 0,
      then: yup
        .string("Enter Discount Fixed")
        .required("Dicount Fixed is required"),
      otherwise: null,
    }),
  discount_percentage: yup
    .string()
    .nullable()
    .when("type_discount", {
      is: 1,
      then: yup
        .string("Enter discount precentage")
        .required("discount percentage is required"),
      otherwise: null,
    }),
  validity_period: yup
    .number()
    .nullable()
    .when("is_single_voucher", {
      is: 1,
      then: yup.number().min(1).required("Error in Validity period"),
      otherwise: null,
    }),
  code: yup
    .string()
    .nullable()
    .when("is_single_voucher", {
      is: 1,
      then: yup.string("Enter the code").required("the code  is required"),
      otherwise: null,
    }),
  reminder: yup
    .number()
    .typeError("Please enter a valid number")
    .integer("reminder can't include a decimal point")
    .required("reminder is required"),
  cap: yup.string().when("type_discount", {
    is: 1,
    then: yup.string("Enter Cap").required("Cap is required"),
    otherwise: null,
  }),
  available_count: yup.number().when("is_single_voucher", {
    is: 1,
    then: yup.number().min(1).required("Error in avaible count"),
    otherwise: null,
  }),
  available_per_month_for_user: yup
    .number()
    .min(1)
    .required("Error in avaible per month for user"),
  is_active: yup.number().min(0).max(1).required("Error in is Active"),
  is_single_voucher: yup
    .number()
    .min(0)
    .max(1)
    .required("Error in is Single Voucher"),
  company_id: yup.number().required("Error in company id is required"),
});

const CreateEditVoucher = ({
  isCreate,
  companies,
  createHandler,
  voucher,
  updateHandler,
}) => {
  // images of the form
  // logo
  const [logo, setLogo] = useState(
    voucher
      ? voucher.logo
        ? { file: null, data_url: voucher.logo }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [logoError, setLogoError] = useState(null);
  // logo_ar
  const [logoAr, setLogoAr] = useState(
    voucher
      ? voucher.logo_ar
        ? { file: null, data_url: voucher.logo_ar }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [logoArError, setLogoArError] = useState(null);
  // logo_dark
  const [logoDark, setLogoDark] = useState(
    voucher
      ? voucher.logo_dark
        ? { file: null, data_url: voucher.logo_dark }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [logoDarkError, setLogoDarkError] = useState(null);
  // logo_ar_dark
  const [logoArDark, setLogoArDark] = useState(
    voucher
      ? voucher.logo_ar_dark
        ? { file: null, data_url: voucher.logo_ar_dark }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [logoArDarkError, setLogoArDarkError] = useState(null);
  // discount
  const [discount, setDiscount] = useState(
    voucher
      ? voucher.discount
        ? { file: null, data_url: voucher.discount }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [discountError, setDiscountError] = useState(null);
  // discount_ar
  const [discountAr, setDiscountAr] = useState(
    voucher
      ? voucher.discount_ar
        ? { file: null, data_url: voucher.discount_ar }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [discountArError, setDiscountArError] = useState(null);
  const [discountDark, setDiscountDark] = useState(
    voucher
      ? voucher.discount_dark
        ? { file: null, data_url: voucher.discount_dark }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [discountDarkError, setDiscountDarkError] = useState(null);
  const [discountArDark, setDiscountArDark] = useState(
    voucher
      ? voucher.discount_ar_dark
        ? { file: null, data_url: voucher.discount_ar_dark }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [discountArDarkError, setDiscountArDarkError] = useState(null);

  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files) {
      if (
        e.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type.includes("csv")
      ) {
        setFile(e.target.files[0]);
        setFileError(null);
      } else {
        setFileError("Error file type");
      }
    } else {
      setFileError("Error file");
    }
  };

  const formik = useFormik({
    initialValues: {
      id: !isCreate ? (voucher ? voucher.id : "") : "",
      collection_name: voucher ? voucher.collection_name : "",
      title: voucher ? voucher.title : "",
      title_ar: voucher ? voucher.title_ar : "",
      description: voucher ? voucher.description : "",
      description_ar: voucher ? voucher.description_ar : "",
      discount_type: voucher ? voucher.discount_type : 0,
      discount_fixed: voucher ? voucher.discount_fixed : "",
      discount_percentage: voucher ? voucher.discount_percentage : "",
      validity_period: voucher ? voucher.validity_period : 1,
      code: voucher ? voucher.code : "",
      reminder: voucher ? voucher.reminder : 1,
      cap: voucher ? voucher.cap : "",
      available_count: voucher ? voucher.available_count : 1,
      available_per_month_for_user: voucher
        ? voucher.available_per_month_for_user
        : 1,
      is_active: voucher ? voucher.is_active : 0,
      is_single_voucher: voucher ? voucher.is_single_voucher : 0,
      badge: voucher ? (voucher.badge ? voucher.badge : "") : "",
      badge_ar: voucher ? (voucher.badge_ar ? voucher.badge_ar : "") : "",
      badge_bg_color: voucher
        ? voucher.badge_bg_color
          ? voucher.badge_bg_color
          : ""
        : "",
      company_id: voucher ? voucher.company_id : 1,
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    const result = { ...values };
    let error = false;
    if (logo.file !== null) {
      result.logo = logo.file;
    } else if (logo.data_url === null) {
      setLogoError("Enter Logo!");
      error = true;
    }
    if (logoAr.file !== null) {
      result.logo_ar = logoAr.file;
    } else if (logoAr.data_url === null) {
      setLogoArError("Enter Logo Arabic !");
      error = true;
    }
    if (logoDark.file !== null) {
      result.logo_dark = logoDark.file;
    } else if (logoDark.data_url === null) {
      setLogoDarkError("Enter Logo Dark!");
      error = true;
    }
    if (logoArDark.file !== null) {
      result.logo_ar_dark = logoArDark.file;
    } else if (logoArDark.data_url === null) {
      setLogoArDarkError("Enter Logo Arabic Dark!");
      error = true;
    }
    if (discount.file !== null) {
      result.discount = discount.file;
    } else if (discount.data_url === null) {
      setDiscountError("Enter Discount Image!");
      error = true;
    }
    if (discountAr.file !== null) {
      result.discount_ar = discountAr.file;
    } else if (discountAr.data_url === null) {
      setDiscountArError("Enter Discount Arabic Image!");
      error = true;
    }
    if (discountDark.file !== null) {
      result.discount_dark = discountDark.file;
    } else if (discountDark.data_url === null) {
      setDiscountDarkError("Enter Discount Image Dark!");
      error = true;
    }
    if (discountArDark.file !== null) {
      result.discount_ar_dark = discountArDark.file;
    } else if (discountArDark.data_url === null) {
      setDiscountDarkError("Enter Discount Arabic Image Dark!");
      error = true;
    }
    if (result.is_single_voucher === 0) {
      if (file !== null) {
        result.file = file;
      }
    }
    if (result.badge !== "" && result.badge_ar === "") {
      formik.setFieldError("badge_ar", "badge Arabic can't be empty");
      error = true;
    }
    if (result.badge !== "" && result.badge_bg_color === "") {
      formik.setFieldError("badge_bg_color", "badge BG Color can't be empty");
      error = true;
    }
    if (error === false) {
      if (isCreate) {
        createHandler(result);
      } else {
        updateHandler(result);
      }
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {(logoError !== null ||
          logoArError !== null ||
          logoDarkError !== null ||
          logoArDarkError !== null ||
          discountError !== null ||
          discountArError !== null ||
          discountDarkError !== null ||
          discountArDarkError !== null ||
          fileError !== null) && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {logoError !== null && (
                  <li key="logo" style={{ color: "#fff" }}>
                    {logoError}
                  </li>
                )}
                {logoArError !== null && (
                  <li key="logoAr" style={{ color: "#fff" }}>
                    {logoArError}
                  </li>
                )}
                {logoDarkError !== null && (
                  <li key="logoDark" style={{ color: "#fff" }}>
                    {logoDarkError}
                  </li>
                )}
                {logoArDarkError !== null && (
                  <li key="logoArDark" style={{ color: "#fff" }}>
                    {logoArDarkError}
                  </li>
                )}
                {discountError !== null && (
                  <li key="discount" style={{ color: "#fff" }}>
                    {discountError}
                  </li>
                )}
                {discountArError !== null && (
                  <li key="discountAr" style={{ color: "#fff" }}>
                    {discountArError}
                  </li>
                )}
                {discountDarkError !== null && (
                  <li key="discountDark" style={{ color: "#fff" }}>
                    {discountDarkError}
                  </li>
                )}
                {discountArDarkError !== null && (
                  <li key="discountArDark" style={{ color: "#fff" }}>
                    {discountADarkError}
                  </li>
                )}
                {fileError !== null && (
                  <li key="fileError" style={{ color: "#fff" }}>
                    {fileError}
                  </li>
                )}
              </ul>
            </div>
          </Grid>
        )}
        {Object.keys(formik.errors).length !== 0 && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {Object.keys(formik.errors).map((error, index) => (
                  <li key={index} style={{ color: "#fff" }}>
                    {formik.errors[error]}
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        )}
        {/* collection_name */}
        <Grid item xs={12} lg={4}>
          <InputLabel className={classes.label}>Collection Name:</InputLabel>
          <Input
            id="collection_name"
            name="collection_name"
            label="Enter Collection Name"
            value={formik.values.collection_name}
            onChange={formik.handleChange}
            error={
              formik.touched.collection_name &&
              Boolean(formik.errors.collection_name)
            }
            helperText={
              formik.touched.collection_name && formik.errors.collection_name
            }
            type="text"
          />
        </Grid>
        {/* title */}
        <Grid item xs={12} sm={6} lg={4}>
          <InputLabel className={classes.label}>Title:</InputLabel>
          <Input
            id="title"
            name="title"
            label="Enter Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            type="text"
          />
        </Grid>
        {/* title_ar */}
        <Grid item xs={12} sm={6} lg={4}>
          <InputLabel className={classes.label}>Title Arabic:</InputLabel>
          <Input
            id="title_ar"
            name="title_ar"
            label="Enter Title Arabic"
            value={formik.values.title_ar}
            onChange={formik.handleChange}
            error={formik.touched.title_ar && Boolean(formik.errors.title_ar)}
            helperText={formik.touched.title_ar && formik.errors.title_ar}
            type="text"
          />
        </Grid>
        {/* Description English */}
        <Grid item xs={12}>
          <InputLabel
            className={classes.label}
            style={
              formik.touched.description && Boolean(formik.errors.description)
                ? { color: red }
                : {}
            }
          >
            Description English:
          </InputLabel>
          <CustomEditor
            name="description"
            value={formik.values.description}
            handleChange={formik.handleChange}
          />
          {formik.touched.description && formik.errors.description && (
            <span style={{ color: "red" }}>{formik.errors.description}</span>
          )}
        </Grid>
        {/* Description Arabic */}
        <Grid item xs={12}>
          <InputLabel
            className={classes.label}
            style={
              formik.touched.description_ar &&
              Boolean(formik.errors.description_ar)
                ? { color: red }
                : {}
            }
          >
            Description Arabic:
          </InputLabel>
          <CustomEditor
            name="description_ar"
            value={formik.values.description_ar}
            handleChange={formik.handleChange}
          />
          {formik.touched.description_ar && formik.errors.description_ar && (
            <span style={{ color: "red" }}>{formik.errors.description_ar}</span>
          )}
        </Grid>
        <Grid item xs={12}>
          <h6 className={classes.label} style={{ fontSize: "1.5rem" }}>
            Discount
          </h6>
        </Grid>
        {/* discount_type */}
        <Grid item xs={12}>
          <CustomSwitch
            name="discount_type"
            value={formik.values.discount_type}
            onChangeHandler={formik.handleChange}
            title={
              formik.values.discount_type === 0
                ? "Fixed Discount"
                : "Percentage Discount"
            }
          />
        </Grid>
        {formik.values.discount_type === 0 ? (
          <>
            {/* Fixed discount */}
            <Grid item xs={12}>
              <InputLabel className={classes.label}>Fixed Dicount:</InputLabel>
              <Input
                id="discount_fixed"
                name="discount_fixed"
                label="Enter Fixed Dicount"
                value={formik.values.discount_fixed}
                onChange={formik.handleChange}
                error={
                  formik.touched.discount_fixed &&
                  Boolean(formik.errors.discount_fixed)
                }
                helperText={
                  formik.touched.discount_fixed && formik.errors.discount_fixed
                }
                type="text"
              />
            </Grid>
          </>
        ) : (
          <>
            {/* Percentage discount */}
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.label}>
                Percentage Dicount:
              </InputLabel>
              <Input
                id="discount_percentage"
                name="discount_percentage"
                label="Enter Percentage Dicount"
                value={formik.values.discount_percentage}
                onChange={formik.handleChange}
                error={
                  formik.touched.discount_percentage &&
                  Boolean(formik.errors.discount_percentage)
                }
                helperText={
                  formik.touched.discount_percentage &&
                  formik.errors.discount_percentage
                }
                type="text"
              />
            </Grid>
            {/* Cap */}
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.label}>Cap:</InputLabel>
              <Input
                id="cap"
                name="cap"
                label="Enter Cap"
                value={formik.values.cap}
                onChange={formik.handleChange}
                error={formik.touched.cap && Boolean(formik.errors.cap)}
                helperText={formik.touched.cap && formik.errors.cap}
                type="text"
              />
            </Grid>
          </>
        )}
        {/* reminder  */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>
            Reminders (Number of Days):
          </InputLabel>
          <Input
            id="reminder"
            name="reminder"
            label="Enter Reminder"
            value={formik.values.reminder}
            onChange={formik.handleChange}
            error={formik.touched.reminder && Boolean(formik.errors.reminder)}
            helperText={formik.touched.reminder && formik.errors.reminder}
            type="number"
          />
        </Grid>
        {/* available_per_month_for_user  */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>
            available per month for user:
          </InputLabel>
          <Input
            id="available_per_month_for_user"
            name="available_per_month_for_user"
            label="Enter available per month for user"
            value={formik.values.available_per_month_for_user}
            onChange={formik.handleChange}
            error={
              formik.touched.available_per_month_for_user &&
              Boolean(formik.errors.available_per_month_for_user)
            }
            helperText={
              formik.touched.available_per_month_for_user &&
              formik.errors.available_per_month_for_user
            }
            type="number"
          />
        </Grid>
        {/* is_active */}
        <Grid item xs={12}>
          <CustomSwitch
            name="is_active"
            value={formik.values.is_active}
            onChangeHandler={formik.handleChange}
            title="Active"
          />
        </Grid>
        <Grid item xs={12}>
          <h6 className={classes.label} style={{ fontSize: "1.5rem" }}>
            Images:
          </h6>
        </Grid>
        {/* Logo */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Logo:</InputLabel>
          <ImageUpload
            image={logo}
            setImage={setLogo}
            error={logoError}
            setError={setLogoError}
          />
        </Grid>
        {/* discount_image */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Discount image:</InputLabel>
          <ImageUpload
            image={discount}
            setImage={setDiscount}
            error={discountError}
            setError={setDiscountError}
          />
        </Grid>
        {/* voucher preview */}
        <Grid item xs={12}>
          {logo.data_url !== null &&
            discount.data_url !== null &&
            typeof logo.data_url !== "undefined" &&
            typeof discount.data_url !== "undefined" && (
              <VoucherPreview
                isDark={false}
                logo={logo.data_url}
                discountImage={discount.data_url}
              />
            )}
        </Grid>
        {/* LogoAr */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Logo Arabic:</InputLabel>
          <ImageUpload
            image={logoAr}
            setImage={setLogoAr}
            error={logoArError}
            setError={setLogoArError}
          />
        </Grid>
        {/* discount_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>
            Discount Arabic image:
          </InputLabel>
          <ImageUpload
            image={discountAr}
            setImage={setDiscountAr}
            error={discountArError}
            setError={setDiscountArError}
          />
        </Grid>
        {/* voucher ar preview */}
        <Grid item xs={12}>
          {logoAr.data_url !== null &&
            discountAr.data_url !== null &&
            typeof logoAr.data_url !== "undefined" &&
            typeof discountAr.data_url !== "undefined" && (
              <VoucherPreview
                isDark={false}
                logo={logoAr.data_url}
                discountImage={discountAr.data_url}
              />
            )}
        </Grid>
        {/* logo_dark */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>logo Dark:</InputLabel>
          <ImageUpload
            image={logoDark}
            setImage={setLogoDark}
            error={logoDarkError}
            setError={setLogoDarkError}
          />
        </Grid>
        {/* discount_dark */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>
            Discount Image Dark:
          </InputLabel>
          <ImageUpload
            image={discountDark}
            setImage={setDiscountDark}
            error={discountDarkError}
            setError={setDiscountDarkError}
          />
        </Grid>
        {/* voucher dark preview */}
        <Grid item xs={12}>
          {logoDark.data_url !== null &&
            discountDark.data_url !== null &&
            typeof logoDark.data_url !== "undefined" &&
            typeof discountDark.data_url !== "undefined" && (
              <VoucherPreview
                isDark={true}
                logo={logoDark.data_url}
                discountImage={discountDark.data_url}
              />
            )}
        </Grid>
        {/* logo_ar_dark */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>logo Arabic Dark:</InputLabel>
          <ImageUpload
            image={logoArDark}
            setImage={setLogoArDark}
            error={logoArDarkError}
            setError={setLogoArDarkError}
          />
        </Grid>
        {/* discount_ar_dark */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>
            Discount Arabic Image Dark:
          </InputLabel>
          <ImageUpload
            image={discountArDark}
            setImage={setDiscountArDark}
            error={discountArDarkError}
            setError={setDiscountArDarkError}
          />
        </Grid>
        {/* voucher dark preview */}
        <Grid item xs={12}>
          {logoArDark.data_url !== null &&
            discountArDark.data_url !== null &&
            typeof logoArDark.data_url !== "undefined" &&
            typeof discountArDark.data_url !== "undefined" && (
              <VoucherPreview
                isDark={true}
                logo={logoArDark.data_url}
                discountImage={discountArDark.data_url}
              />
            )}
        </Grid>
        {/* badge */}
        <Grid item xs={12} sm={6} lg={4}>
          <InputLabel className={classes.label}>
            Badge Text (max 5 characters):
          </InputLabel>
          <Input
            id="badge"
            name="badge"
            label="Enter Badge"
            value={formik.values.badge}
            onChange={formik.handleChange}
            type="text"
            required={false}
          />
        </Grid>
        {/* badge_ar */}
        <Grid item xs={12} sm={6} lg={4}>
          <InputLabel className={classes.label}>
            Badge Text Arabic(max 5 characters):
          </InputLabel>
          <Input
            id="badge_ar"
            name="badge_ar"
            label="Enter Badge Arabic"
            value={formik.values.badge_ar}
            onChange={formik.handleChange}
            required={false}
            type="text"
          />
        </Grid>
        {/* badge color */}
        <Grid item xs={12} lg={4}>
          <InputLabel className={classes.label}>Badge BG Color:</InputLabel>
          <ColorPicker
            name="badge_bg_color"
            color={formik.values.badge_bg_color}
            setColor={formik.handleChange}
          />
        </Grid>
        {/* company_id */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Company:</InputLabel>
          <Select
            id="company_id"
            name="company_id"
            label="Company"
            className={classes.selectInput}
            value={formik.values.company_id}
            onChange={formik.handleChange}
            multiple={false}
            inputProps={{ "aria-label": "Without label" }}
          >
            {companies.map((comp) => (
              <MenuItem key={comp.id} value={comp.id}>
                <ListItemText primary={comp.name} />
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>{MerchantsHelperText}</FormHelperText> */}
        </Grid>

        {/* is_single_voucher */}
        {isCreate && (
          <Grid item xs={12}>
            <CustomSwitch
              name="is_single_voucher"
              value={formik.values.is_single_voucher}
              onChangeHandler={formik.handleChange}
              title={
                formik.values.is_single_voucher === 1
                  ? "Single Code Voucher"
                  : "Multi Code Voucher "
              }
            />
          </Grid>
        )}
        {formik.values.is_single_voucher === 0 ? (
          <>
            {isCreate && (
              <>
                <Grid item xs={12}>
                  <h6 className={classes.label} style={{ fontSize: "1.5rem" }}>
                    Multi Codes Voucher
                  </h6>
                </Grid>
                {/* Fixed discount */}
                <Grid item xs={12}>
                  <InputLabel className={classes.label}>Codes File:</InputLabel>
                  <input
                    id="file"
                    onChange={handleFileChange}
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </Grid>
              </>
            )}
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <h6 className={classes.label} style={{ fontSize: "1.5rem" }}>
                Single Code Voucher
              </h6>
            </Grid>
            {/* code */}
            <Grid item xs={12} lg={4}>
              <InputLabel className={classes.label}>Code:</InputLabel>
              <Input
                id="code"
                name="code"
                label="Enter Code"
                value={formik.values.code}
                onChange={formik.handleChange}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                type="text"
              />
            </Grid>
            {/*  validity_period */}
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Validity Period (number of days):
              </InputLabel>
              <Input
                id="validity_period"
                name="validity_period"
                label="Enter Validity Period"
                value={formik.values.validity_period}
                onChange={formik.handleChange}
                error={
                  formik.touched.validity_period &&
                  Boolean(formik.errors.validity_period)
                }
                helperText={
                  formik.touched.validity_period &&
                  formik.errors.validity_period
                }
                type="number"
              />
            </Grid>
            {/* available_count  */}
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                available Count:
              </InputLabel>
              <Input
                id="available_count"
                name="available_count"
                label="Enter Available Count"
                value={formik.values.available_count}
                onChange={formik.handleChange}
                error={
                  formik.touched.available_count &&
                  Boolean(formik.errors.available_count)
                }
                helperText={
                  formik.touched.available_count &&
                  formik.errors.available_count
                }
                type="number"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateEditVoucher;
