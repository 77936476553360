import { object } from "prop-types";
import hexToRgb from "variables/hextoRGB";

const sidebarStyle = (theme) => ({
  drawerPaper: {
    border: "none",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    zIndex: "1",
    overflowX: "hidden",
    boxShadow: theme.shadows[3],
    width: "260px",
    [theme.breakpoints.up("lg")]: {
      width: "260px",
      position: "fixed",
      height: "100%",
    },
    [theme.breakpoints.down("md")]: {
      width: "260px",
      boxShadow: theme.shadows[3],
      position: "fixed",
      display: "block",
      top: "0",
      height: "100vh",
      right: "0",
      left: "auto",
      zIndex: "1032",
      visibility: "visible",
      overflowY: "visible",
      borderTop: "none",
      textAlign: "left",
      paddingRight: "0px",
      paddingLeft: "0",
      transform: `translate3d(260px, 0, 0)`,
      transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    },
  },
  logo: {
    position: "relative",
    padding: "15px 15px",
    zIndex: "4",
    "&:after": {
      content: '""',
      position: "absolute",
      bottom: "0",

      height: "1px",
      right: "15px",
      width: "calc(100% - 30px)",
    },
  },
  logoLink: {
    textTransform: "uppercase",
    padding: "5px 0",
    display: "block",
    fontSize: theme.typography.fontSize,
    textAlign: "left",
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.lineHeight,
    textDecoration: "none",
    backgroundColor: "transparent",
    "&,&:hover": {
      color: theme.palette.primary.main + "!important",
    },
  },
  logoImage: {
    width: "100%",
    display: "inline-block",
    maxHeight: "30px",
    margin: "0 auto",
  },
  img: {
    width: "100%",
    verticalAlign: "middle",
    border: "0",
  },
  background: {
    height: "100%",
    width: "100%",
    display: "block",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },
  list: {
    marginTop: "20px",
    paddingLeft: "0",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "0",
    listStyle: "none",
    position: "unset",
    backgroundColor:
      "rgba(" + hexToRgb(theme.palette.background.paper) + ", 0.8)",
    zIndex: 200,
  },
  item: {
    position: "relative",
    display: "inline",
    textDecoration: "none",
  },
  itemLink: {
    width: "auto",
    transition: "all 300ms linear",
    margin: "10px 15px 0",
    borderRadius: "3px",
    position: "relative",
    display: "flex",
    padding: "10px 15px",
    backgroundColor: "transparent",
    color: theme.palette.text.primary + "!important",
    "&:hover,&:focus,&:active": {
      backgroundColor: theme.palette.primary.main + "!important",
      color: theme.palette.primary.contentText + "!important",
    },
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    marginRight: "15px",
    textAlign: "center",
    verticalAlign: "middle",
  },
  itemText: {
    margin: "0",
    lineHeight: "30px",
    fontSize: "13px",
  },
  whiteFont: {
    // color: theme.palette.text.primary,
  },
  primary: {
    backgroundColor: theme.palette.primary.main + "!important",
    "&:hover,&:focus,&:active": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  primaryText: {
    color: theme.palette.primary.contentText + "!important",
  },
  sidebarWrapperContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "calc(100vh - 100px)",
  },
  sidebarWrapper: {
    // position: "relative",
    // height: "calc(100vh - 75px)",
    overflow: "auto",
    width: "260px",
    zIndex: "4",
    flex: 1,
    overflowScrolling: "touch",
  },
  activePro: {
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      width: "100%",
      bottom: "13px",
    },
  },
});

export default sidebarStyle;
