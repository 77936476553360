import React from "react";
import useClasses from "hooks/useClasses";
import Map from "./Map";
import styles from "./CollectableStyle";

const Company = ({ collectable }) => {
  const classes = useClasses(styles);
  return (
    <div>
      <h2 className={classes.sectionTitle}>Company Data:</h2>
      <div>
        <span className={classes.label}>Company:</span>
        <span className={classes.content}>{collectable.company}</span>
      </div>
      <div>
        <span className={classes.label}>Branch:</span>
        <span className={classes.content}>{collectable.branch}</span>
      </div>
      <div>
        <span className={classes.label}>Branch Address:</span>
        <span className={classes.content}>{collectable.branch_address}</span>
      </div>
      <div>
        <span className={classes.label}>Request Type:</span>
        <span className={classes.content}>
          {collectable.is_dropoff === 1
            ? "Dropoff"
            : collectable.is_pickup === 1
            ? "Pickup"
            : "not determine yet"}
        </span>
      </div>
      <div style={{ margin: "20px auto", height: "250px" }}>
        <Map
          lngProps={collectable.branch_lng}
          latProps={collectable.branch_lat}
          contentProps={`<div style="color: #191919"><p><strong>Address:</strong> ${collectable.branch_address}</p></div>`}
        />
      </div>
    </div>
  );
};

export default Company;
