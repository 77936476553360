import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getConditions = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_CONDITIONS,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getConditions(formData);
    dispatch({ type: actionsType.GET_CONDITIONS, payload: data });
    dispatch(getAllCategories(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteCondition =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteCondition({ ids: idsArray });
      dispatch(getConditions(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "Condition deleted Successfully" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getAllCategories = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_CONDITIONS_CATEGORIES,
      payload: null,
    });
    const { data } = await api.getAllCategories();

    dispatch({
      type: actionsType.GET_CONDITIONS_CATEGORIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createCondition =
  (newData, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.createCondition(newData);
      dispatch(getConditions(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "New Condition has been created successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const updateCondition =
  (newData, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.updateCondition(newData);
      dispatch(getConditions(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Condition has been updated successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
