import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import RecycableInfo from "components/Admin/Collectables/Collectable/Collectable";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import useClasses from "hooks/useClasses";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { useDispatch, useSelector } from "react-redux";
import {
  getCollectable,
  approveSingle,
  rejectSingle,
  bounceSingle,
} from "store/actions/collectables";

const styles = (theme) => ({
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap",
  },
});

const Collectable = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useClasses(styles);
  const idsArray = useSelector(
    (state) => state.companies.companyCollectablesIds
  );
  let positionId;
  if (idsArray) {
    positionId = idsArray.indexOf(id);
  }
  const selectedCollectable = useSelector(
    (state) => state.collectables.collectable
  );
  useEffect(() => {
    dispatch(getCollectable(id, history));
  }, [id, dispatch]);

  const approveHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to approve this Recycable ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="success"
            size="lg"
            onClick={approveRecycable}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Approve
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  const approveRecycable = () => {
    setOpenModal(false);
    dispatch(approveSingle(id, idsArray, history));
  };
  const rejectRecycable = () => {
    setOpenModal(false);
    dispatch(rejectSingle(id, idsArray, history));
  };
  const bounceRecycable = () => {
    setOpenModal(false);
    dispatch(bounceSingle(id, idsArray, history));
  };
  const previousHandler = () => {
    history.replace(`/admin/companies/recycable/${idsArray[positionId - 1]}`);
  };
  const nextHandler = () => {
    history.replace(`/admin/companies/recycable/${idsArray[positionId + 1]}`);
  };
  const rejectHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to Reject this recycable ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="danger"
            size="lg"
            onClick={rejectRecycable}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Reject
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  const bounceHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to Bounce this recycable ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="danger"
            size="lg"
            onClick={bounceRecycable}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Bounce
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  return (
    <div>
      {selectedCollectable && (
        <div>
          <div>
            <Button
              onClick={history.goBack}
              color="transparent"
              style={{ padding: 0 }}
            >
              <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
              <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
            </Button>
          </div>
          <Card>
            <CardHeader color="primary">
              <h4>Recycable Info</h4>
            </CardHeader>
            <CardBody>
              <div className={classes.controlsContainer}>
                {typeof positionId !== "undefined" && (
                  <div>
                    <Button
                      color="secondary"
                      onClick={previousHandler}
                      disabled={positionId === 0}
                    >
                      <ArrowBackIcon
                        style={{ width: "25px", height: "25px" }}
                      />
                    </Button>
                  </div>
                )}
                <div className={classes.controlsContainer}>
                  {(selectedCollectable.status === "Processing" ||
                    selectedCollectable.status === "Not Approved") && (
                    <Button color="primary" onClick={approveHandler}>
                      Approve
                    </Button>
                  )}
                  {selectedCollectable.status === "Processing" && (
                    <Button color="danger" onClick={rejectHandler}>
                      Reject
                    </Button>
                  )}
                  {selectedCollectable.is_collected === 1 &&
                    selectedCollectable.is_archived === 0 && (
                      <Button color="danger" onClick={bounceHandler}>
                        Bounce
                      </Button>
                    )}
                </div>
                {typeof idsArray !== "undefined" &&
                typeof positionId !== "undefined" &&
                positionId < idsArray.length - 1 ? (
                  <div>
                    <Button color="secondary" onClick={nextHandler}>
                      <ArrowForwardIcon
                        style={{ width: "25px", height: "25px" }}
                      />
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button color="secondary" onClick={history.goBack}>
                      Return
                    </Button>
                  </div>
                )}
              </div>
              <RecycableInfo collectable={selectedCollectable} />
            </CardBody>
          </Card>
        </div>
      )}
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default Collectable;
