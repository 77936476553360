import React from "react";
import useClasses from "hooks/useClasses";
import Table from "components/Shared/CustomTable/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import EnhancedTableHead from "./CollactablesTableHead";
import CollectInput from "./CollectInput";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

export default function EnhancedTable({
  rows,
  showRecycable,
  collectRecycable,
}) {
  const classes = useClasses(styles);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleCollect = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="primary"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to Collect this Recycable ? </h5>
          <CollectInput
            collectHandler={(notes) => collectRecycable(id, notes)}
            cancelHandler={() => setOpenModal(false)}
          />
        </div>
      );
    }
    setOpenModal(true);
  };

  const tableHead = <EnhancedTableHead classes={classes} />;
  return (
    <React.Fragment>
      <Table tableHead={tableHead}>
        {rows.length !== 0 ? (
          rows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow key={row.id} hover>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                  onClick={() => showRecycable(row.id)}
                >
                  {row.item_name}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                  onClick={() => showRecycable(row.id)}
                >
                  {row.client_name}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                  onClick={() => showRecycable(row.id)}
                >
                  {row.client_phone}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                  onClick={() => showRecycable(row.id)}
                >
                  {row.is_collected === 0 ? "Not Collected" : "Collected"}
                </TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  padding="normal"
                  align="center"
                  onClick={() => showRecycable(row.id)}
                >
                  {row.created_at}
                </TableCell>
                <TableCell component="th" padding="normal" align="center">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {row.is_collected === 0 && (
                        <Tooltip title="Collect">
                          <IconButton
                            aria-label="collect"
                            onClick={() => handleCollect(row.id)}
                          >
                            <CheckIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                    <Tooltip title="open collectable details">
                      <IconButton
                        aria-label="show"
                        onClick={() => showRecycable(row.id)}
                      >
                        <VisibilityIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell colSpan={12} align="center">
              No Collectable Found
            </TableCell>
          </TableRow>
        )}
      </Table>
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
