import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getCarousels = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_CAROUSELS,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCarousels(formData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({ type: actionsType.GET_CAROUSELS, payload: data });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteCarousel =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteCarousel({ ids: idsArray });
      dispatch(getCarousels(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "Carousel deleted Successfully" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const changeStatusCarousel =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusCarousel({ ids: idsArray });
      dispatch(getCarousels(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Carousel status Changed Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const createCarousel = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("title", newData.title);
    formNewData.append("title_ar", newData.title_ar);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    formNewData.append("bg_image", newData.bg_image);
    formNewData.append("bg_image_ar", newData.bg_image_ar);
    formNewData.append("redirect_url", newData.redirect_url);
    formNewData.append("is_active", newData.is_active);
    await api.createCarousel(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Carousel has been created successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/carousels/");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateCarousel = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("id", newData.id);
    formNewData.append("title", newData.title);
    formNewData.append("title_ar", newData.title_ar);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    if (newData.bg_image) {
      formNewData.append("bg_image", newData.bg_image);
    }
    if (newData.bg_image_ar) {
      formNewData.append("bg_image_ar", newData.bg_image_ar);
    }
    formNewData.append("redirect_url", newData.redirect_url);
    formNewData.append("is_active", newData.is_active);
    await api.updateCarousel(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Carousel has been updated successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/carousels/");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCarousel = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_CAROUSEL,
      payload: null,
    });
    const { data } = await api.getCarousel(id);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({ type: actionsType.GET_CAROUSEL, payload: data.data });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
