import * as actionsType from "../actions/actionsType";

const transactionsReducer = (
  state = { transactions: null, waitingTransactions: null },
  action
) => {
  switch (action.type) {
    case actionsType.GET_TRANSACTIONS:
      return { ...state, transactions: action.payload };
    case actionsType.GET_WAITING_TRANSACTIONS:
      return { ...state, waitingTransactions: action.payload };
    default:
      return state;
  }
};

export default transactionsReducer;
