const styles = (theme) => ({
  cardTitleWhite: {
    color: theme.palette.primary.main,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "900",
    marginBottom: "3px",
    textDecoration: "none",
  },
  successText: {
    color: theme.palette.success.main,
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px",
  },
  stats: {
    color: theme.palette.grey[0],
    display: "inline-flex",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px",
    },
  },
  cardCategory: {
    color: theme.palette.grey[0],
    margin: "0",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0",
  },
  cardCategoryWhite: {
    color: theme.palette.text.primary,
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitle: {
    color: theme.palette.grey[2],
    marginTop: "0px",
    minHeight: "auto",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: theme.palette.grey[1],
      fontWeight: "400",
      lineHeight: "1",
    },
  },
});

export default styles;
