const styles = (theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contentText,
  },
  content: {
    flex: "3",
    textAlign: "center",
  },
  title: {
    color: theme.palette.primary.contentText,
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
});

export default styles;
