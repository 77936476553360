import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Table from "components/Admin/Collectables/ArchivedBatches";

import { useDispatch, useSelector } from "react-redux";
import { getBranchArchivedBatches } from "store/actions/archivedBatches";

const BranchArchivedBatches = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { archivedBatches } = useSelector((state) => state.branches);
  const [page, setPage] = useState(
    archivedBatches ? archivedBatches.pag.page : 1
  );
  const [amount, setAmount] = useState(
    archivedBatches ? archivedBatches.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    archivedBatches ? archivedBatches.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    archivedBatches ? archivedBatches.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  // const classes = useClasses(styles);
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getBranchArchivedBatches(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          branch_id: id,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  const showItemsHandler = (id) => {
    history.push("/admin/branches/archived-items/" + id);
  };
  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4>Branch's Archived Batches</h4>
          </CardHeader>
          <CardBody>
            {archivedBatches && (
              <div>
                <Table
                  rows={archivedBatches.data}
                  totalRows={archivedBatches.pag.total}
                  rowsPerPage={amount}
                  setRowsPerPage={setAmount}
                  order={orderType}
                  setOrder={setOrderType}
                  orderBy={orderField}
                  setOrderBy={setOrderField}
                  page={page - 1}
                  setPage={setPage}
                  showItems={showItemsHandler}
                  searchHandler={setSearchValue}
                  searchValue={searchValue}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default BranchArchivedBatches;
