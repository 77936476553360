import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getArchivedWaitingRecycler =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_RECYCLER_WAITING_BATCHES,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.getArchivedWaitingRecycler(formData);
      dispatch({
        type: actionsType.GET_RECYCLER_WAITING_BATCHES,
        payload: data,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const getArchivedApprovedRecycler =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_RECYCLER_APPROVED_BATCHES,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.getArchivedApprovedRecycler(formData);
      dispatch({
        type: actionsType.GET_RECYCLER_APPROVED_BATCHES,
        payload: data,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const getArchivedCollectedRecycler =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_RECYCLER_COLLECTED_BATCHES,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.getArchivedCollectedRecycler(formData);
      dispatch({
        type: actionsType.GET_RECYCLER_COLLECTED_BATCHES,
        payload: data,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getArchivedItems = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_RECYCLER_ITEMS,
      payload: null,
    });
    dispatch({
      type: actionsType.GET_RECYCLER_BATCH,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.SET_RECYCLER_ARRAY,
      payload: [],
    });
    const res = await api.getArchivedBatch(formData);
    const archivedBatch = res.data.data;
    const { data } = await api.getArchivedBatchItems(formData);
    let idsArray = [];
    data.data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_RECYCLER_ITEMS,
      payload: data.data,
    });
    dispatch({
      type: actionsType.GET_RECYCLER_BATCH,
      payload: archivedBatch,
    });
    dispatch({
      type: actionsType.SET_RECYCLER_ARRAY,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCollectable = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_RECYCLER_COLLECTABLE,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCollectableRecycler(id);
    dispatch({
      type: actionsType.GET_RECYCLER_COLLECTABLE,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const collectBatch =
  (batchId, code, formData, history, location) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });

      await api.collectBatchRecycler({
        archived_collectables_id: batchId,
        code: code,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      if (location === 2) {
        dispatch(getArchivedItems(formData, history));
      } else if (location === 3) {
        dispatch(getArchivedApprovedRecycler(formData, history));
      }
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Batch Collected Successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const approveBatch =
  (batchData, formData, history, location) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.approveBatchRecycler({
        archived_collection_id: batchData.id,
        min_price: batchData.minPrice,
        max_price: batchData.maxPrice,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      if (location === 1) {
        dispatch(getArchivedWaitingRecycler(formData, history));
      } else if (location === 2) {
        dispatch(getArchivedItems(formData, history));
      }
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Batch price send successfully to the admin to be approved",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const rejectBatch =
  (batchId, formData, history, location) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.rejectBatchRecycler({
        archived_collection_id: batchId,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      history.push("/admin/recycler/waiting-batches");
      dispatch(getArchivedWaitingRecycler(formData, history));

      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Batch rejected successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
