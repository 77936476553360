const styles = (theme) => ({
  footer: {
    flex: "1",
    paddingRight: "30px",
    textAlign: "end",
  },
  a: {
    textDecoration: "none",
    color: theme.palette.text.primary,
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.text.primary,
    },
    "&:focus": {
      color: theme.palette.text.primary,
    },
  },
  footerLinks: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-arround",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
  },
});

export default styles;
