import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useClasses from "hooks/useClasses";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import styles from "./VouchersStyle";
import { useHistory } from "react-router-dom";
import VoucherInput from "components/Manager/Vouchers/VoucherInput";
import VoucherInfo from "components/Manager/Vouchers/VoucherInfo";
import { searchVoucherCode, setVoucherUsed } from "store/actions/managers";
import CustomLoading from "components/Shared/CustomLoading/CutomLoading";

export default function VoucherManager() {
  const voucherInfo = useSelector((state) => state.manager.managerVoucher);
  const isSearching = useSelector((state) => state.manager.voucherIsSearching);
  const searchingMessage = useSelector(
    (state) => state.manager.voucherSearchMessage
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useClasses(styles);

  const searchCodeHandler = (code) => {
    dispatch(searchVoucherCode({ code: code }, history));
  };

  const usedVoucherHandler = (code) => {
    dispatch(setVoucherUsed({ code: code }, history));
  };
  return (
    <div>
      <div>
        <Card>
          <CardHeader color="primary">
            <h4>Search for voucher</h4>
          </CardHeader>
          <CardBody style={{ minHeight: "300px" }}>
            <VoucherInput searchCode={searchCodeHandler} />
            <div>
              {isSearching && <CustomLoading />}
              {searchingMessage && (
                <div className={classes.messageContainer}>
                  <span className={classes.message}>{searchingMessage}</span>
                </div>
              )}
              {voucherInfo && (
                <VoucherInfo
                  voucherInfo={voucherInfo}
                  handleUse={usedVoucherHandler}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
