import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/Admin/Branches/Branches";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import {
  getBranches,
  changeStatusBranch,
  resetBranch,
  deleteBranch,
} from "store/actions/branches";

const Branches = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const branchData = useSelector((state) => state.branches.branches);
  const [page, setPage] = useState(branchData ? branchData.pag.page : 1);
  const [amount, setAmount] = useState(branchData ? branchData.pag.amount : 10);
  const [orderField, setOrderField] = useState(
    branchData ? branchData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    branchData ? branchData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    branchData ? branchData.pag.search_by : "name"
  );

  useEffect(() => {
    dispatch(
      getBranches(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  const showCollectedHandler = (id) => {
    history.push("/admin/branches/collected/" + id);
  };
  const showArchivedHandler = (id) => {
    history.push("/admin/branches/archived-batches/" + id);
  };
  const showNotCollectedHandler = (id) => {
    history.push("/admin/branches/not-collected/" + id);
  };

  const editBranchHandler = (id) => {
    history.push("/admin/branches/edit/" + id);
  };

  const addBranchHandler = () => {
    history.push("/admin/branches/add");
  };

  const changeStatusHandler = (id) => {
    dispatch(
      changeStatusBranch(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  const deleteBranchHandler = (id) => {
    dispatch(
      deleteBranch(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  const resetBranchHandler = (id) => {
    dispatch(
      resetBranch(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Drop-off Locations Table</h4>
        </CardHeader>
        <CardBody>
          {branchData && (
            <Table
              rows={branchData.data}
              totalRows={branchData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              showCollected={showCollectedHandler}
              showArchived={showArchivedHandler}
              showNotCollected={showNotCollectedHandler}
              editBranch={editBranchHandler}
              addBranch={addBranchHandler}
              changeStatus={changeStatusHandler}
              deleteBranch={deleteBranchHandler}
              resetBranch={resetBranchHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Branches;
