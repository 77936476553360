import * as actionsType from "../actions/actionsType";

const usersReducer = (
  state = {
    allBranches: null,
    usersRecyclers: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_USERS_RECYCLERS:
      return { ...state, usersRecyclers: action.payload };
    case actionsType.GET_ALL_BRANCHES_RECYCLERS:
      return { ...state, allBranches: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
