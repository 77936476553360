import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getSettings = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_SETTINGS, payload: null });
    const { data } = await api.getSettings();
    dispatch({ type: actionsType.GET_SETTINGS, payload: data.data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateSettings = (data, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.updateSettings(data);
    dispatch(getSettings(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
