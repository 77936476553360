import React, { Fragment } from "react";
import { Grid, InputLabel } from "@mui/material";
import Input from "components/Shared/CustomInput/Input";
import useClasses from "hooks/useClasses";

import styles from "./BranchStyle";

const Maps = ({
  lngProps,
  latProps,
  contentProps,
  onChangeHandler,
  errorLat,
  helperTextLat,
  errorLng,
  helperTextLng,
}) => {
  const classes = useClasses(styles);
  const mapRef = React.useRef(null);
  let google = window.google;
  let lat = latProps;
  let lng = lngProps;
  let map = null;
  let myLatlng = new google.maps.LatLng(lat, lng);
  React.useEffect(() => {
    map = mapRef.current;
    const mapOptions = {
      zoom: 16,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      mapId: process.env.REACT_APP_GOOGLE_MAP_API_ID,
    };
    map = new google.maps.Map(map, mapOptions);
  }, [mapRef]);
  React.useEffect(() => {
    if (map) {
      let marker = new google.maps.marker.AdvancedMarkerElement({
        position: myLatlng,
        map: map,
        // animation: google.maps.Animation.DROP,
        title: "Branch",
        // draggable: true,
      });

      marker.addListener("drag", (mapsMouseEvent) => {
        let lngLat = mapsMouseEvent.latLng.toJSON();
        let lat = { target: { name: "lat", value: lngLat.lat } };
        let lng = { target: { name: "lng", value: lngLat.lng } };
        onChangeHandler(lat);
        onChangeHandler(lng);
      });

      map.addListener("click", (mapsMouseEvent) => {
        let lngLat = mapsMouseEvent.latLng.toJSON();
        let lat = { target: { name: "lat", value: lngLat.lat } };
        let lng = { target: { name: "lng", value: lngLat.lng } };
        marker.setMap(null);
        myLatlng = new google.maps.LatLng(lngLat.lat, lngLat.lng);
        marker = new google.maps.marker.AdvancedMarkerElement({
          position: myLatlng,
          map: map,
          // animation: google.maps.Animation.DROP,
          title: "Branch",
          // draggable: true,
        });
        onChangeHandler(lat);
        onChangeHandler(lng);
      });
    }
  }, [lngProps, latProps, contentProps, map]);
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Latitude:</InputLabel>
          <Input
            id="lat"
            name="lat"
            label="Enter Latitude"
            value={latProps}
            onChange={onChangeHandler}
            error={errorLat}
            helperText={helperTextLat}
            type="text"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Longitude:</InputLabel>
          <Input
            id="lng"
            name="lng"
            label="Enter Longitude"
            value={lngProps}
            onChange={onChangeHandler}
            error={errorLng}
            helperText={helperTextLng}
            type="text"
          />
        </Grid>
      </Grid>
      <div style={{ height: `100vh`, marginTop: "20px" }} ref={mapRef} />
    </Fragment>
  );
};

export default Maps;
