const styles = (theme) => ({
  container: {
    width: "100%",
  },
  searchInputContainer: {
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "0",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  searchInput: {
    flex: 2,
  },
  searchButton: {
    margin: "20px 10px 0",
  },
});

export default styles;
