import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import MenuItem from "@mui/material/MenuItem";
import useClasses from "hooks/useClasses";

import styles from "./styles";

const validationSchema = yup.object({
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  name: yup.string("Enter name").required("Name is required"),
  role: yup.string("Enter role").required("Select a role"),
  branch: yup.string("Select a branch").required("Select a branch"),
});

const CreateNewUser = ({ branches, createUser }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      branch: "",
      role: "manager",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    createUser({
      display_name: values.name,
      email: values.email,
      role: values.role,
      branch_id: values.branch,
    });
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <GridContainer>
        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            Name:
          </FormLabel>
          <Input
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </GridItem>

        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            Email:
          </FormLabel>
          <Input
            id="email"
            name="email"
            label="Enter Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </GridItem>
        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Role: </FormLabel>
            <RadioGroup
              aria-label="Role"
              id="role"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="manager"
                control={<Radio />}
                label="Manager"
              />
              <FormControlLabel
                value="agent"
                control={<Radio />}
                label="Agent"
              />
            </RadioGroup>
          </FormControl>
        </GridItem>
        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            Branch:
          </FormLabel>
          <Select
            id="branch"
            name="branch"
            label="Branch"
            className={classes.selectInput}
            value={formik.values.branch}
            onChange={formik.handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            error={formik.touched.branch && Boolean(formik.errors.branch)}
          >
            <MenuItem value="">
              <em>...</em>
            </MenuItem>
            {branches.map((branch, index) => (
              <MenuItem key={index} value={branch.id}>
                {branch.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched.branch && formik.errors.branch}
          </FormHelperText>
        </GridItem>
        <GridItem xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            Add Branch User
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default CreateNewUser;
