import React from "react";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function Branches({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchHandler,
  searchValue,
  selectedSearchBy,
  handleSearchByChange,

  showCollected,
  showArchived,
  showNotCollected,
}) {
  const [selected, setSelected] = React.useState([]);

  const selectedToolbarButtons = null;

  const toolbarButtons = null;

  const searchByOptions = [
    {
      value: "name",
      label: "Name",
    },
    {
      value: "name_ar",
      label: "Name Arabic",
    },
    {
      value: "phone",
      label: "Phone Number",
    },
    {
      value: "company_name",
      label: "Company Name",
    },
  ];
  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "Id",
    },
    {
      id: "name",
      sortable: true,
      label: "Name",
    },
    {
      id: "name_ar",
      sortable: true,
      label: "Name Arabic",
    },
    {
      id: "collectables_assigned",
      sortable: false,
      label: "Recycables Assigned",
    },
    {
      id: "collectables_collected",
      sortable: false,
      label: "Recycables Collected",
    },
    {
      id: "collectables_delivered",
      sortable: false,
      label: "Recycables Delivered",
    },
    {
      id: "phone",
      sortable: true,
      label: "Phone",
    },
    {
      id: "company_name",
      sortable: true,
      label: "Company",
    },
    {
      id: "is_closed",
      sortable: true,
      label: "Status",
    },
    {
      id: "Recycables details",
      sortable: false,
      label: "Details",
    },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {"# " + row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.name_ar}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.collectables_assigned}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.collectables_collected}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.collectables_delivered}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.phone}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.company_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_closed === 0 ? "Not Closed" : "Closed"}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => showCollected(row.id)}
                    style={{ margin: "10px", width: "150px" }}
                  >
                    Collected
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => showNotCollected(row.id)}
                    style={{ margin: "10px", width: "150px" }}
                  >
                    Not Collected
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => showArchived(row.id)}
                    style={{ margin: "10px", width: "150px" }}
                  >
                    Archived
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Company Branches
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        handleFilterReset
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
      />
    </React.Fragment>
  );
}
