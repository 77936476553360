import React from "react";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RestoreIcon from "@mui/icons-material/Restore";
import TimelineData from "./TimelineData";

export default function ClientCartItems({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,

  deleteHandler,
}) {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleDelete = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to empty this CART ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-arround",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            color="danger"
            size="lg"
            onClick={() => {
              deleteHandler();
              setOpenModal(false);
            }}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Delete
          </Button>
          <Button
            color="secondary"
            size="lg"
            onClick={() => setOpenModal(false)}
            style={{ flex: 1, margin: "0 10px" }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  // const toolbarButtons = (
  //   <Button color="danger" onClick={handleDelete}>
  //     Clear Cart
  //   </Button>
  // );
  const toolbarButtons = null;

  const searchByOptions = [
    {
      value: "item_name",
      label: "Item Name",
    },
    {
      value: "branch_name",
      label: "Branch Name",
    },
    {
      value: "qr_code",
      label: "Qr code",
    },
  ];
  const headCells = [
    {
      id: "item_name",
      sortable: true,
      label: "Item Name",
    },
    {
      id: "branch_name",
      sortable: true,
      label: "Branch Name",
    },
    {
      id: "qr_code",
      sortable: true,
      label: "Qr Code",
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created At",
    },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.item_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.branch_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.qr_code}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Client Cart Items
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={null}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
