import React, { useState } from "react";
import useClasses from "hooks/useClasses";
import { useFormik } from "formik";
import * as yup from "yup";

import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button.js";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";

import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";
import styles from "./ReportInputStyle";
import DatePickerRange from "./DatePicker";
import BranchesInput from "./BranchesInput";

const validationSchema = yup.object({
  startDate: yup
    .string("Please Select Start Date")
    .required("Please Select Start Date"),
  endDate: yup
    .string("Please Select End Date")
    .required("Please Select end Date"),
  is_all_branch: yup.number().min(0).max(1).required("Error in All Branch"),
  recycableStatus: yup
    .array()
    .min(1, "Please choose at least one Recycable Status")
    .required("Please choose at least Recycable Status"),
  documentType: yup
    .string("Please Select Document Type")
    .required("Document Type is required"),
});

const ReportInput = ({ allBranches, exportReport }) => {
  const formik = useFormik({
    initialValues: {
      startDate: null,
      endDate: null,
      is_all_branch: 0,
      branches: [],
      recycableStatus: [],
      documentType: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const classes = useClasses(styles);
  const handleSubmit = (values) => {
    let branch_ids = [];
    let error = false;
    if (values.is_all_branch === 0 && values.branches.length === 0) {
      formik.setFieldError("branches", "Please select at least one Branch");
      error = true;
    } else {
      values.branches.forEach((branch) => {
        branch_ids.push(branch.id);
      });
    }

    if (error === false) {
      const result = {
        start_date: values.startDate.format("YYYY-MM-DD"),
        end_date: values.endDate.format("YYYY-MM-DD"),
        is_all_branch: values.is_all_branch,
        branch_ids,
        recycable_status: values.recycableStatus,
        document_type: values.documentType,
      };
      exportReport(result);
    }
  };

  const changeStartDate = (date) => {
    if (date) {
      formik.handleChange({ target: { name: "startDate", value: date } });
    } else {
      formik.handleChange({ target: { name: "startDate", value: null } });
    }
  };
  const changeEndDate = (date) => {
    if (date) {
      formik.handleChange({ target: { name: "endDate", value: date } });
    } else {
      formik.handleChange({ target: { name: "endDate", value: null } });
    }
  };

  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(formik.errors).length !== 0 && (
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "15px",
                  padding: "5px",
                }}
              >
                <ul>
                  {Object.keys(formik.errors).map((error, index) => (
                    <li key={index} style={{ color: "#fff" }}>
                      {formik.errors[error]}
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          )}

          {/* Date Start end */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Filter By Date</InputLabel>
            <DatePickerRange
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              changeStartDate={changeStartDate}
              changeEndDate={changeEndDate}
            />
          </Grid>
          {/* branch */}
          {/* is_all_branch  */}
          <Grid item xs={12} sm={12}>
            <CustomSwitch
              name="is_all_branch"
              value={formik.values.is_all_branch}
              onChangeHandler={formik.handleChange}
              title="Is All Branch"
            />
          </Grid>
          {/* branches  */}
          {formik.values.is_all_branch === 0 && (
            <Grid item xs={12}>
              <BranchesInput
                branches={formik.values.branches}
                onChangeHandler={formik.handleChange}
                allBranches={allBranches}
                error={
                  formik.touched.branches && Boolean(formik.errors.branches)
                }
                helperText={formik.touched.branches && formik.errors.branches}
              />
            </Grid>
          )}
          {/* recycableStatus */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label} id="recyclable-status-label">
              Recyclable Status:
            </InputLabel>
            <Select
              id="recycableStatus"
              name="recycableStatus"
              className={classes.selectInput}
              value={formik.values.recycableStatus}
              onChange={formik.handleChange}
              label="Recyclable Status"
              multiple={true}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
            >
              <MenuItem value="collected">
                <Checkbox
                  checked={
                    formik.values.recycableStatus.indexOf("collected") > -1
                  }
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary="collected" />
              </MenuItem>
              <MenuItem value="not-collected">
                <Checkbox
                  checked={
                    formik.values.recycableStatus.indexOf("not-collected") > -1
                  }
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary="not-collected" />
              </MenuItem>
              <MenuItem value="archived">
                <Checkbox
                  checked={
                    formik.values.recycableStatus.indexOf("archived") > -1
                  }
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary="archived" />
              </MenuItem>
            </Select>
          </Grid>
          {/* documentType */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label} id="document-type-label">
              Document Type:
            </InputLabel>
            <Select
              labelId="document-type-label"
              className={classes.selectInput}
              value={formik.values.documentType}
              defaultValue=""
              onChange={formik.handleChange}
              inputProps={{
                name: "documentType",
                id: "documentType",
              }}
            >
              <MenuItem value="">
                <em>Select Document Type</em>
              </MenuItem>
              <MenuItem value="pdf">PDF</MenuItem>
              <MenuItem value="excel">Excel</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <div className={classes.buttonContainer}>
          <Button color="primary" fullWidth variant="contained" type="submit">
            Export
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ReportInput;
