import * as actionsType from "../actions/actionsType";

const vouchersReducer = (
  state = {
    searchMessage: null,
    isSearching: false,
    vouchers: null,
    voucher: null,
    companies: null,
    categories: null,
    branches: null,
    branchesCategories: null,
    items: null,
    voucherNumbers: null,
    adminVoucher: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
      };
    case actionsType.GET_VOUCHER:
      return {
        ...state,
        voucher: action.payload,
      };
    case actionsType.GET_VOUCHER_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case actionsType.GET_VOUCHER_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case actionsType.GET_VOUCHER_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case actionsType.GET_VOUCHER_BRANCHES:
      return {
        ...state,
        branches: action.payload,
      };
    case actionsType.GET_VOUCHER_BRANCHES_CATEGORIES:
      return {
        ...state,
        branchesCategories: action.payload,
      };
    case actionsType.VOUCHER_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.payload.loading,
        searchMessage: action.payload.message,
      };
    case actionsType.SET_ADMIN_VOUCHER:
      return { ...state, adminVoucher: action.payload };
    case actionsType.GET_VOUCHER_NUMBERS:
      return { ...state, voucherNumbers: action.payload };
    default:
      return state;
  }
};

export default vouchersReducer;
