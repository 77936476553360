import React, { useState } from "react";
import { Grid, InputLabel } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import CustomSwitch from "../../../Shared/CustomSwitch/CustomSwitch";
import useClasses from "hooks/useClasses";

import styles from "./CompanyStyle";

const validationSchema = yup.object({
  name: yup.string("Enter Name").required("Name is required"),
  name_ar: yup.string("Enter Name Arabic").required("Name Arabic is required"),
  phone: yup
    .number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(8)
    .required("A phone number is required"),
  description: yup
    .string("Enter Description")
    .required("Description is required"),
  description_ar: yup
    .string("Enter Description")
    .required("Description Arabic is required"),
  address: yup.string("Enter Address").required("Address is required"),
  address_ar: yup
    .string("Enter Address Arabic")
    .required("Address Arabic is required"),
  link_website: yup
    .string("Enter Website Link")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Please enter a correct Website Link"
    )
    .required("Website Link is required"),
});

const Company = ({ company, isCreate, handleCreate, handleUpdate }) => {
  const [logo, setLogo] = useState(
    company
      ? company.logo
        ? { file: null, data_url: company.logo }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [icon, setIcon] = useState(
    company
      ? company.icon
        ? { file: null, data_url: company.icon }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [logoError, setLogoError] = useState(null);
  const [iconError, setIconError] = useState(null);
  const formik = useFormik({
    initialValues: {
      name: company ? company.name : "",
      name_ar: company ? company.name_ar : "",
      address: company ? company.address : "",
      address_ar: company ? company.address_ar : "",
      link_website: company ? company.link_website : "",
      phone: company ? company.phone : "",
      description: company ? company.description : "",
      description_ar: company ? company.description_ar : "",
      has_pickup: company ? (company.has_pickup === 1 ? 1 : 0) : 0,
      has_dropoff: company ? (company.has_dropoff === 1 ? 1 : 0) : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // const [formData, setFormData] = useState(initialState);
  const classes = useClasses(styles);
  const history = useHistory();

  const handleSubmit = (values) => {
    if (isCreate === true) {
      create(values);
    } else {
      update(values);
    }
  };
  const create = (values) => {
    const result = { ...values };
    let error = false;
    if (logoError || iconError) {
      error = true;
    }
    if (logo.file === null) {
      setLogoError("Please Enter Logo");
      error = true;
    }
    if (icon.file === null) {
      setIconError("Please Enter Icon");
      error = true;
    }

    if (error === false) {
      result.logo = logo.file;
      result.icon = icon.file;
      handleCreate(result);
    }
  };
  const update = (values) => {
    const result = { ...values };
    let error = false;
    if (logoError || iconError) {
      error = true;
    }
    if (logo.file !== null) {
      result.logo = logo.file;
    } else if (logo.data_url === null) {
      setLogoError("Enter Logo!");
      error = true;
    }
    if (icon.file !== null) {
      result.icon = icon.file;
    } else if (icon.data_url === null) {
      setIconError("Enter Icon!");
      error = true;
    }

    if (error === false) {
      handleUpdate(result);
    }
  };

  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(formik.errors).length !== 0 && (
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "15px",
                  padding: "5px",
                }}
              >
                <ul>
                  {Object.keys(formik.errors).map((error, index) => (
                    <li key={index} style={{ color: "#fff" }}>
                      {formik.errors[error]}
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          )}
          {/* name */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Name:</InputLabel>
            <Input
              id="name"
              name="name"
              label="Enter Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              type="text"
            />
          </Grid>
          {/* name_ar */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Name Arabic:</InputLabel>
            <Input
              id="name_ar"
              name="name_ar"
              label="Enter Name Arabic"
              value={formik.values.name_ar}
              onChange={formik.handleChange}
              error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
              helperText={formik.touched.name_ar && formik.errors.name_ar}
              type="text"
            />
          </Grid>
          {/* address */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Address English:</InputLabel>
            <Input
              id="address"
              name="address"
              label="Enter Address English"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              type="text"
            />
          </Grid>
          {/* address_ar */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Address Arabic:</InputLabel>
            <Input
              id="address_ar"
              name="address_ar"
              label="Enter Address Arabic"
              value={formik.values.address_ar}
              onChange={formik.handleChange}
              error={
                formik.touched.address_ar && Boolean(formik.errors.address_ar)
              }
              helperText={formik.touched.address_ar && formik.errors.address_ar}
              type="text"
            />
          </Grid>
          {/* link_website */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Website Link:</InputLabel>
            <Input
              id="link_website"
              name="link_website"
              label="Enter Website Link"
              value={formik.values.link_website}
              onChange={formik.handleChange}
              error={
                formik.touched.link_website &&
                Boolean(formik.errors.link_website)
              }
              helperText={
                formik.touched.link_website && formik.errors.link_website
              }
              type="text"
            />
          </Grid>
          {/* phone */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Phone Number:</InputLabel>
            <Input
              id="phone"
              name="phone"
              label="Enter Your phone number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              type="text"
            />
          </Grid>
          {/* Description English */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Description English:
            </InputLabel>
            <Input
              id="description"
              name="description"
              label="Enter Description"
              multiline
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              type="text"
            />
          </Grid>
          {/* Description Arabic */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Description Arabic:
            </InputLabel>
            <Input
              id="description_ar"
              name="description_ar"
              label="Enter Description Arabic"
              multiline
              value={formik.values.description_ar}
              onChange={formik.handleChange}
              error={
                formik.touched.description_ar &&
                Boolean(formik.errors.description_ar)
              }
              helperText={
                formik.touched.description_ar && formik.errors.description_ar
              }
              type="text"
            />
          </Grid>
          {/* Logo */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Logo:</InputLabel>
            <ImageUpload
              image={logo}
              setImage={setLogo}
              error={logoError}
              setError={setLogoError}
            />
          </Grid>
          {/* icon */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Icon:</InputLabel>
            <ImageUpload
              image={icon}
              setImage={setIcon}
              error={iconError}
              setError={setIconError}
            />
          </Grid>
          {/* has_pickup */}
          <Grid item xs={12} sm={6}>
            <CustomSwitch
              name="has_pickup"
              value={formik.values.has_pickup}
              onChangeHandler={formik.handleChange}
              title="Has Pick up"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomSwitch
              name="has_dropoff"
              value={formik.values.has_dropoff}
              onChangeHandler={formik.handleChange}
              title="Has drop off"
            />
          </Grid>
        </Grid>
        <div className={classes.submitContainer}>
          <Button
            color="primary"
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {company ? "Update Company" : "Add Company"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Company;
