import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Table from "components/Admin/Tutorials/Tutorials";

import {
  getTutorials,
  deleteTutorial,
  changeStatusTutorial,
} from "store/actions/tutorials";

const Tutorials = () => {
  const tutorialsData = useSelector((state) => state.tutorials.tutorials);
  const dispatch = useDispatch();
  const [page, setPage] = useState(tutorialsData ? tutorialsData.pag.page : 1);
  const [amount, setAmount] = useState(
    tutorialsData ? tutorialsData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    tutorialsData ? tutorialsData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    tutorialsData ? tutorialsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    tutorialsData ? tutorialsData.pag.search_by : "title"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getTutorials(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteTutorial(id, pag, history));
  };
  const changeStatusHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(changeStatusTutorial(id, pag, history));
  };

  const createHandler = () => {
    history.push("/admin/tutorials/new-tutorial");
  };

  const updateHandler = (id) => {
    history.push("/admin/tutorials/tutorial/" + id);
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Mobile Tutorial Table</h4>
        </CardHeader>
        <CardBody>
          {tutorialsData && (
            <Table
              rows={tutorialsData.data}
              totalRows={tutorialsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              deleteHandler={deleteHandler}
              changeStatusHandler={changeStatusHandler}
              createHandler={createHandler}
              updateHandler={updateHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Tutorials;
