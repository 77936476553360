import React from "react";
import { Link } from "react-router-dom";
import useClasses from "hooks/useClasses";
import styles from "./CollectableStyle";
import { Avatar } from "@mui/material";
import PhoneInTalkIcon from "@mui/icons-material/Phone";

const Contact = ({ collectable }) => {
  const classes = useClasses(styles);
  return (
    <div>
      <h2 className={classes.sectionTitle}>Contact Data:</h2>
      <div className={classes.containerMain}>
        <div>
          <span className={classes.label}>Client:</span>
          <span className={classes.content}>{collectable.client_name}</span>
        </div>
      </div>
    </div>
  );
};

export default Contact;
