import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Voucher from "components/Admin/Vouchers/CreateEditVoucher";

import { useDispatch, useSelector } from "react-redux";
import {
  getVoucher,
  getCompanies,
  updateVoucher,
} from "store/actions/vouchers";

const EditVoucher = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const voucherData = useSelector((state) => state.vouchers.voucher);
  const companies = useSelector((state) => state.vouchers.companies);

  useEffect(() => {
    dispatch(getVoucher(id, history));
    dispatch(getCompanies(history));
  }, [id, history, dispatch]);

  const updateHandler = (newData) => {
    dispatch(updateVoucher(newData, history));
  };

  return (
    <div>
      <div>
        <Button
          onClick={history.goBack}
          color="transparent"
          style={{ padding: 0 }}
        >
          <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
          <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
        </Button>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4>Update Voucher</h4>
        </CardHeader>
        <CardBody>
          {companies && voucherData && (
            <Voucher
              companies={companies}
              voucher={voucherData}
              isCreate={false}
              createHandler={null}
              updateHandler={updateHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default EditVoucher;
