import * as actionsType from "../actions/actionsType";

const conditionsReducer = (
  state = {
    conditions: null,
    categories: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_CONDITIONS:
      return {
        ...state,
        conditions: action.payload,
      };
    case actionsType.GET_CONDITIONS_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
};

export default conditionsReducer;
