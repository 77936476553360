import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useClasses from "hooks/useClasses";
import Icon from "@mui/material/Icon";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import StoreIcon from "@mui/icons-material/Store";
import BusinessIcon from "@mui/icons-material/Business";

import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import styles from "./ManagerHomeStyle";
import { useHistory } from "react-router-dom";
import CollectInput from "components/Manager/Collectables/CollectInput/CollectInput";
import CollectablesTable from "./CollectablesTable";
import { getManagerHome } from "store/actions/home";
import {
  searchCodeHandler,
  collect,
  setSearchCode,
} from "store/actions/managers";
// import { CircularProgress } from "@mui/material";

function nFormatter(num) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
}

export default function ManagerDashboard() {
  const [showCollected, setShowCollected] = useState(false);
  const [showNotCollected, setShowNotCollected] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const homeNumbers = useSelector((state) => state.home.managerHomeNumbers);
  const searchingMessage = useSelector(
    (state) => state.manager.searchingMessage
  );
  const { searchCode, searchCodeError } = useSelector((state) => state.manager);
  const searchCollectable = useSelector(
    (state) => state.manager.searchCodeCollectable
  );
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getManagerHome(history));
  }, [dispatch]);

  const classes = useClasses(styles);

  const handleSearchCode = (code) => {
    // setIsSearching(true);
    dispatch(searchCodeHandler({ code: code }, history));
  };
  const setSearchCodeHandler = (code) => {
    // setIsSearching(true);
    dispatch(setSearchCode(code));
  };

  const showRecycableHandler = (id) => {
    history.push("/admin/recycables/show/" + id);
  };

  const collectHandler = (id, notes) => {
    const data = {
      id,
      notes,
    };
    dispatch(collect(data, history));
  };

  return (
    <div>
      {homeNumbers && (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader color="secondary" icon>
                  <BusinessIcon />
                </CardHeader>
                <CardBody>
                  <p className={classes.cardCategory}>Company</p>
                  <h3 className={classes.cardTitle}>
                    {homeNumbers.company.name}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader color="danger" icon>
                  <StoreIcon />
                </CardHeader>
                <CardBody>
                  <p className={classes.cardCategory}>Branch</p>
                  <h3 className={classes.cardTitle}>{homeNumbers.name}</h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Card
                style={{ minHeight: "130px", cursor: "pointer" }}
                onClick={() => setShowNotCollected((prev) => !prev)}
              >
                <CardHeader color="warning" icon>
                  <HourglassFullIcon />
                </CardHeader>
                <CardBody>
                  <p className={classes.cardCategory}>Not Collected yet</p>
                  <h3
                    className={classes.cardTitle}
                    onMouseEnter={() => setShowNotCollected(true)}
                    onMouseLeave={() => setShowNotCollected(false)}
                  >
                    {showNotCollected
                      ? homeNumbers.notCollected
                      : nFormatter(homeNumbers.notCollected)}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader
                  color="success"
                  icon
                  onClick={() => history.push("/admin/recycables/collected")}
                >
                  <Icon>check</Icon>
                </CardHeader>
                <CardBody onClick={() => setShowCollected((prev) => !prev)}>
                  <p className={classes.cardCategory}>Collected Requests</p>
                  <h3
                    className={classes.cardTitle}
                    onMouseEnter={() => setShowCollected(true)}
                    onMouseLeave={() => setShowCollected(false)}
                  >
                    {showCollected
                      ? homeNumbers.collected
                      : nFormatter(homeNumbers.collected)}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader
                  color="info"
                  icon
                  onClick={() =>
                    history.push("/admin/recycables/archived-batches")
                  }
                >
                  <Icon>library_books</Icon>
                </CardHeader>
                <CardBody onClick={() => setShowArchived((prev) => !prev)}>
                  <p className={classes.cardCategory}>
                    Archived Batches Requests
                  </p>
                  <h3
                    className={classes.cardTitle}
                    onMouseEnter={() => setShowArchived(true)}
                    onMouseLeave={() => setShowArchived(false)}
                  >
                    {showArchived
                      ? homeNumbers.archived
                      : nFormatter(homeNumbers.archived)}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
      <div>
        <GridContainer>
          <GridItem xs={12} md={12}>
            <Card>
              <CardBody>
                <h4 className={classes.cardTitleWhite}>Collect a Recycable</h4>
                <CollectInput
                  searchCodeHandler={handleSearchCode}
                  searchCode={searchCode}
                  searchCodeError={searchCodeError}
                  setSearchCode={setSearchCodeHandler}
                />
                <div>
                  {searchingMessage && (
                    <div className={classes.messageContainer}>
                      <span className={classes.message}>
                        {searchingMessage}
                      </span>
                    </div>
                  )}
                  {searchCollectable && (
                    <CollectablesTable
                      rows={[searchCollectable]}
                      showRecycable={showRecycableHandler}
                      collectRecycable={collectHandler}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
