import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ColorPicker from "components/Shared/ColorPicker/ColorPicker";
import useClasses from "hooks/useClasses";

import styles from "./styles";
import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";

const validationSchema = yup.object({
  name: yup.string("Enter name").required("Name is required"),
  name_ar: yup.string("Enter name arabic").required("Name arabic is required"),
  description: yup
    .string("Enter description")
    .required("Description is required"),
  description_ar: yup
    .string("Enter description arabic")
    .required("Description arabic is required"),
  is_active: yup.number().min(0).max(1).required("Error in is_active"),
  has_pickup: yup.number().min(0).max(1).required("Error in has_pickup"),
  has_dropoff: yup.number().min(0).max(1).required("Error in has_dropoff"),
  is_offer: yup.number().min(0).max(1).required("Error in is_offer"),
  points: yup.number().integer().min(0),
  offer_points: yup
    .number("Enter offer_points")
    .integer("Error in offer_points")
    .min(0, "Error in offer_points"),
});

const Category = ({
  companies,
  category,
  isCreate,
  createHandler,
  updateHandler,
}) => {
  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };

  const [image, setImage] = useState(
    category
      ? category.image
        ? { file: null, data_url: category.image }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [imageError, setImageError] = useState(null);
  const [imageDark, setImageDark] = useState(
    category
      ? category.image_dark
        ? { file: null, data_url: category.image_dark }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [imageDarkError, setImageDarkError] = useState(null);
  let companies_ids = [];
  if (category) {
    if (category.companies.length !== 0) {
      category.companies.map((comp) => companies_ids.push(comp.id));
    }
  }
  const formik = useFormik({
    initialValues: {
      id: !isCreate ? (category ? category.id : "") : "",
      name: category ? category.name : "",
      name_ar: category ? category.name_ar : "",
      description: category ? category.description : "",
      description_ar: category ? category.description_ar : "",
      badge: category ? (category.badge ? category.badge : "") : "",
      badge_ar: category ? (category.badge_ar ? category.badge_ar : "") : "",
      badge_bg_color: category
        ? category.badge_bg_color
          ? category.badge_bg_color
          : ""
        : "",
      is_active: category ? category.is_active : 0,
      has_pickup: category ? category.has_pickup : 0,
      has_dropoff: category ? category.has_dropoff : 0,
      points: category ? category.points : 0,
      is_offer: category ? category.is_offer : 0,
      offer_points: category ? category.offer_points : 0,
      company_ids: companies_ids,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    const result = { ...values };
    let error = false;
    if (imageError) {
      error = true;
    }
    if (image.file !== null) {
      result.image = image.file;
    } else if (image.data_url === null) {
      setImageError("Enter Image!");
      error = true;
    }
    if (imageDark.file !== null) {
      result.image_dark = imageDark.file;
    } else if (imageDark.data_url === null) {
      setImageDarkError("Enter Image Dark!");
      error = true;
    }
    if (result.has_offer === 1 && result.offer_points === 0) {
      formik.setFieldError(
        "offer_points",
        "when there is offer offer point can't be 0"
      );
      error = true;
    }
    if (result.badge !== "" && result.badge_ar === "") {
      formik.setFieldError("badge_ar", "badge Arabic can't be empty");
      error = true;
    }
    if (result.badge !== "" && result.badge_bg_color === "") {
      formik.setFieldError("badge_bg_color", "badge BG Color can't be empty");
      error = true;
    }
    if (result.has_pickup === 0 && result.has_dropoff === 0) {
      formik.setFieldError(
        "has_pickup",
        "Has_pickup and Has_dropoff can't be both false at least one has to be true"
      );
      formik.setFieldError(
        "has_dropoff",
        "Has_pickup and Has_dropoff can't be both false at least one has to be true"
      );
      error = true;
    }
    if (error === false) {
      if (result.is_offer === 0) {
        result.offer_points = 0;
      }
      if (isCreate) {
        createHandler(result);
      } else {
        updateHandler(result);
      }
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {(imageError !== null || imageDarkError !== null) && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {imageError !== null && (
                  <li key="image" style={{ color: "#fff" }}>
                    {imageError}
                  </li>
                )}
                {imageDarkError !== null && (
                  <li key="imageDark" style={{ color: "#fff" }}>
                    {imageDarkError}
                  </li>
                )}
              </ul>
            </div>
          </Grid>
        )}
        {Object.keys(formik.errors).length !== 0 && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {Object.keys(formik.errors).map((error, index) => (
                  <li key={index} style={{ color: "#fff" }}>
                    {formik.errors[error]}
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        )}
        {/* name */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name:</InputLabel>
          <Input
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </Grid>
        {/* name_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name Arabic:</InputLabel>
          <Input
            id="name_ar"
            name="name_ar"
            label="Enter Name Arabic"
            value={formik.values.name_ar}
            onChange={formik.handleChange}
            error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
            helperText={formik.touched.name_ar && formik.errors.name_ar}
            type="text"
          />
        </Grid>
        {/* Description English */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>
            Description English:
          </InputLabel>
          <Input
            id="description"
            name="description"
            label="Enter Description"
            multiline
            value={formik.values.description}
            onChange={formik.handleChange}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
            type="text"
          />
        </Grid>
        {/* Description Arabic */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Description Arabic:</InputLabel>
          <Input
            id="description_ar"
            name="description_ar"
            label="Enter Description Arabic"
            multiline
            value={formik.values.description_ar}
            onChange={formik.handleChange}
            error={
              formik.touched.description_ar &&
              Boolean(formik.errors.description_ar)
            }
            helperText={
              formik.touched.description_ar && formik.errors.description_ar
            }
            type="text"
          />
        </Grid>
        {/* Image */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Image:</InputLabel>
          <ImageUpload
            image={image}
            setImage={setImage}
            error={imageError}
            setError={setImageError}
          />
        </Grid>
        {/* Image Dark */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Image Dark:</InputLabel>
          <ImageUpload
            image={imageDark}
            setImage={setImageDark}
            error={imageDarkError}
            setError={setImageDarkError}
          />
        </Grid>
        {/* Companies */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Companies:</InputLabel>
          <Select
            id="company_ids"
            name="company_ids"
            label="Companies"
            className={classes.selectInput}
            value={formik.values.company_ids}
            onChange={formik.handleChange}
            multiple={true}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={findName(companies, value)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            inputProps={{ "aria-label": "Without label" }}
          >
            {companies.map((company) => (
              <MenuItem key={company.id} value={company.id}>
                <Checkbox
                  checked={formik.values.company_ids.indexOf(company.id) > -1}
                  color="default"
                  className={classes.customCheckbox}
                />
                <ListItemText primary={company.name} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {/* is_active */}
        <Grid item xs={12}>
          <CustomSwitch
            name="is_active"
            value={formik.values.is_active}
            onChangeHandler={formik.handleChange}
            title="Active"
          />
        </Grid>
        {/* is_offer  */}
        <Grid item xs={12} sm={formik.values.is_offer === 1 ? 4 : 12}>
          <CustomSwitch
            name="is_offer"
            value={formik.values.is_offer}
            onChangeHandler={formik.handleChange}
            title="Offer"
          />
        </Grid>
        {/* offer_points  */}
        {formik.values.is_offer === 1 && (
          <Grid item xs={12} sm={8}>
            <InputLabel className={classes.label}>Offer Points:</InputLabel>
            <Input
              id="offer_points"
              name="offer_points"
              label="Enter Offer Points"
              value={formik.values.offer_points}
              onChange={formik.handleChange}
              error={
                formik.touched.offer_points &&
                Boolean(formik.errors.offer_points)
              }
              helperText={
                formik.touched.offer_points && formik.errors.offer_points
              }
              type="number"
            />
          </Grid>
        )}
        {/* badge */}
        <Grid item xs={12} sm={6} lg={4}>
          <InputLabel className={classes.label}>
            Badge Text (max 5 characters):
          </InputLabel>
          <Input
            id="badge"
            name="badge"
            label="Enter Badge"
            value={formik.values.badge}
            onChange={formik.handleChange}
            required={false}
            type="text"
          />
        </Grid>
        {/* badge_ar */}
        <Grid item xs={12} sm={6} lg={4}>
          <InputLabel className={classes.label}>
            Badge Arabic Text (max 5 characters):
          </InputLabel>
          <Input
            id="badge_ar"
            name="badge_ar"
            label="Enter Badge Arabic"
            value={formik.values.badge_ar}
            onChange={formik.handleChange}
            required={false}
            type="text"
          />
        </Grid>
        {/* badge */}
        <Grid item xs={12} lg={4}>
          <InputLabel className={classes.label}>Badge BG Color:</InputLabel>
          <ColorPicker
            name="badge_bg_color"
            color={formik.values.badge_bg_color}
            setColor={formik.handleChange}
          />
        </Grid>
        {/* has_pickup */}
        <Grid item xs={12} sm={6}>
          <CustomSwitch
            name="has_pickup"
            value={formik.values.has_pickup}
            onChangeHandler={formik.handleChange}
            title="Has Pick up"
          />
        </Grid>
        {/* has_dropoff */}
        <Grid item xs={12} sm={6}>
          <CustomSwitch
            name="has_dropoff"
            value={formik.values.has_dropoff}
            onChangeHandler={formik.handleChange}
            title="Has drop off"
          />
        </Grid>
        {/* points */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Points:</InputLabel>
          <Input
            id="points"
            name="points"
            label="Enter Points"
            value={formik.values.points}
            onChange={formik.handleChange}
            error={formik.touched.points && Boolean(formik.errors.points)}
            helperText={formik.touched.points && formik.errors.points}
            type="number"
          />
        </Grid>
        {/* points */}
        <Grid item xs={12}>
          <span className={classes.label}>Total Points: </span>
          <span className={classes.totalPoints}>
            {formik.values.points + formik.values.offer_points}
          </span>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Category;
