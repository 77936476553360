import React from "react";
import InputLabel from "@mui/material/InputLabel";
import Input from "components/Shared/CustomInput/Input";
import Button from "components/Shared/CustomButtons/Button";

const CollectBatchInput = ({ collectHandler, cancelHandler }) => {
  const [otpCode, setOtpCode] = React.useState("");
  const submitCodeHandle = () => {
    if (otpCode) {
      if (otpCode.length !== 0) {
        collectHandler(otpCode);
      }
    }
  };
  return (
    <div>
      <div>
        <InputLabel
          style={{
            fontWeight: "bold",
            marginLeft: "10px",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          Otp Code:
        </InputLabel>
        <Input
          id="otpCode"
          name="otpCode"
          label="Otp Code"
          value={otpCode}
          onChange={(e) => setOtpCode(e.target.value)}
          type="text"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-arround",
          alignItems: "center",
          width: "100%",
          margin: "20px auto",
        }}
      >
        <Button
          color="primary"
          size="lg"
          onClick={submitCodeHandle}
          style={{ flex: 1, margin: "0 10px" }}
        >
          Collect
        </Button>
        <Button
          color="secondary"
          size="lg"
          onClick={cancelHandler}
          style={{ flex: 1, margin: "0 10px" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CollectBatchInput;
