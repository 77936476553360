import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Table from "components/Admin/RedeemCompanies/RedeemCompanies";

import {
  getRedeemCompanies,
  deleteRedeemCompany,
  changeStatusRedeemCompany,
} from "store/actions/redeemCompanies";

const RedeemCompanies = () => {
  const redeemCompaniesData = useSelector(
    (state) => state.redeemCompanies.redeemCompanies
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    redeemCompaniesData ? redeemCompaniesData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    redeemCompaniesData ? redeemCompaniesData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    redeemCompaniesData ? redeemCompaniesData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    redeemCompaniesData ? redeemCompaniesData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    redeemCompaniesData ? redeemCompaniesData.pag.search_by : "company_name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getRedeemCompanies(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteRedeemCompany(id, pag, history));
  };
  const changeStatusHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(changeStatusRedeemCompany(id, pag, history));
  };

  const createHandler = () => {
    history.push("/admin/redeem-companies/new-company");
  };

  const updateHandler = (id) => {
    history.push("/admin/redeem-companies/company/" + id);
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Redeem Companies Table</h4>
        </CardHeader>
        <CardBody>
          {redeemCompaniesData && (
            <Table
              rows={redeemCompaniesData.data}
              totalRows={redeemCompaniesData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              deleteHandler={deleteHandler}
              changeStatusHandler={changeStatusHandler}
              createHandler={createHandler}
              updateHandler={updateHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default RedeemCompanies;
