import * as actionsType from "../actions/actionsType";

const redeemCompaniesReducer = (
  state = { redeemCompanies: null, redeemCompany: null },
  action
) => {
  switch (action.type) {
    case actionsType.GET_REDEEM_COMPANIES:
      return { ...state, redeemCompanies: action.payload };
    case actionsType.GET_REDEEM_COMPANY:
      return { ...state, redeemCompany: action.payload };
    default:
      return state;
  }
};

export default redeemCompaniesReducer;
