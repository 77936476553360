import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/Admin/Companies/Companies";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { getCompanies, deleteCompany } from "store/actions/companies";

const Companies = () => {
  const dispatch = useDispatch();
  const CompaniesData = useSelector((state) => state.companies.companies);
  const [page, setPage] = useState(CompaniesData ? CompaniesData.pag.page : 1);
  const [amount, setAmount] = useState(
    CompaniesData ? CompaniesData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    CompaniesData ? CompaniesData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    CompaniesData ? CompaniesData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    CompaniesData ? CompaniesData.pag.search_by : "name"
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getCompanies(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycablesHandler = (id) => {
    history.push("/admin/companies/recycables/" + id);
  };

  const editCompanyHandler = (id) => {
    history.push("/admin/companies/edit/" + id);
  };

  const addCompanyHandler = () => {
    history.push("/admin/companies/add");
  };

  const deleteCompaniesHandler = (id) => {
    dispatch(
      deleteCompany(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Companies Table</h4>
        </CardHeader>
        <CardBody>
          {CompaniesData && (
            <Table
              rows={CompaniesData.data}
              totalRows={CompaniesData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              showRecycables={showRecycablesHandler}
              editCompany={editCompanyHandler}
              addCompany={addCompanyHandler}
              deleteCompanies={deleteCompaniesHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Companies;
