import { textAlign } from "@mui/system";

const styles = (theme) => ({
  container: {
    margin: "20px 0",
    padding: "5px",
    width: "100%",
  },
  mainContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-around",
    alignItems: "flex-start",
    [theme.breakpoints.down("lg")]: {
      display: "block",
    },
  },
  childContainer: {
    flex: 1,
    flexGrow: 1,
    padding: "0 5px",
  },
  sectionTitle: {
    color: theme.palette.primary.mainColor,
    marginBottom: 0,
    fontWeight: "900",
  },
  // containerMain: {
  //   marginBottom: "20px"
  // },
  // mainImageContainer: {
  //   margin: "10px"
  // },
  clientInfo: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  userImageContainer: {
    margin: "10px",
  },
  userImage: {
    width: "50px!important",
    height: "50px!important",
    objectFit: "contain!important",
  },
  imagesListContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "10px 15px",
    maxWidth: "500px",
    overflowX: "auto",
    overflowY: "hidden",
  },
  imageContainer: {
    flexShrink: 0,
    width: "110px",
    height: "110px",
    margin: "5px",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  modalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  modalImage: {
    height: "500px",
    width: "700px",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  otherInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  dimensionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 15px",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },

  dimension: {
    flex: 1,
    // textAlign: "center"
  },
  label: {
    fontWeight: 900,
    fontSize: "18px",
    marginRight: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  content: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  voucherImageContainer: {
    width: "80%",
    margin: "10px auto",
  },
  voucherImage: {
    width: "100%",
    objectFit: "contain",
  },
});

export default styles;
