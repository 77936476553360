import React from "react";
import classNames from "classnames";
import styles from "./cardBodyStyle";
import useClasses from "hooks/useClasses";

export default function CardBody(props) {
  const classes = useClasses(styles);
  const { className, children, plain, profile, ...rest } = props;
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyProfile]: profile,
    [className]: className !== undefined,
  });
  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
}
