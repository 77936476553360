import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/Admin/Conditions/Conditions";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import {
  getConditions,
  createCondition,
  updateCondition,
  deleteCondition,
} from "store/actions/conditions";

const DeviceCondition = () => {
  const ConditionsData = useSelector((state) => state.conditions.conditions);
  const allCategories = useSelector((state) => state.conditions.categories);
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    ConditionsData ? ConditionsData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    ConditionsData ? ConditionsData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    ConditionsData ? ConditionsData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    ConditionsData ? ConditionsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    ConditionsData ? ConditionsData.pag.search_by : "name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getConditions(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteCondition(id, pag, history));
  };
  const createHandler = (newData) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(createCondition(newData, pag, history));
  };
  const updateHandler = (newData) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(updateCondition(newData, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Devices Conditions Table</h4>
        </CardHeader>
        <CardBody>
          {ConditionsData && (
            <Table
              rows={ConditionsData.data}
              totalRows={ConditionsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              createHandler={createHandler}
              updateHandler={updateHandler}
              deleteHandler={deleteHandler}
              categories={allCategories}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DeviceCondition;
