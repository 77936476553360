import React from "react";
import useClasses from "hooks/useClasses";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";

const styles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "15px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "20px",
    borderRadius: "15px",
    zIndex: 1500,
  },
});

export default function CustomModal({
  open,
  handleClose,
  children,
  customStyle,
}) {
  const classes = useClasses(styles);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper} style={customStyle}>
            {children}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
