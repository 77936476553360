import React from "react";

const Maps = ({ lngProps, latProps, contentProps }) => {
  const mapRef = React.useRef(null);
  React.useEffect(() => {
    let google = window.google;
    let map = mapRef.current;
    let lat = latProps;
    let lng = lngProps;
    const myLatlng = new google.maps.LatLng(lat, lng);
    const mapOptions = {
      zoom: 18,
      center: myLatlng,
      scrollwheel: false,
      zoomControl: true,
      mapId: process.env.REACT_APP_GOOGLE_MAP_API_ID,
    };
    map = new google.maps.Map(map, mapOptions);

    const marker = new google.maps.marker.AdvancedMarkerElement({
      position: myLatlng,
      map: map,
      // animation: google.maps.Animation.DROP,
      title: "Material Dashboard React!",
    });

    const contentString = contentProps;

    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });
  }, [lngProps, latProps, contentProps]);
  return (
    <>
      <div style={{ height: `100%` }} ref={mapRef}></div>
    </>
  );
};

export default Maps;
