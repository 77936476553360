import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Table from "components/CompanyAdmin/Branches/Branch/BranchCollected";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { getBranchCollected } from "store/actions/companyAdmin";
import { InputLabel } from "@mui/material";

const BranchCollected = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { collected } = useSelector((state) => state.companyAdmin);
  const [page, setPage] = useState(collected ? collected.pag.page : 1);
  const [amount, setAmount] = useState(collected ? collected.pag.amount : 10);
  const [orderField, setOrderField] = useState(
    collected ? collected.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    collected ? collected.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    collected ? collected.pag.search_by : "item_name"
  );

  useEffect(() => {
    dispatch(
      getBranchCollected(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          branch_id: id,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (rId) => {
    history.push("/admin/company-branches/recycable/" + rId);
  };

  // const exportHandler = () => {
  //   const data = {
  //     branch_id: id,
  //     date_from: new Date(startDate).getTime() / 1000,
  //     date_to: new Date(endDate).getTime() / 1000,
  //   };
  //   dispatch(exportBranchCollectedPdf(data, history));
  // };

  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4>Company Branch's Collected Recycables</h4>
          </CardHeader>
          <CardBody>
            {collected && (
              <div>
                <Table
                  rows={collected.data}
                  totalRows={collected.pag.total}
                  rowsPerPage={amount}
                  setRowsPerPage={setAmount}
                  order={orderType}
                  setOrder={setOrderType}
                  orderBy={orderField}
                  setOrderBy={setOrderField}
                  page={page - 1}
                  setPage={setPage}
                  showRecycable={showRecycableHandler}
                  searchHandler={setSearchValue}
                  searchValue={searchValue}
                  selectedSearchBy={searchBy}
                  handleSearchByChange={setSearchBy}
                  // exportHandler={exportHandler}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default BranchCollected;
