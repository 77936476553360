import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "components/Admin/Users/Clients/Clients";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersClients,
  deleteUsersClients,
  changeStatusUsersClients,
} from "store/actions/usersClients";

const Clients = () => {
  const clientData = useSelector((state) => state.usersClients.clients);
  const dispatch = useDispatch();
  const [page, setPage] = useState(clientData ? clientData.pag.page : 1);
  const [amount, setAmount] = useState(clientData ? clientData.pag.amount : 10);
  const [orderField, setOrderField] = useState(
    clientData ? clientData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    clientData ? clientData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    clientData ? clientData.pag.search_by : "display_name"
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getUsersClients(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, dispatch]);

  const showClientHandler = (id) => {
    history.push("/admin/users/clients/" + id);
  };

  const searchHandler = (s) => {
    setSearchValue(s);
    dispatch(
      getUsersClients(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: s,
          search_by: searchBy,
        },
        history
      )
    );
  };

  const deleteClientHandler = (ids) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteUsersClients(ids, pag));
  };

  const lockClientHandler = (ids) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(changeStatusUsersClients(ids, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Client Table</h4>
        </CardHeader>
        <CardBody>
          {clientData && (
            <Table
              rows={clientData.data}
              totalRows={clientData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              showClient={showClientHandler}
              deleteClient={deleteClientHandler}
              lockClient={lockClientHandler}
              searchHandler={searchHandler}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Clients;
