import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Table from "components/Admin/Carousels/Carousels";

import {
  getCarousels,
  deleteCarousel,
  changeStatusCarousel,
} from "store/actions/carousels";

const Carousels = () => {
  const carouselsData = useSelector((state) => state.carousels.carousels);
  const dispatch = useDispatch();
  const [page, setPage] = useState(carouselsData ? carouselsData.pag.page : 1);
  const [amount, setAmount] = useState(
    carouselsData ? carouselsData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    carouselsData ? carouselsData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    carouselsData ? carouselsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    carouselsData ? carouselsData.pag.search_by : "title"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getCarousels(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteCarousel(id, pag, history));
  };
  const changeStatusHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(changeStatusCarousel(id, pag, history));
  };

  const createHandler = () => {
    history.push("/admin/carousels/new-carousel");
  };

  const updateHandler = (id) => {
    history.push("/admin/carousels/carousel/" + id);
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Carousels Table</h4>
        </CardHeader>
        <CardBody>
          {carouselsData && (
            <Table
              rows={carouselsData.data}
              totalRows={carouselsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              deleteHandler={deleteHandler}
              changeStatusHandler={changeStatusHandler}
              createHandler={createHandler}
              updateHandler={updateHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Carousels;
