import * as actionsType from "../actions/actionsType";

const companyAdminUsersReducer = (
  state = {
    branchUsers: null,
    companyAdmin: null,
    deletedUsers: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_COMPANY_DELETED_USERS:
      return { ...state, deletedUsers: action.payload };
    case actionsType.GET_COMPANY_BRANCH_USERS:
      return { ...state, branchUsers: action.payload };
    case actionsType.GET_COMPANY_ADMIN_COMPANY:
      return { ...state, companyAdmin: action.payload };
    default:
      return state;
  }
};

export default companyAdminUsersReducer;
