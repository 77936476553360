import * as actionsType from "../actions/actionsType";

const usersReducer = (
  state = {
    clients: null,
    client: null,
    clientRecycables: null,
    clientCartItems: null,
    clientTransactions: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_CLIENT_TRANSACTIONS:
      return { ...state, clientTransactions: action.payload };
    case actionsType.GET_CLIENT_CART_ITEMS:
      return { ...state, clientCartItems: action.payload };
    case actionsType.GET_CLIENT_RECYCABLES:
      return { ...state, clientRecycables: action.payload };
    case actionsType.GET_USERS_CLIENTS:
      return { ...state, clients: action.payload };
    case actionsType.GET_USERS_CLIENT:
      return { ...state, client: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
