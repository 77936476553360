import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useClasses from "hooks/useClasses";

import { Grid, InputLabel } from "@mui/material";
import Input from "components/Shared/CustomInput/Input";
import styles from "./styles";

import Button from "components/Shared/CustomButtons/Button";

const validationSchema = yup.object({
  amount: yup
    .number()
    .min(1)
    .typeError("Please enter a valid number")
    .integer("amount can't include a decimal point")
    .required("amount is required"),
});

const AddAmount = ({ id, addHandler }) => {
  const formik = useFormik({
    initialValues: {
      voucher_id: id,
      amount: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    const result = { ...values };
    addHandler(result);
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Amount:</InputLabel>
          <Input
            id="amount"
            name="amount"
            label="Enter Amount"
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
            type="number"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddAmount;
