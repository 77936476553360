import React, { useEffect, useState } from "react";
import useClasses from "hooks/useClasses";
import styles from "./BatchInfoStyle";
import LocationCityIcon from "@mui/icons-material/LocationCity";

import { useTheme } from "@mui/material";

const BatchInfo = ({ archivedBatch }) => {
  const [currentStatus, setCurrentStatus] = useState("");

  const classes = useClasses(styles);
  const theme = useTheme();
  useEffect(() => {
    if (
      archivedBatch.is_recycler_approved === 0 &&
      archivedBatch.is_recycler_rejected === 0 &&
      archivedBatch.is_admin_approved === 0 &&
      archivedBatch.is_admin_rejected === 0
    ) {
      setCurrentStatus("Waiting for the recycler to approve or reject");
    } else if (
      archivedBatch.is_recycler_approved === 1 &&
      archivedBatch.is_admin_approved === 0 &&
      archivedBatch.is_admin_rejected === 0
    ) {
      setCurrentStatus(
        "Recycler approved waiting for the admin to approve or reject"
      );
    } else if (
      archivedBatch.is_recycler_rejected === 1 &&
      archivedBatch.is_admin_approved === 0 &&
      archivedBatch.is_admin_rejected === 0
    ) {
      setCurrentStatus(
        "Recycler rejected waiting for the admin to re-assign a recycler"
      );
    } else if (archivedBatch.is_admin_rejected === 1) {
      setCurrentStatus(
        "Admin rejected the recycler offer waiting the recycler to give another offer"
      );
    } else if (
      archivedBatch.is_recycler_approved === 1 &&
      archivedBatch.is_recycler_rejected === 0 &&
      archivedBatch.is_admin_approved === 1 &&
      archivedBatch.is_admin_rejected === 0 &&
      !archivedBatch.is_collected
    ) {
      setCurrentStatus(
        "Admin and recycler approve waiting for the recycler to collect it from the branch"
      );
    } else if (
      archivedBatch.is_recycler_approved === 1 &&
      archivedBatch.is_admin_approved === 1 &&
      archivedBatch.is_collected === 1
    ) {
      setCurrentStatus("Batch Collected ");
    }
  }, [archivedBatch]);

  return (
    <div className={classes.container}>
      {/* Branch Name */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Branch Name:</span>
        <span className={classes.content}>{archivedBatch.branch}</span>
      </div>
      {/* Addresses */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>
          <LocationCityIcon
            style={{
              width: "20px",
              height: "20px",
              verticalAlign: "middle",
              color: theme.palette.primary.mainColor,
            }}
          />
          {" Branch Address:"}
        </span>
        {archivedBatch.branch_lat && archivedBatch.branch_lng ? (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${archivedBatch.branch_lat},${archivedBatch.branch_lng}`}
            target="_blank"
          >
            <span className={classes.content}>
              {archivedBatch.branch_address}
            </span>
          </a>
        ) : (
          <span className={classes.content}>
            {archivedBatch.branch_address}
          </span>
        )}
      </div>

      <div className={classes.infoContainer}>
        <span className={classes.label}>Recycler assigned:</span>
        <span className={classes.content}>{archivedBatch.recycler}</span>
      </div>
      {/* current status */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Current Batch Status:</span>
        <span className={classes.content}>{currentStatus}</span>
      </div>
    </div>
  );
};

export default BatchInfo;
