import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import useClasses from "hooks/useClasses";

import styles from "./styles";
import ColorPicker from "components/Shared/ColorPicker/ColorPicker";

const validationSchema = yup.object({
  company_name: yup
    .string("Enter Company Name")
    .required("Company Name is required"),
  company_name_ar: yup
    .string("Enter Company Name Arabic")
    .required("Company Name Arabic is required"),
  bg_color: yup
    .string("Select Background Color")
    .required("Background Color is required"),
  terms_url: yup
    .string("Enter Terms Link")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Please enter a correct Terms Link"
    )
    .required("Terms Link is required"),
  description_before: yup
    .string("Enter description Before")
    .required("Description Before is required"),
  description_before_ar: yup
    .string("Enter description Before Arabic")
    .required("Description Before Arabic is required"),
  description_after: yup
    .string("Enter description After")
    .required("Description After is required"),
  description_after_ar: yup
    .string("Enter description After Arabic")
    .required("Description After Arabic is required"),
  redeem_service_text: yup
    .string("Enter Redeem Service Text")
    .required("Redeem Service Text is required"),
  redeem_service_text_ar: yup
    .string("Enter Redeem Service Text Arabic")
    .required("Redeem Service Text Arabic is required "),
  version_ios: yup.number().integer().min(0),
  version_android: yup.number().integer().min(0),
  display_code: yup.number().integer().min(0),
});

const RedeemyCompany = ({
  redeemCompany,
  isCreate,
  createHandler,
  updateHandler,
}) => {
  const [logo, setLogo] = useState(
    redeemCompany
      ? redeemCompany.logo
        ? { file: null, data_url: redeemCompany.logo }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [logoError, setLogoError] = useState(null);
  const [logoAr, setLogoAr] = useState(
    redeemCompany
      ? redeemCompany.logo_ar
        ? { file: null, data_url: redeemCompany.logo_ar }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [logoArError, setLogoArError] = useState(null);
  const formik = useFormik({
    initialValues: {
      id: !isCreate ? (redeemCompany ? redeemCompany.id : "") : "",
      company_name: redeemCompany ? redeemCompany.company_name : "",
      company_name_ar: redeemCompany ? redeemCompany.company_name_ar : "",
      bg_color: redeemCompany ? redeemCompany.bg_color : "",
      terms_url: redeemCompany ? redeemCompany.terms_url : "",
      description_before: redeemCompany ? redeemCompany.description_before : "",
      description_before_ar: redeemCompany
        ? redeemCompany.description_before_ar
        : "",
      description_after: redeemCompany ? redeemCompany.description_after : "",
      description_after_ar: redeemCompany
        ? redeemCompany.description_after_ar
        : "",
      redeem_service_text: redeemCompany
        ? redeemCompany.redeem_service_text
        : "",
      redeem_service_text_ar: redeemCompany
        ? redeemCompany.redeem_service_text_ar
        : "",
      version_ios: redeemCompany ? redeemCompany.version_ios : 0,
      version_android: redeemCompany ? redeemCompany.version_android : 0,
      display_code: redeemCompany ? redeemCompany.display_code : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    const result = { ...values };
    let error = false;
    if (logoError || logoArError) {
      error = true;
    }
    if (logo.file !== null) {
      result.logo = logo.file;
    } else if (logo.data_url === null) {
      setLogoError("Enter Logo!");
      error = true;
    }
    if (logoAr.file !== null) {
      result.logo_ar = logoAr.file;
    } else if (logoAr.data_url === null) {
      setLogoArError("Enter Logo Arabic!");
      error = true;
    }
    if (error === false) {
      if (isCreate) {
        createHandler(result);
      } else {
        updateHandler(result);
      }
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {(logoError !== null || logoArError !== null) && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {logoError !== null && (
                  <li key="logo" style={{ color: "#fff" }}>
                    {logoError}
                  </li>
                )}
                {logoArError !== null && (
                  <li key="logoAr" style={{ color: "#fff" }}>
                    {logoArError}
                  </li>
                )}
              </ul>
            </div>
          </Grid>
        )}
        {Object.keys(formik.errors).length !== 0 && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {Object.keys(formik.errors).map((error, index) => (
                  <li key={index} style={{ color: "#fff" }}>
                    {formik.errors[error]}
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        )}
        {/* Company_name */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Company Name:</InputLabel>
          <Input
            id="company_name"
            name="company_name"
            label="Enter Company Name"
            value={formik.values.company_name}
            onChange={formik.handleChange}
            error={
              formik.touched.company_name && Boolean(formik.errors.company_name)
            }
            helperText={
              formik.touched.company_name && formik.errors.company_name
            }
            type="text"
          />
        </Grid>
        {/* Company_name_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>
            Company Name Arabic:
          </InputLabel>
          <Input
            id="company_name_ar"
            name="company_name_ar"
            label="Enter Company Name Arabic"
            value={formik.values.company_name_ar}
            onChange={formik.handleChange}
            error={
              formik.touched.company_name_ar &&
              Boolean(formik.errors.company_name_ar)
            }
            helperText={
              formik.touched.company_name_ar && formik.errors.company_name_ar
            }
            type="text"
          />
        </Grid>
        {/* bg_color */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Background Color:</InputLabel>
          <ColorPicker
            name="bg_color"
            color={formik.values.bg_color}
            setColor={formik.handleChange}
          />
        </Grid>
        {/* logo  */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Logo:</InputLabel>
          <ImageUpload
            image={logo}
            setImage={setLogo}
            error={logoError}
            setError={setLogoError}
          />
        </Grid>
        {/* logo_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Logo Arabic:</InputLabel>
          <ImageUpload
            image={logoAr}
            setImage={setLogoAr}
            error={logoArError}
            setError={setLogoArError}
          />
        </Grid>
        {/* terms_url  */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Terms Link:</InputLabel>
          <Input
            id="terms_url"
            name="terms_url"
            label="Enter Terms Link"
            value={formik.values.terms_url}
            onChange={formik.handleChange}
            error={formik.touched.terms_url && Boolean(formik.errors.terms_url)}
            helperText={formik.touched.terms_url && formik.errors.terms_url}
            type="text"
          />
        </Grid>
        {/* description_before  */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Description Before:</InputLabel>
          <Input
            id="description_before"
            name="description_before"
            label="Enter Description Before"
            multiline
            value={formik.values.description_before}
            onChange={formik.handleChange}
            error={
              formik.touched.description_before &&
              Boolean(formik.errors.description_before)
            }
            helperText={
              formik.touched.description_before &&
              formik.errors.description_before
            }
            type="text"
          />
        </Grid>
        {/* description_before_ar  */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>
            Description Before Arabic:
          </InputLabel>
          <Input
            id="description_before_ar"
            name="description_before_ar"
            label="Enter Description Before Arabic"
            multiline
            value={formik.values.description_before_ar}
            onChange={formik.handleChange}
            error={
              formik.touched.description_before_ar &&
              Boolean(formik.errors.description_before_ar)
            }
            helperText={
              formik.touched.description_before_ar &&
              formik.errors.description_before_ar
            }
            type="text"
          />
        </Grid>
        {/* description_after  */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Description After:</InputLabel>
          <Input
            id="description_after"
            name="description_after"
            label="Enter Description After"
            multiline
            value={formik.values.description_after}
            onChange={formik.handleChange}
            error={
              formik.touched.description_after &&
              Boolean(formik.errors.description_after)
            }
            helperText={
              formik.touched.description_after &&
              formik.errors.description_after
            }
            type="text"
          />
        </Grid>
        {/* description_after_ar  */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>
            Description After Arabic:
          </InputLabel>
          <Input
            id="description_after_ar"
            name="description_after_ar"
            label="Enter Description After Arabic"
            multiline
            value={formik.values.description_after_ar}
            onChange={formik.handleChange}
            error={
              formik.touched.description_after_ar &&
              Boolean(formik.errors.description_after_ar)
            }
            helperText={
              formik.touched.description_after_ar &&
              formik.errors.description_after_ar
            }
            type="text"
          />
        </Grid>
        {/* redeem_service_text */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>
            Redeem Service Text:
          </InputLabel>
          <Input
            id="redeem_service_text"
            name="redeem_service_text"
            label="Enter Redeem Text"
            value={formik.values.redeem_service_text}
            onChange={formik.handleChange}
            error={
              formik.touched.redeem_service_text &&
              Boolean(formik.errors.redeem_service_text)
            }
            helperText={
              formik.touched.redeem_service_text &&
              formik.errors.redeem_service_text
            }
            type="text"
          />
        </Grid>
        {/* redeem_service_text_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>
            Redeem Service Text Arabic:
          </InputLabel>
          <Input
            id="redeem_service_text_ar"
            name="redeem_service_text_ar"
            label="Enter Redeem Text Arabic"
            value={formik.values.redeem_service_text_ar}
            onChange={formik.handleChange}
            error={
              formik.touched.redeem_service_text_ar &&
              Boolean(formik.errors.redeem_service_text_ar)
            }
            helperText={
              formik.touched.redeem_service_text_ar &&
              formik.errors.redeem_service_text_ar
            }
            type="text"
          />
        </Grid>
        {/* version_ios  */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>IOS Version:</InputLabel>
          <Input
            id="version_ios"
            name="version_ios"
            label="Enter IOS Version"
            value={formik.values.version_ios}
            onChange={formik.handleChange}
            error={
              formik.touched.version_ios && Boolean(formik.errors.version_ios)
            }
            helperText={formik.touched.version_ios && formik.errors.version_ios}
            type="number"
          />
        </Grid>
        {/* version_android  */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Android Version:</InputLabel>
          <Input
            id="version_android"
            name="version_android"
            label="Enter Android Version"
            value={formik.values.version_android}
            onChange={formik.handleChange}
            error={
              formik.touched.version_android &&
              Boolean(formik.errors.version_android)
            }
            helperText={
              formik.touched.version_android && formik.errors.version_android
            }
            type="number"
          />
        </Grid>
        {/* display_code  */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Display Code:</InputLabel>
          <Input
            id="display_code"
            name="display_code"
            label="Enter Display Code"
            value={formik.values.display_code}
            onChange={formik.handleChange}
            error={
              formik.touched.display_code && Boolean(formik.errors.display_code)
            }
            helperText={
              formik.touched.display_code && formik.errors.display_code
            }
            type="number"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default RedeemyCompany;
