import * as actionsType from "../actions/actionsType";

const recyclerReducer = (
  state = {
    branches: null,
    archivedItems: null,
    archivedWaiting: null,
    archivedApproved: null,
    collectableIds: null,
    collectable: null,
    archivedCollected: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_RECYCLER_WAITING_BATCHES:
      return {
        ...state,
        archivedWaiting: action.payload,
      };
    case actionsType.GET_RECYCLER_APPROVED_BATCHES:
      return {
        ...state,
        archivedApproved: action.payload,
      };
    case actionsType.GET_RECYCLER_COLLECTED_BATCHES:
      return {
        ...state,
        archivedCollected: action.payload,
      };
    case actionsType.GET_RECYCLER_ITEMS:
      return {
        ...state,
        archivedItems: action.payload,
      };
    case actionsType.GET_RECYCLER_BATCH:
      return {
        ...state,
        archivedBatch: action.payload,
      };
    case actionsType.SET_RECYCLER_ARRAY:
      return {
        ...state,
        collectableIds: action.payload,
      };
    case actionsType.GET_RECYCLER_COLLECTABLE:
      return { ...state, collectable: action.payload };
    default:
      return state;
  }
};

export default recyclerReducer;
