import React, { useState } from "react";
import { Grid, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import useClasses from "hooks/useClasses";

import styles from "./BranchStyle";

export default function CompanyInput({
  onChangeHandler,
  selectedCompany,
  selectedCompanyId,
  companiesList,
}) {
  const classes = useClasses(styles);

  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };
  const [open, setOpen] = React.useState(false);
  const handleChange = (e) => {
    setOpen(false);
    onChangeHandler(e);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Grid item xs={12}>
      <InputLabel className={classes.label}>Company:</InputLabel>
      <Select
        id="company_id"
        name="company_id"
        label="Company"
        className={classes.selectInput}
        value={selectedCompanyId}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
        input={<Input />}
        inputProps={{ "aria-label": "Without label" }}
      >
        {companiesList.map((company) => (
          <MenuItem key={company.id} value={company.id}>
            <ListItemText primary={company.name} />
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
}
