const styles = (theme) => ({
  loadingContainer: {
    textAlign: "center",
    background: theme.palette.background.default,
    opacity: "0.6",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
  },
  loading: {
    display: "block",
    color: theme.palette.primary.main,
    width: "100px!important",
    height: "100px!important",
  },
});

export default styles;
