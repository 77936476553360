import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";
// Recyclers

export const getRecyclers = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_USERS_RECYCLERS, payload: null });
    const { data } = await api.getUsersRecyclers(formData);
    dispatch({ type: actionsType.GET_USERS_RECYCLERS, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createNewRecycler =
  (newUser, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.createUsersRecyclers(newUser);
      dispatch(getRecyclers(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "the new Recycler user has been created successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const updateRecycler =
  (newUser, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.UpdateUsersRecyclers(newUser);
      dispatch(getRecyclers(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Recycler user has been updated successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const deleteRecycler =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteUsersRecyclers({ ids: idsArray });
      dispatch(getRecyclers(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Recycler user has been Deleted successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const changeActiveRecycler =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeActiveUsersRecyclers({ ids: idsArray });
      dispatch(getRecyclers(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Recycler user's status has been changed successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
// export const getAllBranches = (history) => async (dispatch) => {
//   try {
//     dispatch({
//       type: actionsType.SET_LOADING,
//       payload: true,
//     });
//     dispatch({ type: actionsType.GET_ALL_BRANCHES_RECYCLERS, payload: null });
//     const { data } = await api.getAllBranches();
//     dispatch({ type: actionsType.GET_ALL_BRANCHES_RECYCLERS, payload: data });
//     dispatch({
//       type: actionsType.SET_LOADING,
//       payload: false,
//     });
//   } catch (error) {
//     dispatch(errorHandler(error, history));
//   }
// };
