import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// core components
import Navbar from "components/Shared/Navbars/Navbar.js";
import Footer from "components/Shared/Footer/CustomFooter";
import Sidebar from "components/Shared/Sidebar/Sidebar.js";

import {
  adminRoutes,
  subAdminRoutes,
  managerRoutes,
  agentRoutes,
  recyclerRoutes,
  companyAdminRoutes,
  adminLinks,
  subAdminLinks,
  managerLinks,
  agentLinks,
  recyclerLinks,
  companyAdminLinks,
} from "routes";

import styles from "./Styles";
import useClasses from "hooks/useClasses";

import logo from "assets/img/navLogo.png";

import { getAccount } from "store/actions/auth";

export default function Admin() {
  const [dashboardRoutes, setDashboardRoutes] = React.useState(null);
  const [dashboardLinks, setDashboardLinks] = React.useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const authData = useSelector((state) => state.auth.authData);
  React.useEffect(() => {
    if (!authData) {
      dispatch(getAccount(history));
    } else {
      if (authData.role === "admin") {
        setDashboardRoutes(adminRoutes);
        setDashboardLinks(adminLinks);
      } else if (authData.role === "sub-admin") {
        setDashboardRoutes(subAdminRoutes);
        setDashboardLinks(subAdminLinks);
      } else if (authData.role === "manager") {
        setDashboardRoutes(managerRoutes);
        setDashboardLinks(managerLinks);
      } else if (authData.role === "agent") {
        setDashboardRoutes(agentRoutes);
        setDashboardLinks(agentLinks);
      } else if (authData.role === "recycler") {
        setDashboardRoutes(recyclerRoutes);
        setDashboardLinks(recyclerLinks);
      } else if (authData.role === "company-admin") {
        setDashboardRoutes(companyAdminRoutes);
        setDashboardLinks(companyAdminLinks);
      }
    }
  }, [authData]);

  // styles
  const classes = useClasses(styles);
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashboardLinks}
        logo={logo}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
      />
      <div className={classes.mainPanel}>
        <Navbar
          handleDrawerToggle={handleDrawerToggle}
          role={authData?.role}
          notificationsCount={authData?.unread_notification_count}
        />
        <div className={classes.content}>
          <div className={classes.container}>{dashboardRoutes}</div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
