import React from "react";
import useClasses from "hooks/useClasses";
import { NavLink } from "react-router-dom";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  createModalHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
  clientLink: {
    color: theme.palette.primary.contentText,
    textDecoration: "none",
  },
});

export default function Notifications({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,
  readAllHandler,
  readHandler,
}) {
  const classes = useClasses(styles);

  const toolbarButtons = (
    <div style={{ margin: "10px 0 " }}>
      <Button color="primary" onClick={readAllHandler}>
        Read All
      </Button>
    </div>
  );

  const searchByOptions = [
    {
      value: "title",
      label: "Title",
    },
    {
      value: "message",
      label: "Message",
    },
  ];
  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "ID",
    },
    {
      id: "title",
      sortable: true,
      label: "Title",
    },
    {
      id: "message",
      sortable: true,
      label: "Message",
    },
    {
      id: "type",
      sortable: true,
      label: "Type",
    },
    {
      id: "read_at",
      sortable: true,
      label: "Read At",
    },
    {
      id: "action",
      sortable: false,
      label: "Action",
    },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              key={row.id}
              hover
              style={{ background: !row.read_at ? "#ccc" : null }}
            >
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => readHandler(row.id, row.item_id, row.type)}
              >
                {row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => readHandler(row.id, row.item_id, row.type)}
              >
                {row.title}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => readHandler(row.id, row.item_id, row.type)}
              >
                {row.body}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.type === 1
                  ? "new User"
                  : row.type === 2
                  ? "new Recycable"
                  : row.type === 3
                  ? "Branch"
                  : row.type === 4
                  ? "voucher"
                  : row.type === 5
                  ? "transaction"
                  : "other"}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.read_at
                  ? new Date(row.read_at).toLocaleString()
                  : "Not Read Yet"}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <Button
                  color="primary"
                  disabled={row.read_at !== null}
                  onClick={() => readHandler(row.id, row.item_id, row.type)}
                >
                  Read notification
                </Button>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Notifications
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={null}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
    </React.Fragment>
  );
}
