import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ColorPicker from "components/Shared/ColorPicker/ColorPicker";
import useClasses from "hooks/useClasses";

import styles from "./styles";
import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";
import CustomEditor from "components/Shared/CustomEditor/CustomEditor";

const validationSchema = yup.object({
  title: yup.string("Enter title"),
  title_ar: yup.string("Enter title arabic"),
  description: yup.string("Enter description"),
  description_ar: yup.string("Enter description arabic"),
  is_active: yup.number().min(0).max(1).required("Error in is_active"),
  redirect_url: yup
    .string("Enter Redirect Link")
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Please enter a correct Redirect Link"
    ),
});

const Carousel = ({ carousel, isCreate, createHandler, updateHandler }) => {
  const [image, setImage] = useState(
    carousel
      ? carousel.bg_image
        ? { file: null, data_url: carousel.bg_image }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [imageError, setImageError] = useState(null);
  const [imageAr, setImageAr] = useState(
    carousel
      ? carousel.bg_image_ar
        ? { file: null, data_url: carousel.bg_image_ar }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [imageArError, setImageArError] = useState(null);
  const formik = useFormik({
    initialValues: {
      id: !isCreate ? (carousel ? carousel.id : "") : "",
      title: carousel ? (carousel.title ? carousel.title : "") : "",
      title_ar: carousel ? (carousel.title_ar ? carousel.title_ar : "") : "",
      description: carousel
        ? carousel.description
          ? carousel.description
          : ""
        : "",
      description_ar: carousel
        ? carousel.description_ar
          ? carousel.description_ar
          : ""
        : "",
      is_active: carousel ? carousel.is_active : 0,
      redirect_url: carousel
        ? carousel.redirect_url
          ? carousel.redirect_url
          : ""
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    const result = { ...values };
    let error = false;
    if (imageError || imageArError) {
      error = true;
    }
    if (image.file !== null) {
      result.bg_image = image.file;
    } else if (image.data_url === null) {
      setImageError("Enter Image!");
      error = true;
    }
    if (imageAr.file !== null) {
      result.bg_image_ar = imageAr.file;
    } else if (imageAr.data_url === null) {
      setImageArError("Enter Image Arabic!");
      error = true;
    }
    if (error === false) {
      if (result.is_offer === 0) {
        result.offer_points = 0;
      }
      if (isCreate) {
        createHandler(result);
      } else {
        updateHandler(result);
      }
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {(imageError !== null || imageArError !== null) && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {imageError !== null && (
                  <li key="image" style={{ color: "#fff" }}>
                    {imageError}
                  </li>
                )}
                {imageArError !== null && (
                  <li key="imageAr" style={{ color: "#fff" }}>
                    {imageArError}
                  </li>
                )}
              </ul>
            </div>
          </Grid>
        )}
        {Object.keys(formik.errors).length !== 0 && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {Object.keys(formik.errors).map((error, index) => (
                  <li key={index} style={{ color: "#fff" }}>
                    {formik.errors[error]}
                  </li>
                ))}
              </ul>
            </div>
          </Grid>
        )}
        {/* title */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Title:</InputLabel>
          <Input
            id="title"
            name="title"
            label="Enter Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
            required={false}
            type="text"
          />
        </Grid>
        {/* title_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Title Arabic:</InputLabel>
          <Input
            id="title_ar"
            name="title_ar"
            label="Enter Title Arabic"
            value={formik.values.title_ar}
            onChange={formik.handleChange}
            error={formik.touched.title_ar && Boolean(formik.errors.title_ar)}
            helperText={formik.touched.title_ar && formik.errors.title_ar}
            required={false}
            type="text"
          />
        </Grid>
        {/* Description English */}
        <Grid item xs={12} sm={6}>
          <InputLabel
            className={classes.label}
            style={
              formik.touched.description && Boolean(formik.errors.description)
                ? { color: red }
                : {}
            }
          >
            Description English:
          </InputLabel>
          <CustomEditor
            name="description"
            value={formik.values.description}
            handleChange={formik.handleChange}
          />
          {formik.touched.description && formik.errors.description && (
            <span style={{ color: "red" }}>{formik.errors.description}</span>
          )}
        </Grid>
        {/* Description Arabic */}
        <Grid item xs={12} sm={6}>
          <InputLabel
            className={classes.label}
            style={
              formik.touched.description_ar &&
              Boolean(formik.errors.description_ar)
                ? { color: red }
                : {}
            }
          >
            Description Arabic:
          </InputLabel>
          <CustomEditor
            name="description_ar"
            value={formik.values.description_ar}
            handleChange={formik.handleChange}
          />
          {formik.touched.description_ar && formik.errors.description_ar && (
            <span style={{ color: "red" }}>{formik.errors.description_ar}</span>
          )}
        </Grid>
        {/* Image */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Image:</InputLabel>
          <ImageUpload
            image={image}
            setImage={setImage}
            error={imageError}
            setError={setImageError}
          />
        </Grid>
        {/* ImageAr */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Image Arabic:</InputLabel>
          <ImageUpload
            image={imageAr}
            setImage={setImageAr}
            error={imageArError}
            setError={setImageArError}
          />
        </Grid>
        {/* redirect_url */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Redirect Link:</InputLabel>
          <Input
            id="redirect_url"
            name="redirect_url"
            label="Enter Redirect Link"
            value={formik.values.redirect_url}
            onChange={formik.handleChange}
            required={false}
            error={
              formik.touched.redirect_url && Boolean(formik.errors.redirect_url)
            }
            helperText={
              formik.touched.redirect_url && formik.errors.redirect_url
            }
            type="text"
          />
        </Grid>
        {/* is_active */}
        <Grid item xs={12}>
          <CustomSwitch
            name="is_active"
            value={formik.values.is_active}
            onChangeHandler={formik.handleChange}
            title="Active"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default Carousel;
