import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import useClasses from "hooks/useClasses";
import { useTheme } from "@mui/material/styles";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Button from "components/Shared/CustomButtons/Button.js";
import Poppers from "@mui/material/Popper";
// import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import Person from "@mui/icons-material/Person";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import styles from "./headerLinksStyle";
import { userLogout } from "store/actions/auth";
import { ColorModeContext } from "variables/colorContext";
import { Notifications } from "@mui/icons-material";
import { Badge, Divider, Hidden } from "@mui/material";

export default function AdminNavbarLinks({ role, notificationsCount }) {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const classes = useClasses(styles);
  const history = useHistory();
  const dispatch = useDispatch();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = (event) => {
    history.push("/admin/notifications");
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleLogout = () => {
    setOpenProfile(null);
    dispatch(userLogout(history));
  };
  const handleSettings = () => {
    setOpenProfile(null);
    history.push("/admin/profile");
  };
  const openKnowledge = () => {
    history.push("/admin/knowledge-base");
  };

  return (
    <div className={classes.adminLinkContainer}>
      <Tooltip title="Knowlegde Base">
        <IconButton aria-label="open" onClick={openKnowledge}>
          <CollectionsBookmarkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={`${theme.palette.mode} mode`}>
        <IconButton
          sx={{ ml: 1 }}
          onClick={colorMode.toggleColorMode}
          color="inherit"
        >
          {theme.palette.mode === "dark" ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
      </Tooltip>

      <div className={classes.manager}>
        <Button
          color="transparent"
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openNotification ? "notification-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
        >
          <Badge
            badgeContent={notificationsCount}
            color="error"
            showZero={false}
            max={999}
          >
            <Notifications className={classes.icons} />
          </Badge>
        </Button>
      </div>

      <div className={classes.manager}>
        <Button
          color="transparent"
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-owns={openProfile ? "profile-menu-list-grow" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
        >
          <Person />
          <Hidden mdUp implementation="css">
            <p className={classes.linkText}>Profile</p>
          </Hidden>
        </Button>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          className={
            classNames({ [classes.popperClose]: !openProfile }) +
            " " +
            classes.popperNav
          }
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleSettings}
                      className={classes.dropdownItem}
                    >
                      Account
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={handleLogout}
                      className={classes.dropdownItem}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
      </div>
    </div>
  );
}
