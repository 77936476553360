import React from "react";
import Knowledge from "./Knowledge";
function StaffKnowledgeBase({ staff }) {
  return (
    <div>
      {staff.length === 0 && (
        <div style={{ textAlign: "center" }}>
          <h3>Coming Soon</h3>
        </div>
      )}
      {staff.map((value, index) => (
        <Knowledge title={value.title} yid={value.yid} key={index} />
      ))}
    </div>
  );
}

export default StaffKnowledgeBase;
