import * as actionsType from "../actions/actionsType";

const settingsReducer = (state = { settings: null }, action) => {
  switch (action.type) {
    case actionsType.GET_SETTINGS:
      return { ...state, settings: action.payload };
    default:
      return state;
  }
};

export default settingsReducer;
