import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Table from "components/Admin/PickupLocations/Districts/Districts";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import {
  getDistricts,
  deleteDistrict,
  createDistrict,
  updateDistrict,
  changeStatusDistrict,
} from "store/actions/locationDistrict";

const Districts = () => {
  const districtsData = useSelector((state) => state.locations.districts);
  const allCities = useSelector((state) => state.locations.allCities);
  const dispatch = useDispatch();
  const [page, setPage] = useState(districtsData ? districtsData.pag.page : 1);
  const [amount, setAmount] = useState(
    districtsData ? districtsData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    districtsData ? districtsData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    districtsData ? districtsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    districtsData ? districtsData.pag.search_by : "name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getDistricts(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(deleteDistrict(id, pag, history));
  };
  const createHandler = (newData) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(createDistrict(newData, pag, history));
  };
  const updateHandler = (newData) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(updateDistrict(newData, pag, history));
  };
  const changeStatusHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
      search_by: searchBy,
    };
    dispatch(changeStatusDistrict(id, pag, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Districts Table</h4>
        </CardHeader>
        <CardBody>
          {districtsData && (
            <Table
              rows={districtsData.data}
              totalRows={districtsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              createHandler={createHandler}
              updateHandler={updateHandler}
              deleteHandler={deleteHandler}
              cities={allCities}
              changeStatusHandler={changeStatusHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Districts;
