const styles = (theme) => ({
  container: {
    padding: "10px",
    width: "100%",
    overflowX: "auto",
    // width: "100%",
    // height: "100%",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "space-between",
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.contarstText,
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  childContainer: {
    flex: 1,
    margin: "0 10px",
    minWidth: "320px",
  },
  chipGreen: {
    backgroundColor: "#006400!important",
    color: "#fff!important",
  },
  chipRed: {
    backgroundColor: "#CD0000!important",
    color: "#fff!important",
  },
  chipPurple: {
    backgroundColor: "#8B008B!important",
    color: "#fff!important",
  },
  chipBlue: {
    backgroundColor: "#0000CD!important",
    color: "#fff!important",
  },
  contentContainer: {
    margin: "10px 0",
  },
  label: {
    fontWeight: "bold",
    color: theme.palette.primary.mainColor + "!important",
  },
  content: {
    fontWeight: "semiBold",
    color: theme.palette.primary.contarstText,
  },
  title: {
    color: theme.palette.primary.contarstText,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  htmlContainer: {
    border: "1px solid " + theme.palette.primary.main,
    padding: "20px",
    marginBottom: "20px",
  },
  chips: {
    margin: "10px auto",
  },
  chip: {
    margin: "auto 5px",
    backgroundColor: theme.palette.primary.main + "!important",
    color: theme.palette.primary.contarstText + "!important",
  },
  voucherImageContainer: {
    width: "300px",
    height: "150px",
    position: "relative",
  },
  bgContainer: {
    width: "300px",
    height: "150px",
  },
  bg: {
    width: "300px",
    height: "150px",
  },
  logoContainer: {
    position: "absolute",
    width: "80px",
    height: "100px",
    top: "25px",
    left: "20px",
  },
  logo: { width: "100%", height: "100%", objectFit: "contain" },
  discountContainer: {
    position: "absolute",
    width: "150px",
    height: "100px",
    top: "25px",
    right: "20px",
  },
  discount: { width: "100%", height: "100%", objectFit: "contain" },
  colorPreview: {
    border: "5px solid " + theme.palette.primary.main,
  },
});

export default styles;
