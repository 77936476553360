import React, { useState, useEffect } from "react";

import useClasses from "hooks/useClasses";

import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import styles from "./Styles";

function nFormatter(num) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
}

export default function VoucherNumbers({ voucherNumbers }) {
  const [showTotal, setShowTotal] = useState(false);
  const [showUsed, setShowUsed] = useState(false);
  const [showNotUsed, setShowNotUsed] = useState(false);
  const [showExpired, setShowExpired] = useState(false);
  const [showNearBy, setShowNearby] = useState(false);
  const classes = useClasses(styles);

  return (
    <div>
      {voucherNumbers && (
        <GridContainer>
          {/* total */}
          <GridItem xs={12} sm={6} md={4}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowTotal((prev) => !prev)}
            >
              <CardHeader color="secondary" icon>
                <h4 className={classes.cardCategory}>TOTAL Codes</h4>
              </CardHeader>
              <CardBody>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowTotal(true)}
                  onMouseLeave={() => setShowTotal(false)}
                >
                  {showTotal
                    ? voucherNumbers.total
                    : nFormatter(voucherNumbers.total)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
          {/* used */}
          <GridItem xs={12} sm={6} md={4}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowUsed((prev) => !prev)}
            >
              <CardHeader color="success" icon>
                <h4 className={classes.cardCategory}>USED Codes</h4>
              </CardHeader>
              <CardBody>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowUsed(true)}
                  onMouseLeave={() => setShowUsed(false)}
                >
                  {showUsed
                    ? voucherNumbers.used
                    : nFormatter(voucherNumbers.used)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
          {/* nearby */}
          <GridItem xs={12} sm={6} md={4}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowNearby((prev) => !prev)}
            >
              <CardHeader color="warning" icon>
                <h4 className={classes.cardCategory}>NEARBY EXPIRED Codes</h4>
              </CardHeader>
              <CardBody>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowNearby(true)}
                  onMouseLeave={() => setShowNearby(false)}
                >
                  {showNearBy
                    ? voucherNumbers.notUsedWithNearbyExpired
                    : nFormatter(voucherNumbers.notUsedWithNearbyExpired)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
          {/* not used */}
          <GridItem xs={12} sm={6} md={4}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowNotUsed((prev) => !prev)}
            >
              <CardHeader color="danger" icon>
                <h4 className={classes.cardCategory}>NOT USED Codes</h4>
              </CardHeader>
              <CardBody>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowNotUsed(true)}
                  onMouseLeave={() => setShowNotUsed(false)}
                >
                  {showNotUsed
                    ? voucherNumbers.notUsedWithoutExpired
                    : nFormatter(voucherNumbers.notUsedWithoutExpired)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
          {/* expired */}
          <GridItem xs={12} sm={6} md={4}>
            <Card
              style={{ minHeight: "130px", cursor: "pointer" }}
              onClick={() => setShowExpired((prev) => !prev)}
            >
              <CardHeader color="danger" icon>
                <h4 className={classes.cardCategory}>EXPIRED Codes</h4>
              </CardHeader>
              <CardBody>
                <h3
                  className={classes.cardTitle}
                  onMouseEnter={() => setShowExpired(true)}
                  onMouseLeave={() => setShowExpired(false)}
                >
                  {showExpired
                    ? voucherNumbers.notUsedWithExpired
                    : nFormatter(voucherNumbers.notUsedWithExpired)}
                </h3>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  );
}
