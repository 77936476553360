import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";

import useClasses from "hooks/useClasses";

import styles from "./styles";

const validationSchema = yup.object({
  name: yup.string("Enter name").required("Name is required"),
  name_ar: yup.string("Enter name arabic").required("Name arabic is required"),
});

const State = ({ state, isCreate, createHandler, updateHandler }) => {
  const formik = useFormik({
    initialValues: {
      name: state ? state.name : "",
      name_ar: state ? state.name_ar : "",
      is_active: state ? state.is_active : 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    if (isCreate) {
      createHandler(values);
    } else {
      const result = { ...values };
      result.id = state.id;
      updateHandler(result);
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* name */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name:</InputLabel>
          <Input
            id="name"
            name="name"
            label="Enter Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            type="text"
          />
        </Grid>
        {/* name_ar */}
        <Grid item xs={12} sm={6}>
          <InputLabel className={classes.label}>Name Arabic:</InputLabel>
          <Input
            id="name_ar"
            name="name_ar"
            label="Enter Name Arabic"
            value={formik.values.name_ar}
            onChange={formik.handleChange}
            error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
            helperText={formik.touched.name_ar && formik.errors.name_ar}
            type="text"
          />
        </Grid>
        {/* is_active */}
        <Grid item xs={12}>
          <CustomSwitch
            name="is_active"
            value={formik.values.is_active}
            onChangeHandler={formik.handleChange}
            title="Active"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isCreate ? "Add" : "Update"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default State;
