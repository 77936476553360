import React from "react";
import useClasses from "hooks/useClasses";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
});

export default function EnhancedTable(props) {
  const {
    tableHead,
    tableToolbar,
    count,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
  } = props;
  const classes = useClasses(styles);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {tableToolbar}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            {tableHead}
            <TableBody>{props.children}</TableBody>
          </Table>
        </TableContainer>
        {count && count !== 0 ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        ) : null}
      </Paper>
    </div>
  );
}
