import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import ClientInfo from "components/Admin/Users/Clients/Client/ClientInfo";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useDispatch, useSelector } from "react-redux";
import { getUsersClient } from "store/actions/usersClients";
import ClientRecycables from "./ClientRecycables";
import ClientTransaction from "./ClientTransaction";
import ClientCart from "./ClientCart";
import CustomTabs from "components/Shared/CustomTabs/CustomTabs";

const Client = () => {
  const tabs = [
    {
      index: 0,
      label: "Recycables List",
      component: <ClientRecycables />,
    },
    {
      index: 1,
      label: "Transactions List",
      component: <ClientTransaction />,
    },
    {
      index: 2,
      label: "Cart List",
      component: <ClientCart />,
    },
  ];
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const client = useSelector((state) => state.usersClients.client);
  useEffect(() => {
    dispatch(getUsersClient(id, history));
  }, [id, dispatch]);

  return (
    <div>
      {client && (
        <div>
          <div>
            <Button
              onClick={history.goBack}
              color="transparent"
              style={{ padding: 0 }}
            >
              <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
              <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
            </Button>
          </div>
          <Card>
            <CardHeader color="primary">
              <h4>Client Info</h4>
            </CardHeader>
            <CardBody>
              <ClientInfo client={client} />
              <div>
                <CustomTabs tabs={tabs} />
              </div>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Client;
