import React from "react";
import useClasses from "hooks/useClasses";
import Modal from "components/Shared/CustomModal/CustomModal";
import styles from "./CollectableStyle";
import { useTheme } from "@mui/material";

const Images = ({ collectable }) => {
  const classes = useClasses(styles);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const onClickHandler = (image) => {
    setModalContent(
      <div className={classes.modalContainer}>
        <img
          src={image}
          alt="Recycable"
          className={classes.modalImage}
          onClick={() => setOpenModal(false)}
        />
      </div>
    );
    setOpenModal(true);
  };
  const theme = useTheme();
  return (
    <>
      <div className={classes.imagesListContainer}>
        {collectable.images.map((image, index) => (
          <div key={index} className={classes.imageContainer}>
            <img
              alt={collectable.client_name}
              src={image.url}
              className={classes.image}
              onClick={() => onClickHandler(image.url)}
            />
          </div>
        ))}
      </div>
      <Modal
        customStyle={{
          height: "100%",
          backgroundColor: theme.palette.primary.main,
          outline: "none",
        }}
        open={openModal}
        handleClose={() => setOpenModal(false)}
      >
        {modalContent}
      </Modal>
    </>
  );
};

export default Images;
