const styles = (theme) => ({
  form: {
    marginBottom: "20px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: theme.palette.primary.mainColor + "!important",
  },
  labelError: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: "red",
  },
  selectInput: {
    width: "100%",
  },
  customCheckbox: {
    color: theme.palette.primary.main,
    "&:checked": {
      color: theme.palette.primary.main,
    },
  },
  customRadio: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
});

export default styles;
