const styles = (theme) => ({
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      display: "block",
      textAlign: "center",
    },
  },
  buttonContainer: {
    margin: "5px",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      margin: "5px auto",
    },
  },
  createModalHeader: {
    color: theme.palette.common.white + "!important",
    background:
      "linear-gradient(60deg, " +
      theme.palette.primary.light +
      ", " +
      theme.palette.primary.dark +
      ")!important",
    boxShadow: theme.shadows[1],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
});

export default styles;
