import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useClasses from "hooks/useClasses";
import IconButton from "@mui/material/IconButton";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import VoucherInfo from "components/Admin/Vouchers/Voucher/Voucher";
import AddNewCode from "components/Admin/Vouchers/AddNewCodes";
import AddAmount from "components/Admin/Vouchers/AddAmount";
import AssignCatBranches from "components/Admin/Vouchers/AssignCatBranches";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { useDispatch, useSelector } from "react-redux";
import {
  getVoucher,
  changeStatusVoucher,
  deleteVoucher,
  addNewCode,
  addAmount,
} from "store/actions/vouchers";

import styles from "./styles";
import { assignCategoriesBranches } from "store/actions/vouchers";
import VoucherNumbers from "components/Admin/Vouchers/VoucherNumbers/VoucherNumbers";

const Voucher = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useClasses(styles);
  const voucherData = useSelector((state) => state.vouchers.voucher);
  const allCategories = useSelector((state) => state.vouchers.categories);
  const allBranches = useSelector((state) => state.vouchers.branches);
  const voucherNumbers = useSelector((state) => state.vouchers.voucherNumbers);

  useEffect(() => {
    dispatch(getVoucher(id, history));
  }, [id, history, dispatch]);

  const openNewCode = () => {
    setModalContent(
      <>
        <div className={classes.createModalHeader}>
          <span className={classes.createModalTitle}>Add New Codes</span>
          <IconButton aria-label="delete" onClick={() => setOpenModal(false)}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div className={classes.createModalBody}>
          <AddNewCode id={id} addHandler={addNewCodesHandler} />
        </div>
      </>
    );
    setOpenModal(true);
  };

  const addNewCodesHandler = (code) => {
    dispatch(addNewCode(code, history));
    setOpenModal(false);
  };
  const openAmount = () => {
    setModalContent(
      <>
        <div className={classes.createModalHeader}>
          <span className={classes.createModalTitle}>Add Amount</span>
          <IconButton aria-label="delete" onClick={() => setOpenModal(false)}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div className={classes.createModalBody}>
          <AddAmount id={id} addHandler={addAmountHandler} />
        </div>
      </>
    );
    setOpenModal(true);
  };

  const addAmountHandler = (amount) => {
    dispatch(addAmount(amount, history));
    setOpenModal(false);
  };

  const showItemsHandler = () => {
    history.push("/admin/vouchers/items/" + id);
  };
  const updateHandler = () => {
    history.push("/admin/vouchers/edit/" + id);
  };

  const openDeleteModal = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="error"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to delete this Voucher ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div>
            <Button color="danger" size="lg" onClick={deleteHandler}>
              Delete
            </Button>
          </div>
          <div>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  const deleteHandler = () => {
    dispatch(deleteVoucher([id], history));
    setOpenModal(false);
  };

  const openChangeStatusModal = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="error"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to change status of this voucher ? </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div>
            <Button color="primary" size="lg" onClick={changeStatusHandler}>
              Change
            </Button>
          </div>
          <div>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
    setOpenModal(true);
  };

  const changeStatusHandler = () => {
    dispatch(changeStatusVoucher([id], history));
    setOpenModal(false);
  };

  const openAssignCatBranchModal = () => {
    setModalContent(
      <>
        <div className={classes.createModalHeader}>
          <span className={classes.createModalTitle}>
            Assigning Categories and Branches
          </span>
          <IconButton aria-label="delete" onClick={() => setOpenModal(false)}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
        <div className={classes.createModalBody}>
          <AssignCatBranches
            voucher={voucherData}
            allCategories={allCategories}
            allBranches={allBranches}
            assignHandler={assignHandler}
          />
        </div>
      </>
    );
    setOpenModal(true);
  };

  const assignHandler = (newData) => {
    dispatch(assignCategoriesBranches(newData, history));
    setOpenModal(false);
  };

  return (
    <>
      {voucherNumbers && <VoucherNumbers voucherNumbers={voucherNumbers} />}
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4>Voucher information</h4>
          </CardHeader>
          <CardBody>
            {voucherData && allCategories && allBranches && (
              <>
                <div className={classes.buttonsContainer}>
                  <div className={classes.buttonContainer}>
                    <Button color="secondary" onClick={updateHandler}>
                      Update
                    </Button>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button color="danger" onClick={openDeleteModal}>
                      Delete
                    </Button>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button color="primary" onClick={openChangeStatusModal}>
                      {voucherData.is_active === 0 ? "Activate" : "deactivate"}
                    </Button>
                  </div>
                  <div className={classes.buttonContainer}>
                    <Button color="secondary" onClick={showItemsHandler}>
                      Show Codes
                    </Button>
                  </div>
                </div>
                <div className={classes.buttonsContainer}>
                  {voucherData.is_single_voucher === 0 ? (
                    <div className={classes.buttonContainer}>
                      <Button color="primary" onClick={openNewCode}>
                        Add new Codes
                      </Button>
                    </div>
                  ) : (
                    <div className={classes.buttonContainer}>
                      <Button color="primary" onClick={openAmount}>
                        Add new Amount
                      </Button>
                    </div>
                  )}
                  <div className={classes.buttonContainer}>
                    <Button color="primary" onClick={openAssignCatBranchModal}>
                      Assign Cat-Branches
                    </Button>
                  </div>
                </div>
                <VoucherInfo
                  voucher={voucherData}
                  categories={allCategories}
                  branches={allBranches}
                />
              </>
            )}
            <Modal
              open={openModal}
              handleClose={() => setOpenModal(false)}
              customStyle={{
                width: "100%",
                maxWidth: "500px",
                maxHeight: "500px",
                padding: 0,
                overflowY: "hidden",
              }}
            >
              {modalContent}
            </Modal>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Voucher;
