import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getBranches = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCHES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getBranches(formData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({ type: actionsType.GET_BRANCHES, payload: data });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteBranch =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteBranch({ ids: idsArray });
      dispatch(getBranches(formData, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "Branch deleted Successfully" },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const resetBranch =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.resetBranch({
        ids: idsArray,
      });
      dispatch(getBranches(formData, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "branch reseted Successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const changeStatusBranch =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusBranch({
        ids: idsArray,
      });
      dispatch(getBranches(formData, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "status has been changed successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const clearBranch = () => (dispatch) => {
  dispatch({
    type: actionsType.GET_BRANCH,
    payload: null,
  });
};

export const getBranch = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const branchRes = await api.getBranch(id);
    dispatch({
      type: actionsType.GET_BRANCH,
      payload: branchRes.data.data,
    });
    dispatch(getCompanies(history));
    dispatch(getDays(history));
    dispatch(getCategories(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_COMPANIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllCompanies();
    dispatch({
      type: actionsType.GET_BRANCH_COMPANIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getDays = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_DAYS,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getDays();
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.GET_BRANCH_DAYS,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCategories = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_CATEGORIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllCategories();
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.GET_BRANCH_CATEGORIES,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getSubCategories = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_SUBCATEGORIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllSubCategories();
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.GET_BRANCH_SUBCATEGORIES,
      payload: data,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createBranch = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("name", newData.name);
    formNewData.append("name_ar", newData.name_ar);
    formNewData.append("phone", newData.phone);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    formNewData.append("icon", newData.icon);
    formNewData.append("lat", newData.lat);
    formNewData.append("lng", newData.lng);
    formNewData.append("address", newData.address);
    formNewData.append("address_ar", newData.address_ar);
    formNewData.append("company_id", newData.company_id);
    formNewData.append("capacity", newData.capacity);
    formNewData.append("days", newData.days);
    formNewData.append("category_id", newData.category_id);
    await api.createBranch(formNewData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "New Branch has been created successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.push("/admin/branches");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const updateBranch = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const formNewData = new FormData();
    formNewData.append("id", newData.id);
    formNewData.append("name", newData.name);
    formNewData.append("name_ar", newData.name_ar);
    formNewData.append("phone", newData.phone);
    formNewData.append("description", newData.description);
    formNewData.append("description_ar", newData.description_ar);
    if (newData.icon) {
      formNewData.append("icon", newData.icon);
    }
    formNewData.append("lat", newData.lat);
    formNewData.append("lng", newData.lng);
    formNewData.append("address", newData.address);
    formNewData.append("address_ar", newData.address_ar);
    formNewData.append("company_id", newData.company_id);
    formNewData.append("capacity", newData.capacity);
    formNewData.append("days", newData.days);
    formNewData.append("category_id", newData.category_id);
    await api.updateBranch(formNewData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch(getBranch(newData.id, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "branch has been updated successfully",
      },
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getBranchCollected = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_BRANCH_COLLECTED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY,
      payload: [],
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getBranchCollectablesCollected(formData);
    let idsArray = [];
    data.data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_BRANCH_COLLECTED,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_BRANCH_ARRAY,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const archiveBranchCollected =
  (formData, data, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.createArchivedBatch(data);
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch(getBranchCollected(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "branch collected has been archived successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getBranchNotCollected =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_BRANCH_NOT_COLLECTED,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_BRANCH_ARRAY,
        payload: [],
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.getBranchCollectablesNotCollected(formData);
      let idsArray = [];
      data.data.data.map((d) => idsArray.push(d.id));
      dispatch({
        type: actionsType.GET_BRANCH_NOT_COLLECTED,
        payload: data.data,
      });
      dispatch({
        type: actionsType.SET_BRANCH_ARRAY,
        payload: idsArray,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const exportBranchCollectedPdf = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.branchCollectablesCollectedPdf(id);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    window.open(data, "_blank");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
