import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getTransactions = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_TRANSACTIONS, payload: null });
    const { data } = await api.getTransactions(formData);
    dispatch({ type: actionsType.GET_TRANSACTIONS, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getWaitingTransactions =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({ type: actionsType.GET_WAITING_TRANSACTIONS, payload: null });
      const { data } = await api.getWaitingTransactions(formData);
      dispatch({ type: actionsType.GET_WAITING_TRANSACTIONS, payload: data });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const successfulSend =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.successfulSend({ ids: idsArray });
      dispatch(getWaitingTransactions(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Transaction's Result sent the Client successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const failedSend = (idsArray, formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.failedSend({ ids: idsArray });
    dispatch(getWaitingTransactions(formData, history));
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "Transaction's Result sent the Client successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
