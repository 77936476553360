import * as actionsType from "../actions/actionsType";

const collectablesReducer = (
  state = {
    waiting: null,
    approved: null,
    rejected: null,
    expired: null,
    archived: null,
    collectable: null,
    companies: null,
    selectedCompany: null,
    collectableIdsArray: null,
    rejectReason: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_REJECT_REASON:
      return {
        ...state,
        rejectReason: action.payload,
      };
    case actionsType.SET_COLLECTABLES_ARRAY:
      return {
        ...state,
        collectableIdsArray: action.payload,
      };
    case actionsType.GET_COLLECTABLES_WAITING:
      return {
        ...state,
        waiting: action.payload,
      };
    case actionsType.GET_COLLECTABLES_APPROVED:
      return {
        ...state,
        approved: action.payload,
      };
    case actionsType.GET_COLLECTABLES_REJECTED:
      return {
        ...state,
        rejected: action.payload,
      };
    case actionsType.GET_COLLECTABLES_EXPIRED:
      return {
        ...state,
        expired: action.payload,
      };
    case actionsType.GET_COLLECTABLES_ARCHIVED:
      return {
        ...state,
        archived: action.payload,
      };
    case actionsType.GET_COLLECTABLE:
      return { ...state, collectable: action.payload };
    case actionsType.GET_COLLECTABLES_COMPANIES:
      return { ...state, companies: action.payload };
    default:
      return state;
  }
};

export default collectablesReducer;
