import React from "react";
import Table from "components/Shared/CustomTable/Table/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function ArchivedItems({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,

  showRecycable,
}) {
  const searchByOptions = [
    {
      value: "model_name",
      label: "Model Name",
    },
    {
      value: "user_name",
      label: "User Name",
    },
    {
      value: "user_phone",
      label: "User phone",
    },
    {
      value: "code",
      label: "Code",
    },
    {
      value: "branch_name",
      label: "Branch Name",
    },
  ];
  const headCells = [
    {
      id: "model_name",
      sortable: true,
      label: "Model Name",
    },
    {
      id: "user_name",
      sortable: true,
      label: "User Name",
    },
    {
      id: "user_phone",
      sortable: true,
      label: "User Phone",
    },
    {
      id: "agent_name",
      sortable: true,
      label: "Agent Name",
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created Date",
    },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => showRecycable(row.id)}
              >
                {row.item_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.client_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.client_phone}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => showRecycable(row.id)}
              >
                {row.agent_name}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
                onClick={() => showRecycable(row.id)}
              >
                {row.created_at}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <Tooltip title="open collectable details">
                  <IconButton
                    aria-label="show"
                    onClick={() => showRecycable(row.id)}
                  >
                    <VisibilityIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Collectables Found
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={null}
        toolbarButtons={null}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
    </React.Fragment>
  );
}
