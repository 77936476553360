const styles = (theme) => ({
  container: {
    margin: "20px 5px",
    width: "100%",
  },

  infoContainer: {
    margin: "10px 0",
    flex: 1,
  },
  buttonsContainer: {
    margin: "10px 0",
    display: "flex",
    justifyContent: "space-arround",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  buttonContainer: {
    flex: 1,
    margin: "10px",
    textAlign: "center",
  },
  label: {
    fontWeight: 900,
    fontSize: "18px",
    marginRight: "5px",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  content: {
    color: theme.palette.text.primary,
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  singleAddressContainer: {
    margin: "10px 5px",
  },
});

export default styles;
