import React from "react";
import PropTypes from "prop-types";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
const headCells = [
  {
    id: "item_name",
    numeric: false,
    disablePadding: true,
    label: "Recyclable Name",
  },
  {
    id: "client_name",
    numeric: false,
    disablePadding: true,
    label: "Client Name",
  },
  {
    id: "client_phone",
    numeric: false,
    disablePadding: true,
    label: "Client Phone",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "created_at",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  { id: "options", numeric: false, disablePadding: true, label: "Options" },
];

const EnhancedTableHead = (props) => {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="center" padding="normal">
            <span>{headCell.label}</span>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default EnhancedTableHead;
