import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/Admin/Transactions/WaitingTransactions";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import {
  getWaitingTransactions,
  successfulSend,
  failedSend,
} from "store/actions/transactions";

const WaitingTransactions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const waitingTransactionsData = useSelector(
    (state) => state.transactions.waitingTransactions
  );
  const [page, setPage] = useState(
    waitingTransactionsData ? waitingTransactionsData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    waitingTransactionsData ? waitingTransactionsData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    waitingTransactionsData
      ? waitingTransactionsData.pag.order_field
      : "created_at"
  );
  const [orderType, setOrderType] = useState(
    waitingTransactionsData ? waitingTransactionsData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    waitingTransactionsData
      ? waitingTransactionsData.pag.search_by
      : "client_name"
  );

  useEffect(() => {
    dispatch(
      getWaitingTransactions(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const sendSuccessfullHandler = (ids) => {
    dispatch(
      successfulSend(
        ids,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };
  const sendFaildHandler = (ids) => {
    dispatch(
      failedSend(
        ids,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Transactions Table</h4>
        </CardHeader>
        <CardBody>
          {waitingTransactionsData && (
            <Table
              rows={waitingTransactionsData.data}
              totalRows={waitingTransactionsData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
              handleSuccess={sendSuccessfullHandler}
              handleFailure={sendFaildHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default WaitingTransactions;
