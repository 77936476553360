const headerLinksStyle = (theme) => ({
  adminLinkContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  manager: {
    height: "40px",
    width: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});

export default headerLinksStyle;
