import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// import InputLabel from "@mui/material/InputLabel";
// import NativeSelect from "@mui/material/NativeSelect";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
// import DatePicker from "components/Admin/Branches/DatePicker";
import Table from "components/Recycler/Collectables/Collectables";

import { useDispatch, useSelector } from "react-redux";
import { getArchivedItems } from "store/actions/recycler";
import BatchInfo from "components/Recycler/Collectables/BatchInfo";
import { approveBatch } from "store/actions/recycler";
import { rejectBatch } from "store/actions/recycler";
import { collectBatch } from "store/actions/recycler";

const BranchArchivedItems = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { archivedItems, archivedBatch } = useSelector(
    (state) => state.recycler
  );
  const [page, setPage] = useState(archivedItems ? archivedItems.pag.page : 1);
  const [amount, setAmount] = useState(
    archivedItems ? archivedItems.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    archivedItems ? archivedItems.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    archivedItems ? archivedItems.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    archivedItems ? archivedItems.pag.search_by : "item_name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getArchivedItems(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          archived_collectables_id: id,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/recycler/recycable/" + id);
  };

  const approveBatchHandler = (price) => {
    dispatch(
      approveBatch(
        { id, minPrice: price.minPrice, maxPrice: price.maxPrice },
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          archived_collectables_id: id,
        },
        history,
        2
      )
    );
  };
  const rejectBatchHandler = () => {
    dispatch(
      rejectBatch(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          archived_collectables_id: id,
        },
        history,
        2
      )
    );
  };

  const collectBatchHandler = (code) => {
    dispatch(
      collectBatch(
        id,
        code,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          archived_collectables_id: id,
        },
        history,
        2
      )
    );
  };

  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        {archivedBatch && (
          <Card>
            <CardHeader color="primary">
              <h4>Batch #{id}</h4>
            </CardHeader>
            <CardBody>
              <BatchInfo
                archivedBatch={archivedBatch}
                approveBatch={approveBatchHandler}
                rejectBatch={rejectBatchHandler}
                collectBatch={collectBatchHandler}
              />
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader color="primary">
            <h4>Batch #{id}'s Recycables</h4>
          </CardHeader>
          <CardBody>
            {archivedItems && (
              <div>
                <Table
                  rows={archivedItems.data}
                  totalRows={archivedItems.pag.total}
                  rowsPerPage={amount}
                  setRowsPerPage={setAmount}
                  order={orderType}
                  setOrder={setOrderType}
                  orderBy={orderField}
                  setOrderBy={setOrderField}
                  page={page - 1}
                  setPage={setPage}
                  showRecycable={showRecycableHandler}
                  searchHandler={setSearchValue}
                  searchValue={searchValue}
                  selectedSearchBy={searchBy}
                  handleSearchByChange={setSearchBy}
                />
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default BranchArchivedItems;
