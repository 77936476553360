import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Branch from "components/Admin/Branches/Branch/Branch";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  getCompanies,
  getDays,
  createBranch,
  getCategories,
} from "store/actions/branches";

const CreateBranch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { companies, days, categories } = useSelector(
    (state) => state.branches
  );
  useEffect(() => {
    dispatch(getCompanies(history));
    dispatch(getDays(history));
    dispatch(getCategories(history));
  }, [dispatch]);

  const createHandler = (newData) => {
    dispatch(createBranch(newData, history));
  };

  return (
    <div>
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4>Branch Info</h4>
          </CardHeader>
          <CardBody>
            {companies && days && categories && (
              <Branch
                categories={categories}
                branch={null}
                companiesList={companies}
                daysList={days}
                isCreate={true}
                handleUpdate={null}
                handleCreate={createHandler}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default CreateBranch;
