// auth
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";
// profile
export const GET_NOTIFICATION = "GET_NOTIFICATION";

// Home
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_LOADING = "SET_LOADING";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const GET_HOME = "GET_HOME";
export const GET_MANAGER_HOME = "GET_MANAGER_HOME";
export const GET_COMPANY_ADMIN_HOME = "GET_COMPANY_ADMIN_HOME";

// users | Admins
export const GET_USERS_ADMINS = "GET_USERS_ADMINS";

// users | Branches
export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const GET_USERS_BRANCHES = "GET_USERS_BRANCHES";

// users | Client
export const GET_USERS_CLIENTS = "GET_USERS_CLIENTS";
export const GET_USERS_CLIENT = "GET_USERS_CLIENT";
export const GET_CLIENT_RECYCABLES = "GET_CLIENT_RECYCABLES";
export const GET_CLIENT_CART_ITEMS = "GET_CLIENT_CART_ITEMS";
export const GET_CLIENT_TRANSACTIONS = "GET_CLIENT_TRANSACTIONS";

// users | Managers
export const GET_USERS_AGENTS_MANAGER = "GET_USERS_AGENTS_MANAGER";

// users | COMPANY ADMIN
export const GET_USERS_COMPANY_ADMIN = "GET_USERS_COMPANY_ADMIN";
export const GET_ALL_COMPANIES = "GET_ALL_COMPANIES";

// users | Recyclers
export const GET_USERS_RECYCLERS = "GET_USERS_RECYCLERS";
export const GET_ALL_BRANCHES_RECYCLERS = "GET_ALL_BRANCHES_RECYCLERS";

// collectables
export const GET_COLLECTABLES_WAITING = "GET_COLLECTABLES_WAITING";
export const GET_COLLECTABLES_APPROVED = "GET_COLLECTABLES_APPROVED";
export const GET_COLLECTABLES_REJECTED = "GET_COLLECTABLES_REJECTED";
export const GET_COLLECTABLES_EXPIRED = "GET_COLLECTABLES_EXPIRED";
export const GET_COLLECTABLES_ARCHIVED = "GET_COLLECTABLES_ARCHIVED";
export const GET_COLLECTABLES_COLLECTED = "GET_COLLECTABLES_COLLECTED";
export const SET_COLLECTABLES_ARRAY = "SET_COLLECTABLES_ARRAY";
export const GET_COLLECTABLE = "GET_COLLECTABLE";
export const GET_COLLECTABLE_MANAGER = "GET_COLLECTABLE_MANAGER";
export const GET_COLLECTABLES_COMPANIES = "GET_COLLECTABLES_COMPANIES";
export const SEARCH_CODE = "SEARCH_CODE";
export const SET_SEARCH_CODE = "SET_SEARCH_CODE";
export const SET_SEARCH_CODE_ERROR = "SET_SEARCH_CODE_ERROR";
export const SET_IS_SEARCHING = "SET_IS_SEARCHING";
export const GET_REJECT_REASON = "GET_REJECT_REASON";
export const GET_COLLECTABLES_ARCHIVED_BATCHES =
  "GET_COLLECTABLES_ARCHIVED_BATCHES";
export const GET_COLLECTABLES_ARCHIVED_ITEMS =
  "GET_COLLECTABLES_ARCHIVED_ITEMS";
export const GENERATE_CODE = "GENERATE_CODE";

// Archived Batches
export const GET_WAITING_BATCHES = "GET_WAITING_BATCHES";
export const GET_APPROVED_BATCHES = "GET_APPROVED_BATCHES";
export const GET_COLLECTED_BATCHES = "GET_COLLECTED_BATCHES";
export const GET_REJECTED_BATCHES = "GET_REJECTED_BATCHES";
export const GET_RECYCLERS_LIST = "GET_RECYCLERS_LIST";
export const GET_ARCHIVED_BATCH_ITEMS = "GET_ARCHIVED_BATCH_ITEMS";
export const GET_ARCHIVED_BATCH = "GET_ARCHIVED_BATCH";
export const SET_ITEMS_ARRAY = "SET_ITEMS_ARRAY";

// support
export const GET_MANAGER_SUPPORT = "GET_MANAGER_SUPPORT";
export const GET_SUPPORT = "GET_SUPPORT";

// companies
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANY = "GET_COMPANY";
export const GET_MAIN_COMPANIES = "GET_MAIN_COMPANIES";
export const GET_COMPANY_NUMBERS = "GET_COMPANY_NUMBERS";
export const GET_COMPANY_COLLECTABLES = "GET_COMPANY_COLLECTABLES";
export const SET_COMPANY_COLLECTABLES_ARRAY = "SET_COMPANY_COLLECTABLES_ARRAY";

// branches
export const GET_BRANCHES = "GET_BRANCHES";
export const GET_BRANCH = "GET_BRANCH";
export const GET_BRANCH_COMPANIES = "GET_BRANCH_COMPANIES";
export const GET_BRANCH_SUBCATEGORIES = "GET_BRANCH_SUBCATEGORIES";
export const GET_BRANCH_DAYS = "GET_BRANCH_DAYS";
export const GET_BRANCH_CATEGORIES = "GET_BRANCH_CATEGORIES";
export const GET_BRANCH_ARCHIVED_BATCHES = "GET_BRANCH_ARCHIVED_BATCHES";
export const GET_BRANCH_ARCHIVED = "GET_BRANCH_ARCHIVED";
export const SET_BRANCH_ARRAY = "SET_BRANCH_ARRAY";
export const GET_BRANCH_COLLECTED = "GET_BRANCH_COLLECTED";
export const GET_BRANCH_NOT_COLLECTED = "GET_BRANCH_NOT_COLLECTED";

// category
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORIES_COMPANIES = "GET_CATEGORIES_COMPANIES";

// subCategory
export const GET_SUB_CATEGORIES = "GET_SUB_CATEGORIES";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_PRIORITIES = "GET_ALL_PRIORITIES";

// subCategory
export const GET_CONDITIONS_CATEGORIES = "GET_CONDITIONS_CATEGORIES";
export const GET_CONDITIONS = "GET_CONDITIONS";

// brand
export const GET_BRANDS = "GET_BRANDS";
export const GET_BRANDS_CATEGORIES = "GET_BRANDS_CATEGORIES";

// Voucher
export const SET_MANAGER_VOUCHER = "SET_MANAGER_VOUCHER";
export const SET_ADMIN_VOUCHER = "SET_ADMIN_VOUCHER";
export const GET_VOUCHER_NUMBERS = "GET_VOUCHER_NUMBERS";
export const VOUCHER_IS_SEARCHING = "VOUCHER_IS_SEARCHING";
export const GET_VOUCHERS = "GET_VOUCHERS";
export const GET_VOUCHER = "GET_VOUCHER";
export const GET_VOUCHER_ITEMS = "GET_VOUCHER_ITEMS";
export const GET_VOUCHER_COMPANIES = "GET_VOUCHER_COMPANIES";
export const GET_VOUCHER_CATEGORIES = "GET_VOUCHER_CATEGORIES";
export const GET_VOUCHER_BRANCHES = "GET_VOUCHER_BRANCHES";
export const GET_VOUCHER_BRANCHES_CATEGORIES =
  "GET_VOUCHER_BRANCHES_CATEGORIES";
export const MANAGER_VOUCHER_IS_SEARCHING = "MANAGER_VOUCHER_IS_SEARCHING";

// Location
export const GET_STATE = "GET_STATE";
export const GET_CITIES = "GET_CITIES";
export const GET_ALL_STATES = "GET_ALL_STATES";
export const GET_DISTRICTS = "GET_DISTRICTS";
export const GET_ALL_CITIES = "GET_ALL_CITIES";

// transactions
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_WAITING_TRANSACTIONS = "GET_WAITING_TRANSACTIONS";

// settings
export const GET_SETTINGS = "GET_SETTINGS";

//update mobile
export const GET_MOBILE_UPDATE = "GET_MOBILE_UPDATE";

//Carousels
export const GET_CAROUSELS = "GET_CAROUSELS";
export const GET_CAROUSEL = "GET_CAROUSEL";

//tutorial
export const GET_TUTORIALS = "GET_TUTORIALS";
export const GET_TUTORIAL = "GET_TUTORIAL";

//Redeem company
export const GET_REDEEM_COMPANIES = "GET_REDEEM_COMPANIES";
export const GET_REDEEM_COMPANY = "GET_REDEEM_COMPANY";

//Recycler
export const GET_RECYCLER_WAITING_BATCHES = "GET_RECYCLER_WAITING_BATCHES";
export const GET_RECYCLER_APPROVED_BATCHES = "GET_RECYCLER_APPROVED_BATCHES";
export const GET_RECYCLER_COLLECTED_BATCHES = "GET_RECYCLER_COLLECTED_BATCHES";
export const GET_RECYCLER_ITEMS = "GET_RECYCLER_ITEMS";
export const SET_RECYCLER_ARRAY = "SET_RECYCLER_ARRAY";
export const GET_RECYCLER_COLLECTABLE = "GET_RECYCLER_COLLECTABLE";
export const GET_RECYCLER_BATCH = "GET_RECYCLER_BATCH";

// company admin
// branches
export const GET_COLLECTABLE_COMPANY_ADMIN = "GET_COLLECTABLE_COMPANY_ADMIN";
export const GET_BRANCHES_COMPANY_ADMIN = "GET_BRANCHES_COMPANY_ADMIN";
export const GET_BRANCH_COMPANY_ADMIN = "GET_BRANCH_COMPANY_ADMIN";
export const GET_BRANCH_ARCHIVED_BATCHES_COMPANY_ADMIN =
  "GET_BRANCH_ARCHIVED_BATCHES_COMPANY_ADMIN";
export const GET_BRANCH_ARCHIVED_ITEMS_COMPANY_ADMIN =
  "GET_BRANCH_ARCHIVED_ITEMS_COMPANY_ADMIN";
export const GET_ARCHIVED_BATCH_COMPANY_ADMIN =
  "GET_ARCHIVED_BATCH_COMPANY_ADMIN";
export const SET_BRANCH_ARRAY_COMPANY_ADMIN = "SET_BRANCH_ARRAY_COMPANY_ADMIN";
export const GET_BRANCH_COLLECTED_COMPANY_ADMIN =
  "GET_BRANCH_COLLECTED_COMPANY_ADMIN";
export const GET_BRANCH_NOT_COLLECTED_COMPANY_ADMIN =
  "GET_BRANCH_NOT_COLLECTED_COMPANY_ADMIN";

// users
export const GET_COMPANY_BRANCH_USERS = "GET_COMPANY_BRANCH_USERS";
export const GET_COMPANY_DELETED_USERS = "GET_COMPANY_DELETED_USERS";
export const GET_COMPANY_ALL_BRANCHES = "GET_COMPANY_ALL_BRANCHES";
export const GET_COMPANY_ADMIN_COMPANY = "GET_COMPANY_ADMIN_COMPANY";
