import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Table from "components/Recycler/Collectables/ArchivedBatches";

import { useDispatch, useSelector } from "react-redux";
import { getArchivedApprovedRecycler } from "store/actions/recycler";
import { collectBatch } from "store/actions/recycler";

const ApprovedBatchesRecycler = () => {
  const dispatch = useDispatch();
  const { archivedApproved } = useSelector((state) => state.recycler);
  const [page, setPage] = useState(
    archivedApproved ? archivedApproved.pag.page : 1
  );
  const [amount, setAmount] = useState(
    archivedApproved ? archivedApproved.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    archivedApproved ? archivedApproved.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    archivedApproved ? archivedApproved.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    archivedApproved ? archivedApproved.pag.search_by : "branch_name"
  );

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getArchivedApprovedRecycler(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  const showBatchHandler = (id) => {
    history.push("/admin/recycler/batch-items/" + id);
  };

  const collectBatchHandler = (id, code) => {
    dispatch(
      collectBatch(
        id,
        code,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history,
        3
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Approved Archived Batches</h4>
        </CardHeader>
        <CardBody>
          {archivedApproved && (
            <div>
              <Table
                rows={archivedApproved.data}
                totalRows={archivedApproved.pag.total}
                rowsPerPage={amount}
                setRowsPerPage={setAmount}
                order={orderType}
                setOrder={setOrderType}
                orderBy={orderField}
                setOrderBy={setOrderField}
                page={page - 1}
                setPage={setPage}
                showBatch={showBatchHandler}
                searchHandler={setSearchValue}
                searchValue={searchValue}
                selectedSearchBy={searchBy}
                handleSearchByChange={setSearchBy}
                isApproved={true}
                collectBatch={collectBatchHandler}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ApprovedBatchesRecycler;
