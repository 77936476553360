const styles = (theme) => ({
  container: {
    margin: "20px 5px",
    flexGrow: 1,
    width: "100%",
  },
  cutomTab: {
    fontWeight: 900,
  },
  selected: {
    color: theme.palette.primary.main + "!important",
    borderBottomColor: theme.palette.primary.main + "!important",
  },
  indicator: {
    backgroundColor: theme.palette.primary.main + "!important",
  },
  mainInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  mainInfo: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  mainImageContainer: {
    margin: "10px",
  },
  mainImage: {
    width: "50px",
    height: "50px",
    // [theme.breakpoints.down("xs")]: {
    //   width: "25px",
    //   height: "25px",
    //   margin: "0 auto",
    // },
  },
  imageContainer: {
    flex: 1,
    margin: "5px",
  },
  image: {
    height: "auto",
    width: "300px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  modalImage: {
    height: "500px",
    width: "700px",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: "black",
  },
  dataField: {
    marginBottom: "20px",
  },
  label: {
    fontWeight: 900,
    fontSize: "18px",
    marginRight: "5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  content: {
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  imagesContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imageContainer: {
    display: "block",
    margin: "5px",
    padding: "10px",
  },
  mediaImage: {
    border: "5px solid #eee",
    width: "auto",
    height: "200px",
  },
  selectedImage: {
    border: "5px solid " + theme.palette.primary.main,
    width: "auto",
    height: "200px",
  },
});

export default styles;
