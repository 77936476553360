import React from "react";
import useClasses from "hooks/useClasses";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import styles from "./KnowledgeBaseStyle";
import Knowledge from "./Knowledge";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};
const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

function AdminKnowledgeBase({ admin, manager, companyAdmin, recycler }) {
  const classes = useClasses(styles);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Provider Info Tabs"
          classes={{
            indicator: classes.indicator,
          }}
        >
          <Tab
            label="Admins"
            {...a11yProps(0)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Managers"
            {...a11yProps(1)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Company Admin"
            {...a11yProps(2)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
          <Tab
            label="Recyclers"
            {...a11yProps(3)}
            classes={{
              textColorPrimary: classes.cutomTab,
              selected: classes.selected,
            }}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {admin.map((value, index) => (
          <Knowledge title={value.title} yid={value.yid} key={index} />
        ))}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {manager.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <h3>Coming Soon</h3>
          </div>
        )}
        {manager.map((value, index) => (
          <Knowledge title={value.title} yid={value.yid} key={index} />
        ))}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {companyAdmin.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <h3>Coming Soon</h3>
          </div>
        )}
        {companyAdmin.map((value, index) => (
          <Knowledge title={value.title} yid={value.yid} key={index} />
        ))}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {recycler.length === 0 && (
          <div style={{ textAlign: "center" }}>
            <h3>Coming Soon</h3>
          </div>
        )}
        {recycler.map((value, index) => (
          <Knowledge title={value.title} yid={value.yid} key={index} />
        ))}
      </TabPanel>
    </div>
  );
}

export default AdminKnowledgeBase;
