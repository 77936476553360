import React, { useState } from "react";
import useClasses from "hooks/useClasses";
import { Grid, InputLabel } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";
import Select from "@mui/material/Select";
import CompanyInput from "./CompanyInput";
import WorkingDays from "./WorkingDaysInput";
import BranchMap from "./BranchMap";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import ListItemText from "@mui/material/ListItemText";

import styles from "./BranchStyle";
import ImageUpload from "components/Shared/ImageUpload/ImageUploadInput";

const validationSchema = yup.object({
  name: yup.string("Enter Name").required("Name is required"),
  name_ar: yup.string("Enter Name Arabic").required("Name Arabic is required"),
  address: yup.string("Enter Address").required("Address is required"),
  address_ar: yup
    .string("Enter Address Arabic")
    .required("Address Arabic is required"),
  phone: yup
    .number()
    .typeError("That doesn't look like a phone number")
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(8)
    .required("A phone number is required"),
  description: yup
    .string("Enter Description")
    .required("Description is required"),
  description_ar: yup
    .string("Enter Description")
    .required("Description Arabic is required"),
  capacity: yup
    .number()
    .typeError("Please enter a valid number")
    .positive("cap can't be equal or less then zero")
    .integer("cap can't include a decimal point")
    .required("cap is required"),
  company_id: yup
    .number("choose one of the company id")
    .required("Company is required"),
  lat: yup.number().required("Latitude is required"),
  lng: yup.number().required("Longitude is required"),
  days: yup
    .array()
    .min(1, "Please choose at least one day")
    .required("Please choose at least one day"),
});

const Branch = ({
  categories,
  branch,
  companiesList,
  daysList,
  isCreate,
  handleCreate,
  handleUpdate,
}) => {
  const findName = (array, id) => {
    return array.map((a) => {
      if (a.id === id) {
        return a.name;
      }
    });
  };
  const [icon, setIcon] = useState(
    branch
      ? branch.icon
        ? { file: null, data_url: branch.icon }
        : { file: null, data_url: null }
      : { file: null, data_url: null }
  );
  const [iconError, setIconError] = useState(null);
  let category_id = [];
  if (branch) {
    if (branch.categories.length !== 0) {
      branch.categories.map((cat) => {
        const index = categories.findIndex((el) => el.id === cat.id);
        if (index !== -1) {
          category_id.push(categories[index].id);
        }
      });
    }
  }

  const formik = useFormik({
    initialValues: {
      name: branch ? branch.name : "",
      name_ar: branch ? branch.name_ar : "",
      phone: branch ? branch.phone : "",
      description: branch ? branch.description : "",
      description_ar: branch ? branch.description_ar : "",
      lat: branch ? branch.lat : "30.033333",
      lng: branch ? branch.lng : "31.233334",
      address: branch ? branch.address : "",
      address_ar: branch ? branch.address_ar : "",
      company_id: branch ? branch.company_id : "",
      capacity: branch ? branch.capacity : "",
      days: branch ? branch.days : [],
      category_id: category_id,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  // const [formData, setFormData] = useState(initialState);
  const classes = useClasses(styles);
  const handleSubmit = (values) => {
    let result = {
      ...values,
      lat: values.lat.toString(),
      lng: values.lng.toString(),
    };
    if (icon.file !== null) {
      result.icon = icon.file;
      setIconError(null);
    } else if (icon.data_url === null) {
      setIconError("Enter Image!");
      return;
    }
    if (isCreate === true) {
      create(result);
    } else {
      update(result);
    }
  };
  const create = (values) => {
    handleCreate(values);
  };
  const update = (values) => {
    handleUpdate(values);
  };
  return (
    <div>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {Object.keys(formik.errors).length !== 0 && (
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "15px",
                  padding: "5px",
                }}
              >
                <ul>
                  {Object.keys(formik.errors).map((error, index) => (
                    <li key={index} style={{ color: "#fff" }}>
                      {formik.errors[error]}
                    </li>
                  ))}
                </ul>
              </div>
            </Grid>
          )}
          {iconError !== null && (
            <Grid item xs={12}>
              <div
                style={{
                  backgroundColor: "red",
                  borderRadius: "15px",
                  padding: "5px",
                }}
              >
                <span style={{ color: "#fff" }}>{iconError}</span>
              </div>
            </Grid>
          )}
          {/* name */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Name:</InputLabel>
            <Input
              id="name"
              name="name"
              label="Enter Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              type="text"
            />
          </Grid>
          {/* name_ar */}
          <Grid item xs={12} sm={6}>
            <InputLabel className={classes.label}>Name Arabic:</InputLabel>
            <Input
              id="name_ar"
              name="name_ar"
              label="Enter Name Arabic"
              value={formik.values.name_ar}
              onChange={formik.handleChange}
              error={formik.touched.name_ar && Boolean(formik.errors.name_ar)}
              helperText={formik.touched.name_ar && formik.errors.name_ar}
              type="text"
            />
          </Grid>
          {/* address */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Address English:</InputLabel>
            <Input
              id="address"
              name="address"
              label="Enter Address English"
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.touched.address && Boolean(formik.errors.address)}
              helperText={formik.touched.address && formik.errors.address}
              type="text"
            />
          </Grid>
          {/* address_ar */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Address Arabic:</InputLabel>
            <Input
              id="address_ar"
              name="address_ar"
              label="Enter Address Arabic"
              value={formik.values.address_ar}
              onChange={formik.handleChange}
              error={
                formik.touched.address_ar && Boolean(formik.errors.address_ar)
              }
              helperText={formik.touched.address_ar && formik.errors.address_ar}
              type="text"
            />
          </Grid>
          {/* phone */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Phone Number:</InputLabel>
            <Input
              id="phone"
              name="phone"
              label="Enter Your phone number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              type="text"
            />
          </Grid>
          {/* Description English */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Description English:
            </InputLabel>
            <Input
              id="description"
              name="description"
              label="Enter Description"
              multiline
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              type="text"
            />
          </Grid>
          {/* Description Arabic */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>
              Description Arabic:
            </InputLabel>
            <Input
              id="description_ar"
              name="description_ar"
              label="Enter Description Arabic"
              multiline
              value={formik.values.description_ar}
              onChange={formik.handleChange}
              error={
                formik.touched.description_ar &&
                Boolean(formik.errors.description_ar)
              }
              helperText={
                formik.touched.description_ar && formik.errors.description_ar
              }
              type="text"
            />
          </Grid>
          {/* icon */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Icon:</InputLabel>
            <ImageUpload
              image={icon}
              setImage={setIcon}
              error={iconError}
              setError={setIconError}
            />
          </Grid>
          {/* Capacity Number of Collectables */}
          <Grid item xs={12} sm={4}>
            <InputLabel className={classes.label}>
              Capacity Number of Collectables:
            </InputLabel>
            <Input
              id="capacity"
              name="capacity"
              label="Enter Capacity number"
              value={formik.values.capacity}
              onChange={formik.handleChange}
              error={formik.touched.capacity && Boolean(formik.errors.capacity)}
              helperText={formik.touched.capacity && formik.errors.capacity}
              type="number"
            />
          </Grid>
          {/* Working Days */}
          <Grid item xs={12} sm={8}>
            <WorkingDays
              days={formik.values.days}
              onChangeHandler={formik.handleChange}
              daysList={daysList}
              error={formik.touched.days && Boolean(formik.errors.days)}
              helperText={formik.touched.days && formik.errors.days}
            />
          </Grid>
          {/* Company */}
          <Grid item xs={12}>
            <CompanyInput
              selectedCompany={formik.values.company}
              selectedCompanyId={formik.values.company_id}
              onChangeHandler={formik.handleChange}
              companiesList={companiesList}
            />
          </Grid>
          {/* Categories */}
          <Grid item xs={12}>
            <InputLabel className={classes.label}>Categories:</InputLabel>
            <Select
              id="category_id"
              name="category_id"
              label="Categories"
              className={classes.selectInput}
              value={formik.values.category_id}
              onChange={formik.handleChange}
              multiple={true}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={findName(categories, value)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )}
              inputProps={{ "aria-label": "Without label" }}
            >
              {categories.map((cat) => (
                <MenuItem key={cat.id} value={cat.id}>
                  <Checkbox
                    checked={formik.values.category_id.indexOf(cat.id) > -1}
                    color="default"
                    className={classes.customCheckbox}
                  />
                  <ListItemText primary={cat.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {/* map */}
          <Grid item xs={12}>
            <BranchMap
              lngProps={formik.values.lng}
              latProps={formik.values.lat}
              onChangeHandler={formik.handleChange}
              errorLat={formik.touched.lat && Boolean(formik.errors.lat)}
              helperTextLat={formik.touched.lat && formik.errors.lat}
              errorLng={formik.touched.lng && Boolean(formik.errors.lng)}
              helperTextLng={formik.touched.lng && formik.errors.lng}
              contentProps={`<div class="info-window-content"><p><strong>Address:</strong> ${formik.values.address}</p></div>`}
            />
          </Grid>
        </Grid>
        <div className={classes.buttonContainer}>
          <Button color="primary" fullWidth variant="contained" type="submit">
            {branch ? "Update Branch" : "Add Branch"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Branch;
