const styles = (theme) => ({
  form: {
    margin: "10px",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: theme.palette.primary.mainColor + "!important",
  },
  labelError: {
    fontWeight: "bold",
    marginBottom: "10px",
    color: "red",
  },
  selectInput: {
    width: "100%",
  },
  customCheckbox: {
    color: theme.palette.primary.main,
    display: "block",
    "&:checked": {
      color: theme.palette.primary.main,
    },
  },
  customRadio: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
  chip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: "100%",
  },
  submitContainer: {
    margin: "20px auto",
    width: "100%",
    textAlign: "center",
  },
  submit: {
    width: "100%",
  },
  switchContainer: {
    marginBottom: "20px",
  },
  switchBase: {
    color: "#ccc",
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.primary.dark
          : theme.palette.primary.light,
    },
  },
  checked: {},
  track: {},
});

export default styles;
