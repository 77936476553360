import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Modal from "components/Shared/CustomModal/CustomModal";
import RecycableInfo from "components/Manager/Collectables/Collectable/Collectable";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import useClasses from "hooks/useClasses";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

import { useDispatch, useSelector } from "react-redux";
import { getCollectableManager, collect } from "store/actions/managers";
import CollectInput from "components/Manager/Home/CollectInput";

const styles = {
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
  },
};

const Collectable = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useClasses(styles);
  const selectedCollectable = useSelector(
    (state) => state.manager.collectableManager
  );
  useEffect(() => {
    dispatch(getCollectableManager(id, history));
  }, [id, dispatch]);

  const collectHandler = () => {
    setModalContent(
      <div style={{ textAlign: "center" }}>
        <ErrorOutlineRoundedIcon
          color="primary"
          style={{ width: "100px", height: "100px" }}
        />
        <h5>Are You sure want to collect this Recycable ? </h5>
        <CollectInput
          collectHandler={(notes) => collectRecycable(id, notes)}
          cancelHandler={() => setOpenModal(false)}
        />
      </div>
    );
    setOpenModal(true);
  };

  const collectRecycable = (id, notes) => {
    const data = {
      id,
      notes,
    };
    setOpenModal(false);
    dispatch(collect(data, history));
  };
  return (
    <div>
      {selectedCollectable && (
        <div>
          <div>
            <Button
              onClick={history.goBack}
              color="transparent"
              style={{ padding: 0 }}
            >
              <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
              <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
            </Button>
          </div>
          <Card>
            <CardHeader color="primary">
              <h4>Recycable Info</h4>
            </CardHeader>
            <CardBody>
              <div className={classes.controlsContainer}>
                {selectedCollectable.is_collected === 0 && (
                  <Button color="secondary" onClick={collectHandler}>
                    Collect
                  </Button>
                )}
              </div>
              <RecycableInfo collectable={selectedCollectable} />
            </CardBody>
          </Card>
        </div>
      )}
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default Collectable;
