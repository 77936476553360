import React from "react";
import Select from "@mui/material/Select";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import useClasses from "hooks/useClasses";

import styles from "./MobileStyle";

export default function FieldSelect({
  onChangeHandler,
  selectedValue,
  fieldName,
  fieldList,
}) {
  const classes = useClasses(styles);

  const [open, setOpen] = React.useState(false);
  const handleChange = (e) => {
    setOpen(false);
    onChangeHandler(e);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Select
      id={fieldName}
      name={fieldName}
      label={fieldName}
      className={classes.selectInput}
      value={selectedValue}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onChange={handleChange}
      input={<Input />}
      inputProps={{ "aria-label": "Without label" }}
    >
      {fieldList.map((item, index) => (
        <MenuItem
          key={index}
          value={item}
          style={{ display: "block", marginLeft: "10px" }}
        >
          <ListItemText primary={item} />
        </MenuItem>
      ))}
    </Select>
  );
}
