const styles = (theme) => ({
  voucherImageContainer: {
    width: "320px",
    height: "170px",
    position: "relative",
    margin: "10px auto",
    padding: "10px",
    backgroundColor: theme.palette.background.default,
  },
  bgContainer: {
    width: "300px",
    height: "150px",
  },
  bg: {
    width: "300px",
    height: "150px",
  },
  logoContainer: {
    position: "absolute",
    width: "80px",
    height: "100px",
    top: "35px",
    left: "30px",
  },
  logo: { width: "100%", height: "100%", objectFit: "contain" },
  discountContainer: {
    position: "absolute",
    width: "150px",
    height: "100px",
    top: "35px",
    right: "30px",
  },
  discount: { width: "100%", height: "100%", objectFit: "contain" },
});

export default styles;
