import React from "react";
import useClasses from "hooks/useClasses";
import Table from "components/Shared/CustomTable/Table/Table";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  createModalHeader: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#fff",
    padding: "0 10px",
    borderRadius: "15px 15px 0 0",
  },
  createModalTitle: {
    fontWeight: 900,
  },
  createModalBody: {
    padding: "15px",
    maxHeight: "450px",
    overflowY: "scroll",
  },
});

export default function Carousels({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  selectedSearchBy,
  handleSearchByChange,

  deleteHandler,
  changeStatusHandler,
  updateHandler,
  createHandler,
}) {
  const classes = useClasses(styles);
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(null);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDelete = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete this Carousel ? </h5>
          <div>
            <Button color="danger" size="lg" onClick={() => deleteHandler(id)}>
              Delete
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to delete those Carousels ? </h5>
          <div>
            <Button
              color="danger"
              size="lg"
              onClick={() => deleteHandler(selected)}
            >
              Delete
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const handleChangeStatus = (id) => {
    if (id) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to change Status to this Carousel ? </h5>
          <div>
            <Button
              color="primary"
              size="lg"
              onClick={() => changeStatusHandler(id)}
            >
              Change
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    } else if (selected.length !== 0) {
      setModalContent(
        <div style={{ textAlign: "center" }}>
          <ErrorOutlineRoundedIcon
            color="error"
            style={{ width: "100px", height: "100px" }}
          />
          <h5>Are You sure want to change status to those Carousels ? </h5>
          <div>
            <Button
              color="primary"
              size="lg"
              onClick={() => changeStatusHandler(selected)}
            >
              Change
            </Button>
            <Button
              color="secondary"
              size="lg"
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
    setOpenModal(true);
  };

  const selectedToolbarButtons = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0 5px",
      }}
    >
      <Button color="danger" aria-label="delete" onClick={() => handleDelete()}>
        Delete Selected
      </Button>
      <Button
        color="primary"
        aria-label="change"
        onClick={() => handleChangeStatus()}
      >
        Change Status
      </Button>
    </div>
  );

  const toolbarButtons = (
    <div>
      <Button color="primary" onClick={createHandler}>
        Add new Carousel
      </Button>
    </div>
  );

  const searchByOptions = [
    {
      value: "title",
      label: "Title",
    },
    {
      value: "title_ar",
      label: "Title Arabic",
    },
  ];
  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "ID",
    },
    {
      id: "title",
      sortable: true,
      label: "Title",
    },
    {
      id: "title_ar",
      sortable: true,
      label: "Title Arabic",
    },
    {
      id: "description",
      sortable: false,
      label: "Description",
    },
    {
      id: "description_ar",
      sortable: false,
      label: "Description Arabic",
    },
    { id: "bg_image", sortable: false, label: "Image" },
    { id: "bg_image_ar", sortable: false, label: "Image Arabic" },
    { id: "redirect_url", sortable: false, label: "Redirect Url" },
    { id: "status", sortable: true, label: "Status" },
    { id: "created_At", sortable: true, label: "Created At" },
    { id: "options", sortable: false, label: "Options" },
  ];

  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell padding="checkbox">
                <Checkbox
                  onClick={(event) => handleClick(event, row.id)}
                  checked={isItemSelected}
                  inputProps={{ "aria-labelledby": labelId }}
                  color="primary"
                />
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.title}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.title_ar}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div
                  style={{
                    height: "100px",
                    overflowY: "auto",
                    width: "100%",
                    padding: "5px",
                  }}
                >
                  <div dangerouslySetInnerHTML={{ __html: row.description }} />
                </div>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div
                  style={{
                    height: "100px",
                    overflowY: "auto",
                    width: "100%",
                    padding: "5px",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: row.description_ar }}
                  />
                </div>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div style={{ width: "50px" }}>
                  <img
                    src={row.bg_image}
                    alt={row.id}
                    style={{ width: "100%" }}
                  />
                </div>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div style={{ width: "50px" }}>
                  <img
                    src={row.bg_image_ar}
                    alt={row.id + "_dark"}
                    style={{ width: "100%" }}
                  />
                </div>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.redirect_url}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                <div>
                  <div style={{ margin: "10px auto" }}>
                    <span style={{ fontSize: "1rem", fontWeight: "bold" }}>
                      {row.is_active === 1 ? "Active" : "Not Active"}
                    </span>
                  </div>
                  <div>
                    <Button
                      color={row.is_active === 1 ? "danger" : "primary"}
                      aria-label="change"
                      onClick={() => handleChangeStatus([row.id])}
                    >
                      {row.is_active === 1 ? "Deactivate" : "Activate"}
                    </Button>
                  </div>
                </div>
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Edit Carousel">
                    <IconButton
                      aria-label="edit"
                      onClick={() => updateHandler(row.id)}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Carousel">
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete([row.id])}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Carousel
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={selectedSearchBy}
        handleSearchByChange={handleSearchByChange}
        tableCells={tableCells}
        selected={selected}
        setSelected={setSelected}
        headCells={headCells}
        isSelectable={true}
        selectedToolbarButtons={selectedToolbarButtons}
        toolbarButtons={toolbarButtons}
        showSearchBy={true}
        searchByOptions={searchByOptions}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
      />
      <Modal open={openModal} handleClose={() => setOpenModal(false)}>
        {modalContent}
      </Modal>
    </React.Fragment>
  );
}
