const styles = (theme) => ({
  cardHeader: {
    padding: "0.75rem 1.25rem",
    marginBottom: "0",
    borderBottom: "none",
    boxShadow: "none!important",
    background: "transparent",
    zIndex: "3 !important",
  },
  warningCardHeader: {
    color: theme.palette.primary.contentText + "!important",
    background:
      "linear-gradient(60deg, " +
      theme.palette.warning.light +
      ", " +
      theme.palette.warning.dark +
      ")!important",
    boxShadow: theme.shadows[1],
  },
  successCardHeader: {
    color: theme.palette.primary.contentText + "!important",
    background:
      "linear-gradient(60deg, " +
      theme.palette.success.light +
      ", " +
      theme.palette.success.dark +
      ")!important",
    boxShadow: theme.shadows[1],
  },
  dangerCardHeader: {
    color: theme.palette.primary.contentText + "!important",
    background:
      "linear-gradient(60deg, " +
      theme.palette.error.light +
      ", " +
      theme.palette.error.dark +
      ")!important",
    boxShadow: theme.shadows[1],
  },
  infoCardHeader: {
    color: theme.palette.primary.contentText + "!important",
    background:
      "linear-gradient(60deg, " +
      theme.palette.info.light +
      ", " +
      theme.palette.info.dark +
      ")!important",
    boxShadow: theme.shadows[1],
  },
  primaryCardHeader: {
    color: theme.palette.primary.contentText + "!important",
    background:
      "linear-gradient(60deg, " +
      theme.palette.primary.light +
      ", " +
      theme.palette.primary.dark +
      ")!important",
    boxShadow: theme.shadows[1],
  },
});

export default styles;
