import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useDispatch, useSelector } from "react-redux";
import Voucher from "components/Admin/Vouchers/CreateEditVoucher";
import { getCompanies, createVoucher } from "store/actions/vouchers";

const CreateVoucher = () => {
  const companies = useSelector((state) => state.vouchers.companies);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanies(history));
  }, [history, dispatch]);

  const createHandler = (newData) => {
    dispatch(createVoucher(newData, history));
  };

  return (
    <div>
      <div>
        <Button
          onClick={history.goBack}
          color="transparent"
          style={{ padding: 0 }}
        >
          <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
          <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
        </Button>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4>Create New Voucher</h4>
        </CardHeader>
        <CardBody>
          {companies && (
            <Voucher
              companies={companies}
              voucher={null}
              isCreate={true}
              createHandler={createHandler}
              updateHandler={null}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default CreateVoucher;
