import * as actionsType from "../actions/actionsType";

const carouselsReducer = (
  state = { carousels: null, carousel: null },
  action
) => {
  switch (action.type) {
    case actionsType.GET_CAROUSELS:
      return { ...state, carousels: action.payload };
    case actionsType.GET_CAROUSEL:
      return { ...state, carousel: action.payload };
    default:
      return state;
  }
};

export default carouselsReducer;
