import { combineReducers } from "redux";
import auth from "./auth";
import usersAdmins from "./usersAdmins";
import usersBranches from "./usersBranches";
import usersClients from "./usersClients";
import manager from "./manager";
import usersRecyclers from "./usersRecyclers";
import home from "./home";
import vouchers from "./vouchers";
import collectables from "./collectables";
import companies from "./companies";
import branches from "./branches";
import categories from "./categories";
import subCategories from "./subCategories";
import conditions from "./conditions";
import mobileUpdate from "./mobileUpdate";
import brands from "./brands";
import locations from "./locations";
import support from "./support";
import settings from "./settings";
import tutorials from "./tutorials";
import redeemCompanies from "./redeemCompanies";
import transactions from "./transactions";
import carousels from "./carousels";
import notifications from "./notifications";
import recycler from "./recycler.js";
import archivedBatches from "./archivedBatches";
import usersCompanyAdmin from "./usersCompanyAdmin";
import companyAdmin from "./companyAdmin";
import companyAdminUsers from "./companyAdminUsers";

export default combineReducers({
  auth,
  usersAdmins,
  usersBranches,
  usersClients,
  usersCompanyAdmin,
  manager,
  usersRecyclers,
  home,
  collectables,
  support,
  vouchers,
  branches,
  tutorials,
  companies,
  conditions,
  categories,
  subCategories,
  brands,
  locations,
  carousels,
  mobileUpdate,
  redeemCompanies,
  settings,
  transactions,
  notifications,
  recycler,
  archivedBatches,
  companyAdmin,
  companyAdminUsers,
});
