import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { useDispatch, useSelector } from "react-redux";
import SettingsComponent from "components/Admin/Settings/Settings";
import { getSettings, updateSettings } from "store/actions/settings";

const Settings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { settings } = useSelector((state) => state.settings);
  useEffect(() => {
    dispatch(getSettings(history));
  }, [dispatch]);

  const updateHandler = (data) => {
    dispatch(updateSettings(data, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>General Settings</h4>
        </CardHeader>
        <CardBody>
          {settings && (
            <SettingsComponent data={settings} handleUpdate={updateHandler} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Settings;
