import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";
// Clients

export const getUsersClients = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_USERS_CLIENTS, payload: null });
    const { data } = await api.getUsersClients(formData);
    dispatch({ type: actionsType.GET_USERS_CLIENTS, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getUsersClient = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_USERS_CLIENT, payload: null });
    const { data } = await api.getUsersClient(id);
    dispatch({ type: actionsType.GET_USERS_CLIENT, payload: data.data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteUsersClients =
  (idsArray, pagination, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteUsersClients({ ids: idsArray });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "Clients Deleted Successfully" },
      });
      dispatch(getUsersClients(pagination, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const changeStatusUsersClients =
  (idsArray, pagination, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusUsersClients({ ids: idsArray });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "status changed Successfully",
        },
      });
      dispatch(getUsersClients(pagination, history));
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const getClientRecycables = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_CLIENT_RECYCABLES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    const { data } = await api.getClientRecycables(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_CLIENT_RECYCABLES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getClientCartItems = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_CLIENT_CART_ITEMS, payload: null });
    const { data } = await api.getClientCartItems(formData);
    dispatch({ type: actionsType.GET_CLIENT_CART_ITEMS, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getClientTransactions =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({ type: actionsType.GET_CLIENT_TRANSACTIONS, payload: null });
      const { data } = await api.getClientTransactions(formData);
      dispatch({ type: actionsType.GET_CLIENT_TRANSACTIONS, payload: data });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
