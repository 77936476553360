import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";
// Agents users for managers only
export const getUsersAgents = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: null });
    const { data } = await api.managerFetchUsers(formData);
    dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: data });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const createNewUserAgent =
  (newUser, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.managerCreateNewUser(newUser);
      dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: null });
      const { data } = await api.managerFetchUsers(formData);
      dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: data });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "New agent user created successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const deleteUserAgent =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.managerDeleteUsers({ ids: idsArray });
      dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: null });
      const { data } = await api.managerFetchUsers(formData);
      dispatch({ type: actionsType.GET_USERS_AGENTS_MANAGER, payload: data });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "user Branch deleted successfully" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getCollectedManager = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_COLLECTABLES_COLLECTED,
      payload: null,
    });
    const { data } = await api.managerFetchCollected(formData);
    dispatch({
      type: actionsType.GET_COLLECTABLES_COLLECTED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getArchivedBatchesManager =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_COLLECTABLES_ARCHIVED_BATCHES,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.managerFetchArchivedBatches(formData);
      dispatch({
        type: actionsType.GET_COLLECTABLES_ARCHIVED_BATCHES,
        payload: data,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const getArchivedItemsManager =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_COLLECTABLES_ARCHIVED_ITEMS,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.managerFetchArchivedItems(formData);
      dispatch({
        type: actionsType.GET_COLLECTABLES_ARCHIVED_ITEMS,
        payload: data.data,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const generateCode = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GENERATE_CODE,
      payload: null,
    });
    const { data } = await api.managerGenerateCode(id);
    dispatch({
      type: actionsType.GENERATE_CODE,
      payload: data.data.code,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const searchCodeHandler = (code, history) => async (dispatch) => {
  try {
    if (code.code && code.code.length !== 0) {
      dispatch({
        type: actionsType.SET_IS_SEARCHING,
        payload: { loading: true, message: null },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: null,
      });
      dispatch({
        type: actionsType.SEARCH_CODE,
        payload: null,
      });
      const { data } = await api.managerSearchCode(code);
      dispatch({
        type: actionsType.SEARCH_CODE,
        payload: data.data,
      });
      dispatch({
        type: actionsType.SET_IS_SEARCHING,
        payload: { loading: false, message: null },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } else {
      dispatch({
        type: actionsType.SET_SEARCH_CODE_ERROR,
        payload: "Search Code is Required",
      });
    }
  } catch (error) {
    dispatch({
      type: actionsType.SET_IS_SEARCHING,
      payload: { loading: false, message: null },
    });
    let errorsMessage = "";
    let errors = error?.response?.data?.errors;
    if (errors) {
      if (errors.length !== 0) {
        errors.map((err) => (errorsMessage += " " + err));
      }
    }
    if (errorsMessage.length === 0) {
      errorsMessage = "Something went wrong";
    }
    dispatch({
      type: actionsType.SET_SEARCH_CODE_ERROR,
      payload: errorsMessage,
    });
    dispatch(errorHandler(error, history));
  }
};
export const setSearchCode = (code) => async (dispatch) => {
  dispatch({
    type: actionsType.SET_SEARCH_CODE,
    payload: code,
  });
  dispatch({
    type: actionsType.SET_SEARCH_CODE_ERROR,
    payload: null,
  });
};

export const getCollectableManager = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLE_MANAGER,
      payload: null,
    });

    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCollectableManager(id);
    dispatch({
      type: actionsType.GET_COLLECTABLE_MANAGER,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const collect = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.managerCollect({
      collectable_id: newData.id,
      note: newData.notes,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable collected Successfully",
      },
    });
    dispatch({
      type: actionsType.SET_SEARCH_CODE,
      payload: "",
    });
    dispatch({
      type: actionsType.SET_SEARCH_CODE_ERROR,
      payload: "",
    });
    dispatch({
      type: actionsType.SEARCH_CODE,
      payload: "",
    });
    dispatch({
      type: actionsType.GET_COLLECTABLE_MANAGER,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.replace("/admin/dashboard");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const exportArchive = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.managerExportArchive(formData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    window.open(data, "_blank");
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const searchVoucherCode = (code, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.MANAGER_VOUCHER_IS_SEARCHING,
      payload: { loading: true, message: null },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_MANAGER_VOUCHER,
      payload: null,
    });
    const { data } = await api.managerVoucherSearch(code);
    dispatch({
      type: actionsType.SET_MANAGER_VOUCHER,
      payload: data.data,
    });
    dispatch({
      type: actionsType.MANAGER_VOUCHER_IS_SEARCHING,
      payload: { loading: false, message: null },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    if (error.response.status === 422) {
      dispatch({
        type: actionsType.MANAGER_VOUCHER_IS_SEARCHING,
        payload: { loading: false, message: "No voucher Found" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } else {
      dispatch({
        type: actionsType.MANAGER_VOUCHER_IS_SEARCHING,
        payload: { loading: false, message: null },
      });
      dispatch(errorHandler(error, history));
    }
  }
};

export const setVoucherUsed = (code, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.managerVoucherIsUsed(code);
    dispatch({
      type: actionsType.SET_MANAGER_VOUCHER,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "voucher used successfully" },
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
