import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useClasses from "hooks/useClasses";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import styles from "./styles";
import CustomLoading from "components/Shared/CustomLoading/CutomLoading";
import { useHistory } from "react-router-dom";

import VoucherInput from "components/Admin/Vouchers/VoucherSearch/VoucherInput";
import VoucherInfo from "components/Admin/Vouchers/VoucherSearch/VoucherInfo";

import { adminVoucherSearch, adminVoucherRedeem } from "store/actions/vouchers";

export default function VoucherSearch() {
  const voucherInfo = useSelector((state) => state.vouchers.adminVoucher);
  const isSearching = useSelector((state) => state.vouchers.isSearching);
  const searchingMessage = useSelector((state) => state.vouchers.searchMessage);
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useClasses(styles);

  const searchCodeHandler = (code) => {
    dispatch(adminVoucherSearch({ code: code }, history));
  };

  const usedVoucherHandler = (code) => {
    dispatch(adminVoucherRedeem({ code: code }, history));
  };
  return (
    <div>
      <div>
        <Card>
          <CardHeader color="primary">
            <h4>Search for voucher</h4>
          </CardHeader>
          <CardBody style={{ minHeight: "300px" }}>
            <VoucherInput searchCode={searchCodeHandler} />
            <div>
              {isSearching && <CustomLoading />}
              {searchingMessage && (
                <div className={classes.messageContainer}>
                  <span className={classes.message}>{searchingMessage}</span>
                </div>
              )}
              {voucherInfo && (
                <VoucherInfo
                  voucherInfo={voucherInfo}
                  handleUse={usedVoucherHandler}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
