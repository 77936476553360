import * as actionsType from "../actions/actionsType";

const usersReducer = (
  state = {
    agentsManager: null,
    collected: null,
    archivedBatches: null,
    archivedItems: null,
    generatedCode: null,
    isSearching: false,
    searchingMessage: null,
    searchCodeCollectable: null,
    collectableManager: null,
    voucherIsSearching: null,
    voucherSearchMessage: null,
    managerVoucher: null,
    searchCode: "",
    searchCodeError: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_USERS_AGENTS_MANAGER:
      return { ...state, agentsManager: action.payload };
    case actionsType.GET_COLLECTABLES_COLLECTED:
      return {
        ...state,
        collected: action.payload,
      };
    case actionsType.GET_COLLECTABLES_ARCHIVED_BATCHES:
      return {
        ...state,
        archivedBatches: action.payload,
      };
    case actionsType.GENERATE_CODE:
      return { ...state, generatedCode: action.payload };
    case actionsType.GET_COLLECTABLES_ARCHIVED_ITEMS:
      return {
        ...state,
        archivedItems: action.payload,
      };

    case actionsType.SET_IS_SEARCHING:
      return {
        ...state,
        isSearching: action.payload.loading,
        searchingMessage: action.payload.message,
      };
    case actionsType.SEARCH_CODE:
      return {
        ...state,
        searchCodeCollectable: action.payload,
      };
    case actionsType.SET_SEARCH_CODE:
      return { ...state, searchCode: action.payload };
    case actionsType.SET_SEARCH_CODE_ERROR:
      return { ...state, searchCodeError: action.payload };
    case actionsType.GET_COLLECTABLE_MANAGER:
      return { ...state, collectableManager: action.payload };
    case actionsType.MANAGER_VOUCHER_IS_SEARCHING:
      return {
        ...state,
        voucherIsSearching: action.payload.loading,
        voucherSearchMessage: action.payload.message,
      };
    case actionsType.SET_MANAGER_VOUCHER:
      return { ...state, managerVoucher: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
