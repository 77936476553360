import React, { useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Modal from "components/Shared/CustomModal/CustomModal";
import Search from "@mui/icons-material/Search";
// core components
import CustomInput from "components/Shared/CustomInput/CustomInput.js";
import Button from "components/Shared/CustomButtons/Button.js";

import styles from "./CollectInputStyle";
import useClasses from "hooks/useClasses";
import { InputLabel } from "@mui/material";
import Input from "components/Shared/CustomInput/Input";

const CollectInput = ({
  searchCodeHandler,
  searchCode,
  searchCodeError,
  setSearchCode,
}) => {
  const classes = useClasses(styles);

  const setSearchHandler = (e) => {
    setSearchCode(e.target.value);
  };

  const handleSearchCode = () => {
    searchCodeHandler(searchCode);
  };

  const onEnterPressed = (e) => {
    if (e.keyCode === 13) {
      handleSearchCode();
    }
  };

  return (
    <div className={classes.container}>
      <InputLabel
        style={{
          fontWeight: "bold",
          marginLeft: "10px",
          marginBottom: "10px",
        }}
      >
        Search by code (Case Sensitive):
      </InputLabel>
      <div className={classes.searchInputContainer}>
        <div className={classes.searchInput}>
          <Input
            id="code"
            name="code"
            label="Search by code (Case Sensitive)"
            value={searchCode}
            onChange={setSearchHandler}
            onKeyDown={onEnterPressed}
            required={true}
            error={Boolean(searchCodeError)}
            helperText={searchCodeError}
          />
        </div>
        <Button
          className={classes.searchButton}
          color="primary"
          aria-label="edit"
          justIcon
          round
          onClick={handleSearchCode}
        >
          <Search />
        </Button>
      </div>
    </div>
  );
};

export default CollectInput;
