import React from "react";
import useClasses from "hooks/useClasses";
import styles from "./CollectableStyle";
import TimelineData from "./TimelineData";
import { collect } from "store/actions/collectables";

const Request = ({ collectable }) => {
  const classes = useClasses(styles);
  return (
    <div>
      <h2 className={classes.sectionTitle}>Request Status Data:</h2>
      <div>
        <span className={classes.label}>Request Date:</span>
        <span className={classes.content}>
          {new Date(collectable.created_at).toLocaleString()}
        </span>
      </div>
      <div>
        <span className={classes.label}>Is Collected:</span>
        {collectable.is_collected === 1 ? (
          <span className={classes.content}>
            Is Collected by {collectable.agent_name} at{" "}
            {new Date(collectable.collected_time).toLocaleString()}
          </span>
        ) : (
          <span>Not Collected</span>
        )}
      </div>
      <div>
        <span className={classes.label}>Is Expired:</span>
        <span className={classes.content}>
          {collectable.is_expired === 1 ? "Expired" : "Not Expired"}
        </span>
      </div>
      <div>
        <span className={classes.label}>Expired on:</span>
        <span className={classes.content}>
          {new Date(collectable.expiry_date).toLocaleString()}
        </span>
      </div>
      <div>
        <span className={classes.label}>Is Archived:</span>
        <span className={classes.content}>
          {collectable.is_archived === 1 ? "Archived" : "Not Archived"}
        </span>
      </div>
      <div>
        <span className={classes.label}>Request Status:</span>
        <TimelineData collectable={collectable} />
      </div>
    </div>
  );
};

export default Request;
