import React from "react";
import useClasses from "hooks/useClasses";
import Table from "components/Shared/CustomTable/Table/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Button from "components/Shared/CustomButtons/Button";
import Modal from "components/Shared/CustomModal/CustomModal";
import BatchStatus from "./BatchesStatus";

const styles = (theme) => ({
  code: {
    color: theme.palette.primary.mainColor,
  },
});

export default function ArchivedBatches({
  rows,
  totalRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  searchValue,
  searchHandler,
  showItems,
  code,
  generateCode,
  closeCode,
  timer,
}) {
  const classes = useClasses(styles);
  const [openModal, setOpenModal] = React.useState(false);
  const handleGenerateCode = (id) => {
    generateCode(id);
    setOpenModal(true);
  };

  const headCells = [
    {
      id: "id",
      sortable: true,
      label: "Batch ID",
    },
    {
      id: "branch_name",
      sortable: true,
      label: "Branch Name",
    },
    {
      id: "number_of_recyclables",
      sortable: false,
      label: "Number of collectables",
    },
    {
      id: "recycler_name",
      sortable: true,
      label: "Assigned Recycler",
    },
    {
      id: "Status",
      sortable: false,
      label: "Status",
    },
    {
      id: "created_at",
      sortable: true,
      label: "Created At",
    },
    { id: "options", sortable: false, label: "Options" },
  ];
  const tableCells = (
    <>
      {rows.length !== 0 ? (
        rows.map((row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow key={row.id} hover>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                Batch {row.id}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.branch}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.number_of_recyclables} Collectables
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.recycler}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {row.is_collected === 0 ? (
                  <div
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      minWidth: "350px",
                    }}
                  >
                    <h3>Not Collected</h3>
                  </div>
                ) : (
                  <div>
                    <h3>Collected By {row.recycler}</h3>
                  </div>
                )}
              </TableCell>
              <TableCell
                component="th"
                id={labelId}
                scope="row"
                padding="normal"
                align="center"
              >
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell component="th" padding="normal" align="center">
                {row.is_approved === 1 && (
                  <Tooltip title="Generate Code">
                    <IconButton
                      aria-label="show"
                      onClick={() => handleGenerateCode(row.id)}
                    >
                      <QrCodeIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="open collectable details">
                  <IconButton
                    aria-label="show"
                    onClick={() => showItems(row.id)}
                  >
                    <VisibilityIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={12} align="center">
            No Transactions
          </TableCell>
        </TableRow>
      )}
    </>
  );
  return (
    <React.Fragment>
      <Table
        rows={rows}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        searchHandler={searchHandler}
        searchValue={searchValue}
        selectedSearchBy={null}
        handleSearchByChange={null}
        tableCells={tableCells}
        selected={[]}
        setSelected={null}
        headCells={headCells}
        isSelectable={false}
        selectedToolbarButtons={null}
        toolbarButtons={null}
        showSearchBy={false}
        searchByOptions={[]}
        showFilter={false}
        filterTitle={""}
        selectedFilter={null}
        handleFilterChange={null}
        filterOptions={null}
        handleFilterReset={null}
        hideToolbar={true}
      />
      <Modal
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          closeCode();
        }}
      >
        <div style={{ textAlign: "center" }}>
          <h5>Generated Code For Recycler to collect this Batch</h5>
          <div>
            <h1 className={classes.code}>{code}</h1>
            <h5>
              {" "}
              {`${Math.floor(timer / 60)}`.padStart(2, 0)}:
              {`${timer % 60}`.padStart(2, 0)}
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              color="secondary"
              size="lg"
              onClick={() => {
                setOpenModal(false);
                closeCode();
              }}
              style={{ flex: 1, margin: "0 10px" }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}
