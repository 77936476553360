import React from "react";
import useClasses from "hooks/useClasses";
import Main from "./CollectableMainData";
import Company from "./CompanyData";

import styles from "./CollectableStyle";
import Contact from "./ContactData";
import Benifit from "./BenifitData";
import Request from "./RequestData";

const CollectableInfo = ({ collectable }) => {
  const classes = useClasses(styles);
  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <div className={classes.childContainer}>
          <Main collectable={collectable} />
          <Request collectable={collectable} />
        </div>
        <div className={classes.childContainer}>
          <Contact collectable={collectable} />
          <Benifit collectable={collectable} />
          <Company collectable={collectable} />
        </div>
      </div>
    </div>
  );
};

export default CollectableInfo;
