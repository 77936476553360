import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import Button from "components/Shared/CustomButtons/Button.js";
import Tutorial from "components/Admin/Tutorials/Tutorial";

import { getTutorial, updateTutorial } from "store/actions/tutorials";

const UpdateTutorial = () => {
  const tutorialData = useSelector((state) => state.tutorials.tutorial);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(getTutorial(id, history));
  }, [id, history, dispatch]);

  const updateHandler = (newData) => {
    dispatch(updateTutorial(newData, history));
  };

  return (
    <div>
      <div>
        <Button
          onClick={history.goBack}
          color="transparent"
          style={{ padding: 0 }}
        >
          <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
          <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
        </Button>
      </div>
      <Card>
        <CardHeader color="primary">
          <h4>Update Mobile Tutorial</h4>
        </CardHeader>
        <CardBody>
          {tutorialData && (
            <Tutorial
              tutorial={tutorialData}
              isCreate={false}
              createHandler={null}
              updateHandler={updateHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default UpdateTutorial;
