import React from "react";
import useClasses from "hooks/useClasses";
import { TextField, Grid, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styles from "./customInputStyle";
import { bgcolor } from "@mui/system";

const Input = ({
  onKeyDown,
  name,
  label,
  onChange,
  half,
  autoFocus,
  type,
  handleShowPassword,
  multiline,
  value,
  disabled,
  helperText,
  error,
  required,
  id,
}) => {
  const classes = useClasses(styles);
  return (
    <Grid item xs={12} sm={half ? 6 : 12}>
      <TextField
        id={id}
        error={error}
        helperText={helperText}
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        variant="outlined"
        multiline={multiline}
        rows={multiline ? 3 : 1}
        required={required === false ? false : true}
        step={type ? (type === "number" ? ".01" : null) : null}
        fullWidth
        label={label}
        autoFocus={autoFocus}
        type={type}
        onKeyDown={onKeyDown}
        InputProps={
          name === "password" ||
          name === "passwordConfirmation" ||
          name === "old_password" ||
          name === "new_password" ||
          name === "password_confirmation"
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword}>
                      {type === "password" ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {
                sx: (theme) => ({
                  input: {
                    WebkitBoxShadow: `0 0 0 100px ${
                      theme.palette.mode === "light" ? "#fff" : "#191919"
                    } inset!important`,
                  },
                }),
              }
        }
      />
    </Grid>
  );
};

export default Input;
