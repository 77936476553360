import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

export default function BatchStatus({ batch }) {
  const data = batch.status.reverse();
  return (
    <Timeline>
      {data && (
        <>
          {data.length !== 0 &&
            data.map((item, index) => (
              <TimelineItem key={item.id}>
                <TimelineSeparator>
                  <TimelineDot style={{ backgroundColor: item.color }} />
                  {index < data.length - 1 && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent sx={{ width: "100%", flex: "auto" }}>
                  <div style={{ width: "100%" }}>
                    <h3 style={{ margin: 0 }}>
                      {item.name ? item.name : "Status"}
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                    <span style={{ fontSize: "0.8rem" }}>
                      {new Date(item.created_at).toLocaleString()}
                    </span>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
        </>
      )}
    </Timeline>
  );
}
