import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import useClasses from "hooks/useClasses";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "components/Admin/Collectables/Collectables";

import { useDispatch, useSelector } from "react-redux";
import { getCompanyCollectables } from "store/actions/companies";
import CompanyNumbers from "components/Admin/Companies/CompanyNumbers/CompanyNumbers";

const styles = (theme) => ({
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignsItems: "center",
    flexWrap: "wrap",
  },
});

const CompanyRecycables = () => {
  const { id } = useParams();
  const { companyCollectables, companyNumbers } = useSelector(
    (state) => state.companies
  );
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    companyCollectables ? companyCollectables.pag.page : 1
  );
  const [amount, setAmount] = useState(
    companyCollectables ? companyCollectables.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    companyCollectables ? companyCollectables.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    companyCollectables ? companyCollectables.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    companyCollectables ? companyCollectables.pag.search_by : "item_name"
  );
  const classes = useClasses(styles);
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getCompanyCollectables(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
          company_id: id,
        },
        history
      )
    );
  }, [id, page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/companies/recycable/" + id);
  };

  return (
    <div>
      <CompanyNumbers companyNumbers={companyNumbers} />
      <div>
        <div>
          <Button
            onClick={history.goBack}
            color="transparent"
            style={{ padding: 0 }}
          >
            <ArrowBackIcon style={{ width: "25px", height: "25px" }} />
            <h4 style={{ height: "25px", fontWeight: 900 }}>Back</h4>
          </Button>
        </div>
        <Card>
          <CardHeader color="primary">
            <h4>Company's Recycables</h4>
          </CardHeader>
          <CardBody>
            {companyCollectables && (
              <Table
                rows={companyCollectables.data}
                totalRows={companyCollectables.pag.total}
                rowsPerPage={amount}
                setRowsPerPage={setAmount}
                order={orderType}
                setOrder={setOrderType}
                orderBy={orderField}
                setOrderBy={setOrderField}
                page={page - 1}
                setPage={setPage}
                showRecycable={showRecycableHandler}
                searchHandler={setSearchValue}
                searchValue={searchValue}
                selectedSearchBy={searchBy}
                handleSearchByChange={setSearchBy}
              />
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default CompanyRecycables;
