import React, { useState } from "react";
import queryString from "query-string";
import {
  Avatar,
  Typography,
  Container,
  Paper,
  Grid,
  InputLabel,
} from "@mui/material";
import { useDispatch } from "react-redux";
// import { signin } from "../../store/actions/auth";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import Button from "components/Shared/CustomButtons/Button.js";
import Input from "components/Shared/CustomInput/Input";
import useClasses from "hooks/useClasses";
import { resetPass } from "store/actions/auth";
import styles from "./styles";

const validationSchema = yup.object({
  password: yup.string().required("Password is required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const ResetPass = () => {
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showComfirmPassword, setShowComfirmPassword] = useState(false);
  const classes = useClasses(styles);
  const dispatch = useDispatch();
  const history = useHistory();
  const { email, token } = queryString.parse(history.location.search);
  if (!email || !token) {
    history.push("/");
  }
  const handleSubmit = (values) => {
    dispatch(
      resetPass(
        {
          email,
          token,
          password_confirmation: values.passwordConfirmation,
          password: values.password,
        },
        history
      )
    );
  };

  const handleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleShowComfirmPassword = () => {
    setShowComfirmPassword(
      (prevShowComfirmPassword) => !prevShowComfirmPassword
    );
  };

  return (
    <div className={classes.main}>
      <Container component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={3}>
          <div className={classes.header}>
            <Typography className={classes.title} variant="h4">
              Reset Password
            </Typography>
          </div>
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <>
                <Input
                  name="email"
                  label="Email"
                  value={email}
                  disabled={true}
                />
              </>
              <>
                <InputLabel style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Password
                </InputLabel>
                <Input
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  handleShowPassword={handleShowPassword}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </>
              <>
                <InputLabel style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Comfirm Password
                </InputLabel>
                <Input
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  label="Confirm Password"
                  type={showComfirmPassword ? "text" : "password"}
                  handleShowPassword={handleShowComfirmPassword}
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.passwordConfirmation &&
                    Boolean(formik.errors.passwordConfirmation)
                  }
                  helperText={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                  }
                />
              </>
            </Grid>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              className={classes.submit}
              type="submit"
            >
              Reset
            </Button>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="center"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Grid item>
                <span>
                  <strong>Contact support: </strong>
                  <a href="tel:+201287158065" target="_blank" rel="noopener">
                    +201287158065
                  </a>
                </span>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default ResetPass;
