import React from "react";
import { Typography, Container, Paper } from "@mui/material";
import Header from "components/Shared/CutsomHeader/CustomHeader";
import Footer from "components/Shared/CustomFooter/CustomFooter";
import styles from "./copyrightStyle";
import useClasses from "hooks/useClasses";

export default function Copyright() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "scroll";
  }, []);
  const copyrightData = `<div class="col-12 text">
  <h5>Ownership of and License to Use RealGuia Properties</h5>
  <p>
      <strong>Use of the Services.</strong> RealGuia&ZeroWidthSpace; owns all rights, title and interest in the Services. TheService are protected by copyright and other intellectual property laws.RealGuia&ZeroWidthSpace;grants you a limited license to use the Services solely for your personalnon-commercial purposes. No part of the services may be copied, reproduced,distributed, downloaded or transmitted without express consent from the Founderand CEO.
  </p>
  <p>
      <strong>You shall not:</strong>
  </p>
  <p>
      </p><ul>
          <li>
              License, sell, rent, lease, transfer, assign, reproduce, distribute, host,or otherwise commercially exploit the Services 
          </li>
          <li>
              Not use any metatags or other “hidden text” using RealGuia name ortrademarks
          </li>
          <li>
              Not modify, translate, adapt, merge, make derivative works of anypart o f the Services 
          </li>
          <li>
              Not use automated or manual software devices or process (including,but not limited to spiders, robots, scrapers, crawlers, avatars, datamining tools)
          </li>
          <li>
              Not access the Services to build a similar or competitive website,application or services 
          </li>
          <li>
              As a community based Platform, RealGuia encourages registered users tointeract on the Platform, including blogs, images, reviews and any otherinteractive functionality that is developed in the future. You agree to use thesefunctions to post, review and receive information and material that are properand related to the travel topic on the Platform. You also agree and undertakethat you will not use the Platform in an inappropriate manner (as judged byRealGuia in its absolute discretion), including (but not limited to): 
          </li>
          <li>
              use foul or abusive language or behaviour or otherwise violate the legalrights of others; 
          </li>
          <li>
              post, upload or distribute any indecent, obscene, defamatory or unlawfulmaterial or information - the transmission of unlawful, threatening,pornographic, political or racist material is strictly prohibited; 
          </li>
          <li>
              advertise or offer to sell any goods or services, conduct surveys,promotions or links to other Platforms;
          </li>
          <li>
              post images or content that is protected by copyright or otherintellectual property rights, unless you own or control the rights or havegained the necessary consents;
          </li>
          <li>
              delete or falsify the origin or source of images or content uploaded; or
          </li>
          <li>
              post files that contain viruses or any similar software that may causedamage to the Platform or to an individual user’s computer.
          </li>
      </ul>
  <p></p>
  <p>
      By using any community forums on the Platform you are consenting to the publicationof your contribution accessible by all users of the Platform. Any material you upload tothe Platform will be considered to be non-confidential and non-proprietary andRealGuia has the right to use, copy, distribute and disclose to third parties any suchmaterial for any purpose. We also have the right to disclose your identity to any thirdparty who is claiming that any material posted or uploaded by you to our Platformconstitutes a violation of their intellectual property rights, or of their right to privacy.You acknowledge that forums, postings, blogs, reviews and other communications shallnot be considered, reviewed, screened or approved by Realguia and we will not beresponsible, or liable to any third party, for the content or accuracy of any materialsposted by you or any other user of the Platform. We have the right to remove anymaterial or posting you make on our Platform if, in our opinion, such material does notcomply with the content standards set out in these Terms of Use.
  </p>
  <p>
      <strong>Trademarks.</strong> &ZeroWidthSpace; RealGuia’s name and other related graphics, logos, service marks andtrade names used on or in connection with the Services are the trademarked and maynot be used without permission in connection with any third-party products orservices. Other trademarks, service marks and trade names that may appear on or inthe Services are the property of their respective owners. You will not remove, alter or obscure any copyright notice, trademark, service mark or other proprietary rightsnotices incorporated in or accompanying the Services.
  </p>
</div>`;
  const classes = useClasses(styles);
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        <Container component="main" maxWidth="md">
          <div className={classes.header}>
            <Typography className={classes.title} variant="h4">
              Copyright Policy
            </Typography>
          </div>
          <Paper className={classes.paper} elevation={3}>
            <div
              style={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: copyrightData }}
            ></div>
          </Paper>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
