import React, { useState, useEffect } from "react";
import Snackbar from "components/Shared/Snackbar/Snackbar.js";

const CustomLocalMessage = ({ message, closeMessage, messageClickHandler }) => {
  const [Notification, setNotification] = useState(false);
  useEffect(() => {
    if (message) {
      setNotification(true);
    }
  }, [message]);

  const closeNotification = () => {
    closeMessage();
    setNotification(false);
  };
  return (
    <div>
      {message && (
        <Snackbar
          place="tr"
          color={message.type}
          message={message.text}
          open={Notification}
          closeNotification={closeNotification}
          close
          clickHandler={messageClickHandler}
        />
      )}
    </div>
  );
};

export default CustomLocalMessage;
