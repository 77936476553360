import React from "react";
import useClasses from "hooks/useClasses";
import Avatar from "@mui/material/Avatar";
import styles from "./ClientInfoStyle";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessIcon from "@mui/icons-material/Business";
import LockIcon from "@mui/icons-material/Lock";
import UnLockIcon from "@mui/icons-material/LockOpen";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PhoneInTalkIcon from "@mui/icons-material/Phone";
import ClientAddressMap from "./ClientAddressMap";
import { useTheme } from "@mui/material";

const ClientInfo = ({ client }) => {
  const classes = useClasses(styles);
  const theme = useTheme();
  return (
    <div className={classes.container}>
      {/* Image */}
      <div className={classes.imageContainer}>
        <span className={classes.label}>Image:</span>
        <Avatar alt={client.name} src={client.image} className={classes.image}>
          <span style={{ textTransform: "uppercase" }}>
            {client.display_name[0]}
          </span>
        </Avatar>
      </div>
      {/* Name */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Name:</span>
        <span className={classes.content}>{client.display_name}</span>
      </div>
      {/* Email */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>
          <AlternateEmailIcon
            style={{
              width: "20px",
              height: "20px",
              verticalAlign: "middle",
              color: theme.palette.primary.mainColor,
            }}
          />{" "}
          Email:
        </span>
        <span className={classes.content}>{client.email}</span>
      </div>
      {/* Phone */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>
          <PhoneInTalkIcon
            style={{
              width: "20px",
              height: "20px",
              verticalAlign: "middle",
              color: theme.palette.primary.mainColor,
            }}
          />{" "}
          Phone:
        </span>
        {client.phone ? (
          <a href={`tel:${client.phone}`}>
            <span className={classes.content}>{client.phone}</span>
          </a>
        ) : (
          <span className={classes.content}>N/A</span>
        )}
      </div>
      {/* Addresses */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>
          <BusinessIcon
            style={{
              width: "20px",
              height: "20px",
              verticalAlign: "middle",
              color: theme.palette.primary.mainColor,
            }}
          />
          {" Addresses:"}
        </span>
        <div>
          {client.addresses ? (
            client.addresses.length !== 0 ? (
              <>
                {client.addresses.map((address, index) => (
                  <div
                    className={classes.singleAddressContainer}
                    key={address.id}
                  >
                    <div>
                      <span className={classes.label}>
                        Address {index + 1}:
                      </span>
                      <span className={classes.content}>{address.title}</span>
                    </div>
                    <div>
                      <span
                        className={classes.content}
                      >{`${address.street}, ${address.district.name}, ${address.city.name}, ${address.state.name}`}</span>
                    </div>
                    <div>
                      <span className={classes.label}>Landmark:</span>
                      <span className={classes.content}>
                        {address.landmark}
                      </span>
                    </div>
                    {address.lat && address.lon && (
                      <div style={{ height: "250px" }}>
                        <ClientAddressMap
                          lngProps={address.lon}
                          latProps={address.lat}
                          contentProps={`<div style="color: #191919"><p><strong>Address:</strong> ${address.street}, ${address.district.name}, ${address.city.name}, ${address.state.name}</p></div>`}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              "N/A"
            )
          ) : (
            "N/A"
          )}
        </div>
      </div>
      {/* Block */}
      <div className={classes.infoContainer}>
        <span className={classes.label}>Blocked:</span>
        {client.is_blocked === 0 ? (
          <span className={classes.content}>
            <UnLockIcon
              style={{
                width: "20px",
                height: "20px",
                verticalAlign: "middle",
                color: theme.palette.primary.mainColor,
              }}
            />
            Not Blocked
          </span>
        ) : (
          <span className={classes.content}>
            <LockIcon
              style={{
                width: "20px",
                height: "20px",
                verticalAlign: "middle",
                color: "red",
              }}
            />
            Blocked
          </span>
        )}
      </div>
      {/* Points */}
      <div className={classes.infoFlexContainer}>
        <div className={classes.infoContainer}>
          <span className={classes.label}>Total Points:</span>
          <span className={classes.content}>{client.points}</span>
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;
