import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/CompanyAdmin/Users/DeletedUsers/DeletedUsers";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { getCompanyDeletedUsers } from "store/actions/companyAdminUsers";

const DeletedUserLog = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const deletedUsersData = useSelector(
    (state) => state.companyAdminUsers.deletedUsers
  );
  const [page, setPage] = useState(
    deletedUsersData ? deletedUsersData.pag.page : 1
  );
  const [amount, setAmount] = useState(
    deletedUsersData ? deletedUsersData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    deletedUsersData ? deletedUsersData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    deletedUsersData ? deletedUsersData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const [searchBy, setSearchBy] = useState(
    deletedUsersData ? deletedUsersData.pag.search_by : "deleted_user_name"
  );

  useEffect(() => {
    dispatch(
      getCompanyDeletedUsers(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Deleted Users Log</h4>
        </CardHeader>
        <CardBody>
          {deletedUsersData && (
            <Table
              rows={deletedUsersData.data}
              totalRows={deletedUsersData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              //
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DeletedUserLog;
