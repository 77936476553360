import React from "react";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import Button from "components/Shared/CustomButtons/Button";

const AssignInput = ({ recyclerList, assignHandler, cancelHandler }) => {
  const [selectedRecyclerId, setSelectedRecyclerId] = React.useState(
    recyclerList[0]?.id
  );
  const handleChangeRecyclerId = (event) => {
    setSelectedRecyclerId(event.target.value);
  };
  return (
    <div>
      <div>
        <InputLabel
          shrink
          htmlFor="recylcer-select"
          style={{ textAlign: "start" }}
        >
          Select Recycler:
        </InputLabel>
        <NativeSelect
          // className={classes.filterInput}
          value={selectedRecyclerId}
          onChange={handleChangeRecyclerId}
          inputProps={{ id: "recylcer-select" }}
        >
          {recyclerList.map((recycler, index) => (
            <option key={recycler.id} value={recycler.id}>
              {recycler.display_name} - {recycler.email}
            </option>
          ))}
        </NativeSelect>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-arround",
          alignItems: "center",
          width: "100%",
          margin: "20px auto",
        }}
      >
        <Button
          color="primary"
          size="lg"
          onClick={() => assignHandler(selectedRecyclerId)}
          style={{ flex: 1, margin: "0 10px" }}
        >
          Assign
        </Button>
        <Button
          color="secondary"
          size="lg"
          onClick={cancelHandler}
          style={{ flex: 1, margin: "0 10px" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AssignInput;
