import React, { useState } from "react";
import { SketchPicker } from "react-color";

function ColorPicker({ name, color, setColor }) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const onChangeHandler = (hex) => {
    if (hex.length === 4) {
      var digit = hex;

      digit = digit
        .split("")
        .map((item) => {
          if (item == "#") {
            return item;
          }
          return item + item;
        })
        .join("");
      setColor({
        target: { name, value: digit },
      });
    } else if (hex.length < 7) {
      let num = 6 - hex.length;
      for (let i = 0; i < num; ++i) {
        hex += "F";
      }
      setColor({
        target: { name, value: hex },
      });
    } else {
      setColor({
        target: { name, value: hex },
      });
    }
  };
  return (
    <div>
      <div
        style={{
          backgroundColor: "#eee",
          width: "100%",
          height: "56px",
          margin: "10px 0",
          cursor: "pointer",
          border: "1px solid #ced4da",
          borderRadius: ".25rem",
          padding: "10px",
        }}
        onClick={() => setShowColorPicker((prev) => !prev)}
      >
        <div
          style={{
            backgroundColor: color,
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
      {showColorPicker && (
        <SketchPicker
          color={color}
          onChangeComplete={(color) => onChangeHandler(color.hex)}
        />
      )}
    </div>
  );
}

export default ColorPicker;
