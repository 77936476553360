import React from "react";
import { Typography, Container, Paper } from "@mui/material";
import Header from "components/Shared/CutsomHeader/CustomHeader";
import Footer from "components/Shared/CustomFooter/CustomFooter";
import styles from "./policyStyle";
import useClasses from "hooks/useClasses";

export default function Policy() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "scroll";
  }, []);
  const policyData = `<div class="col-12 text">
  <p>
      This Privacy Policy describes the policies and procedures of RealGuia Inc. (“RealGuia”, “we”, “our, or “us) on the collection, use, and disclosure of your information on http://realguia.com (the “Site” or “our Site”), and any mobile applications, features, or content including our email newsletter (collectively with the Site, known as the “Services”) offered by us.
  </p>
  <p>
      This privacy policy is to help you understand the kinds of information we may gather about you when you visit the Site or use any of its services, how we may use and disclose the information, and how you can control, correct and/or update the information. When you access and use the Site or Services, you are expressly consenting that you have read, understood and agreed to the collection, transfer, storage, disclosure, and other uses of your information as described in this Privacy Policy.  
  </p>
  <h5>What information do we collect?</h5>
  <p>
      <strong>Registration:</strong> in order to use certain functions of the Site, you must complete an online registration form. As part of that process, you will provide us with your email address and a password. If you create the account using one of the available social networking or third-party platform connection options (a “social sign-in” like Google or Facebook), we will collect your associated email address and related profile information from the social sign-in provider.
  </p>
  <p>
      <strong>User-provided information:</strong> in using the site, you may provide information about your potential or actual travel destinations, activities, transportation, including dates, note, and other information that makes the use of the Site valuable to you. If you chose to share your travel itineraries with others, you will provide their email addresses and may provide personalized communications with them. As we add new features to the Site, there may be other information you enter, which we will collect and store. To take full advantage of features and functionality of RealGuia, you may also provide us with additional information for your profile, such as date of birth, or your travel preferences.
  </p>
  <p>
      <strong>Cookies:</strong> The site employs cookies to address technical support issues, to improve the speed of navigation of the Site, and to understand how you use the Site. These recognize you and your access privileges and also track your Site usage. RealGuia may use certain tracking technologies such as cookies, web beacons, or third-party analytics tools to obtain such information. Our website utilizes tracking technologies from third-party service providers we have engaged to provide services on our behalf. In some cases, these are analytics providers that provide services such as application analytics and crash reporting. In other cases, these are advertising or campaign measurement providers. These providers may also automatically collect some of the information described above, including, for example, usage information, IP address, access times, browser type and language, device type, device identifiers, and Wi-Fi information. These providers may also automatically collect the above information about you through the site and services, including personally identifiable information about your online activities over time and across different websites, devices, online services, and applications when you use our website. Some third parties help us and others associate your activities across the browsers and devices you use, or that your household uses, for retargeting, cross-device advertising, analytics, and measurement purposes. The third-party analytics and advertising providers we use include: Google Analytics and Facebook Analytics. Some third-party providers allow you choices about their collection or use of your information. Some of these providers may also be members of the Network Advertising Initiative (NAI) or Digital Advertising Alliance (DAA), which each provide a simple way to opt out of ad targeting from participating companies.
      <br>
      You can set your browser to refuse cookies or to remove cookies from your hard drive, but if you do, you will not be able to access or use some portions of the Site.
  </p>
  <p>
      <strong>Log Files:</strong> The Site server automatically recognizes the Internet URL from which you access the Site. We may also log your internet protocol “IP” address, internet service provider, response times, and date/time stamp for system administration, internal marketing, and system troubleshooting purposes.
  </p>
  <p>
      <strong>Contacts:</strong> If you would like us to invite another individual to join RealGuia, you may provide us their email address so that we may send them an invitation on your behalf to visit RealGuia. When using the website, the App may also access your device contacts if you enable those permissions.
  </p>
  <h5>How might we use or disclose this information?</h5>
  <p>
      One goal in collecting personal information from you is to provide you with an efficient and customized experience. For example: If you are logged into your account and using the Site, we may associate information regarding your usage of the Site with your account.
  </p>
  <h5>How Personal Data Is Used</h5>
  <p>
      RealGuia will use Personal Data, as described in this Policy and as disclosed to you in the RealGuia Services, for the following purposes:
      <br>
      </p><ul>
          <li>
              Creating and maintaining your travel itineraries and your profile and otherwise providing, operating, hosting, connecting, and improving the RealGuia Services. 
          </li>
          <li>
              Responding to your comments and questions and providing customer service. 
          </li>
          <li>
              Training and operating automated systems that recognize and extract travel and reward-program information from emails. 
          </li>
          <li>
              Updating you on new features and functionality of RealGuia Services, as well as other news and information about products and services offered by RealGuia, other affiliated companies, and our selected partners. 
          </li>
          <li>
              Providing you information via email or another form of electronic notification related to your use, requests, and purchases, such as transaction confirmations, invoices, technical notices, and other notices about security, privacy, and administrative issues relating to your use of the RealGuia Services. 
          </li>
          <li>
              Personalizing your RealGuia Services experience, content, marketing, and recommendations, including to target content and services to more closely match your interests or location. 
          </li>
          <li>
              Creating anonymized data sets from Personal Data to improve our and our affiliates’ products and services. 
          </li>
          <li>
              Enforcing our terms and conditions or protecting our business, partners, or users. 
          </li>
          <li>
              Protecting against, investigating, and deterring fraudulent, unauthorized, infringing, or illegal activity.
          </li>
      </ul>
  <p></p>
  <h5>Choices You Have Regarding Personal Data</h5>
  <br>
  <h5>Profile and Transaction Information</h5>
  <p>
      Through the profile and settings pages within the Site, you can update profile information associated with your account at any time, such as contact and payment information, travel preferences, privacy and sharing settings, and alerts and connected applications. Depending upon the type of access you grant to third parties, such as third-party applications that you have allowed access to your account or team members that you’ve allowed to manage your itineraries; they may also be permitted to edit the information you have provided to us and to designate others to access and edit such information. You may change who has access to your information by changing your publishing options in your account settings. You may choose to display certain information you give us in your public profile. Our Site may offer publicly accessible blogs or community forums. You should be aware that any information you choose to share in these areas may be read, collected, and used by others. If you otherwise wish to ask for access, correction, or deletion of any of your information held by us, or a change in the way we use your information, please contact us at: info@realguia.com. We reserve the right to charge you a fee, as permitted by applicable law, and may decline requests that are unreasonable, prohibited by law, or are not required to be honored by applicable law.
  </p>
  <h5>Email and Marketing Choices</h5>
  <p>
      You can manage your email preferences through the selections within the Site by updating your email subscription settings. You can also unsubscribe from promotional emails by: (1) following the unsubscribe instructions in our emails; or (2) emailing us at info@realguia.com You may update your email preferences from time to time. Please note that even if you unsubscribe from promotional email messages, we may still need to contact you with important transactional information related to your account and your use of the Service. For example, even if you have unsubscribed from our email messages, we will still send you emails relating to security, billing, or account services such as password reset.
  </p>
  <h5>Cookies, Analytics, and Tracking</h5>
  <p>
      You also have choices regarding some of the technologies used to track your use of the RealGuia Services. Note that the settings are limited to the particular browser installed on a particular device. So if you use RealGuia with different browsers or different devices, including a mobile device, you may have to disable the tracking cookies in the browsers of all relevant devices. It may not always be possible to disable cookies in the Site. We may not recognize or respond to every type of "do not track" signal or other mechanisms that provide consumers the ability to exercise choice regarding the collection of Personal Data about an individual consumer’s online activities over time and across third-party websites or online services, but we give you certain choices about how we collect Personal Data as described in this Policy.
  </p>
  <h5>How RealGuia Shares Personal Data</h5>
  <p>
      RealGuia may share your Personal Data as described in this Policy and as follows:
      <br>
      </p><ul>
          <li>
              With your consent. 
          </li>
          <li>
              With third parties to the extent that you have enabled a connection between your RealGuia Services account and a third-party service. 
          </li>
          <li>
              With other RealGuia users or the public through your public profile in accordance with your privacy settings, if you share itineraries, and if you grant another RealGuia user access to manage or view your travel plans. 
          </li>
          <li>
              When we believe it is appropriate to investigate, prevent, or take action regarding illegal or suspected illegal activities; to protect and defend the rights, property, or safety of RealGuia, our users, or others; and in connection with the enforcement of our terms and contracts. 
          </li>
          <li>
              In connection with a corporate transaction, such as a divestiture, merger, acquisition, consolidation, or asset sale, or in the unlikely event of bankruptcy. We may assign or transfer your information as part of the transaction. 
          </li>
          <li>
              With third-party vendors, consultants, and other service providers that are working on our behalf and need access to your information to provide specific services to us as part of the RealGuia, such as credit card processing companies, or hosting, email messaging, or SMS providers. 
          </li>
          <li>
              In an aggregated form that does not directly identify you. 
          </li>
          <li>
              As required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served to us.
          </li>
      </ul>
  <p></p>
  <h5>Security and Retention of Personal Data</h5>
  <p>
      We maintain data handling and storage practices and procedures that are designed to promote the integrity and confidentiality of the personally identifiable information. No method of transmission over the Internet or method of electronic storage is completely secure. Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. We retain your Personal Data for as long as necessary to provide the RealGuia Services and for other essential purposes such as complying with our legal obligations, resolving disputes, and enforcing our agreements. Because these needs can vary for different data types in the context of different services, actual retention periods can vary significantly. The criteria used to determine retention include (1) the length of time Personal Data is needed to provide the applicable services (such as maintaining and improving the performance of the RealGuia Services, enabling system security measures, and maintaining appropriate business and financial records); (2) if users have provided, created, or maintained Personal Data with the expectation that we will retain it until they affirmatively remove it (in which case we may maintain the data until actively deleted by the user); (3) whether the Personal Data is of a sensitive type (a shortened retention time may be appropriate); and (4) whether we are subject to a legal, contractual, or similar obligation to retain the data (such as mandatory data retention laws in the applicable jurisdiction, government orders to preserve data, or data that must be retained for the purposes of litigation).
  </p>
  <h5>Updates and Questions</h5>
  <p>
      We may update this Policy from time to time without notice to you other than posting the revised policy on the RealGuia Services or by providing such notice about or obtaining consent to changes as may be required by applicable law. If we change our Policy, we will post those changes to this Policy and change the effective date above. We encourage you to periodically review this page for the latest information on our privacy practices.
  </p>
  <p>
      If you have any questions about this Policy, or to file any complaint regarding this policy, please contact us at:
  </p>
  <p>
      <strong>Telephone:</strong> (+2) 54 528 3638
      <br>
      info@realguia.com
  </p>
</div>`;
  const classes = useClasses(styles);
  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.content}>
        <Container component="main" maxWidth="md">
          <div className={classes.header}>
            <Typography className={classes.title} variant="h4">
              Privacy Policy
            </Typography>
          </div>
          <Paper className={classes.paper} elevation={3}>
            <div
              style={{ textAlign: "left" }}
              dangerouslySetInnerHTML={{ __html: policyData }}
            ></div>
          </Paper>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
