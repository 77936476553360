import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import Button from "components/Shared/CustomButtons/Button.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import { useDispatch, useSelector } from "react-redux";
import MobileUpdateComponent from "components/Admin/MobileUpdate/MobileUpdate";
import { getMobileUpdate, updateMobileData } from "store/actions/mobileUpdate";

const MobileUpdate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { mobileUpdate } = useSelector((state) => state.mobileUpdate);
  useEffect(() => {
    dispatch(getMobileUpdate(history));
  }, [dispatch]);

  const updateHandler = (data) => {
    dispatch(updateMobileData(data, history));
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Mobile Update Information</h4>
        </CardHeader>
        <CardBody>
          {mobileUpdate && (
            <MobileUpdateComponent
              data={mobileUpdate}
              handleUpdate={updateHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default MobileUpdate;
