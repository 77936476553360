import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getMe = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.me();
    dispatch({ type: actionsType.AUTH, payload: data.data });
    dispatch(SaveDeviceToken(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    localStorage.removeItem("token");
    history.push("/");
    dispatch(errorHandler(error, history));
  }
};
export const updateProfile = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.updateProfile(newData);
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "Profile Updated Successfully" },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch(getMe(history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const changePassword = (newData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.changePassword(newData);
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: { type: "success", text: "password Changed successfully" },
    });
    dispatch(getMe(history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const SaveDeviceToken = (history) => async (dispatch) => {
  try {
    const token = localStorage.getItem("nt");
    if (token) {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.saveUserDevice({ token: token });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    }
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const getNotifications = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_NOTIFICATION,
      payload: null,
    });
    const { data } = await api.getAllNotifications(formData);
    dispatch({
      type: actionsType.GET_NOTIFICATION,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const readAllNotifications = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.readAllNotifications();
    dispatch(getMe(history));
    dispatch(getNotifications(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const readNotification = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.readNotification(id);
    dispatch(getMe(history));
    dispatch(getNotifications(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
