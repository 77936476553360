const cardStyle = (theme) => ({
  card: {
    border: "0",
    marginBottom: "30px",
    marginTop: "30px",
    borderRadius: "6px",
    color: theme.palette.text.primary,
    background: theme.palette.background.paper,
    width: "100%",
    boxShadow: theme.shadows[2],
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: "0",
    wordWrap: "break-word",
    fontSize: ".875rem",
  },
  // cardPlain: {
  //   background: "transparent",
  //   boxShadow: "none"
  // },
  // cardProfile: {
  //   marginTop: "30px",
  //   textAlign: "center"
  // },
  // cardChart: {
  //   "& p": {
  //     marginTop: "0px",
  //     paddingTop: "0px"
  //   }
  // }
});

export default cardStyle;
