const styles = (theme) => ({
  header: {
    flex: "1",
    width: "95%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 auto",
    paddingTop: "30px",
  },
  brand: {
    flex: "3",
    marginLeft: "10px",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      height: "30px",
    },
  },
  logo: {
    height: "100%",
    objectFit: "cover",
  },
  loginButtonContainer: {
    flex: "1",
    textAlign: "center",
    marginRight: "10px",
  },
});

export default styles;
