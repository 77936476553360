import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Button from "components/Shared/CustomButtons/Button";
import Input from "components/Shared/CustomInput/Input";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import MenuItem from "@mui/material/MenuItem";
import useClasses from "hooks/useClasses";

import styles from "./styles";

const validationSchema = yup.object({
  email: yup
    .string("Enter email")
    .email("Enter a valid email")
    .required("Email is required"),
  display_name: yup.string("Enter name").required("Name is required"),
  role: yup.string("Enter role").required("Select a role"),
});

const SubAdminForm = ({
  isNewUser,
  createUser,
  updateUser,
  userUpdateData,
}) => {
  const formik = useFormik({
    initialValues: {
      email: isNewUser ? "" : userUpdateData.email,
      display_name: isNewUser ? "" : userUpdateData.display_name,
      role: isNewUser ? "admin" : userUpdateData.role,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    if (isNewUser) {
      createUser({
        display_name: values.display_name,
        email: values.email,
        role: values.role,
      });
    } else {
      updateUser({
        role: values.role,
        display_name: values.display_name,
        email: values.email,
        id: userUpdateData.id,
      });
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <GridContainer>
        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Name:
          </FormLabel>
          <Input
            id="display_name"
            name="display_name"
            label="Enter Name"
            value={formik.values.display_name}
            onChange={formik.handleChange}
            error={
              formik.touched.display_name && Boolean(formik.errors.display_name)
            }
            helperText={
              formik.touched.display_name && formik.errors.display_name
            }
            type="text"
          />
        </GridItem>

        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormLabel
            component="legend"
            style={{
              fontWeight: "bold",
              marginLeft: "10px",
            }}
          >
            Email:
          </FormLabel>
          <Input
            id="email"
            name="email"
            label="Enter Email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </GridItem>
        <GridItem xs={12} style={{ marginBottom: "20px" }}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              style={{
                fontWeight: "bold",
                marginLeft: "10px",
              }}
            >
              Role:{" "}
            </FormLabel>
            <RadioGroup
              aria-label="Role"
              id="role"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="admin"
                control={<Radio />}
                label="Admin"
              />
              <FormControlLabel
                value="sub-admin"
                control={<Radio />}
                label="Sub Admin"
              />
              <FormControlLabel
                value="viewer"
                control={<Radio />}
                label="Viewer"
              />
            </RadioGroup>
          </FormControl>
        </GridItem>
        <GridItem xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            {isNewUser ? "Add Admin User" : "Edit Admin"}
          </Button>
        </GridItem>
      </GridContainer>
    </form>
  );
};

export default SubAdminForm;
