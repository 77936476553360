import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, InputLabel, Input } from "@mui/material";
import Button from "components/Shared/CustomButtons/Button";
import CustomInput from "components/Shared/CustomInput/Input";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";

import useClasses from "hooks/useClasses";

import styles from "./styles";
import CustomSwitch from "components/Shared/CustomSwitch/CustomSwitch";

const validationSchema = yup.object({
  old_password: yup
    .string("Enter Old password")
    .required("Old password is required"),
  new_password: yup
    .string("Enter New password")
    .required("New password is required"),
  password_confirmation: yup
    .string("Enter Confirm password")
    .required("Confirm password is required"),
});

const ChangePassword = ({ updateHandler }) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      password_confirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    const result = { ...values };
    updateHandler(result);
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* old_password */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Old Password:</InputLabel>
          <CustomInput
            id="old_password"
            name="old_password"
            label="Old Password"
            type={showOldPassword ? "text" : "password"}
            handleShowPassword={() =>
              setShowOldPassword((prevShowPassword) => !prevShowPassword)
            }
            value={formik.values.old_password}
            onChange={formik.handleChange}
            error={
              formik.touched.old_password && Boolean(formik.errors.old_password)
            }
            helperText={
              formik.touched.old_password && formik.errors.old_password
            }
          />
        </Grid>
        {/* new_password */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>New Password:</InputLabel>
          <CustomInput
            id="new_password"
            name="new_password"
            label="New Password"
            type={showNewPassword ? "text" : "password"}
            handleShowPassword={() =>
              setShowNewPassword((prevShowPassword) => !prevShowPassword)
            }
            value={formik.values.new_password}
            onChange={formik.handleChange}
            error={
              formik.touched.new_password && Boolean(formik.errors.new_password)
            }
            helperText={
              formik.touched.new_password && formik.errors.new_password
            }
          />
        </Grid>
        {/* password_confirmation */}
        <Grid item xs={12}>
          <InputLabel className={classes.label}>Confirm Password:</InputLabel>
          <CustomInput
            id="password_confirmation"
            name="password_confirmation"
            label="Confrim Password"
            type={showConfirmPassword ? "text" : "password"}
            handleShowPassword={() =>
              setShowConfirmPassword((prevShowPassword) => !prevShowPassword)
            }
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            error={
              formik.touched.password_confirmation &&
              Boolean(formik.errors.password_confirmation)
            }
            helperText={
              formik.touched.password_confirmation &&
              formik.errors.password_confirmation
            }
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePassword;
