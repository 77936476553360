import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import useClasses from "hooks/useClasses";

// @mui/material components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import Tooltip from "@mui/material/Tooltip";
// @mui/icons-material
import Menu from "@mui/icons-material/Menu";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import Button from "components/Shared/CustomButtons/Button.js";

//hooks
import { useRouteName } from "hooks";

import styles from "./headerStyle";
import { Badge, Hidden, useTheme } from "@mui/material";
import { ColorModeContext } from "variables/colorContext.js";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { Notifications } from "@mui/icons-material";

export default function Header(props) {
  const theme = useTheme();
  const [openNotification, setOpenNotification] = React.useState(null);
  const colorMode = React.useContext(ColorModeContext);
  const classes = useClasses(styles);
  const isMobile = useMediaQuery("(max-width:899px)");
  const routeName = useRouteName();
  const history = useHistory();
  const openKnowledge = () => {
    history.push("/admin/knowledge-base");
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickNotification = (event) => {
    history.push("/admin/notifications");
  };
  return (
    <AppBar color="transparent" className={classes.appBar}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <span className={classes.title}>{routeName}</span>
        </div>
        {isMobile ? (
          <>
            <Tooltip title="Knowlegde Base">
              <IconButton aria-label="open" onClick={openKnowledge}>
                <CollectionsBookmarkIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={`${theme.palette.mode} mode`}>
              <IconButton
                sx={{ ml: 1 }}
                onClick={colorMode.toggleColorMode}
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            </Tooltip>
            <div className={classes.manager}>
              <Button
                color="transparent"
                justIcon={window.innerWidth > 959}
                simple={!(window.innerWidth > 959)}
                aria-owns={
                  openNotification ? "notification-menu-list-grow" : null
                }
                aria-haspopup="true"
                onClick={handleClickNotification}
              >
                <Badge
                  badgeContent={props.notificationsCount}
                  color="error"
                  showZero={false}
                  max={999}
                >
                  <Notifications className={classes.icons} />
                </Badge>
              </Button>
            </div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </>
        ) : (
          <AdminNavbarLinks
            role={props.role}
            notificationsCount={props.notificationsCount}
          />
        )}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  handleDrawerToggle: PropTypes.func,
};
