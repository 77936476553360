import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Table from "components/Admin/Collectables/Collectables";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import { getArchived } from "store/actions/collectables";
import { bounceCollectable } from "store/actions/collectables";

const Archived = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const archivedData = useSelector((state) => state.collectables.archived);
  const [page, setPage] = useState(archivedData ? archivedData.pag.page : 1);
  const [amount, setAmount] = useState(
    archivedData ? archivedData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    archivedData ? archivedData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    archivedData ? archivedData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");
  const [searchBy, setSearchBy] = useState(
    archivedData ? archivedData.pag.search_by : "item_name"
  );

  useEffect(() => {
    dispatch(
      getArchived(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, dispatch]);

  const showRecycableHandler = (id) => {
    history.push("/admin/recycables/show/" + id);
  };

  const bounceRecycableHandler = (id) => {
    dispatch(
      bounceCollectable(
        id,
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
          search_by: searchBy,
        },
        history
      )
    );
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Archived Collectables</h4>
        </CardHeader>
        <CardBody>
          {archivedData && (
            <Table
              rows={archivedData.data}
              totalRows={archivedData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              showRecycable={showRecycableHandler}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              selectedSearchBy={searchBy}
              handleSearchByChange={setSearchBy}
              bounceRecycable={bounceRecycableHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Archived;
