import React from "react";
import { Grid, InputLabel, Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import useClasses from "hooks/useClasses";

import styles from "./style";

export default function CustomSwitch({ value, onChangeHandler, name, title }) {
  const classes = useClasses(styles);

  const handleChangeSwitch = () => {
    let change;
    if (value === 1) {
      change = {
        target: { name, value: 0 },
      };
    } else {
      change = {
        target: { name, value: 1 },
      };
    }

    onChangeHandler(change);
  };

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          className={classes.switchContainer}
          control={
            <Switch
              classes={{
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
              }}
              checked={value === 1}
              onChange={handleChangeSwitch}
              name={name}
            />
          }
          label={
            <InputLabel className={classes.label} style={{ margin: "auto" }}>
              {title}
            </InputLabel>
          }
        />
      </Grid>
    </>
  );
}
