const styles = (theme) => ({
  main: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    paddingTop: "4rem",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderRadius: "15px",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "10px",
  },
  title: {
    margin: "0",
    fontWeight: "900",
    color: theme.palette.primary.main,
  },
  avatar: {
    backgroundColor: "transparent",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "3rem",
  },
  submit: {
    margin: "2rem 0 1rem!important",
  },
  selectInput: {
    width: "100%",
  },
});

export default styles;
