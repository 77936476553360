import React from "react";
import classNames from "classnames";
import useClasses from "hooks/useClasses";

import Snack from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
// @mui/icons-material
import Close from "@mui/icons-material/Close";
// core components
import styles from "./snackbarContentStyle";

export default function Snackbar(props) {
  const classes = useClasses(styles);
  const { message, color, close, icon, place, open, clickHandler } = props;
  var action = [];
  var messageObject;
  const messageClasses = classNames({
    [classes.iconMessage]: icon !== undefined,
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => props.closeNotification()}
      >
        <Close className={classes.close} />
      </IconButton>,
    ];
  }
  if (clickHandler) {
    messageObject = (
      <div onClick={clickHandler} style={{ cursor: "pointer" }}>
        {icon !== undefined ? <props.icon className={classes.icon} /> : null}
        <span className={messageClasses}>{message}</span>
      </div>
    );
  } else {
    messageObject = (
      <div>
        {icon !== undefined ? <props.icon className={classes.icon} /> : null}
        <span className={messageClasses}>{message}</span>
      </div>
    );
  }
  return (
    <Snack
      anchorOrigin={{
        vertical: place.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
          place.indexOf("l") !== -1
            ? "left"
            : place.indexOf("c") !== -1
            ? "center"
            : "right",
      }}
      open={open}
      message={messageObject}
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + " " + classes[color],
          message: classes.message,
        },
      }}
    />
  );
}
