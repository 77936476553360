import * as actionsType from "../actions/actionsType";

const usersReducer = (
  state = {
    usersAdmins: null,
  },
  action
) => {
  switch (action.type) {
    case actionsType.GET_USERS_ADMINS:
      return { ...state, usersAdmins: action.payload };
    default:
      return state;
  }
};

export default usersReducer;
