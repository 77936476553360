import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getWaiting = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_WAITING,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.fetchWaiting(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_WAITING,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getRejectReason = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_REJECT_REASON,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllRejectReasons();
    dispatch({
      type: actionsType.GET_REJECT_REASON,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getArchived = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_ARCHIVED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.fetchArchived(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_ARCHIVED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getApproved = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_APPROVED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.fetchApproved(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_APPROVED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getRejected = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_REJECTED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.fetchRejected(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_REJECTED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getExpired = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_EXPIRED,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: [],
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.fetchExpired(formData);
    let idsArray = [];
    data.data.map((d) => idsArray.push(d.id));
    dispatch({
      type: actionsType.GET_COLLECTABLES_EXPIRED,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_COLLECTABLES_ARRAY,
      payload: idsArray,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getCompanies = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_COMPANIES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getAllCompanies();
    dispatch({
      type: actionsType.GET_COLLECTABLES_COMPANIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const approveCollectables =
  (idsArray, formData, history, isWaiting) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.approveCollectables({
        ids: idsArray,
      });
      if (isWaiting) {
        dispatch(getWaiting(formData, history));
      } else {
        dispatch(getRejected(formData, history));
      }
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "collectables Approved Successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const rejectCollectables =
  (idsArray, reasonId, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.rejectCollectables({
        ids: idsArray,
        reason_id: reasonId,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch(getWaiting(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "collectables Rejected Successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const reviveExpired =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.reviveExpired({
        ids: idsArray,
      });
      dispatch(getExpired(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "expiry date has been renew successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const bounceCollectable =
  (id, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.bounceCollectable({
        collectable_id: id,
      });
      dispatch(getApproved(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "collectable has been bounced Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getCollectable = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLE,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCollectable(id);
    dispatch({
      type: actionsType.GET_COLLECTABLE,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const approveSingle = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.approveCollectables({
      ids: [id],
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable Approved Successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch(getCollectable(id, history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const rejectSingle = (id, reasonId, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.rejectCollectables({
      ids: [id],
      reason_id: reasonId,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable rejected Successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch(getCollectable(id, history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const reviveSingle = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.reviveExpired({
      ids: [id],
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "expiry date has been renew successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    dispatch(getCollectable(id, history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const bounceSingle = (id, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    await api.bounceCollectable({
      collectable_id: id,
    });
    dispatch({
      type: actionsType.SET_MESSAGE,
      payload: {
        type: "success",
        text: "collectable has been bounced Successfully",
      },
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
    history.goBack();
    // dispatch(getCollectable(id, history));
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
