const styles = (theme) => ({
  switchContainer: {
    marginBottom: "20px",
  },
  switchBase: {
    color: "#ccc",
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.primary.dark
          : theme.palette.primary.light,
    },
  },
  checked: {},
  track: {},
});

export default styles;
