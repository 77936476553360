const headerStyle = (theme) => ({
  appBar: {
    boxShadow: "none!important",
    marginBottom: "0!important",
    position: "relative!important",
    width: "100%!important",
    zIndex: "1029!important",
    color: theme.palette.text.primary + "!important",
    padding: "10px 0!important",
    // transition: "all 150ms ease 0s",
    minHeight: "50px!important",
    display: "block!important",
  },
  container: {
    padding: "0 15px!important",
    width: "90%",
    margin: "0 auto",
    minHeight: "50px!important",
  },
  flex: {
    flex: 1,
  },
  title: {
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: theme.typography.fontSize,
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
});

export default headerStyle;
