import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Table from "components/Admin/Vouchers/Vouchers";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";

import {
  getVouchers,
  deleteVoucherTableList,
  changeStatusVoucher,
} from "store/actions/vouchers";

const Vouchers = () => {
  const vouchersData = useSelector((state) => state.vouchers.vouchers);
  const dispatch = useDispatch();
  const [page, setPage] = useState(vouchersData ? vouchersData.pag.page : 1);
  const [amount, setAmount] = useState(
    vouchersData ? vouchersData.pag.amount : 10
  );
  const [orderField, setOrderField] = useState(
    vouchersData ? vouchersData.pag.order_field : "created_at"
  );
  const [orderType, setOrderType] = useState(
    vouchersData ? vouchersData.pag.order_type : "desc"
  );
  const [searchValue, setSearchValue] = useState("");

  const history = useHistory();

  useEffect(() => {
    dispatch(
      getVouchers(
        {
          page,
          amount,
          order_field: orderField,
          order_type: orderType,
          search_value: searchValue,
        },
        history
      )
    );
  }, [page, amount, orderField, orderType, searchValue, history, dispatch]);

  const deleteHandler = (id) => {
    const pag = {
      page,
      amount,
      order_field: orderField,
      order_type: orderType,
      search_value: searchValue,
    };
    dispatch(deleteVoucherTableList(id, pag, history));
  };
  const changeStatusHandler = (id) => {
    dispatch(changeStatusVoucher(id, history));
  };

  const createHandler = () => {
    history.push("/admin/vouchers/add");
  };
  const updateHandler = (id) => {
    history.push("/admin/vouchers/edit/" + id);
  };

  const showHandler = (id) => {
    history.push("/admin/vouchers/show/" + id);
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary">
          <h4>Vouchers Table</h4>
        </CardHeader>
        <CardBody>
          {vouchersData && (
            <Table
              rows={vouchersData.data}
              totalRows={vouchersData.pag.total}
              rowsPerPage={amount}
              setRowsPerPage={setAmount}
              order={orderType}
              setOrder={setOrderType}
              orderBy={orderField}
              setOrderBy={setOrderField}
              page={page - 1}
              setPage={setPage}
              searchHandler={setSearchValue}
              searchValue={searchValue}
              //
              showVoucher={showHandler}
              createHandler={createHandler}
              changeStatusHandler={changeStatusHandler}
              deleteHandler={deleteHandler}
              updateHandler={updateHandler}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Vouchers;
