import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getWaitingBatches = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_WAITING_BATCHES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getWaitingArchivedBatches(formData);
    dispatch({
      type: actionsType.GET_WAITING_BATCHES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const getApprovedBatches = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_APPROVED_BATCHES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getApprovedArchivedBatches(formData);
    dispatch({
      type: actionsType.GET_APPROVED_BATCHES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const getCollectedBatches = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTED_BATCHES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getCollectedArchivedBatches(formData);
    dispatch({
      type: actionsType.GET_COLLECTED_BATCHES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
export const getRejectedBatches = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_REJECTED_BATCHES,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getRejectedArchivedBatches(formData);
    dispatch({
      type: actionsType.GET_REJECTED_BATCHES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getRecyclersList = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_RECYCLERS_LIST,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.getRecyclersList();
    dispatch({
      type: actionsType.GET_RECYCLERS_LIST,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getArchivedBatchItems =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_ARCHIVED_BATCH_ITEMS,
        payload: null,
      });
      dispatch({
        type: actionsType.GET_ARCHIVED_BATCH,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({
        type: actionsType.SET_ITEMS_ARRAY,
        payload: [],
      });
      const res = await api.getArchivedBatch(formData);
      const archivedBatch = res.data.data;
      const { data } = await api.getArchivedBatchItems(formData);
      let idsArray = [];
      data.data.data.map((d) => idsArray.push(d.id));
      dispatch({
        type: actionsType.GET_ARCHIVED_BATCH,
        payload: archivedBatch,
      });
      dispatch({
        type: actionsType.GET_ARCHIVED_BATCH_ITEMS,
        payload: data.data,
      });
      dispatch({
        type: actionsType.SET_ITEMS_ARRAY,
        payload: idsArray,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const approveBatch =
  (id, formData, history, location) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.approveBatch({
        archived_collection_id: id,
      });
      if (location === 1) {
        // Waiting
        dispatch(getWaitingBatches(formData, history));
      } else if (location === 2) {
        // single
        dispatch(getArchivedBatchItems(formData, history));
      }
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Batch Approved Successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const rejectBatch =
  (id, formData, history, location) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.rejectBatch({
        archived_collection_id: id,
      });
      if (location === 1) {
        // Waiting
        dispatch(getWaitingBatches(formData, history));
      } else if (location === 2) {
        // single
        dispatch(getArchivedBatchItems(formData, history));
      }
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Batch Rejected Successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const reAssignRecycler =
  (id, recyclerId, formData, history, location) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.assignRecycler({
        archived_collection_id: id,
        recycler_id: recyclerId,
      });
      if (location === 1) {
        // Waiting
        dispatch(getWaitingBatches(formData, history));
      } else if (location === 2) {
        // single
        dispatch(getArchivedBatchItems(formData, history));
      } else {
        // rejected
        dispatch(getRejectedBatches(formData, history));
      }
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "Recycler re-assigned to the Batch Successfully",
        },
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getBatchCollectables = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.GET_COLLECTABLES_ARCHIVED_ITEMS,
      payload: null,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    const { data } = await api.managerFetchArchivedItems(formData);
    dispatch({
      type: actionsType.GET_COLLECTABLES_ARCHIVED_ITEMS,
      payload: data.data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const getBranchArchivedBatches =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_BRANCH_ARCHIVED_BATCHES,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      const { data } = await api.getBranchCollectablesArchivedBatches(formData);
      dispatch({
        type: actionsType.GET_BRANCH_ARCHIVED_BATCHES,
        payload: data,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
export const getBranchArchivedItems =
  (formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.GET_BRANCH_ARCHIVED,
        payload: null,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      dispatch({
        type: actionsType.SET_BRANCH_ARRAY,
        payload: [],
      });
      const { data } = await api.getBranchCollectablesArchivedItems(formData);
      let idsArray = [];
      data.data.data.map((d) => idsArray.push(d.id));
      dispatch({
        type: actionsType.GET_BRANCH_ARCHIVED,
        payload: data.data,
      });
      dispatch({
        type: actionsType.SET_BRANCH_ARRAY,
        payload: idsArray,
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };
