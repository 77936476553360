import React from "react";
import InputLabel from "@mui/material/InputLabel";
import Input from "components/Shared/CustomInput/Input";
import Button from "components/Shared/CustomButtons/Button";

const CollectInput = ({ collectHandler, cancelHandler }) => {
  const [notes, setNotes] = React.useState("");
  const submitHandler = () => {
    collectHandler(notes);
  };
  return (
    <div>
      <div>
        <InputLabel
          style={{
            fontWeight: "bold",
            marginLeft: "10px",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          Notes:
        </InputLabel>
        <Input
          id="notes"
          name="notes"
          label="additional Notes(optional)"
          value={notes}
          multiline
          onChange={(e) => setNotes(e.target.value)}
          type="text"
          required={false}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-arround",
          alignItems: "center",
          width: "100%",
          margin: "20px auto",
        }}
      >
        <Button
          color="primary"
          size="lg"
          onClick={submitHandler}
          style={{ flex: 1, margin: "0 10px" }}
        >
          Collect
        </Button>
        <Button
          color="secondary"
          size="lg"
          onClick={cancelHandler}
          style={{ flex: 1, margin: "0 10px" }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default CollectInput;
