import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import useClasses from "hooks/useClasses";
import Icon from "@mui/material/Icon";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import StoreIcon from "@mui/icons-material/Store";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";

import GridItem from "components/Shared/Grid/GridItem.js";
import GridContainer from "components/Shared/Grid/GridContainer.js";
import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import styles from "./CompanyAdminHomeStyle";
import { useHistory } from "react-router-dom";

import { getCompanyAdminHome } from "store/actions/home";

// import { CircularProgress } from "@mui/material";

function nFormatter(num) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
}

export default function CompanyAdminDashboard() {
  const [showCollected, setShowCollected] = useState(false);
  const [showNotCollected, setShowNotCollected] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const homeNumbers = useSelector(
    (state) => state.home.companyAdminHomeNumbers
  );
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getCompanyAdminHome(history));
  }, [dispatch]);

  const classes = useClasses(styles);

  return (
    <div>
      {homeNumbers && (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader color="secondary" icon>
                  <BusinessIcon />
                </CardHeader>
                <CardBody>
                  <p className={classes.cardCategory}>Company</p>
                  <h3 className={classes.cardTitle}>
                    {homeNumbers.company_name}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader color="secondary" icon>
                  <PeopleIcon />
                </CardHeader>
                <CardBody>
                  <p className={classes.cardCategory}>
                    Number Of Company Admin
                  </p>
                  <h3 className={classes.cardTitle}>
                    {homeNumbers.company_admin_number}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader color="danger" icon>
                  <StoreIcon />
                </CardHeader>
                <CardBody>
                  <p className={classes.cardCategory}>Number of Branches</p>
                  <h3 className={classes.cardTitle}>
                    {homeNumbers.number_of_branches}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Card
                style={{ minHeight: "130px", cursor: "pointer" }}
                onClick={() => setShowNotCollected((prev) => !prev)}
              >
                <CardHeader color="warning" icon>
                  <HourglassFullIcon />
                </CardHeader>
                <CardBody>
                  <p className={classes.cardCategory}>Not Collected yet</p>
                  <h3
                    className={classes.cardTitle}
                    onMouseEnter={() => setShowNotCollected(true)}
                    onMouseLeave={() => setShowNotCollected(false)}
                  >
                    {showNotCollected
                      ? homeNumbers.total_number_of_not_collected
                      : nFormatter(homeNumbers.total_number_of_not_collected)}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader color="success" icon>
                  <Icon>check</Icon>
                </CardHeader>
                <CardBody onClick={() => setShowCollected((prev) => !prev)}>
                  <p className={classes.cardCategory}>Collected Requests</p>
                  <h3
                    className={classes.cardTitle}
                    onMouseEnter={() => setShowCollected(true)}
                    onMouseLeave={() => setShowCollected(false)}
                  >
                    {showCollected
                      ? homeNumbers.total_number_of_collected
                      : nFormatter(homeNumbers.total_number_of_collected)}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4}>
              <Card style={{ minHeight: "130px", cursor: "pointer" }}>
                <CardHeader
                  color="info"
                  icon
                  onClick={() =>
                    history.push("/admin/recycables/archived-batches")
                  }
                >
                  <Icon>library_books</Icon>
                </CardHeader>
                <CardBody onClick={() => setShowArchived((prev) => !prev)}>
                  <p className={classes.cardCategory}>
                    Archived Batches Requests
                  </p>
                  <h3
                    className={classes.cardTitle}
                    onMouseEnter={() => setShowArchived(true)}
                    onMouseLeave={() => setShowArchived(false)}
                  >
                    {showArchived
                      ? homeNumbers.total_number_archived
                      : nFormatter(homeNumbers.total_number_archived)}
                  </h3>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  );
}
