import * as api from "../api";
import * as actionsType from "./actionsType";
import { errorHandler } from "./general";

export const getDistricts = (formData, history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_DISTRICTS,
      payload: null,
    });
    const { data } = await api.getDistricts(formData);
    dispatch({ type: actionsType.GET_DISTRICTS, payload: data });
    dispatch(getAllCities(history));
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};

export const deleteDistrict =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.deleteDistrict({ ids: idsArray });
      dispatch(getDistricts(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: { type: "success", text: "District deleted Successfully" },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const createDistrict =
  (newData, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.createDistrict(newData);
      dispatch(getDistricts(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "New District has been created successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const updateDistrict =
  (newData, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.updateDistrict(newData);
      dispatch(getDistricts(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "District has been updated successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const changeStatusDistrict =
  (idsArray, formData, history) => async (dispatch) => {
    try {
      dispatch({
        type: actionsType.SET_LOADING,
        payload: true,
      });
      await api.changeStatusDistrict({ ids: idsArray });
      dispatch(getDistricts(formData, history));
      dispatch({
        type: actionsType.SET_MESSAGE,
        payload: {
          type: "success",
          text: "District's Status Changed Successfully",
        },
      });
      dispatch({
        type: actionsType.SET_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch(errorHandler(error, history));
    }
  };

export const getAllCities = (history) => async (dispatch) => {
  try {
    dispatch({
      type: actionsType.SET_LOADING,
      payload: true,
    });
    dispatch({
      type: actionsType.GET_ALL_CITIES,
      payload: null,
    });
    const { data } = await api.getAllCities();

    dispatch({
      type: actionsType.GET_ALL_CITIES,
      payload: data,
    });
    dispatch({
      type: actionsType.SET_LOADING,
      payload: false,
    });
  } catch (error) {
    dispatch(errorHandler(error, history));
  }
};
