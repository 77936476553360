import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @mui/icons-material
import Dashboard from "@mui/icons-material/Dashboard";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PeopleIcon from "@mui/icons-material/People";
import OfferIcon from "@mui/icons-material/LocalOffer";
import StoreIcon from "@mui/icons-material/Store";
import SummarizeIcon from "@mui/icons-material/Summarize";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import CategoryIcon from "@mui/icons-material/Category";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import BallotIcon from "@mui/icons-material/Ballot";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import SecurityUpdateWarningIcon from "@mui/icons-material/SecurityUpdateWarning";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import InventoryIcon from "@mui/icons-material/Inventory";
import AodIcon from "@mui/icons-material/Aod";
// core components/views for Admin layout

import DashboardPage from "views/Dashboard/Dashboard.js";
import KnowledgeBasePage from "views/KnowledgeBase/KnowledgeBase";
// admin
// Recycables pages
import RecycablesWaiting from "views/Admin/Collectables/Waiting/Waiting";
import RecycablesApproved from "views/Admin/Collectables/Approved/Approved";
import RecycablesRejected from "views/Admin/Collectables/Rejected/Rejected";
import RecycablesExpired from "views/Admin/Collectables/Expired/Expired";
import RecycablesArchived from "views/Admin/Collectables/Archived/Archived";
import Recycable from "views/Admin/Collectables/Collectable/Collectable";
// users pages
import UsersClients from "views/Admin/Users/Clients/Clients";
import UsersClient from "views/Admin/Users/Clients/Client/Client";
import UsersBranches from "views/Admin/Users/Agents/Agents";
import UsersSubAdmin from "views/Admin/Users/SubAdmins/SubAdmins";
import UsersRecyclers from "views/Admin/Users/Recyclers/Recyclers";
import UsersCompanyAdmin from "views/Admin/Users/CompanyAdmin/CompanyAdmin";

// Companies pages
import Companies from "views/Admin/Companies/Companies";
import Company from "views/Admin/Companies/Company/Company";
import CreateCompany from "views/Admin/Companies/Company/CreateCompany";
import CompanyRecycables from "views/Admin/Companies/Company/CompanyRecycables";
import CompanyRecycable from "views/Admin/Companies/Company/CompanyCollectable";
import Branches from "views/Admin/Branches/Branches";
import CreateBranch from "views/Admin/Branches/Branch/CreateBranch";
import Branch from "views/Admin/Branches/Branch/Branch";
import BranchCollected from "views/Admin/Branches/Branch/BranchCollected";
import BranchNotCollected from "views/Admin/Branches/Branch/BranchNotCollected";
import BranchCollectable from "views/Admin/Branches/Branch/BranchCollectable";
import BranchArchivedBatches from "views/Admin/Branches/Branch/BranchArchivedBatches";
import BranchArchivedItems from "views/Admin/Branches/Branch/BranchArchivedItems";

import Categories from "views/Admin/Categories/Categories";
import Category from "views/Admin/Categories/Category";
import CreateCategory from "views/Admin/Categories/CreateCategory";

import SubCategories from "views/Admin/SubCategories/SubCategories";
import DeviceConditions from "views/Admin/DeviceConditions/DeviceConditions";
import Brands from "views/Admin/Brands/Brands";

import Carousels from "views/Admin/Carousels/Carousels";
import Carousel from "views/Admin/Carousels/Carousel";
import CreateCarousel from "views/Admin/Carousels/CreateCarousel";

import Tutorials from "views/Admin/Tutorials/Tutorials";
import Tutorial from "views/Admin/Tutorials/Tutorial";
import CreateTutorial from "views/Admin/Tutorials/CreateTutorial";

import RedeemCompanies from "views/Admin/RedeemCompanies/RedeemCompanies";
import RedeemCompany from "views/Admin/RedeemCompanies/RedeemCompany";
import CreateRedeemCompany from "views/Admin/RedeemCompanies/CreateRedeemCompany";

import Vouchers from "views/Admin/Vouchers/Vouchers";
import Voucher from "views/Admin/Vouchers/Voucher/Voucher";
import CreateVoucher from "views/Admin/Vouchers/Voucher/CreateVoucher";
import VoucherItems from "views/Admin/Vouchers/Voucher/VoucherItems";
import VoucherSearch from "views/Admin/Vouchers/VoucherSearch/VoucherSearch";
import EditVoucher from "views/Admin/Vouchers/Voucher/EditVoucher";

// archived batches
import WaitingBatches from "views/Admin/ArchivedBatches/Waiting";
import ApprovedBatches from "views/Admin/ArchivedBatches/Approved";
import RejectedBatches from "views/Admin/ArchivedBatches/Rejected";
import ArchivedItems from "views/Admin/ArchivedBatches/ArchivedItems";
import ArchivedCollectable from "views/Admin/ArchivedBatches/ArchivedCollectable";

import MobileUpdate from "views/Admin/MobileUpdate/MobileUpdate";
import States from "views/Admin/PickupLocations/States/States";
import Cities from "views/Admin/PickupLocations/Cities/Cities";
import Districts from "views/Admin/PickupLocations/Districts/Districts";
import Transactions from "views/Admin/Transactions/Transactions";
import WaitingTransactions from "views/Admin/Transactions/WaitingTransactions/WaitingTransactions";

// manager
import RecycablesArchivedBatchesManager from "views/Manager/Collectables/Archived/ArchivedBatches";
import RecycablesArchivedItemsManager from "views/Manager/Collectables/Archived/ArchivedItems";
import RecycablesCollected from "views/Manager/Collectables/Collected/Collected";
import RecycableManager from "views/Manager/Collectables/Collectable/Collectable";
import ManagerVouchers from "views/Manager/Vouchers/Vouchers";
// import BranchArchived from "views/Admin/Branches/Branch/BranchArchived";

// recycler
import WaitingBatchesRecycler from "views/Recycler/Waiting";
import ApprovedBatchesRecycler from "views/Recycler/Approved";
import RecyclerArchivedItems from "views/Recycler/ArchivedItems";
import RecyclerCollectable from "views/Recycler/Collectable";

import Settings from "views/Admin/Settings/Settings";
import Profile from "views/Profile/Profile";
import Notifications from "views/Notifications/Notifications";
import CollectedBatches from "views/Admin/ArchivedBatches/Collected";
import CollectedBatchesRecycler from "views/Recycler/Collected";

// companyAdmin
import CompanyAdminBranches from "views/CompanyAdmin/Branches/Branches";
import CompanyAdminArchiveBatches from "views/CompanyAdmin/Branches/Branch/BranchArchivedBatches";
import CompanyAdminArchiveItems from "views/CompanyAdmin/Branches/Branch/BranchArchivedItems";
import CompanyAdminCollected from "views/CompanyAdmin/Branches/Branch/BranchCollected";
import CompanyAdminNotCollected from "views/CompanyAdmin/Branches/Branch/BranchNotCollected";
import CompanyAdminCollectable from "views/CompanyAdmin/Branches/Branch/BranchCollectable";
import CompanyAdminBranchUsers from "views/CompanyAdmin/Users/Agents/Agents";
import CompanyAdminCompanyUsers from "views/CompanyAdmin/Users/CompanyAdmin/CompanyAdmin";
import CompanyAdminReports from "views/CompanyAdmin/Reports/Reports";
import CompanyAdminDeletedUsers from "views/CompanyAdmin/Users/DeletedUsersLog/DeletedUserLog";

export const routesLink = [
  {
    path: "/dashboard",
    name: "Dashboard",
    layout: "/admin",
  },
  {
    path: "/recycables/waiting",
    name: "Recycables - Waiting For Approve",
    layout: "/admin",
  },
  {
    path: "/recycables/archived",
    name: "Recycables - Archived",
    layout: "/admin",
  },
  {
    path: "/recycables/collected",
    name: "Recycables - Collected",
    layout: "/admin",
  },
  {
    path: "/recycables/approved",
    name: "Recycables - Approved",
    layout: "/admin",
  },
  {
    path: "/recycables/rejected",
    name: "Recycables - Rejected",
    layout: "/admin",
  },
  {
    path: "/recycables/expired",
    name: "Recycables - Expired",
    layout: "/admin",
  },
  {
    path: "/recycables/show",
    name: "Recycables - Single Recycle",
    layout: "/admin",
  },
  {
    path: "/archived-batches/waiting",
    name: "Waiting Archived Batches",
    layout: "/admin",
  },
  {
    path: "/archived-batches/approved",
    name: "Approved Archived Batches",
    layout: "/admin",
  },
  {
    path: "/archived-batches/collected",
    name: "Collected Archived Batches",
    layout: "/admin",
  },
  {
    path: "/archived-batches/rejected",
    name: "Rejected Archived Batches",
    layout: "/admin",
  },
  {
    path: "/archived-batches/items",
    name: "Rejected Archived batches",
    name: "Items",
    layout: "/admin",
  },
  {
    path: "/users/clients",
    name: "Users - Clients",
    layout: "/admin",
  },
  {
    path: "/users/recyclers",
    name: "Users - Recyclers",
    layout: "/admin",
  },
  {
    path: "/users/company-admin",
    name: "Users - Company Admin",
    layout: "/admin",
  },
  {
    path: "/users/branches",
    name: "Users - Branches",
    layout: "/admin",
  },
  {
    path: "/users/deleted-users",
    name: "Deleted Users Log",
    layout: "/admin",
  },
  {
    path: "/users/sub-admin",
    name: "Users - Sub Admin",
    layout: "/admin",
  },
  {
    path: "/branch-user",
    name: "Users branch",
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "All Categories",
    layout: "/admin",
  },
  {
    path: "/categories/category",
    name: "Edit Categories",
    layout: "/admin",
  },
  {
    path: "/categories/new-category",
    name: "Add Categories",
    layout: "/admin",
  },
  {
    path: "/sub-categories",
    name: "Sub Categories",
    layout: "/admin",
  },
  {
    path: "/device-conditions",
    name: "Device Conditions",
    layout: "/admin",
  },
  {
    path: "/brands",
    name: "Brands",
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    layout: "/admin",
  },
  {
    path: "/companies",
    name: "All Companies",
    layout: "/admin",
  },
  {
    path: "/companies/recycables",
    name: "Companies - Recycables",
    layout: "/admin",
  },
  {
    path: "/companies/edit",
    name: "Edit Company",
    layout: "/admin",
  },
  {
    path: "/companies/add",
    name: "New Company",
    layout: "/admin",
  },
  {
    path: "/branches",
    name: "Drop-off Locations",
    layout: "/admin",
  },
  {
    path: "/company-branches",
    name: "Company Branches",
    layout: "/admin",
  },
  {
    path: "/pickup-locations/states",
    name: "Pickup Locations - States",
    layout: "/admin",
  },
  {
    path: "/pickup-locations/cities",
    name: "Pickup Locations - Cities",
    layout: "/admin",
  },
  {
    path: "/pickup-locations/districts",
    name: "Pickup Locations - Districts",
    layout: "/admin",
  },
  {
    path: "/transactions/all-transactions",
    name: "Transactions - All Transactions",
    layout: "/admin",
  },
  {
    path: "/transactions/waiting-transactions",
    name: "Transactions - Waiting Transactions",
    layout: "/admin",
  },
  {
    path: "/carousels",
    name: "All Carousel",
    layout: "/admin",
  },
  {
    path: "/carousels/carousel",
    name: "Edit Carousel",
    layout: "/admin",
  },
  {
    path: "/carousels/new-carousel",
    name: "Add Carousel",
    layout: "/admin",
  },
  {
    path: "/tutorials",
    name: "All Mobile Tutorials",
    layout: "/admin",
  },
  {
    path: "/tutorials/tutorial",
    name: "Edit Mobile Tutorial",
    layout: "/admin",
  },
  {
    path: "/tutorials/new-tutorial",
    name: "Add New Mobile Tutorial",
    layout: "/admin",
  },
  {
    path: "/redeem-companies",
    name: "All Redeem Companies",
    layout: "/admin",
  },
  {
    path: "/redeem-companies/company",
    name: "Edit Redeem Company",
    layout: "/admin",
  },
  {
    path: "/redeem-companies/new-company",
    name: "Add New Redeem Company",
    layout: "/admin",
  },
  {
    path: "/knowledge-base",
    name: "Knowledge Base Page",
    layout: "/admin",
  },
  {
    path: "/mobile-update",
    name: "Mobile Update",
    layout: "/admin",
  },
  {
    path: "/general-settings",
    name: "General Settings",
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "Profile",
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    layout: "/admin",
  },
  {
    path: "/recycler",
    name: "Recycler",
    children: [
      {
        path: "/recycler-branches",
        name: "Branches",
        layout: "/admin",
      },
      {
        path: "/recycler-collected",
        name: "Collected Recycables",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
];

export const adminLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycables",
    name: "Recycables",
    icon: ImportantDevicesIcon,
    children: [
      {
        path: "/waiting",
        name: "Waiting For Approve",
        layout: "/admin",
      },
      {
        path: "/approved",
        name: "Approved",
        layout: "/admin",
      },
      {
        path: "/rejected",
        name: "Rejected",
        layout: "/admin",
      },
      {
        path: "/expired",
        name: "Expired",
        layout: "/admin",
      },
      {
        path: "/archived",
        name: "Archived",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/archived-batches",
    name: "Archived Batches",
    icon: InventoryIcon,
    children: [
      {
        path: "/waiting",
        name: "Waiting",
        layout: "/admin",
      },
      {
        path: "/approved",
        name: "Approved",
        layout: "/admin",
      },
      {
        path: "/rejected",
        name: "Rejected",
        layout: "/admin",
      },
      {
        path: "/collected",
        name: "Collected",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleIcon,
    children: [
      {
        path: "/clients",
        name: "Clients",
        layout: "/admin",
      },
      {
        path: "/branch-users",
        name: "Branches",
        layout: "/admin",
      },
      {
        path: "/sub-admin",
        name: "Sub Admin",
        layout: "/admin",
      },
      {
        path: "/recyclers",
        name: "Recyclers",
        layout: "/admin",
      },
      {
        path: "/company-admin",
        name: "Company Admin",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: CategoryIcon,
    layout: "/admin",
  },
  {
    path: "/sub-categories",
    name: "Sub Categories",
    icon: BallotIcon,
    layout: "/admin",
  },
  {
    path: "/device-conditions",
    name: "Device Conditions",
    icon: SecurityUpdateWarningIcon,
    layout: "/admin",
  },
  {
    path: "/brands",
    name: "Brands",
    icon: LibraryBooksIcon,
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    icon: LoyaltyIcon,
    children: [
      {
        path: "/",
        name: "Vouchers",
        layout: "/admin",
      },
      {
        path: "/search-vouchers",
        name: "Search Vouchers",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/companies",
    name: "Companies",
    icon: ApartmentIcon,
    layout: "/admin",
  },
  {
    path: "/branches",
    name: "Drop-off Locations",
    icon: StoreIcon,
    layout: "/admin",
  },
  {
    path: "/pickup-locations",
    name: "Pickup Locations",
    icon: AddLocationAltIcon,
    children: [
      {
        path: "/states",
        name: "States",
        layout: "/admin",
      },
      {
        path: "/cities",
        name: "Cities",
        layout: "/admin",
      },
      {
        path: "/districts",
        name: "Districts",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/transactions",
    name: "transactions",
    icon: CurrencyExchangeIcon,
    children: [
      {
        path: "/all-transactions",
        name: "All Transactions",
        layout: "/admin",
      },
      {
        path: "/waiting-transactions",
        name: "Waiting Transactions",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },

  {
    path: "/carousels",
    name: "Carousels",
    icon: ViewCarouselIcon,
    layout: "/admin",
  },
  {
    path: "/tutorials",
    name: "Mobile Tutorials",
    icon: AodIcon,
    layout: "/admin",
  },
  {
    path: "/redeem-companies",
    name: "Redeem Companies",
    icon: ApartmentIcon,
    layout: "/admin",
  },
  {
    path: "/general-settings",
    name: "General Settings",
    icon: AppSettingsAltIcon,
    layout: "/admin",
  },
  {
    path: "/mobile-update",
    name: "Mobile Update",
    icon: SystemUpdateIcon,
    layout: "/admin",
  },
];

export const subAdminLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycables",
    name: "Recycables",
    icon: ImportantDevicesIcon,
    children: [
      {
        path: "/waiting",
        name: "Waiting For Approve",
        layout: "/admin",
      },
      {
        path: "/approved",
        name: "Approved",
        layout: "/admin",
      },
      {
        path: "/rejected",
        name: "Rejected",
        layout: "/admin",
      },
      {
        path: "/expired",
        name: "Expired",
        layout: "/admin",
      },
      {
        path: "/archived",
        name: "Archived",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
];

export const managerLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycables",
    name: " Recycables Requests",
    icon: ImportantDevicesIcon,
    children: [
      {
        path: "/collected",
        name: "Collected",
        layout: "/admin",
      },
      {
        path: "/archived-batches",
        name: "Archived Batches",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },

  // {
  //   path: "/vouchers",
  //   name: "Vouchers",
  //   icon: LoyaltyIcon,
  //   layout: "/admin",
  // },
];

export const agentLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    icon: LoyaltyIcon,
    layout: "/admin",
  },
];

export const recyclerLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/recycler/waiting-batches",
    name: "Waiting Archived Batches",
    icon: StoreIcon,
    layout: "/admin",
  },
  {
    path: "/recycler/approved-batches",
    name: "Approved Archived Batches",
    icon: StoreIcon,
    layout: "/admin",
  },
  {
    path: "/recycler/collected-batches",
    name: "Collected Archived Batches",
    icon: StoreIcon,
    layout: "/admin",
  },
];
export const companyAdminLinks = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    layout: "/admin",
  },
  {
    path: "/company-branches",
    name: "Branches",
    icon: StoreIcon,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: PeopleIcon,
    children: [
      {
        path: "/branch-users",
        name: "Branches",
        layout: "/admin",
      },
      {
        path: "/company-admin",
        name: "Company Admin",
        layout: "/admin",
      },
      {
        path: "/deleted-users",
        name: "Deleted Users Log",
        layout: "/admin",
      },
    ],
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: SummarizeIcon,
    layout: "/admin",
  },
];

export const adminRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycables">
      <Route
        exact
        path="/admin/recycables/waiting"
        component={RecycablesWaiting}
      />
      <Route
        exact
        path="/admin/recycables/approved"
        component={RecycablesApproved}
      />
      <Route
        exact
        path="/admin/recycables/rejected"
        component={RecycablesRejected}
      />
      <Route
        exact
        path="/admin/recycables/archived"
        component={RecycablesArchived}
      />
      <Route
        exact
        path="/admin/recycables/expired"
        component={RecycablesExpired}
      />
      <Route path="/admin/recycables/show/:id" component={Recycable} />
    </Route>
    <Route path="/admin/archived-batches">
      <Route
        exact
        path="/admin/archived-batches/waiting"
        component={WaitingBatches}
      />
      <Route
        exact
        path="/admin/archived-batches/approved"
        component={ApprovedBatches}
      />
      <Route
        exact
        path="/admin/archived-batches/rejected"
        component={RejectedBatches}
      />
      <Route
        exact
        path="/admin/archived-batches/collected"
        component={CollectedBatches}
      />
      <Route
        path="/admin/archived-batches/item/:id"
        component={ArchivedItems}
      />
      <Route
        path="/admin/archived-batches/recycable/:id"
        component={ArchivedCollectable}
      />
    </Route>
    <Route path="/admin/users">
      <Route path="/admin/users/clients">
        <Route exact path="/admin/users/clients/" component={UsersClients} />
        <Route path="/admin/users/clients/:id" component={UsersClient} />
      </Route>
      <Route exact path="/admin/users/branch-users" component={UsersBranches} />
      <Route exact path="/admin/users/sub-admin" component={UsersSubAdmin} />
      <Route exact path="/admin/users/recyclers" component={UsersRecyclers} />
      <Route
        exact
        path="/admin/users/company-admin"
        component={UsersCompanyAdmin}
      />
    </Route>
    <Route path="/admin/categories">
      <Route exact path="/admin/categories" component={Categories} />
      <Route
        exact
        path="/admin/categories/new-category"
        component={CreateCategory}
      />
      <Route path="/admin/categories/category/:id" component={Category} />
    </Route>
    <Route exact path="/admin/brands" component={Brands} />
    <Route exact path="/admin/sub-categories" component={SubCategories} />
    <Route exact path="/admin/device-conditions" component={DeviceConditions} />
    <Route path="/admin/companies">
      <Route exact path="/admin/companies" component={Companies} />
      <Route exact path="/admin/companies/add" component={CreateCompany} />
      <Route path="/admin/companies/edit/:id" component={Company} />
      <Route
        path="/admin/companies/recycables/:id"
        component={CompanyRecycables}
      />
      <Route
        path="/admin/companies/recycable/:id"
        component={CompanyRecycable}
      />
    </Route>
    <Route path="/admin/branches">
      <Route exact path="/admin/branches" component={Branches} />
      <Route exact path="/admin/branches/add" component={CreateBranch} />
      <Route path="/admin/branches/edit/:id" component={Branch} />
      <Route path="/admin/branches/collected/:id" component={BranchCollected} />
      <Route
        path="/admin/branches/archived-batches/:id"
        component={BranchArchivedBatches}
      />
      <Route
        path="/admin/branches/archived-items/:id"
        component={BranchArchivedItems}
      />
      <Route
        path="/admin/branches/not-collected/:id"
        component={BranchNotCollected}
      />
      <Route
        path="/admin/branches/recycable/:id"
        component={BranchCollectable}
      />
    </Route>
    <Route path="/admin/vouchers">
      <Route exact path="/admin/vouchers" component={Vouchers} />
      <Route
        exact
        path="/admin/vouchers/search-vouchers"
        component={VoucherSearch}
      />
      <Route exact path="/admin/vouchers/show/:id" component={Voucher} />
      <Route exact path="/admin/vouchers/edit/:id" component={EditVoucher} />
      <Route exact path="/admin/vouchers/add" component={CreateVoucher} />
      <Route exact path="/admin/vouchers/items/:id" component={VoucherItems} />
    </Route>
    <Route path="/admin/transactions">
      <Route
        exact
        path="/admin/transactions/all-transactions"
        component={Transactions}
      />
      <Route
        exact
        path="/admin/transactions/waiting-transactions"
        component={WaitingTransactions}
      />
    </Route>
    <Route path="/admin/pickup-locations">
      <Route exact path="/admin/pickup-locations/states" component={States} />
      <Route exact path="/admin/pickup-locations/cities" component={Cities} />
      <Route
        exact
        path="/admin/pickup-locations/districts"
        component={Districts}
      />
    </Route>
    <Route path="/admin/carousels">
      <Route exact path="/admin/carousels" component={Carousels} />
      <Route
        exact
        path="/admin/carousels/new-carousel"
        component={CreateCarousel}
      />
      <Route path="/admin/carousels/carousel/:id" component={Carousel} />
    </Route>
    <Route path="/admin/tutorials">
      <Route exact path="/admin/tutorials" component={Tutorials} />
      <Route
        exact
        path="/admin/tutorials/new-tutorial"
        component={CreateTutorial}
      />
      <Route path="/admin/tutorials/tutorial/:id" component={Tutorial} />
    </Route>
    <Route path="/admin/redeem-companies">
      <Route exact path="/admin/redeem-companies" component={RedeemCompanies} />
      <Route
        exact
        path="/admin/redeem-companies/new-company"
        component={CreateRedeemCompany}
      />
      <Route
        path="/admin/redeem-companies/company/:id"
        component={RedeemCompany}
      />
    </Route>
    <Route exact path="/admin/knowledge-base" component={KnowledgeBasePage} />
    <Route exact path="/admin/general-settings" component={Settings} />
    <Route exact path="/admin/mobile-update" component={MobileUpdate} />
    <Route exact path="/admin/profile" component={Profile} />
    <Route exact path="/admin/notifications" component={Notifications} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export const subAdminRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycables">
      <Route
        exact
        path="/admin/recycables/waiting"
        component={RecycablesWaiting}
      />
      <Route
        exact
        path="/admin/recycables/approved"
        component={RecycablesApproved}
      />
      <Route
        exact
        path="/admin/recycables/rejected"
        component={RecycablesRejected}
      />
      <Route
        exact
        path="/admin/recycables/archived"
        component={RecycablesArchived}
      />
      <Route
        exact
        path="/admin/recycables/expired"
        component={RecycablesExpired}
      />
      <Route path="/admin/recycables/show/:id" component={Recycable} />
    </Route>
    <Route exact path="/admin/knowledge-base" component={KnowledgeBasePage} />
    <Route exact path="/admin/profile" component={Profile} />
    <Route exact path="/admin/notifications" component={Notifications} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export const managerRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycables">
      <Route
        exact
        path="/admin/recycables/collected"
        component={RecycablesCollected}
      />
      <Route
        exact
        path="/admin/recycables/archived-batches"
        component={RecycablesArchivedBatchesManager}
      />
      <Route
        path="/admin/recycables/archived-items/:id"
        component={RecycablesArchivedItemsManager}
      />
      <Route path="/admin/recycables/show/:id" component={RecycableManager} />
    </Route>
    {/* <Route exact path="/admin/vouchers" component={ManagerVouchers} /> */}
    <Route exact path="/admin/knowledge-base" component={KnowledgeBasePage} />
    <Route exact path="/admin/profile" component={Profile} />
    <Route exact path="/admin/notifications" component={Notifications} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export const agentRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycables">
      <Route path="/admin/recycables/show/:id" component={RecycableManager} />
    </Route>
    <Route exact path="/admin/vouchers" component={ManagerVouchers} />
    <Route exact path="/admin/knowledge-base" component={KnowledgeBasePage} />
    <Route exact path="/admin/profile" component={Profile} />
    <Route exact path="/admin/notifications" component={Notifications} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export const recyclerRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/recycler">
      <Route
        exact
        path="/admin/recycler/waiting-batches"
        component={WaitingBatchesRecycler}
      />
      <Route
        exact
        path="/admin/recycler/approved-batches"
        component={ApprovedBatchesRecycler}
      />
      <Route
        exact
        path="/admin/recycler/collected-batches"
        component={CollectedBatchesRecycler}
      />
      <Route
        path="/admin/recycler/batch-items/:id"
        component={RecyclerArchivedItems}
      />
      <Route
        path="/admin/recycler/recycable/:id"
        component={RecyclerCollectable}
      />
    </Route>
    <Route exact path="/admin/knowledge-base" component={KnowledgeBasePage} />
    <Route exact path="/admin/profile" component={Profile} />
    <Route exact path="/admin/notifications" component={Notifications} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

export const companyAdminRoutes = () => (
  <Switch>
    <Route exact path="/admin/dashboard" component={DashboardPage} />
    <Route path="/admin/company-branches">
      <Route
        exact
        path="/admin/company-branches"
        component={CompanyAdminBranches}
      />
      <Route
        path="/admin/company-branches/collected/:id"
        component={CompanyAdminCollected}
      />
      <Route
        path="/admin/company-branches/archived-batches/:id"
        component={CompanyAdminArchiveBatches}
      />
      <Route
        path="/admin/company-branches/archived-items/:id"
        component={CompanyAdminArchiveItems}
      />
      <Route
        path="/admin/company-branches/not-collected/:id"
        component={CompanyAdminNotCollected}
      />
      <Route
        path="/admin/company-branches/recycable/:id"
        component={CompanyAdminCollectable}
      />
    </Route>
    <Route path="/admin/users">
      <Route
        exact
        path="/admin/users/branch-users"
        component={CompanyAdminBranchUsers}
      />
      <Route
        exact
        path="/admin/users/company-admin"
        component={CompanyAdminCompanyUsers}
      />
      <Route
        exact
        path="/admin/users/deleted-users"
        component={CompanyAdminDeletedUsers}
      />
    </Route>
    <Route exact path="/admin/reports" component={CompanyAdminReports} />
    <Route exact path="/admin/knowledge-base" component={KnowledgeBasePage} />
    <Route exact path="/admin/profile" component={Profile} />
    <Route exact path="/admin/notifications" component={Notifications} />
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);
