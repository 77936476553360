import React, { useState } from "react";
import { useFormik } from "formik";
import useClasses from "hooks/useClasses";

import { Grid, InputLabel } from "@mui/material";

import styles from "./styles";

import Button from "components/Shared/CustomButtons/Button";

const AddNewCodes = ({ id, addHandler }) => {
  const [file, setFile] = useState("");
  const [fileError, setFileError] = useState(null);
  const formik = useFormik({
    initialValues: {
      voucher_id: id,
      file: "",
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const classes = useClasses(styles);

  const handleSubmit = (values) => {
    const result = { ...values };
    if (file !== "") {
      result.file = file;
      addHandler(result);
    }
  };
  const handleFileChange = (e) => {
    if (e.target.files) {
      if (
        e.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type.includes("csv")
      ) {
        setFile(e.target.files[0]);
        setFileError(null);
      } else {
        setFileError("Error file type");
      }
    } else {
      setFileError("Error file");
    }
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {fileError !== null && (
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "red",
                borderRadius: "15px",
                padding: "5px",
              }}
            >
              <ul>
                {fileError !== null && (
                  <li key="fileError" style={{ color: "#fff" }}>
                    {fileError}
                  </li>
                )}
              </ul>
            </div>
          </Grid>
        )}
        {/* file xls, xlsx, csv */}
        <Grid>
          <InputLabel className={classes.label}>File:</InputLabel>
          <input
            id="file"
            onChange={handleFileChange}
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Button
            color="primary"
            fullWidth
            variant="contained"
            className={classes.submit}
            type="submit"
          >
            Add
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddNewCodes;
