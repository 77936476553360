import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Card from "components/Shared/Card/Card.js";
import CardHeader from "components/Shared/Card/CardHeader/CardHeader.js";
import CardBody from "components/Shared/Card/CardBody/CardBody.js";
import CustomLoading from "components/Shared/CustomLoading/CutomLoading";

import ReportInput from "components/CompanyAdmin/Report/ReportInput";
import VoucherInfo from "components/Manager/Vouchers/VoucherInfo";

import { searchVoucherCode, setVoucherUsed } from "store/actions/managers";
import { getCompanyAllBranches } from "store/actions/companyAdminUsers";
import { companyAdminExportReport } from "store/actions/companyAdmin";

export default function Reports() {
  const { allCompanyBranches, isReporting, reportData } = useSelector(
    (state) => state.companyAdmin
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCompanyAllBranches(history));
  }, [dispatch]);

  const exportReportHandler = (data) => {
    dispatch(companyAdminExportReport(data, history));
  };

  return (
    <div>
      <div>
        <Card>
          <CardHeader color="primary">
            <h4>Company Report</h4>
          </CardHeader>
          <CardBody style={{ minHeight: "300px" }}>
            {allCompanyBranches && (
              <ReportInput
                allBranches={allCompanyBranches}
                exportReport={exportReportHandler}
              />
            )}
            <div>
              {isReporting && <CustomLoading />}
              {/* {reportData && (
                <VoucherInfo
                  reportInfo={reportData}
                  handleDownload={usedVoucherHandler}
                  handle={usedVoucherHandler}
                />
              )} */}
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
